import { Component, OnInit, Input } from '@angular/core';
import { VoucherService } from '../api-client/voucher.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../api-client/user.service';
import { UiService } from '../ui.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { APP_CONST } from '../app-constants';
import { BuildingService } from '../api-client/building.service';
import { config } from '../config';

@Component({
  selector: 'app-related-vouchers',
  templateUrl: './related-vouchers.component.html',
  styleUrls: ['./related-vouchers.component.css']
})
export class RelatedVouchersComponent implements OnInit {

  @Input() linkItemType: any;
  @Input() linkItemId: any;
  @Input() linkLedgerId: any;
  @Input() tagName: any;
  @Input() lastPaidPeriod: any;
  vouchers = [];
  APP_CONST = APP_CONST;
  propertyId:any;
  selectedInvoices = [];
  result:any;
  filterParams:any;
  societyInvoiceType: number = APP_CONST.INVOICE_TYPE_SOCIETY;
  rentInvoiceType: number = APP_CONST.INVOICE_TYPE_RENT;
  purchaseInvoiceType: number = APP_CONST.INVOICE_TYPE_PURCHASE;
  constructor(private _voucherService: VoucherService,
    private _tosterService: ToastrService,
    private _userService: UserService,
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _buildingService: BuildingService) { }

  ngOnInit() {
    this.propertyId = parseInt(this._route.snapshot.paramMap.get('id'));
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    console.log("ledger",this.linkLedgerId);
    this.vouchersData();
  }

  async vouchersData(){
    if(!this.linkItemType && !this.tagName && !this.linkLedgerId) return;
    var data = {communityId: CommonMethods.getCommunityId(),propertyTypeId: this.linkItemType,propertyId:this.linkItemId,voucherType: APP_CONST.INVOICE_TYPE_SOCIETY,accountId:this.linkLedgerId};
    await this._voucherService.filterVoucher(data).toPromise().then((result:any)=>{
      for(var i=0;i<result.data.length;i++){
        var dateTime = result.data[i].voucherDate.split(" ");
        var date = dateTime[0].split("-");
        result.data[i].day = CommonMethods.daySuffix(date[2]);

      }
      this.vouchers = result.data;
      console.log( this.vouchers);
    })
  }

  /* async allLogsbindvendor(){
    if(!this.maintenanceLogs) return;
    this.maintenanceLogItems = this.maintenanceLogs;
    for(var i=0;i<this.maintenanceLogItems.length;i++){
      var dateTime = this.maintenanceLogItems[i].maintenanceDate.split(" ");
      var date = dateTime[0].split("-");
      this.maintenanceLogItems[i].day = CommonMethods.daySuffix(date[2]);
      if(this.maintenanceLogItems[i].vendorId){
       await this._ledgerService.details(this.maintenanceLogItems[i].vendorId).toPromise().then((result:any)=>{
          this.maintenanceLogItems[i].vendor = result.ledgerName;
        })
      }else{
        this.maintenanceLogItems[i].vendor = null;
      }
    }

    console.log(this.maintenanceLogItems);
  } */

  redirectTo(item: any) {
    this._router.navigate([{
      outlets: { leftMainPopup: ["maintenance-log", item.id] }
    }]);
    return;
  }

  allLogs(){
    this._router.navigateByUrl("professional's-visits");
    return;
  }

  voucherDetails(item:any){
    var url = CommonMethods.invoiceType.filter((x:any)=>x.id==item.voucherType);
    if(url.length>0){
      if(url[0].name == 'rent'){
        this._router.navigateByUrl(`society-invoice/${item.id}`);
      }else{
        this._router.navigateByUrl(`${url[0].name}/${item.id}`);
      }
      return;
    }
  }

  ledgerDetails(item:any,arg:string){
    var itemId = 0;
    if(arg=='to'){
      itemId = item.ledgerToId;
    }else{
      itemId = item.ledgerFromId;
    }
    if(!itemId) return;
    this._router.navigateByUrl(`ledger/${itemId}`);
      return;
  }

  addInvoice() {
    this._router.navigate([{ "outlets": { "rightMainPopup": ["billing-charges", { "mode": "InvoiceCreation", "type": APP_CONST.INVOICE_TYPE_SOCIETY ,"propertyId": this.propertyId}] } }]);
  }

    /*---Select invoices---*/
    selectInvoice(elem: any): void {
      var val = parseInt(elem.value);
      if (elem.checked) {
        this.selectedInvoices.push(val);
      } else {
        var index: number = this.selectedInvoices.indexOf(val);
        if (index !== -1) {
          this.selectedInvoices.splice(index, 1);
        }
      }
    }

    acknowledgement(): void {
      if (this.selectedInvoices.length > 0) {
        var selectedLedgerId = 0;
        var error = false;
        var statusError = false;
        var paymentUrl: string = "";
        this.vouchers.filter((x: any) => {
          this.selectedInvoices.forEach(obj => {
            if (obj == x.id) {
              if ( this.societyInvoiceType || this.rentInvoiceType) {
                paymentUrl = "collection";
                if (selectedLedgerId > 0) {
                  if (x.ledgerToId != selectedLedgerId) {
                    error = true;
                  }
                } else {
                  selectedLedgerId = x.ledgerToId;
                }
              } else {
                paymentUrl = "payment";
                if (selectedLedgerId > 0) {
                  if (x.ledgerFromId != selectedLedgerId) {
                    error = true;
                  }
                } else {
                  selectedLedgerId = x.ledgerFromId;
                }
              }
              if (x.status != 'Pending' && x.status != 'Partially Paid') {
                statusError = true;
              }
            }
          })
        });
        if (!error) {
          if (!statusError) {
            this._router.navigate([paymentUrl], { state: { "invoices": this.selectedInvoices } });
          } else {
            this._tosterService.error("To pay you need to select only Pending or Partially Paid invoices.");
          }
        } else {
          this._tosterService.error("To pay multiple invoices, you need to select invoices of same account.");
        }
      } else {
        this._tosterService.error("To pay you need to select atleast of one invoice.");
      }
    }

    downloadInvoice(invoice: any): void {
      var authkey = localStorage.getItem("authKey");
      var udid = localStorage.getItem("UDID");
      var communityId = CommonMethods.getCommunityId();
      var is_duble_invoice = 1;
      window.open(`${config.apiEndPointBase}/voucher/download/${invoice.id}?authkey=${authkey}&udid=${udid}&communityId=${communityId}&is_duble_invoice=${is_duble_invoice}`);
      return;
    }

}
