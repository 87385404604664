import { Component, OnInit } from '@angular/core';
import { AccountGroupService } from '../api-client/account-group.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountGroupModel } from '../Model/accountGroup.model';
import { AccountGroupFormBuilder } from './account-group.form-builder';
import { ToastrService } from 'ngx-toastr';
import { UiService } from '../ui.service';
import { CommonMethods } from '../common-methods';
import { LedgerService } from '../api-client/ledger.service';
import { APP_CONST } from '../app-constants';

@Component({
  selector: 'app-account-group-details',
  templateUrl: './account-group-details.component.html',
  styleUrls: ['./account-group-details.component.css']
})
export class AccountGroupDetailsComponent implements OnInit {

  groupDetails: any;
  accountGroupForm: any;
  submitted = false;
  communityId: any;
  accountGroupId = null;
  accountGroupName: any;
  groupName = '--Select--';
  model = new AccountGroupModel();
  isDefault = 0;
  currentBalance = 0;
  ledgers = [];
  subGroups = [];
  deleteAccountGroupParams: any = {
    itemId: 0,
    itemName: null,
    deleteText: "Are you sure, you want to delete this group?"
  };
  error = [];
  APP_CONST = APP_CONST;
  constructor(private _accountGroupService: AccountGroupService,
    private _formBuilder: AccountGroupFormBuilder,
    private _uiService: UiService,
    private _router: Router,
    private _ledgerService: LedgerService,
    private _tosterService: ToastrService,
    private _route: ActivatedRoute) { }

  ngOnInit() {
    this.leftMenu();
    this.groupDetails = {};
    this.accountGroupForm = this._formBuilder.getForm();
    this._route.params.subscribe(params => {
      if (params.id > 0) {
        this.details(params.id);
        this.subGroupsByGroupId(params.id);
      } else {
        this.isDefault = 0;
        this.leftMenu();
        this.enableAllFields();
        this.resetForm();
      }
    })
  }

  get f() { return this.accountGroupForm.controls; }

  async onSubmit() {
    this.error = [];
    if (this.isDefault) {
      this._tosterService.error("Sorry! You do not have privilege to edit this group.");
      this.error.push("Sorry! You do not have privilege to edit this group.");
      return false;
    }
    this.submitted = true;
    if (this.accountGroupForm.invalid) {
      return;
    }
    this.model.communityId = Number(CommonMethods.getCommunityId());
    this.model.id = this.accountGroupId;
    this.model.isSubLedgerGroup = (this.model.isSubLedgerGroup) ? 1 : 0;
    this.model.isDefault = 0;
    this.model.deserialize(this.accountGroupForm.value);
    //delete this.model.numberOfBuildings;
    await this._accountGroupService.save(this.model)
      .toPromise().then(
        (response: any) => {
          if (response) {
            this._tosterService.success("Account group save");
            if (!this.model.id) {
              this.accountGroupId = response.id;
            }
            this.error = [];
          }else{
            this.error.push("Server error");
          }
        },
        error => {
          this._tosterService.error(error.message);
        }
      )
  }

  async details(id: number) {
    await this._accountGroupService.details(id).toPromise().then((result: any) => {
      if (result.currentBalance) {
        this.currentBalance = result.currentBalance;
      }
      this.isDefault = result.isDefault;
      if (result.isDefault) {
        this._uiService.leftMenuItems.next([]);
        this.disableAllFields();
      } else {
        this.leftMenu();
        this.enableAllFields();
      }
      this.accountGroupId = result.id;
      this.ledgersByGroup();
      this.accountGroupName = result.groupName;
      this.deleteAccountGroupParams.itemId = result.id;
      this.deleteAccountGroupParams.itemName = result.groupName;
      this._formBuilder.setValues(result);
      if (result.parentId > 0) {
        this._accountGroupService.details(result.parentId).toPromise().then((response: any) => {
          this.groupName = response.groupName;
        });
      } else {
        this.groupName = '--Select--';
      }

      //this.groupDetails = result;     
    })
  }

  async save() {
    await this.onSubmit();
    if(this.error.length>0) return;
    setTimeout(() => {
      if (this.accountGroupId > 0) {
        this._router.navigateByUrl(`account-group/${this.accountGroupId}`);
        return;
      }
    }, 1000);
  }

  async saveAndNew() {
    await this.onSubmit();
    if(this.error.length>0) return;
    if (!this.accountGroupForm.invalid) {
      this.resetForm();
    }
  }

  confirmationPopUp() {
    if (this.accountGroupId > 0) {
      this._uiService.deleteItemData = this.deleteAccountGroupParams;
      this._uiService.deleteItemCallBack = this.delete.bind(this);
      this._router.navigate([{
        outlets: { centerPopup: ['delete-item'] }
      }]);
    }
  }

  delete() {
    if (this.deleteAccountGroupParams.pressBtn == "Ok") {
      this._accountGroupService.delete(this.deleteAccountGroupParams.itemId).subscribe((result: any) => {
        this._tosterService.success("Group Deleted");
        this.resetForm();
        return;
      });
    }
  }

  resetForm() {
    this.submitted = false;
    this.accountGroupId = null;
    this.accountGroupName = "";
    this.groupName = "";
    this.error = [];
    this.accountGroupForm.reset();
    setTimeout(() => {
      this._router.navigateByUrl("account-group/0");
    }, 100)
  }

  ngOnDestroy(): void {
    this._uiService.leftMenuItems.next([]);
  }

  redirectionType(item: any, itemName: any, itemValue: any, mode: any, displayType: any) {
    let params: any;
    if (displayType == 'listing') {
      if (mode == "groups") {
        params = [{ outlets: { rightMainPopup: ["account-groups"] } }];
      } else if (mode == "dashboard") {
        this._router.navigateByUrl("/dashboard"); return;
      }
      this._router.navigate(params); return;
    }
  }

  disableAllFields() {
    this.accountGroupForm.disable();
  }
  enableAllFields() {
    this.accountGroupForm.enable();
  }

  leftMenu() {
    this._uiService.leftMenuItems.next([
      { code: 'F4', name: 'Save', action: this.save.bind(this), class: 'green lighten-1', privilege: APP_CONST.ACCOUNTING_SETUP_WRITE },
      { code: 'F5', name: 'Save & Add New', action: this.saveAndNew.bind(this), privilege: APP_CONST.ACCOUNTING_SETUP_WRITE },
      { code: 'F7', name: 'Delete', action: this.confirmationPopUp.bind(this), class: 'red lighten-1', privilege: APP_CONST.ACCOUNTING_SETUP_REMOVE }
    ]);
  }

  ledgersByGroup() {
    if (!this.accountGroupId) return;
    var data = { communityId: CommonMethods.getCommunityId(), accountGroupId: this.accountGroupId };
    this._ledgerService.itemsByGroup(data).subscribe((result: any) => {
      this.ledgers = result;
    })
  }

  subGroupsByGroupId(id: number) {
    this._accountGroupService.subGroupsByGroupId(id).subscribe((result: any) => {
      this.subGroups = result;
    })
  }

  redirectToGroup(item: any) {
    this._router.navigateByUrl("account-group/" + item.id);
  }

  redirectToLedger(item: any) {
    this._router.navigateByUrl("ledger/" + item.id);
  }

}
