import { Component, OnInit } from '@angular/core';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { Router, ActivatedRoute } from '@angular/router';
import { PropertyService } from '../api-client/property.service';
import { UiService } from '../ui.service';
import { CommonMethods } from '../common-methods';
import { APP_CONST } from '../app-constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-todo-filter',
  templateUrl: './todo-filter.component.html',
  styleUrls: ['./todo-filter.component.css']
})
export class TodoFilterComponent implements OnInit {

  filterParams: any = {
    searchData: '',
    linkItemTypeId: null,
    linkItemId: null,
    fromDate: null,
    toDate: null,
    priority: null,
    status: null,
    category: null,
  };
  
  excludeLinkitemTypes = [];
  linkItemTypes = CommonMethods.excludeLinkItemTypes(CommonMethods.linkItemTypes,this.excludeLinkitemTypes);
  itemName= "--Select--";
  listNavigation:any;
  public config = <IDayCalendarConfig>{
    format: "YYYY-MM-DD"
  };
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _propertyService: PropertyService,
    private _tosterService: ToastrService,
    private _uiService: UiService) {
  }  

  ngOnInit() {
    if(!this._uiService.activeFilterData){
      this._uiService.closeLeftPopUp();
      return;
    }
    this.filterParams = this._uiService.activeFilterData;
    if(this.filterParams.linkItemId){
      if(localStorage.getItem("linkItemName")){
        this.itemName = localStorage.getItem("linkItemName");
      }
    }else{
      localStorage.removeItem("linkItemName");
    }
    
    if(this.filterParams.linkItemTypeId){
      this.changeType(this.filterParams.linkItemTypeId);
    }
  }

  applyFilter() {
    if(this.filterParams.fromDate && this.filterParams.toDate){
      var fromDate = new Date(this.filterParams.fromDate);
      var toDate = new Date(this.filterParams.toDate);
      if(fromDate.getTime() > toDate.getTime()){
        this._tosterService.error("From date not greater than to date");
        return;
      }
    }
    this._uiService.activeFilterCallBack();
    if(this.filterParams.linkItemId){
      localStorage.setItem("linkItemName",document.querySelector<HTMLInputElement>("div.linkItemId input[type='text']").value);
    }
    
    this._uiService.closeLeftPopUp();
  }

  changeType(value: any) {
    this.filterParams.linkItemTypeId = 0;
    if (value > 0) {
      this.filterParams.linkItemTypeId = value;
      var listNavigation = this.linkItemTypes.filter((x: any) => x.id == this.filterParams.linkItemTypeId);
      if (listNavigation.length > 0) {
        var type = null;
        if (Object(listNavigation[0].action[1]).type) {
          type = Object(listNavigation[0].action[1]).type;
        }
        this.listNavigation = [listNavigation[0].action[0], { mode: Object(listNavigation[0].action[1]).mode, type: type }];
      }
    }
  }

  clearFilter(){
    this.filterParams.searchData = '';
    this.filterParams.priority = null;
    this.filterParams.linkItemTypeId = 0;
    this.filterParams.linkItemId = 0;
    this.filterParams.fromDate = null;
    this.filterParams.toDate = null;
    this.filterParams.status = null;
    this.filterParams.category = null;

    document.querySelector<HTMLInputElement>("div.linkItemId input[type='text']").value = "";
    localStorage.removeItem("linkItemName");
    
  }


}