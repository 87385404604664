import { LocationService } from './../api-client/location.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CommunityService } from '../api-client/community.service';
import { CommonMethods } from '../common-methods';
import { UiService } from '../ui.service';
import { CommunityModel } from '../Model/community.model';
import { CommunityFormBuilder } from './community.form-builder';
import { ToastrService } from 'ngx-toastr';
import { LedgerService } from '../api-client/ledger.service';
import { APP_CONST } from '../app-constants';
import { IDayCalendarConfig } from 'ng2-date-picker';

@Component({
  selector: 'app-community-details',
  templateUrl: './community-details.component.html',
  styleUrls: ['./community-details.component.css']
})
export class CommunityDetailsComponent implements OnInit {

  communityDetails: any;
  submitted = false;
  model = new CommunityModel();
  communityForm: any;
  countries: any;
  countryId: any;
  selectedCountryName = "Select country";
  selectedStateName = "Select state";
  settingData: any;


  test: boolean = true;
  live: boolean = false;
  id: number;
  autoBillingAt: null;
  billingDueAt: null;
  billingMonth: null;
  cancelReason: null;
  cgstPercentage: null;
  commercialPerSqft: null;
  communityId = CommonMethods.getCommunityId();
  currencyCode = "INR";
  currencyFormat = "#,##,###,####";
  currencySymbol = "₹";
  enableAdvancePayment = 0;
  enablePartPayment = 0;
  forcefullyCancelPaidInvoice: null;
  garagePerSqft: null;
  igstPercentage: null;
  latePaymentPenalty: 0;
  minPaymentAmount: 0;
  paymentApiKey: any;
  paymentApiSecretKey: any;
  paymentChargesPercentage: any;
  paymentGatewayMode = "0";
  paymentLedgerId: null;
  penaltyAmount: null;
  penaltyAppliedAfterDays: 0;
  penaltyType: any;
  prodPaymentApiKey: null;
  prodPaymentApiSecretKey: null;
  prodPaymentChargesPercentage: null;
  prodPaymentLedgerId: null;
  residentialPerSqft: null;
  sgstPercentage: null;
  startAutoBilling: null;
  taxAppliedAbove: null;
  webhookSecretKey: any;

  paymentLedgerName = "--select--";
  prodPaymentLedgerName = "--select--";
  public config = <IDayCalendarConfig>{
    format: "YYYY-MM-DD HH:mm:ss"
  };
  selectedTab = "info";

  constructor(
    private _communityService: CommunityService,
    private _locationService: LocationService,
    private _ledgerService: LedgerService,
    private _uiService: UiService,
    private _formBuilder: CommunityFormBuilder,
    private _router: Router,
    private _tosterService: ToastrService
  ) {
  }

  ngOnInit() {
    this._uiService.leftMenuItems.next([
      { code: 'F4', name: 'Save', action: this.onSubmit.bind(this), class: 'green lighten-1', privilege: APP_CONST.COMMUNITY_WRITE },
      { code: 'F5', name: 'Community Settings', action: this.redirectToCommunitySetting.bind(this), class: 'blue lighten-1', privilege: APP_CONST.COMMUNITY_WRITE }
    ]);

    this.communityDetails = {};
    this.communityForm = this._formBuilder.getForm();
    this.onChanges();
    this.details();
    this.getSettings();
  }

  get f() { return this.communityForm.controls; }

  details() {
    var communityId = Number(CommonMethods.getCommunityId());
    this._communityService.details(communityId).subscribe((result: any) => {
      this.countryId = result.countryId;
      this._formBuilder.setValues(result);
      this._locationService.countryDetails(result.countryId).subscribe((country: any) => {
        this.selectedCountryName = country.countryName;
      });
      this._locationService.stateDetails(result.stateId).subscribe((state: any) => {
        this.selectedStateName = state.stateName;
      });
    })
  }

  onSubmit() {
    //console.log(this.permission); return;
    this.submitted = true;
    if (this.communityForm.invalid) {
      return;
    }
    this.model.deserialize(this.communityForm.value);
    this.model.id = CommonMethods.getCommunityId();
    delete this.model.communityPayable;
    delete this.model.communityReceivable;
    this._communityService.save(this.model)
      .subscribe(
        (response: any) => {
          this._tosterService.success("Community save");
          return false;
        },
        error => {
          this._tosterService.error(error.message);
        }
      )
  }


  onChanges(): void {
    this.communityForm.get('countryId').valueChanges.subscribe((val: any) => {
      this.countryId = val;
    });
  }

  changeMode(arg: any) {
    if (arg) {
      this.live = true;
      this.test = false;
    } else {
      this.live = false;
      this.test = true;
    }
  }

  async getSettings() {
    await this._communityService.getSettings(CommonMethods.getCommunityId()).toPromise().then((result: any) => {
      this.changeMode(result.paymentGatewayMode);
      this.id = result.id;
      this.autoBillingAt = result.autoBillingAt;
      this.billingDueAt = result.billingDueAt;
      this.billingMonth = result.billingMonth;
      this.cancelReason = result.cancelReason;
      this.cgstPercentage = result.cgstPercentage;
      this.commercialPerSqft = result.commercialPerSqft;
      this.communityId = CommonMethods.getCommunityId();
      this.currencyCode = result.currencyCode;
      this.currencyFormat = result.currencyFormat;
      this.currencySymbol = result.currencySymbol;
      this.enableAdvancePayment = result.enableAdvancePayment;
      this.enablePartPayment = result.enablePartPayment;
      this.forcefullyCancelPaidInvoice = result.forcefullyCancelPaidInvoice;
      this.garagePerSqft = result.garagePerSqft;
      this.igstPercentage = result.igstPercentage;
      this.latePaymentPenalty = result.latePaymentPenalty;
      this.minPaymentAmount = result.minPaymentAmount;
      this.paymentApiKey = result.paymentApiKey;
      this.paymentApiSecretKey = result.paymentApiSecretKey;
      this.paymentChargesPercentage = result.paymentChargesPercentage;
      this.paymentGatewayMode = result.paymentGatewayMode;
      //this.paymentLedgerId = result.paymentLedgerId;
      this.penaltyAmount = result.penaltyAmount;
      this.penaltyAppliedAfterDays = result.penaltyAppliedAfterDays;
      this.penaltyType = result.penaltyType;
      this.prodPaymentApiKey = result.prodPaymentApiKey;
      this.prodPaymentApiSecretKey = result.prodPaymentApiSecretKey;
      this.prodPaymentChargesPercentage = result.prodPaymentChargesPercentage;
      //this.prodPaymentLedgerId = result.prodPaymentLedgerId;
      this.residentialPerSqft = result.residentialPerSqft;
      this.sgstPercentage = result.sgstPercentage;
      this.startAutoBilling = result.startAutoBilling;
      this.taxAppliedAbove = result.taxAppliedAbove;
      this.webhookSecretKey = result.webhookSecretKey;

      if (result.paymentLedgerId) {
        this.paymentLedgerId = result.paymentLedgerId;
        this.ledgerDetails(result.paymentLedgerId, 'live');
      }
      if (result.prodPaymentLedgerId) {
        this.prodPaymentLedgerId = result.prodPaymentLedgerId;
        this.ledgerDetails(result.prodPaymentLedgerId, 'test');
      }
    })
  }

  async ledgerDetails(ledgerId: number, mode: any) {
    await this._ledgerService.details(ledgerId).toPromise().then((result: any) => {
      if (mode == 'live') {
        this.paymentLedgerName = result.ledgerName;
      }
      if (mode == 'test') {
        this.prodPaymentLedgerName = result.ledgerName;
      }
    })
  }

  saveSetting() {
    var data = {
      id: this.id,
      autoBillingAt: this.autoBillingAt,
      billingDueAt: this.billingDueAt,
      billingMonth: this.billingMonth,
      cancelReason: this.cancelReason,
      cgstPercentage: this.cgstPercentage,
      commercialPerSqft: this.commercialPerSqft,
      communityId: CommonMethods.getCommunityId(),
      currencyCode: this.currencyCode,
      currencyFormat: this.currencyFormat,
      currencySymbol: this.currencySymbol,
      enableAdvancePayment: (this.enableAdvancePayment) ? 1 : 0,
      enablePartPayment: (this.enablePartPayment) ? 1 : 0,
      forcefullyCancelPaidInvoice: this.forcefullyCancelPaidInvoice,
      garagePerSqft: this.garagePerSqft,
      igstPercentage: this.igstPercentage,
      latePaymentPenalty: this.latePaymentPenalty,
      minPaymentAmount: this.minPaymentAmount,
      paymentApiKey: this.paymentApiKey,
      paymentApiSecretKey: this.paymentApiSecretKey,
      paymentChargesPercentage: this.paymentChargesPercentage,
      paymentGatewayMode: this.paymentGatewayMode,
      paymentLedgerId: this.paymentLedgerId,
      penaltyAmount: this.penaltyAmount,
      penaltyAppliedAfterDays: this.penaltyAppliedAfterDays,
      penaltyType: this.penaltyType,
      prodPaymentApiKey: this.prodPaymentApiKey,
      prodPaymentApiSecretKey: this.prodPaymentApiSecretKey,
      prodPaymentChargesPercentage: this.prodPaymentChargesPercentage,
      prodPaymentLedgerId: this.prodPaymentLedgerId,
      residentialPerSqft: this.residentialPerSqft,
      sgstPercentage: this.sgstPercentage,
      startAutoBilling: this.startAutoBilling,
      taxAppliedAbove: this.taxAppliedAbove,
      webhookSecretKey: this.webhookSecretKey,
    };
    this._communityService.saveSettings(data).subscribe((result: any) => {
      if (result) {
        this._tosterService.success(`${this.selectedTab} save`);
        return;
      }
    })
  }

  changeTab(item: any) {
    this._uiService.leftMenuItems.next([]);
    if (item == "setting") {
      this.selectedTab = "setting";
      this._uiService.leftMenuItems.next([
        { code: 'F4', name: 'Save', action: this.saveSetting.bind(this), class: 'green lighten-1', privilege: APP_CONST.COMMUNITY_WRITE }
      ])
    } else if (item == "gateway") {
      this.selectedTab = "gateway";
      this._uiService.leftMenuItems.next([
        { code: 'F4', name: 'Save', action: this.saveSetting.bind(this), class: 'green lighten-1', privilege: APP_CONST.COMMUNITY_WRITE }
      ])
    } else {
      this.selectedTab = "info";
      this._uiService.leftMenuItems.next([
        { code: 'F4', name: 'Save', action: this.onSubmit.bind(this), class: 'green lighten-1', privilege: APP_CONST.COMMUNITY_WRITE }
      ])
    }
  }

  redirectToCommunitySetting(){
    this._router.navigateByUrl(`community-settings`);
    return;
  }

  ngOnDestroy(): void {
    this._uiService.leftMenuItems.next([]);
  }
}
