import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject,Observable, of  } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../../app/config';
import { ResourceModel } from './../Model/resource.model';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {  

  constructor(private _http: HttpClient) {
  }

  all(data:any){
    return this._http
      .post(config.apiEndPointBase + "/resource/list", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  resourceBycommunity(data:any){
    return this._http
    .post(config.apiEndPointBase + "/resource/list", data, {})
    .pipe(map((response: any) => {
      return response;
    }));
  }

  save(data:ResourceModel): Observable<ResourceModel> {
    if(data.id){
      return this._http.put(config.apiEndPointBase + "/resource",data, {responseType: 'text'})
      .pipe(map((response:any) => {
        return new ResourceModel().deserialize(response);
      }));
    }else{
      return this._http.post(config.apiEndPointBase + "/resource",data, {})
      .pipe(map((response:any) => {
        return new ResourceModel().deserialize(response);
      }));
    }
  }

  details(id:number){
    return this._http.get(config.apiEndPointBase + `/resource/${id}`);
  }

  delete(id:number){
    return this._http.delete(config.apiEndPointBase + `/resource/${id}`,{responseType: 'text'});
  }
  
  getBlockByResourceId(data:any){
    return this._http
      .post(config.apiEndPointBase + "/resource/getBlock", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }
}
