import { Deseriazable } from './deserializable.model';

export class AccountGroupModel implements Deseriazable
{
    id: number;
    groupCode: string;
    groupName: string;
    communityId: number;
    parentId: number;
    isDefault: number;
    isSubLedgerGroup:any;

    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }
}