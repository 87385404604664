import { Component, OnInit } from '@angular/core';
import { CommonMethods } from '../common-methods';
import { UiService } from '../ui.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../api-client/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  mobile: any;
  password: any;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  constructor(
    private _router: Router,
    private _uiService: UiService,
    private _authService: AuthService,
    private _toster:ToastrService,
    private _route: ActivatedRoute) { }

  ngOnInit() {
    this._uiService.isLogin.next(false);
  }

  next(){
    if(this.mobile=="" || this.mobile==null){
      this._toster.error("Mobile or Email cannot be blank");
      return;
    }

    var validateEmailMobile = false;
    if(this.mobile){
      if(CommonMethods.validateMobile(this.mobile)){
        validateEmailMobile = true;
      }else if(CommonMethods.validateEmail(this.mobile)){
        validateEmailMobile = true;
      }else{
        validateEmailMobile = false;
      }
      if(validateEmailMobile==false){
        this._toster.error("Wrong Email or Mobile format.");
        return false;
      }
    }
    
    var data = {mobile:this.mobile};
    this._authService.resetPassword(data).subscribe((result:any)=>{
        this._toster.success("Password sent to your mobile no.");
        return false;
    },error=>{
      this._toster.error(error);
    })
  }

}
