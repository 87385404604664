import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { UiService } from '../ui.service';
import { Subscription } from 'rxjs';
import { CommonMethods } from '../common-methods';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.css']
})
export class LeftMenuComponent implements OnInit, OnDestroy {

  funcKeys = ['F2','F3','F4','F5','F6','F7','F8','F9','F10','F11','F12'];
  leftMenuItems:any = [];
  communityId = Number(CommonMethods.getCommunityId());
  private _itemsChangeSubscriber:Subscription;

  constructor(
    private _router: Router,
    private _uiService: UiService
  ) { 
    this._itemsChangeSubscriber = _uiService.leftMenuItems.subscribe((items)=>{
      this.leftMenuItems = [];
      for(var i=0; i<items.length; i++){
        this.leftMenuItems[items[i].code] = items[i];
      }
    });
  }
  
  ngOnInit() {
    
  }

  getItem(code:any){
    return this.leftMenuItems[code];
  }

  goDashboard(){
    this._router.navigate(['/'], {replaceUrl:true});
  }

  ngOnDestroy(): void {
    this._itemsChangeSubscriber.unsubscribe();
  }
}
