import { Directive, ElementRef, HostListener, Input, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appListNavigator]'
})
export class ListNavigatorDirective implements AfterViewInit {

  @Input('appListNavigator') listParent: HTMLElement;
  @Input() sideBySide: boolean = false;

  activeChildIndex: number = 0;
  listChangeObserver: MutationObserver;
  maxChildrenCount: number = 0;
  listInFocus:boolean = false;

  constructor(private el: ElementRef) {

  }

  ngAfterViewInit(): void {
    const observerConfig = { childList: true, subtree: true };

    this.listChangeObserver = new MutationObserver(this.onListItemChange.bind(this));
    this.listChangeObserver.observe(this.listParent, observerConfig);

    this.el.nativeElement.tabIndex = -1;
    this.el.nativeElement.focus();
  }

  onListItemChange(mutationsList: any, observer: any) {
    this.activeChildIndex = 0;
    if (this.el) {
      this.el.nativeElement.focus();
      this.maxChildrenCount = this.listParent.childElementCount;
    }
    this.activateCurrentListItem();
  }

  activateCurrentListItem() {
    let prevActiveElement = this.listParent.querySelector('.active');
    if (prevActiveElement) {
      prevActiveElement.classList.remove('active');
    }
    let activeElement = this.listParent.children[this.activeChildIndex] as any;
    if (activeElement) {
      activeElement.classList.add('active');

      if (activeElement.scrollIntoViewIfNeeded) {
        activeElement.scrollIntoViewIfNeeded(true);
      } else {
        activeElement.scrollIntoView({ block: 'center' });
      }
    }
    //(activeElement.parentNode as HTMLElement).scrollTop = activeElement.offsetTop;
  }

  @HostListener('focus', ['$event'])
  onFocus(eve: FocusEvent) {
    this.listInFocus = true;
  }

  @HostListener('blur', ['$event'])
  onBlur(eve: FocusEvent) {
    this.listInFocus = false;
  }

  @HostListener('document:keydown', ['$event'])
  onKeyEvent(eve: KeyboardEvent) {

    if(!this.listInFocus) return;

    let prevKeyCode = 38;
    let nextKeyCode = 40;
    if (this.sideBySide) {
      prevKeyCode = 37;
      nextKeyCode = 39;
    }
    
    switch (eve.keyCode) {
      case prevKeyCode://up
        this.activeChildIndex -= this.activeChildIndex > 0 ? 1 : 0;
        break;
      case nextKeyCode://down
        this.activeChildIndex += this.activeChildIndex < this.maxChildrenCount - 1 ? 1 : 0;
        break;
      case 32://space
        let checkElement = this.listParent.querySelector('.active input[type=checkbox]') as HTMLInputElement;
        if(checkElement!=null){
          //checkElement.checked = !checkElement.checked;
          (checkElement as any).click();
        }
        event.preventDefault();
        event.stopPropagation();
        break;
      case 13://enter
        let activeAction = this.listParent.querySelector('.active a');
        if (activeAction) {
          (activeAction as HTMLElement).click();
        }
        break;
    }
    this.activateCurrentListItem();
  }
}
