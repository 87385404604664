import { Component, OnInit } from '@angular/core';
import { UiService } from '../ui.service';
import { CommonMethods } from '../common-methods';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../api-client/auth.service';
import { v1 as uuidv1 } from 'uuid';
import * as introJs from 'intro.js/intro.js';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  introJS = introJs();
  mobile: any;
  password: any;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  showLogin: boolean = true;
  showIntro: boolean = false;
  constructor(
    private _router: Router,
    private _uiService: UiService,
    private _authService: AuthService,
    private _toster: ToastrService,
    private _route: ActivatedRoute) { }

  ngOnInit() {
    this._uiService.isLogin.next(false);
    this.introJS.oncomplete(() => {
      this.showLogin = true;
      this.showIntro = false;
    });
    this.introJS.onexit(() => {
      this.showLogin = true;
      this.showIntro = false;
    });
  }

  next() {
    if (this.mobile == "" || this.mobile == null) {
      this._toster.error("Mobile or Email cannot be blank");
      return;
    }

    if (this.password == "" || this.password == null) {
      this._toster.error("Password cannot be blank");
      return;
    }
    var validateEmailMobile = false;
    if (this.mobile) {
      if (CommonMethods.validateMobile(this.mobile)) {
        validateEmailMobile = true;
      } else if (CommonMethods.validateEmail(this.mobile)) {
        validateEmailMobile = true;
      } else {
        validateEmailMobile = false;
      }
      if (validateEmailMobile == false) {
        this._toster.error("Wrong Email or Mobile format.");
        return false;
      }
    }
    CommonMethods.setItem("UDID", uuidv1());
    /* if(this.mobile && !CommonMethods.validateMobile(this.mobile)){
      this._toster.error("Invalid mobile number");
      return false;
    } */
    var data = { mobile: this.mobile, password: this.password };
    this._authService.auth(data).subscribe((result: any) => {
      if (result.token) {
        this._toster.success("Login Success");
        CommonMethods.setItem("authKey", result.token);
        this._uiService.isLogin.next(true);
        //this._router.navigate(["/dashboard"],{ replaceUrl: true });
        document.location.href = "/dashboard";
        return false;
      }
    }, error => {
      this._toster.error(error);
    })
  }

  intro() {
    setTimeout(() => {
      this.introJS.start();
      setTimeout(() => {
        document.querySelector<HTMLElement>('.introjs-overlay').style.opacity = "0.2";
      }, 100);
    }, 500);
    this.showLogin = false;
    this.showIntro = true;
  }

}
