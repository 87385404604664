import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BlockModel } from './../Model/block.model';
import { AccountGroupModel } from '../Model/accountGroup.model';

@Injectable({
    providedIn: 'root'
})

export class AccountGroupFormBuilder{
    private form;
    private validationRules = {
        groupCode: ['',Validators.required],
        groupName: ['',Validators.required],
        parentId: ['',''],
        isDefault: ['',''],
        isSubLedgerGroup: ['',''],
    }

    constructor(private fb: FormBuilder) {
        this.form = fb.group(this.validationRules);
    }

    getForm() {
        return this.form;
    }

    setValues(response: AccountGroupModel) {
        this.form.patchValue({
            id: response.id,
            communityId: response.communityId,
            groupCode: response.groupCode,
            groupName: response.groupName,
            isDefault: response.isDefault,
            parentId: response.parentId,
            isSubLedgerGroup: response.isSubLedgerGroup,
        });
    }
}