import { Deseriazable } from './deserializable.model';

export class CategoryModel implements Deseriazable
{
    id: number;
    communityId: number;
    name: string;

    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }
}