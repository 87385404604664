import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PropertyService } from '../api-client/property.service';
import { UiService } from '../ui.service';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { CommonMethods } from '../common-methods';
import { APP_CONST } from '../app-constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-log-filter',
  templateUrl: './log-filter.component.html',
  styleUrls: ['./log-filter.component.css']
})
export class LogFilterComponent implements OnInit {

  filterParams: any = {
    searchData:'',
    type : '',
    linkItemTypeId: 0,
    linkItemId: 0,
    fromDate : null,
    toDate : null,
    vendorId: null,
    linkedVoucherId: null,
    category : null,
    jobDoneBy: "1",
    calcelBy: "1",
  };
  vendorName = "--Select--";
  voucherName = "--Select--";
  excludeLinkitemTypes = [APP_CONST.INVOICE_TYPE_SOCIETY,APP_CONST.INVOICE_TYPE_PURCHASE];
  linkItemTypes = CommonMethods.excludeLinkItemTypes(CommonMethods.linkItemTypes,this.excludeLinkitemTypes);
  itemName= "--Select--";
  listNavigation:any;
  public config = <IDayCalendarConfig>{
    format: "YYYY-MM-DD"
  };
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _propertyService: PropertyService,
    private _tosterService: ToastrService,
    private _uiService: UiService) {
  }  

  ngOnInit() {
    if(!this._uiService.activeFilterData){
      this._uiService.closeLeftPopUp();
      return;
    }
    this.filterParams = this._uiService.activeFilterData;
    if(this.filterParams.linkItemId){
      if(localStorage.getItem("linkItemName")){
        this.itemName = localStorage.getItem("linkItemName");
      }
    }else{
      localStorage.removeItem("linkItemName");
    }
    if(this.filterParams.vendorId){
      if(localStorage.getItem("vendorName")){
        this.vendorName = localStorage.getItem("vendorName");
      }else{
        localStorage.removeItem("vendorName");
      }
    }
    if(this.filterParams.linkedVoucherId){
      if(localStorage.getItem("voucherName")){
        this.voucherName = localStorage.getItem("voucherName");
      }else{
        localStorage.removeItem("voucherName");
      }
    }
    if(this.filterParams.linkItemTypeId){
      this.changeType(this.filterParams.linkItemTypeId);
    }
  }

  applyFilter() {
    if(this.filterParams.fromDate && this.filterParams.toDate){
      var fromDate = new Date(this.filterParams.fromDate);
      var toDate = new Date(this.filterParams.toDate);
      if(fromDate.getTime() > toDate.getTime()){
        this._tosterService.error("From date not greater than to date");
        return;
      }
    }
    this._uiService.activeFilterCallBack();
    if(this.filterParams.linkItemId){
      localStorage.setItem("linkItemName",document.querySelector<HTMLInputElement>("div.linkItemId input[type='text']").value);
    }
    if(this.filterParams.vendorId){
      localStorage.setItem("vendorName",document.querySelector<HTMLInputElement>("div.vendorId input[type='text']").value);
    }
    if(this.filterParams.linkedVoucherId){
      localStorage.setItem("voucherName",document.querySelector<HTMLInputElement>("div.linkedVoucherId input[type='text']").value);
    }
    this._uiService.closeLeftPopUp();
  }

  changeType(value: any) {
    this.filterParams.linkItemTypeId = 0;
    if (value > 0) {
      this.filterParams.linkItemTypeId = value;
      var listNavigation = this.linkItemTypes.filter((x: any) => x.id == this.filterParams.linkItemTypeId);
      if (listNavigation.length > 0) {
        var type = null;
        if (Object(listNavigation[0].action[1]).type) {
          type = Object(listNavigation[0].action[1]).type;
        }
        this.listNavigation = [listNavigation[0].action[0], { mode: Object(listNavigation[0].action[1]).mode, type: type }];
      }
    }
  }

  clearFilter(){
    this.filterParams.searchData = '';
    this.filterParams.type = '';
    this.filterParams.linkItemTypeId = 0;
    this.filterParams.linkItemId = 0;
    this.filterParams.fromDate = null;
    this.filterParams.toDate = null;
    this.filterParams.vendorId = null;
    this.filterParams.linkedVoucherId = null;
    this.filterParams.category = null;
    this.filterParams.jobDoneBy = "1";
    this.filterParams.calcelBy = "1";

    document.querySelector<HTMLInputElement>("div.linkItemId input[type='text']").value = "";
    document.querySelector<HTMLInputElement>("div.vendorId input[type='text']").value = "";
    document.querySelector<HTMLInputElement>("div.linkedVoucherId input[type='text']").value = "";

    localStorage.removeItem("linkItemName");
    localStorage.removeItem("vendorName");
    localStorage.removeItem("voucherName");
  }

}
