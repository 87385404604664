import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { ToastrService } from 'ngx-toastr';
import { BlockService } from '../api-client/block.service';
import { VoucherService } from '../api-client/voucher.service';
import { IDayCalendarConfig } from 'ng2-date-picker';

@Component({
  selector: 'app-inter-transfer-report',
  templateUrl: './inter-transfer-report.component.html',
  styleUrls: ['./inter-transfer-report.component.css']
})
export class InterTransferReportComponent implements OnInit {

  communityId: number = parseInt(CommonMethods.getCommunityId());
  months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
  yearArray = [];
  monthArray = [];
  year: any = 0;
  month: any = 0;
  public config = <IDayCalendarConfig>{
    format: "DD-MM-YYYY"
  };

  constructor(private _route: ActivatedRoute,
    private _tosterService: ToastrService,
    private _blockService: BlockService,
    private _voucherService: VoucherService) { }

  ngOnInit() {
    for(let i=2000;i<=2100;i++){
      this.yearArray.push({"name":i,"value":i});
    }
    for(let i=0;i<12;i++){
      this.monthArray.push({"name":this.months[i],"value":i+1});
    }
  }

  /*---Clear all selected data---*/
  clearSelection(): void {
    this.year = 0;
    this.month = 0;
  }

  /*---Export statement---*/
  export(): void {
    if(this.year && this.month){
      let data = {
        communityId: this.communityId,
        year: this.year?this.year:null,
        month: this.month?this.month:null
      };
      this._voucherService.exportInterTransferReport(data);
    }else{
      this._tosterService.error("Please choose year and month to export.");
    }
  }

}
