import { Component, OnInit } from '@angular/core';
import { BuildingService } from '../api-client/building.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../api-client/user.service';
import { UiService } from '../ui.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { CommunityService } from '../api-client/community.service';

@Component({
  selector: 'app-buildings',
  templateUrl: './buildings.component.html',
  styleUrls: ['./buildings.component.css']
})
export class BuildingsComponent implements OnInit {

  buildings: any;
  args: any;
  mode: any;
  routeParams: any;
  routeExtras: NavigationExtras;
  filteredBuildings: any;
  multiSelect: false;
  constructor(private _buildingkService: BuildingService,
    private _tosterService: ToastrService,
    private _userService: UserService,
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _router: Router) {
    this.routeExtras = this._router.getCurrentNavigation().extras;
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this.routeParams = params;
      this.args = {
        "communityId": CommonMethods.getItem("communityId"),
        "communityBlockId": params.blockId
      };
      if (params.mode=="BuildingSelections" && params.multiSelect) {
        this.multiSelect = params.multiSelect;
      }
      this.list(this.args);
    })
  }

  list(data: any) {
    this._buildingkService.all(data).subscribe((result: any) => {
      this.buildings = result;
      this.filteredBuildings = result;
      console.log(result);
    });
    this._route.params.subscribe(data => {
      console.log(data);
      this.mode = data.mode;
    })
  }

  filterBuilding(eve: any) {
    var code = eve.keyCode;
    if (code == 37 || code == 38 || code == 39 || code == 40) {
      return;
    }
    var ele = eve.target;
    var items: any;
    if (ele.value) {
      this.filteredBuildings = this.buildings.filter((x: any) => x.name.toLowerCase().includes(ele.value.toLowerCase()));
    } else {
      //this.list(this.args);
    }
  }

  details(item: any) {
    switch (this.routeParams.mode) {
      case 'PropertySelections':
      case 'RentInvoiceCreation':
        let unitParams = Object.assign({ buildingId: item.id }, this.routeParams);
        this._router.navigate([{
          outlets: { rightMainPopup: ['units', unitParams] }
        }], this.routeExtras);
        break;
      case 'ResourceSelections':
        let resourceParams = Object.assign({ buildingId: item.id }, this.routeParams);
        this._router.navigate([{
          outlets: { rightMainPopup: ['resources', resourceParams] }
        }], this.routeExtras);
        break;
      default:
        if (this._uiService.activeListCallBack) {
          this._uiService.activeListCallBack(item.id, item.name);
          this._uiService.activeListCallBack = null;
          this._uiService.closeItself();
        } else {
          this._router.navigateByUrl("building/" + item.id);
        }
    }
    return false;
  }

  redirectTo(item: any) {
    this._router.navigate([{
      outlets: { rightMainPopup: ['units-resources', item.id] }
    }]);
  }

  redirectToBuilding() {
    this._router.navigate(["building",0], { state: { "blockId": this.args.communityBlockId } });
    setTimeout(()=>{
      this._uiService.closeItself();
    },100);
    return;
  }

  findMatchingItemInSelectedItems(id:any){
    if (this.routeExtras && this.routeExtras.state && this.routeExtras.state.selectedItems) {
      let items = this.routeExtras.state.selectedItems.filter((x:any)=>x.id==id);
      if(items.length>0){
        return items[0];
      }
    }
  }

  itemSelectChange(eve: any, building: any) {
    if (this.routeExtras && this.routeExtras.state && this.routeExtras.state.selectedItems) {
      if (eve.target.checked) {
        this.routeExtras.state.selectedItems.push({id:building.id, name:building.name});
      }else{
        let item = this.findMatchingItemInSelectedItems(building.id);
        if(item){
          let index = this.routeExtras.state.selectedItems.indexOf(item);
          this.routeExtras.state.selectedItems.splice(index, 1);
        }
      }
    }
  }

}
