import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { LedgerService } from '../api-client/ledger.service';
import { VoucherService } from '../api-client/voucher.service';
import { IDayCalendarConfig } from 'ng2-date-picker';

@Component({
  selector: 'app-blockwise-income-report',
  templateUrl: './blockwise-income-report.component.html',
  styleUrls: ['./blockwise-income-report.component.css']
})
export class BlockwiseIncomeReportComponent implements OnInit {

  communityId: number = parseInt(CommonMethods.getCommunityId());
  dateFrom: any;
  dateTo: any;
  accountGroup: string;
  public config = <IDayCalendarConfig>{
    format: "DD-MM-YYYY"
  };

  constructor(private _route: ActivatedRoute,
    private _ledgerService: LedgerService,
    private _voucherService: VoucherService) { }

  ngOnInit() {
  }

  clearDateFrom(): void {
    this.dateFrom = null;
  }

  clearDateTo(): void {
    this.dateTo = null;
  }

  /*---Clear all selected data---*/
  clearSelection(): void {
    this.dateFrom = null;
    this.dateTo = null;
  }

  /*---Export statement---*/
  export(): void {
    let data = {
      communityId: this.communityId,
      fromDate: this.dateFrom?CommonMethods.dbToUsDate(this.dateFrom):null,
      toDate: this.dateTo?CommonMethods.dbToUsDate(this.dateTo):null
    }
    this._voucherService.exportBlockwiseReport(data);
  }

}
