import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject,Observable, of  } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../../app/config';

@Injectable({
    providedIn: 'root'
  })
  export class DashboardService {  
  
    constructor(private _http: HttpClient) {
    }

    details(id:number){
        return this._http.get(config.apiEndPointBase + "/dashboard");
    }

    amountStatistics(id:number){
      return this._http.get(config.apiEndPointBase + `/community/accounts/${id}/amountsStatistics`);
    }

    collectionSummary(data:any){
      let id = data.communityId;
      let mode = data.mode;
      return this._http.get(config.apiEndPointBase + `/community/accounts/${id}/collectionSummary/${mode}`);
    }

    expenseSummary(data:any){
      let id = data.communityId;
      let mode = data.mode;
      return this._http.get(config.apiEndPointBase + `/community/accounts/${id}/expenseSummary/${mode}`);
    }
}