import { Component, OnInit } from '@angular/core';
import { CommonMethods } from '../common-methods';
import { UiService } from '../ui.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TodoService } from '../api-client/todo.service';
import { ToastrService } from 'ngx-toastr';
import { ThreadService } from '../api-client/thread.service';
import { APP_CONST } from '../app-constants';

@Component({
  selector: 'app-all-todo',
  templateUrl: './all-todo.component.html',
  styleUrls: ['./all-todo.component.css']
})
export class AllTodoComponent implements OnInit {

  todoId = null;
  filterParams: any = {
    searchData: '',
    linkItemTypeId: null,
    linkItemId: null,
    fromDate: null,
    toDate: null,
    priority: null,
    status: null,
    category: null,
  };
  filterTodoData:any;
  todosData = [];
  startIndex:number = 0;
  recordPerPage:number = 40;
  totalRecords:number = 0;

  constructor(
    private _uiService: UiService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _todoService: TodoService,
    private _threadService:ThreadService,
    private _tosterService: ToastrService,

  ) { }

  ngOnInit() {
    this.filterTodo();
    this._uiService.leftMenuItems.next([
      { code: 'F3', name: 'Apply Filter', action: this.openFilter.bind(this),privilege: APP_CONST.TODO_READ  },
      { code: 'F4', name: 'Add New', action: this.redirectTo.bind(this),privilege: APP_CONST.TODO_WRITE  },
    ]);

    this._uiService.todoDataReload.subscribe((result:any)=>{
      this.filterTodo();
    })
  }

  async filterTodo() {    
    let data = {
      communityId: CommonMethods.getCommunityId(),
      searchData: this.filterParams.searchData,
      linkItemTypeId: this.filterParams.linkItemTypeId,
      linkItemId: this.filterParams.linkItemId,
      fromDate: this.filterParams.fromDate,
      toDate: this.filterParams.toDate,
      priority: this.filterParams.priority,
      status: this.filterParams.status,
      category: this.filterParams.category,
      index:this.startIndex
    };

    await this._todoService.all(data).toPromise().then((result: any) => {
      this.filterTodoData = result;
      this.bindFilterTodoData();
    });
  }

  async bindFilterTodoData(){
    if(!this.filterTodoData) return;
    this.todoView({id:0});
    this._uiService.todoList = false;
    var result = this.filterTodoData.data;
    for(var i=0;i<result.length;i++){
      if(result[i].category){
        result[i].category = result[i].category.split(",");
      }else{
        result[i].category = [];
      }      
     
      if(result[i].linkItemTypeId){
        var linkItemType = CommonMethods.linkItemTypes.filter((x:any)=>x.id==result[i].linkItemTypeId);
        if(linkItemType.length>0){
          result[i].linkItemType = linkItemType[0].name;
        }
        if(result[i].linkItemTypeId==403 && result[i].linkItemId){
          await this._threadService.details(result[i].linkItemId).toPromise().then((response:any)=>{
            result[i].thread = response;
          })
        }else{
          result[i].thread = null;
        }
      }else{
        result[i].thread = null;
      }
      var priority = CommonMethods.todoPriority.filter((x:any)=>x.id==result[i].priority) ;
      if(priority.length>0){
        result[i].priority = priority[0].name;
      }
      var status = CommonMethods.todoStatus.filter((x:any)=>x.id==result[i].status);
      if(status.length>0){
        result[i].status = status[0].name;
      }
      if(i==0){
        result[i].activeClass = "todoActive";
      }else{
        result[i].activeClass = null;
      }
    }      
    this.todosData = result;
    if (result.length > 0) {
      this._uiService.todoList = true;
      this.todoView(result[0]);
    }
    this.totalRecords = this.filterTodoData.total_records;
  }

  openFilter() {
    this._uiService.activeFilterCallBack = this.filterTodo.bind(this);
    this._uiService.activeFilterData = this.filterParams;
    this._router.navigate([{
      outlets: { leftMainPopup: ['todo-filter'] }
    }]);
  }

  details(item: any) {
    this._router.navigate([{
      outlets: { leftMainPopup: ['todo-details', item.id] }
    }]);
  }

  onPageChange(startIndex:number){
    this.startIndex = startIndex;
    this.filterTodo();
  }

  selectTag(item:any){
    this.filterParams.searchData = item;
    this.startIndex = 0;
    this.filterTodo();
  }

  todoView(item: any) {
    this.todoId = item.id;
    for(var i=0;i<this.todosData.length;i++){
      if(this.todosData[i].id==item.id){
        this.todosData[i].activeClass = "todoActive";
      }else{
        this.todosData[i].activeClass = null;
      }
    }
  }

  redirectTo() {
    this._router.navigate([{
      outlets: { leftMainPopup: ["todo-details", 0] }
    }]);
    return;
  }

  redirectToDashboard() {
    this._router.navigateByUrl("/dashboard"); return;
  }

  ngOnDestroy(): void {
    this._uiService.leftMenuItems.next([]);
  }

}
