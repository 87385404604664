import { Deseriazable } from './deserializable.model';

export class StaffModel implements Deseriazable
{
    id: number;
    communityId: number;
    name: string;
    mobile: string;
    email: string;
    dateOfBirth: string;
    dateOfAnniversary: string;
    unitNumber: string;
    houseNumber: string;
    street: string;
    block: string;
    landmark: string;
    postOffice: string;
    policeStation: string;
    town: string;
    district: string;
    subDistrict: string;
    pinCode: string;
    profession: string;
    stateId: number;
    countryId: number;
    roleId : number;
    photoIdType: string;
    photoIdNumber: string;
    profileCompleteness: number;
    about: string;


    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }
}