import { Component, OnInit,Input} from '@angular/core';
import { UiService } from '../ui.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../api-client/user.service';
import { ToastrService } from 'ngx-toastr';
import { CommonMethods } from '../common-methods';
import { APP_CONST } from '../app-constants';
import { BookingService } from '../api-client/booking.service';

@Component({
  selector: 'app-booking-view',
  templateUrl: './booking-view.component.html',
  styleUrls: ['./booking-view.component.css']
})
export class BookingViewComponent implements OnInit  {

  @Input() bookingId: any;
  cancelBookingParams: any = {
    bookingId: 0,
    deleteText: "Are you sure, you want to delete this booking?"
  }
  constructor(private _uiService: UiService,
    private _toterService: ToastrService,
    private _router: Router,
    private _bookingService: BookingService,
    private _route: ActivatedRoute,
    private _userService: UserService) 
    { }

  ngOnInit() {
  }

  deleteThread() {
    if (this.cancelBookingParams.pressBtn == "Ok") {
      this._bookingService.cancel(this.cancelBookingParams.itemId).subscribe((result: any) => {
        this._toterService.success("Booking Cancelled");
        this._uiService.threadDataReload.next(true);
        return;
      });
    }
  }

}
