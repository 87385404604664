import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-pagination-control',
  templateUrl: './pagination-control.component.html',
  styleUrls: ['./pagination-control.component.css']
})
export class PaginationControlComponent implements OnInit, OnChanges {

  @Input() startIndex: number;
  @Input() pageSize: number;
  @Input() totalRecords: number;

  @Output() onPageChange: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild("pageNumber", {static:false}) pageNumberInput: ElementRef;

  enableGotoPageMode: boolean = false;
  lastFocusIn:EventTarget;

  pages = [];
  currentPage: number;
  numberOfPages: number;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.pages = [];
    this.numberOfPages = Math.ceil(this.totalRecords / this.pageSize);

    if (this.currentPage > this.numberOfPages) {
      this.currentPage = this.numberOfPages;
    }

    for (let i = 1; i <= this.numberOfPages; i++) {
      if (i >= this.currentPage - 2 && i <= this.currentPage + 2) {
        this.pages.push(i);
      }
    }
  }

  ngOnInit() {
    this.currentPage = 1;
  }

  pageChange(page: number) {
    let index = page == 1 ? 0 : (page-1) * this.pageSize;
    this.onPageChange.emit(index);
    this.currentPage = page;
    this.enableGotoPageMode = false;
  }

  capturePageNumber() {
    this.enableGotoPageMode = true;
    setTimeout(()=>{
      this.pageNumberInput.nativeElement.focus();
    }, 100);
  }

  pageNumerEnter(page:number){
    if(page<1 || page>this.numberOfPages){
      this.currentPage = 1;
    }
  }

  numberBoxKeyPress(eve:KeyboardEvent){
    if(eve.keyCode==13){
      eve.stopPropagation();
      eve.preventDefault();
      eve.stopImmediatePropagation();
      eve.cancelBubble=true;
      this.pageChange(this.currentPage);
      return false;
    }
  }

  @HostListener('focus', ['$event'])
  onFocus(eve: FocusEvent) {
    this.lastFocusIn = eve.relatedTarget;
  }

}
