import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  activeListCallBack:any;
  activeFilterCallBack:any;
  activeFilterData:any;
  isLogin = new Subject<boolean>();
  isCommunitySelected = new Subject<boolean>();
  changeAppTitle = new Subject<string>();
  changeBlockDetails = new Subject<string>();
  changeCommunityDetails = new Subject<string>();
  unknownConnectionIssue = new Subject<string>();

  leftMenuItems = new Subject<any>();
  topMenuItems = new Subject<any>();
 
  closeCenterPopup:any;
  useEscToBack = true;

  linkItem:any;
  maintenanceDataReload = new Subject<boolean>();
  deleteItemCallBack:any;
  deleteItemData:any;
  threadDataReload = new Subject<boolean>();
  todoDataReload = new Subject<boolean>();
  increaseRightPanelWidth:any;
  discussionCommentDataReload = new Subject<boolean>();
  memberDataReload = new Subject<boolean>();
  todoList :boolean = false;
  discussionList: boolean = false;

  constructor(
    private _toster: ToastrService,
    private _route: ActivatedRoute,
    private _router: Router) { }


  /* setBlock(item:any) {
    this.changeBlockDetails.next(item);
  } */

  closeItself() {
    this._router.navigate(
      [
        "../",
        {
          outlets: {
            rightMainPopup: null
          }
        }
      ],
      {
        relativeTo: this._route
      }
    );
  }

  closeLeftPopUp() {
    this._router.navigate(
      [
        "../",
        {
          outlets: {
            leftMainPopup: null
          }
        }
      ],
      {
        relativeTo: this._route
      }
    );
  }

  hideCenterPopup() {
    this._router.navigate(
      [
        "../",
        {
          outlets: {
            centerPopup: null
          }
        }
      ],
      {
        relativeTo: this._route
      }
    );
  }

}
