import { Component, OnInit } from '@angular/core';
import { ThreadService } from '../api-client/thread.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../api-client/user.service';
import { UiService } from '../ui.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { APP_CONST } from '../app-constants';

@Component({
  selector: 'app-complains',
  templateUrl: './complains.component.html',
  styleUrls: ['./complains.component.css']
})
export class ComplainsComponent implements OnInit {

  complains: any;
  filteredComplains: any;
  routeParams: any;
  multiSelect: false;

  constructor(
    private _threadService: ThreadService,
    private _tosterService: ToastrService,
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
  }

  ngOnInit() {
    this.list();
  }

  list() {
    var data = { communityId: CommonMethods.getCommunityId(), threadType: APP_CONST.THREAD_TYPE_COMPLAINT };
    this._threadService.all(data).subscribe((result: any) => {
      this.complains = result.data;
      this.filteredComplains = this.complains;
    });
  }

  filterComplain(eve: any) {
    var code = eve.keyCode;
    if (code == 37 || code == 38 || code == 39 || code == 40) {
      return;
    }
    var ele = eve.target;
    var items: any;
    if (ele.value) {
      this.filteredComplains = this.complains.filter((x: any) => x.threadTitle.toLowerCase().includes(ele.value.toLowerCase()));
    } else {
      //this.list();
    }
  }

  details(item: any) {
    if (this._uiService.activeListCallBack) {
      this._uiService.activeListCallBack(item.id, item.threadTitle);
      this._uiService.activeListCallBack = null;
      this._uiService.closeItself();
      return;
    }
    //this._router.navigateByUrl("block/" + item.id);
    return false;
  }

  redirectTo(item: any) {
    this._router.navigateByUrl("block/" + item.id);
  }

}
