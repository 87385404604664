import { Component, OnInit } from '@angular/core';
import { CommonMethods } from '../common-methods';
import { UiService } from '../ui.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LedgerService } from '../api-client/ledger.service';
import { ToastrService } from 'ngx-toastr';
import { CommunityService } from '../api-client/community.service';
import { StaffService } from '../api-client/staff.service';
import { UserService } from '../api-client/user.service';
import { APP_CONST } from '../app-constants';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.css']
})
export class MembersComponent implements OnInit {

  filterParams: any = {
    searchData: '',
    isBlock: 2
  };
  filterMemberData: any;
  memberData = [];
  startIndex: number = 0;
  recordPerPage: number = 40;
  totalRecords: number = 0;
  deleteParams: any = {
    itemId: 0,
    itemName: null,
    deleteText: "Are you sure, you want to block/unblock this member?"
  }

  constructor(
    private _uiService: UiService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _staffService: StaffService,
    private _communityService: CommunityService,
    private _userService: UserService,
    private _ledgerService: LedgerService,
    private _tosterService: ToastrService,

  ) { }

  ngOnInit() {
    this.filterMember();
    this._uiService.leftMenuItems.next([
      { code: 'F5', name: 'Apply Filter', action: this.openFilter.bind(this), privilege: APP_CONST.MEMBER_READ },
      { code: 'F6', name: 'Add New', action: this.addNew.bind(this), privilege: APP_CONST.MEMBER_WRITE },
      { code: 'F8', name: 'Roles & Capability', action: this.redirectToRolesCapability.bind(this), privilege: APP_CONST.MEMBER_WRITE }
    ]);
    this._uiService.memberDataReload.subscribe((result: any) => {
      this.filterMember();
    });
  }


  async filterMember() {
    let data = {
      communityId: CommonMethods.getCommunityId(),
      searchData: this.filterParams.searchData,
      isBlock: this.filterParams.isBlock,
      startIndex: this.startIndex
    };

    await this._userService.members(data).toPromise().then((result: any) => {
      this.filterMemberData = result;
      this.bindFilterMemberData();
    });
  }

  async bindFilterMemberData() {
    if (!this.filterMemberData) return;
    var result = this.filterMemberData.data;
    for (let i = 0; i < result.length; i++) {

    }
    this.memberData = result;
    //console.log(this.memberData);  

    this.totalRecords = this.filterMemberData.total_records;
  }

  openFilter() {
    this._uiService.activeFilterCallBack = this.filterMember.bind(this);
    this._uiService.activeFilterData = this.filterParams;
    this._router.navigate([{
      outlets: { leftMainPopup: ['member-filter'] }
    }]);
  }

  addNew() {
    this._router.navigateByUrl("staff/0");
    return;
  }

  redirectToRolesCapability(){
    this._router.navigateByUrl("capabilities");
    return;
  }

  onPageChange(startIndex: number) {
    this.startIndex = startIndex;
    this.filterMember();
  }

  redirectTo() {
    this._router.navigateByUrl("/dashboard"); return;
  }

  changeRole(item: any) {
    this._router.navigate([{
      outlets: { rightMainPopup: ['change-role', item.id] }
    }],{state: {ledgerId:item.ledgerId,roleId:item.roleId}});
  }

  details(item: any, arg: any) {

    if (arg == 'staff') {
      //console.log(item.id);return;
      this._router.navigateByUrl(`staff/${item.id}`);
      return;
    } else if (arg == 'ledger') {
      //console.log(item.ledgerId);return;
      this._router.navigateByUrl(`ledger/${item.ledgerId}`);
      return;
    }
  }

  changeStatus() {
    if (this.deleteParams.pressBtn == "Ok") {
      var data = { communityId: CommonMethods.getCommunityId(), userId: this.deleteParams.itemId };
      this._communityService.blockUser(data).subscribe((result: any) => {
        this.filterMember();
        this._tosterService.success("Status changed");
        return;
      });
    }
  }

  confirmationPopUp(item: any) {
    if (item.id > 0) {
      this.deleteParams.itemId = item.id;
      this.deleteParams.itemName = item.name;
      this._uiService.deleteItemData = this.deleteParams;
      this._uiService.deleteItemCallBack = this.changeStatus.bind(this);
      this._router.navigate([{
        outlets: { centerPopup: ['delete-item'] }
      }]);
    }
  }

  ngOnDestroy(): void {
    this._uiService.leftMenuItems.next([]);
  }

}
