import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PropertyModel } from './../Model/property.model';
import { CustomValidator } from '../customvalidation';

@Injectable({
    providedIn: 'root'
})

export class unitFormBuilder {
    private form;
    private validationRules = {
        unitNo: ['', Validators.required],
        buildingId: ['', ''],
        communityBlockId: ['',''],
        propertyType: ['', ''],
        floor: ['', ''],
        superBuildArea: ['', CustomValidator.numeric],
        buildUpArea: ['', [Validators.required,CustomValidator.numeric]],
        carpetArea: ['', CustomValidator.numeric],
        category: ['',''],
        description: ['', ''],
        assignTo: ['',''],
        assignMobile: ['',''],
        propertySettings: ['',''],
        customLastPaidPreriod: ['', ''],
    }

    constructor(private fb: FormBuilder) {
        this.form = fb.group(this.validationRules);
    }

    getForm() {
        return this.form;
    }

    setValues(response: PropertyModel) {
        this.form.patchValue({
            id: response.id,
            buildingId: response.buildingId,
            communityBlockId: response.communityBlockId,
            propertyType: response.propertyType,
            ownerId: response.ownerId,
            unitNo: response.unitNo,
            floor: response.floor,
            superBuildArea: response.superBuildArea,
            buildUpArea: response.buildUpArea,
            carpetArea: response.carpetArea,
            description: response.description,
            category: response.category,
            assignTo: response.assignTo,
            assignMobile: response.assignMobile,
            propertySettings: response.propertySettings
        });
    }
}