import { Deseriazable } from './deserializable.model';

export class ThreadModel implements Deseriazable
{
    id: number;
    communityId: string;
    threadTitle: string;
    threadDescription: string;
    threadMode: string;
    threadStatus: string;
    threadType: string;
    assignTo: string;
    assignOn: string;
    category: string;
    closingDate: string; 
    reporterId:string;
    reporterName:string;
    reporterMobile:string;
    linkItemTypeId:string;
    linkItemId:string;
    isComplain:string;
    members:Array<string>;
    linkItemName:string;
    

    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }
}