import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { StaffModel } from '../Model/staff.model';
import { CustomValidator } from '../customvalidation';

@Injectable({
    providedIn: 'root'
})

export class StaffFormBuilder{
    private form;
    private validationRules = {
    name: ['',Validators.required],
    roleId : ['',Validators.required],
    mobile: ['',[Validators.required,CustomValidator.numeric]],
    email: ['',[Validators.required, Validators.email]],
    //dateOfBirth: ['',''],
    //dateOfAnniversary: ['',''],
    unitNumber: ['',Validators.required],
    houseNumber: ['',Validators.required],
    street: ['',Validators.required],
    block: ['',''],
    landmark: ['',''],
    postOffice: ['',Validators.required],
    policeStation: ['',Validators.required],
    town: ['',Validators.required],
    district: ['',Validators.required],
    subDistrict: ['',''],
    pinCode: ['',Validators.required],
    profession: ['',Validators.required],
    stateId: ['',Validators.required],
    countryId: ['',Validators.required],
    photoIdType: ['',''],
    photoIdNumber: ['',''],
    profileCompleteness: ['',''],
    about: ['','']
    }

    constructor(private fb: FormBuilder) {
        this.form = fb.group(this.validationRules);
    }

    getForm() {
        return this.form;
    }

    setValues(response: StaffModel) {
        this.form.patchValue({
            id: response.id,
            communityId: response.communityId,
            roleId : response.roleId ,
            name: response.name,
            mobile: response.mobile,
            email: response.email,
            //dateOfBirth: response.dateOfBirth,
            //dateOfAnniversary: response.dateOfAnniversary,
            unitNumber: response.unitNumber,
            houseNumber: response.houseNumber,
            street: response.street,
            block: response.block,
            landmark: response.landmark,
            postOffice: response.postOffice,
            policeStation: response.policeStation,
            town: response.town,
            district: response.district,
            subDistrict: response.subDistrict,
            pinCode: response.pinCode,
            profession: response.profession,
            stateId: response.stateId,
            countryId: response.countryId,
            photoIdType: response.photoIdType,
            photoIdNumber: response.photoIdNumber,
            proprofileCompletenessfession: response.profileCompleteness,
            about: response.about
        });
    }
}