import { Deseriazable } from './deserializable.model';

export class MaintenanceLogModel implements Deseriazable
{
    id: number;
    communityId: number;
    type: string;
    visitorName: string;
    isJobDone: number;
    visitorDesignation: string;
    visitorPhoneNumber: string;
    verifierName: string;
    verifierDesignation: string;
    verifierPhoneNumber: string;
    maintenanceDate: string;
    linkItemTypeId: string;
    linkItemId: string;
    linkItemName: string;
    linkedVoucherId: string;
    remarks: string;
    category: string;
    nextActionRequired: string;
    nextActionDate: string;
    vendorId: string;
    contractId: string;

    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }
}