import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {ThreadModel} from './../Model/thread.model';

@Injectable({
    providedIn: 'root'
})

export class DiscussionFormBuilder {
    private form;
    private validationRules = {
        threadTitle: ['',Validators.required],
        threadDescription: ['',Validators.required],
        threadMode: ['',Validators.required],
        threadStatus: ['',Validators.required],
        threadType: ['',''],
        assignTo: ['',''],
        assignOn: ['',''],
        category: ['',''],
        closingDate: ['',''],
        reporterId: ['',''],
        reporterName: ['',''], 
        reporterMobile: ['',''],
        linkItemTypeId: ['',''],
        linkItemId: ['',''],
        linkItemName: ['',''],
        isComplain: ['',''], 
        members: ['','']
    }

    constructor(private fb: FormBuilder) {
        this.form = fb.group(this.validationRules);
    }

    getForm() {
        return this.form;
    }

    setValues(response: ThreadModel) {
        this.form.patchValue({
            id: response.id,
            threadTitle: response.threadTitle,
            threadDescription: response.threadDescription,
            threadMode: response.threadMode,
            threadStatus: response.threadStatus,
            threadType: response.threadType,
            assignTo: response.assignTo,
            assignOn: response.assignOn,
            category: response.category,
            closingDate: response.closingDate,
            reporterId: response.reporterId,
            reporterName: response.reporterName,
            reporterMobile: response.reporterMobile,
            linkItemTypeId: response.linkItemTypeId,
            linkItemId: response.linkItemId,
            isComplain: response.isComplain,
            members: response.members,
            linkItemName: response.linkItemName
        });
    }
}