import { Component, OnInit } from '@angular/core';
import { CommunityService } from '../api-client/community.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { StaffFormBuilder } from '../staff-details/staff.form-builder';
import { UiService } from '../ui.service';
import { LocationService } from '../api-client/location.service';
import { StaffService } from '../api-client/staff.service';
import { CommonMethods } from '../common-methods';
import { UserService } from '../api-client/user.service';

@Component({
  selector: 'app-change-role',
  templateUrl: './change-role.component.html',
  styleUrls: ['./change-role.component.css']
})
export class ChangeRoleComponent implements OnInit {

  userId: number;
  role_id: number;
  roleList = [];
  ledgerId = null;
  existingRole = null;
  deleteParams: any = {
    itemId: 0,
    itemName: null,
    deleteText: "Are you sure, you want to change role?"
  }
  routeExtras: NavigationExtras;
  constructor(
    private _tosterService: ToastrService,
    private _router: Router,
    private _userService: UserService,
    private _communityService: CommunityService,
    private _route: ActivatedRoute,
    private _formBuilder: StaffFormBuilder,
    private _uiService: UiService,
    private _locationService: LocationService,
    private _staffService: StaffService
  ) {
    this.routeExtras = this._router.getCurrentNavigation().extras;
  }

  ngOnInit() {
    this.roles();
    this._route.params.subscribe(params => {
      if (params.id > 0) {
        this.userId = params.id;
      }
    });
    this.changeState();
  }

  changeRole() {
    if (this.deleteParams.pressBtn == "Ok") {
      if (!this.role_id) {
        this._tosterService.error("Role can not be blank");
        return;
      };
      var data = { communityId: CommonMethods.getCommunityId(), userId: this.userId, roleId: this.role_id };
      //console.log(data); return;
      this._communityService.changeRole(data).subscribe((result: any) => {
        this._uiService.memberDataReload.next(true);
        this._tosterService.success("Role changed");
        this._uiService.closeItself();
        return;
      });
    }
  }

  async roles() {
    var communityId = Number(CommonMethods.getCommunityId());
    await this._communityService.roles(communityId).toPromise().then((result: any) => {
      /* var list = [];
      for (var i = 0; i < result.length; i++) {
        if (result[i].roleId < 60) {
          list.push(result[i]);
        }
      } */
      this.roleList = result;
    });
  }

  confirmationPopUp() {
    if (!this.role_id) {
      this._tosterService.error("Role can not be blank");
      return;
    }
    if (this.role_id == this.existingRole) {
      this._tosterService.error("Previous role & current role both are same");
      return;
    }
    if (this.ledgerId) {
      if (this.role_id < 60) {
        this._tosterService.error("Sorry you can not assign this role");
        return;
      }
    } else {
      if (this.role_id > 50) {
        this._tosterService.error("Sorry you can not assign this role");
        return;
      }
    }
    if (this.role_id > 0) {
      this.deleteParams.itemName = "Change Role";
      this._uiService.deleteItemData = this.deleteParams;
      this._uiService.deleteItemCallBack = this.changeRole.bind(this);
      this._router.navigate([{
        outlets: { centerPopup: ['delete-item'] }
      }]);
    }
  }

  changeState() {
    if (!this.routeExtras.state) {
      this._router.navigateByUrl(`members`);
      return;
    }
    if (this.routeExtras && this.routeExtras.state) {
      if (this.routeExtras.state.ledgerId) {
        this.ledgerId = this.routeExtras.state.ledgerId;
      }
      if (this.routeExtras.state.roleId) {
        this.existingRole = this.routeExtras.state.roleId;
      }
    }
  }

}
