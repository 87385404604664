import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject,Observable, of  } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../../app/config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  

  constructor(private _http: HttpClient) {
  }

  auth(data:any)
  {
    return this._http
      .post(config.apiEndPointBase+"/web_login_process", data, {})
      .pipe(map((response: any) => {        
        return response;
      }));
  }

  logout(){
    return this._http
      .post(config.apiEndPointBase+"/logout", {}, {})
      .pipe(map((response: any) => {        
        return response;
      }));
  }
  
  resetPassword(data:any){
    return this._http
      .post(config.apiEndPointBase+"/reset_password", data, {responseType: 'text'})
      .pipe(map((response: any) => {        
        return response;
      }));
  }
}
