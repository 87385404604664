import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { UiService } from '../ui.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ThreadService } from '../api-client/thread.service';
import { ToastrService } from 'ngx-toastr';
import { CommonMethods } from '../common-methods';
import { APP_CONST } from '../app-constants';
import { UserService } from '../api-client/user.service';

@Component({
  selector: 'app-discussion-view',
  templateUrl: './discussion-view.component.html',
  styleUrls: ['./discussion-view.component.css']
})
export class DiscussionViewComponent implements OnInit, OnChanges {

  @Input() discussionId: any;
  comment: any;
  linkItemType: any;
  linkItemId: any;
  linkItemName: any;
  linkItemTypeId: any;
  relatedItemName: any;
  reporterName: any;
  reporterMobileNo: any;
  deleteCommentParams: any = {
    itemId: 0,
    itemName: null,
    deleteText: "Are you sure, you want to delete this comment/media?"
  };
  deleteThreadParams: any = {
    itemId: 0,
    itemName: null,
    deleteText: "Are you sure, you want to delete this thread?"
  }
  commentId = null;
  discussionDetails: any;
  attachment: any;
  commentMedia: any;
  mediaName: any;
  tags = [];
  config = CommonMethods.ckEditorConfig;
  writePrivileges = null;
  readPrivileges = null;
  deletePrivileges = null;
  writeCommentPrivileges = null;
  readCommentPrivileges = null;
  deleteCommentPrivileges = null;
  constructor(
    private _uiService: UiService,
    private _toterService: ToastrService,
    private _router: Router,
    private _threadService: ThreadService,
    private _route: ActivatedRoute,
    private _userService: UserService
  ) { }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    console.log(123,this._uiService.discussionList);
    if (!this._uiService.discussionList) {
      this.discussionDetails = null;
    }
    this.tags = [];
    if (!this.discussionId) return;
    this.details(this.discussionId);
  }

  ngOnInit() {
    this.linkItemType = "";
    this.reporterName = "";
    this.reporterMobileNo = "";
    this.tags = [];
    this._uiService.discussionCommentDataReload.subscribe((result: any) => {
      this.details(this.discussionId);
    })
  }

  details(id: number) {
    this._threadService.details(id).subscribe((result: any) => {
      this.discussionPrivileges(result.threadType);
      this.linkItemType = result.threadType;
      this.linkItemId = id;
      this.linkItemName = result.threadTitle;
      this.linkItemTypeId = result.linkItemTypeId;
      this._uiService.linkItem = { id: id, type: APP_CONST.THREAD_TYPE_COMPLAINT, name: result.threadTitle };
      if (result.reporterId) {
        this.getUser(result.reporterId);
      } else if (result.reporterName && result.reporterMobile) {
        this.reporterName = result.reporterName;
        this.reporterMobileNo = result.reporterMobile;
      }
      this.relatedItemName = "";
      var linkItem = CommonMethods.linkItemTypes.filter((x: any) => x.id == result.linkItemTypeId);
      if (linkItem.length > 0) {
        this.relatedItemName = linkItem[0].name;
      }
      /* for (var i = 0; i < result.threadComments.length; i++) {
        if (result.threadComments[i].commentMedia) {
          result.threadComments[i].mediaName = result.threadComments[i].commentMedia.split('/').pop();
        } else {
          result.threadComments[i].mediaName = null;
        }
      } */
      this.discussionDetails = result;
      if (result.category) {
        this.tags = result.category.split(",");
      }
    })
  }
  async getUser(id: number) {
    await this._userService.details(id).toPromise().then((result: any) => {
      this.reporterName = result.name;
    })
  }

  saveComment() {
    if ((this.comment == "" || this.comment == null) && (this.attachment == null)) {
      this._toterService.error("Add comment or attachment.");
      return;
    }
    if (this.comment) {
      var data = { id: this.commentId, communityId: CommonMethods.getCommunityId(), threadId: this.discussionId, comment: this.comment };
      this._threadService.saveComment(data).subscribe((result: any) => {
        this._toterService.success("Comment Added");
        this.comment = "";
        this._uiService.threadDataReload.next(true);
        this.details(this.discussionId);
      });
    }
    if (this.attachment && this.attachment.base64) {
      this.addMediaComment();
    }
  }

  onFileChange(event: any) {
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      //console.log(file.name.split('.').pop());
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.attachment = { fileName: file.name, fileType: file.name.split('.').pop().toLowerCase(), base64: event.target.result.split(',').pop() };
          console.log(this.attachment);
        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  editComment(item: any) {
    this.commentId = item.id;
    this.comment = item.comment;
    this.commentMedia = item.commentMedia;
  }
  addMediaComment() {
    var data = { communityId: CommonMethods.getCommunityId(), threadId: this.discussionId, commentMedia: this.attachment.base64, type: this.attachment.fileType, mediaName: this.attachment.fileName };
    this._threadService.saveCommentMedia(data).subscribe((result: any) => {
      this._toterService.success("Attachment Added");
      this.attachment = {};
      this._uiService.threadDataReload.next(true);
      this.details(this.discussionId);
      return;
    })
  }

  deleteThread() {
    //console.log(this.deleteParams.itemId);
    if (this.deleteThreadParams.pressBtn == "Ok") {
      this._threadService.delete(this.deleteThreadParams.itemId).subscribe((result: any) => {
        this._toterService.success("Thread Deleted");
        this._uiService.threadDataReload.next(true);
        return;
      });
    }
  }

  deleteThreadPopUp(item: any) {
    this.deleteThreadParams.itemId = item.id;
    this.deleteThreadParams.itemName = item.threadTitle;
    this._uiService.deleteItemData = this.deleteThreadParams;
    this._uiService.deleteItemCallBack = this.deleteThread.bind(this);
    this._router.navigate([{
      outlets: { centerPopup: ['delete-item'] }
    }]);
  }

  deleteComment() {
    //console.log(this.deleteParams.itemId);
    if (this.deleteCommentParams.pressBtn == "Ok") {
      this._threadService.deleteComment(this.deleteCommentParams.itemId).subscribe((result: any) => {
        this._toterService.success("Comment Deleted");
        this.commentId = null;
        this.comment = null;
        this.commentMedia = null;
        this._uiService.threadDataReload.next(true);
        this.details(this.discussionId);
        return;
      });
    }
  }

  deleteCommentPopup(item: any) {
    var message = "";
    if (item.comment) {
      if (item.comment.length > 60) {
        message = item.comment.slice(0, 60) + "....";
      } else {
        message = item.comment;
      }
    } else if (item.mediaName) {
      message = item.mediaName;
    }
    this.deleteCommentParams.itemId = item.id;
    this.deleteCommentParams.itemName = message;
    this._uiService.deleteItemData = this.deleteCommentParams;
    this._uiService.deleteItemCallBack = this.deleteComment.bind(this);
    this._router.navigate([{
      outlets: { centerPopup: ['delete-item'] }
    }]);
  }

  redirectTo(item: any) {
    this._router.navigate([{
      outlets: { leftMainPopup: ["discussion-details", item.id] }
    }], { state: { "threadType": item.threadType } });
    return;
  }

  todo(item: any) {
    this._router.navigate([{
      outlets: { leftMainPopup: ["todo-details", 0] }
    }], { state: { id: item.id, name: item.threadTitle, type: APP_CONST.THREAD_TYPE_COMPLAINT } });
    return;
  }

  discussionPrivileges(type: number) {
    if (type == APP_CONST.THREAD_TYPE_DISCUSSION) {
      this.writePrivileges = APP_CONST.DISCUSSION_WRITE;
      this.readPrivileges = APP_CONST.DISCUSSION_WRITE;
      this.deletePrivileges = APP_CONST.DISCUSSION_REMOVE;
    } else if (type == APP_CONST.THREAD_TYPE_COMPLAINT) {
      this.writePrivileges = APP_CONST.COMPLAINT_WRITE;
      this.readPrivileges = APP_CONST.COMPLAINT_READ;
      this.deletePrivileges = APP_CONST.COMPLAINT_REMOVE;
    } else if (type == APP_CONST.THREAD_TYPE_NOTICE) {
      this.writePrivileges = APP_CONST.NOTICE_WRITE;
      this.readPrivileges = APP_CONST.NOTICE_READ;
      this.deletePrivileges = APP_CONST.NOTICE_REMOVE;
    } else if (type == APP_CONST.THREAD_TYPE_RULES) {
      this.writePrivileges = APP_CONST.GUIDELINE_WRITE;
      this.readPrivileges = APP_CONST.GUIDELINE_READ;
      this.deletePrivileges = APP_CONST.GUIDELINE_REMOVE;
    }
    this.readCommentPrivileges = APP_CONST.THREAD_COMMENT_READ;
    this.writeCommentPrivileges = APP_CONST.THREAD_COMMENT_WRITE;
    this.deleteCommentPrivileges = APP_CONST.THREAD_COMMENT_REMOVE;
  }

}
