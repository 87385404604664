import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { APP_CONST } from './app-constants';

export class CommonMethods {

    public static allcapabilities = [];

    public static getItem(item: any) {
        return localStorage.getItem(item);
    }

    public static getCommunityId() {
        return localStorage.getItem("communityId");
    }

    public static setCommunityId(itemValue: any) {
        return localStorage.setItem("communityId", itemValue);
    }

    public static getCommunityDetails() {
        return JSON.parse(localStorage.getItem("communityDetails"));
    }

    public static setCommunityDetails(itemValue: any) {
        return localStorage.setItem("communityDetails", JSON.stringify(itemValue));
    }

    public static setCapability(itemValue: any) {
        return localStorage.setItem("capability", JSON.stringify(itemValue));
    }

    public static getCapability() {
        return JSON.parse(localStorage.getItem("capability"));
    }

    public static setProfile(itemValue: any) {
        return localStorage.setItem("profile", JSON.stringify(itemValue));
    }

    public static getProfile() {
        return JSON.parse(localStorage.getItem("profile"));
    }

    public static setItem(itemName: any, itemValue: any) {
        localStorage.setItem(itemName, itemValue);
    }

    public static removeItem(item: any) {
        localStorage.removeItem(item);
    }

    public static copyObject(destination: any, source: any) {
        for (let key in source) {
            destination[key] = source[key];
        }
    }

    public static validateEmail(inputText: any) {
        var mailformat = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
        if (mailformat.test(inputText)) {
            return true;
        } else {
            return false;
        }
    }

    public static validateMobile(number: any) {
        var phoneno = /^\d{10}$/;
        if (phoneno.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }

    public static getHeaderToken() {
        var headerValues = CommonMethods.getHeaderTokenRaw();

        var httpOptions = {
            headers: new HttpHeaders(headerValues)
        };

        return httpOptions;
    }

    public static getHeaderTokenRaw() {
        var headerValues = {};
        headerValues['Content-Type'] = 'application/json';

        var authKey = localStorage.getItem("authKey");
        var UDID = localStorage.getItem("UDID");

        if (authKey) {
            headerValues['authKey'] = authKey;
        }
        if (UDID) {
            headerValues['UDID'] = UDID;
        }

        return headerValues;
    }

    public static capabilities() {
        return [
            { key: "ViewEmployeeRoles", code: "2000", description: "" },
            { key: "ViewPapers", code: "10000", description: "" },
            { key: "AddPapers", code: "10001", description: "" },
            { key: "UpadtePapers", code: "10002", description: "" },
            { key: "RemovePapers", code: "10003", description: "" },
            { key: "ViewPapersOfOwnDiscipline", code: "10010", description: "" },
            { key: "AddPapersOfOwnDiscipline", code: "10011", description: "" },
            { key: "UpadatePapersOfOwnDiscipline", code: "10012", description: "" },
            { key: "RemovePapersOfOwnDiscipline", code: "10013", description: "" },
            { key: "ViewStudents", code: "20000", description: "" },
            { key: "AddStudents", code: "20001", description: "" },
            { key: "UpadateStudents", code: "20002", description: "" },
            { key: "RemoveStudents", code: "20003", description: "" },
            { key: "ViewStudentsOfOwnDiscipline", code: "20010", description: "" },
            { key: "AddStudentsOfOwnDiscipline", code: "20011", description: "" },
            { key: "UpadateStudentsOfOwnDiscipline", code: "20012", description: "" },
            { key: "RemoveStudentsOfOwnDiscipline", code: "20013", description: "" },
        ]
    }

    public static capabilityKeyToCode(arg: any) {
        for (var i = 0; i < this.capabilities().length; i++) {
            if (this.capabilities()[i].key == arg) {
                return this.capabilities()[i].code;
            }
        }
    }

    public static exportcsvHeader(key: any) {
        var title = key;
        title = title.replace(/([A-Z])/g, " $1");
        title = title.charAt(0).toUpperCase() + title.slice(1);
        return title;
    }

    public static usDateToDb(date: any) {
        if (date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [day, month, year].join('-');
        } else {
            return null;
        }
    }

    public static dbToUsDate(date: any) {
        var exp = date.split("-");
        var year = exp[2];
        var month = exp[1];
        var day = exp[0];
        var convertDate = [year, month, day].join('-');
        return convertDate;
    }

    public static dbToUsMonthDate(date: any, j: number) {
        var exp = date.split("-");
        var year = exp[2];
        var month = exp[1];
        var month: any = +month + +j;
        if (month > 12) {
            var year: any = +year + + 1;
            var year: any = Number(year);
            var month: any = +month + -12;
            // var month: any = '0' + month;
        }
        var day = exp[0];
        var convertDate = [year, month, day].join('-');
        return convertDate;
    }

    public static dbToUsMonthEndDate(date: any, j: number) {
        var exp = date.split("-");
        var year = exp[2];
        var month = exp[1];
        var month: any = +month + +j;
        if (month > 12) {
            var year: any = +year + + 1;
            var year: any = Number(year);
            var month: any = +month + -12;
            // var month: any = '0' + month;
        }
        var day = exp[0];
        // console.log('month',month);
        if (month == 2) {
            var day: any = +day + +27;
        } else if (month == 1 || month == 3 || month == 5 || month == 7 || month == 8 || month === 12 || month === 10) {
            var day: any = +day + +30;
        } else {
            var day: any = +day + +29;
        }
        var convertDate = [year, month, day].join('-');
        return convertDate;
    }

    public static createMonthlyDueData(date: any) {
        var exp = date.split("-");
        var year = exp[0];
        var month = exp[1];  
        var month: any = +month + + 1;
        if (month > 12) {
            var year: any = +year + + 1;
            var year: any = Number(year);
            var month: any = +month + -12;
        }
        if(month < 10){
            var month: any = '0' + month;
        }
        var day = exp[2];
        var day: any = +day + +14;
        var convertDueDate = [year, month, day].join('-');
        return convertDueDate;
    }

    public static numeric(control: any) {
        let val = control.value;

        if (val === null || val === '') return null;

        if (!val.toString().match(/^[0-9]+(\.?[0-9]+)?$/)) return { 'invalidNumber': true };

        return null;
    }

    public static monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    public static dateToIndianFormat(date: any) {
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var exp = date.split("-");
        var day = exp[2];
        var month = exp[1];
        var year = exp[0];
        var convertDate = day.substr(0, 2) + ' ' + months[month - 1] + ', ' + year;
        return convertDate;
    }

    public static voucherType = ["", "maintenance", "rent", "purchase", "money", "credit", "debit", "transfer", "payment"];
    public static linkItemTypes = [
        { id: APP_CONST.PROPERTY_TYPE_BLOCKS, name: 'Block', action: ["blocks", { "mode": "BlockSelections", "multiSelect": true }] },
        { id: APP_CONST.PROPERTY_TYPE_BUILDINGS, name: 'Building', action: ["blocks", { "mode": "BuildingSelections", "multiSelect": true }] },

        { id: APP_CONST.PROPERTY_TYPE_FLAT, name: 'Flat', action: ["blocks", { "mode": "PropertySelections", "type": APP_CONST.PROPERTY_TYPE_FLAT, "multiSelect": true }] },
        { id: APP_CONST.PROPERTY_TYPE_OFFICES, name: 'Offices', action: ["blocks", { "mode": "PropertySelections", "type": APP_CONST.PROPERTY_TYPE_OFFICES, "multiSelect": true }] },
        { id: APP_CONST.PROPERTY_TYPE_SHOPS, name: 'Shops', action: ["blocks", { "mode": "PropertySelections", "type": APP_CONST.PROPERTY_TYPE_SHOPS, "multiSelect": true }] },
        { id: APP_CONST.PROPERTY_TYPE_PARKING_SPACES, name: 'Parking', action: ["blocks", { "mode": "PropertySelections", "type": APP_CONST.PROPERTY_TYPE_PARKING_SPACES, "multiSelect": true }] },
        { id: APP_CONST.PROPERTY_TYPE_GARAGES_TWO_WHEELER, name: 'Garage 2 wheeler', action: ["blocks", { "mode": "PropertySelections", "type": APP_CONST.PROPERTY_TYPE_GARAGES_TWO_WHEELER, "multiSelect": true }] },
        { id: APP_CONST.PROPERTY_TYPE_GARAGES_FOUR_WHEELER, name: 'Garage 4 wheeler', action: ["blocks", { "mode": "PropertySelections", "type": APP_CONST.PROPERTY_TYPE_GARAGES_FOUR_WHEELER, "multiSelect": true }] },
        { id: APP_CONST.PROPERTY_TYPE_AMENITY, name: 'Amenity', action: ["blocks", { "mode": "PropertySelections", "type": APP_CONST.PROPERTY_TYPE_AMENITY, "multiSelect": true }] },
        { id: APP_CONST.PROPERTY_TYPE_QUARTER, name: 'Quarter', action: ["blocks", { "mode": "PropertySelections", "type": APP_CONST.PROPERTY_TYPE_QUARTER, "multiSelect": true }] },
        { id: APP_CONST.PROPERTY_TYPE_PARKING_SPACES_TWO_WHEELER, name: 'Parking Space 2 wheeler', action: ["blocks", { "mode": "PropertySelections", "type": APP_CONST.PROPERTY_TYPE_PARKING_SPACES_TWO_WHEELER, "multiSelect": true }] },
        { id: APP_CONST.PROPERTY_TYPE_PARKING_SPACES_FOUR_WHEELER, name: 'Parking Space 4 wheeler', action: ["blocks", { "mode": "PropertySelections", "type": APP_CONST.PROPERTY_TYPE_PARKING_SPACES_FOUR_WHEELER, "multiSelect": true }] },

        { id: APP_CONST.PROPERTY_TYPE_ASSETS, name: 'Resource', action: ["blocks", { "mode": "ResourceSelections", "multiSelect": true }] },

        { id: APP_CONST.INVOICE_TYPE_SOCIETY, name: 'Socity Invoice ', action: ["vouchers", { "mode": "", "type": APP_CONST.INVOICE_TYPE_SOCIETY, "multiSelect": true }] },
        { id: APP_CONST.INVOICE_TYPE_PURCHASE, name: 'Purchase Invoice', action: ["vouchers", { "mode": "", "type": APP_CONST.INVOICE_TYPE_PURCHASE, "multiSelect": true }] },
        { id: APP_CONST.THREAD_TYPE_COMPLAINT, name: 'Thread Complain', action: ["complains", { "mode": "", "type": APP_CONST.THREAD_TYPE_COMPLAINT, "multiSelect": true }] },
        { id: APP_CONST.TODO, name: 'Todo', action: ["todo-list", { "mode": "", "type": APP_CONST.TODO, "multiSelect": true }] }
    ];

    public static excludeLinkItemTypes(linkItemTypes: any, excludeTypes: any) {
        const filteredItems = linkItemTypes.filter((item: any) => !excludeTypes.includes(item.id));
        return filteredItems;
    }

    public static daySuffix(i: number) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    public static propertyTypes = [
        { id: APP_CONST.PROPERTY_TYPE_FLAT, name: "flat-house" },
        { id: APP_CONST.PROPERTY_TYPE_OFFICES, name: "offices" },
        { id: APP_CONST.PROPERTY_TYPE_SHOPS, name: "shops" },
        { id: APP_CONST.PROPERTY_TYPE_PARKING_SPACES, name: "parking-space" },
        { id: APP_CONST.PROPERTY_TYPE_GARAGES_TWO_WHEELER, name: "garage-2wheeler" },
        { id: APP_CONST.PROPERTY_TYPE_GARAGES_FOUR_WHEELER, name: "garage-4wheeler" },
        { id: APP_CONST.PROPERTY_TYPE_AMENITY, name: "amenity" },
        { id: APP_CONST.PROPERTY_TYPE_QUARTER, name: "quarter" },
        { id: APP_CONST.PROPERTY_TYPE_PARKING_SPACES_TWO_WHEELER, name: "parking-space-2wheeler" },
        { id: APP_CONST.PROPERTY_TYPE_PARKING_SPACES_FOUR_WHEELER, name: "parking-space-4wheeler" },
    ];

    public static threadTypes = [
        { id: APP_CONST.THREAD_TYPE_RULES, name: "Guildelines" },
        { id: APP_CONST.THREAD_TYPE_NOTICE, name: "Notice" },
        { id: APP_CONST.THREAD_TYPE_COMPLAINT, name: "Complaint" },
        { id: APP_CONST.THREAD_TYPE_DISCUSSION, name: "General Discussion" },
    ];

    public static todoPriority = [
        { id: 1, name: "Urgent" },
        { id: 2, name: "High" },
        { id: 3, name: "Medium" },
        { id: 4, name: "Low" },
        { id: 5, name: "Very Low" },
    ];

    public static todoStatus = [
        { id: 1, name: "Open" },
        { id: 2, name: "Completed" },
        { id: 3, name: "Cancelled" },
        { id: 4, name: "Suspended" },
    ];

    public static accountTypes = [
        { id: 501, type: "cash" },
        { id: 502, type: "bank" },
        { id: 503, type: "owners" },
        { id: 504, type: "tenants" },
        { id: 505, type: "vendors" },
        { id: 506, type: "outsiders" },
        { id: 507, type: "general" }
    ];

    public static daysArray = [
        { id: 1, name: "Sunday" },
        { id: 2, name: "Monday" },
        { id: 4, name: "Tuesday" },
        { id: 8, name: "Wednesday" },
        { id: 16, name: "Thursday" },
        { id: 32, name: "Friday" },
        { id: 64, name: "Saturday" }

    ];

    public static monthNamesArray = [{ id: "01", name: "January" }, { id: "02", name: "February" }, { id: "03", name: "March" }, { id: "04", name: "April" }, { id: "05", name: "May" }, { id: "06", name: "June" },
    { id: "07", name: "July" }, { id: "08", name: "August" }, { id: "09", name: "September" }, { id: "10", name: "October" }, { id: "11", name: "November" }, { id: "12", name: "December" }];

    public static ckEditorConfig = {
        height: '6em',
        toolbar: [
            //{ name: 'document',  items: [ 'Source' ] },
            //{ name: 'clipboard', groups: [ 'clipboard', 'undo' ], items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
            //{ name: 'editing', groups: [ 'find', 'selection', 'spellchecker' ], items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
            //{ name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
            '/',
            { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat'] },
            { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'], items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
            { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
            { name: 'insert', items: ['Table'] },
            '/',
            { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
            { name: 'colors', items: ['TextColor', 'BGColor'] },
            //{ name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
            //{ name: 'others', items: [ '-' ] },
            //{ name: 'about', items: [ 'About' ] }
        ]
    };

    public static invoiceType = [
        { id: APP_CONST.INVOICE_TYPE_SOCIETY, name: "society-invoice" },
        { id: APP_CONST.INVOICE_TYPE_PURCHASE, name: "purchase-invoice" },
        { id: APP_CONST.INVOICE_TYPE_COLLECTION, name: "collection" },
        { id: APP_CONST.INVOICE_TYPE_PAYMENTS, name: "payment" },
        { id: APP_CONST.INVOICE_TYPE_RENT, name: "rent" },
        { id: APP_CONST.INVOICE_TYPE_CREDIT_NOTES, name: "credit-notes" },
        { id: APP_CONST.INVOICE_TYPE_DEBIT_NOTES, name: "debit-notes" },
        { id: APP_CONST.INVOICE_TYPE_TRANSFERS, name: "transfers" }
    ];

    public static roles = [
        { roleId: 40, roleName: "Member" },
        { roleId: 50, roleName: "Staff" },
        { roleId: 60, roleName: "Executive Member" },
        { roleId: 70, roleName: "Finance Advisor" },
        { roleId: 80, roleName: "Treasurer" },
        { roleId: 90, roleName: "Secretary" },
        { roleId: 100, roleName: "General Secretary" },
        { roleId: 110, roleName: "Vice-President" },
        { roleId: 120, roleName: "President" }
    ];

    public static contractMode = [
        { name: "annually", days: 365 },
        { name: "half yearly", days: 182 },
        { name: "quarterly", days: 90 },
        { name: "monthly", days: 30 },
        { name: "lifetime", days: 36500 },
    ];

    public static capabilitySetting = [
        { code: "01", name: "Own Invitation Management", permission: ["0102", "0103", "0104", "0105", "0106", "0107", "0108", "0109"] },
        { code: "02", name: "Profile Management", permission: ["0202", "0203", "0204", "0205", "0206", "0207", "0208", "0209"] },
        { code: "04", name: "Homepage Notification", permission: ["0402", "0403", "0404", "0405", "0406", "0407", "0408", "0409"] },
        { code: "05", name: "Community Management", permission: ["0502", "0503", "0504", "0505", "0506", "0507", "0508", "0509"] },
        { code: "06", name: "Block Management", permission: ["0602", "0603", "0604", "0605", "0606", "0607", "0608", "0609"] },
        { code: "07", name: "Building Management", permission: ["0702", "0703", "0704", "0705", "0706", "0707", "0708", "0709"] },
        { code: "08", name: "Properties(Common Area)", permission: ["0802", "0803", "0804", "0805", "0806", "0807", "0808", "0809"] },
        { code: "09", name: "Properties", permission: ["0902", "0903", "0904", "0905", "0906", "0907", "0908", "0909"] },
        { code: "10", name: "Resources or Assets", permission: ["1002", "1003", "1004", "1005", "1006", "1007", "1008", "1009"] },
        { code: "11", name: "My Dashboard", permission: ["1102", "1103", "1104", "1105", "1106", "1107", "1108", "1109"] },
        { code: "12", name: "Accounting Setup", permission: ["1202", "1203", "1204", "1205", "1206", "1207", "1208", "1209"] },
        { code: "13", name: "Billing Charges Setup", permission: ["1302", "1303", "1304", "1305", "1306", "1307", "1308", "1309"] },
        { code: "14", name: "Staffs & Members Management", permission: ["1402", "1403", "1404", "1405", "1406", "1407", "1408", "1409"] },
        { code: "15", name: "Tags or Category", permission: ["1502", "1503", "1504", "1505", "1506", "1507", "1508", "1509"] },
        { code: "16", name: "Documents & Agreements", permission: ["1602", "1603", "1604", "1605", "1606", "1607", "1608", "1609"] },
        { code: "17", name: "Todo Management", permission: ["1702", "1703", "1704", "1705", "1706", "1707", "1708", "1709"] },
        { code: "18", name: "Comment Management(Todo)", permission: ["1802", "1803", "1804", "1805", "1806", "1807", "1808", "1809"] },
        { code: "19", name: "Notice Management", permission: ["1902", "1903", "1904", "1905", "1906", "1907", "1908", "1909"] },
        { code: "20", name: "Guidelines Management", permission: ["2002", "2003", "2004", "2005", "2006", "2007", "2008", "2009"] },
        { code: "21", name: "Discussion Management", permission: ["2102", "2103", "2104", "2105", "2106", "2107", "2108", "2109"] },
        { code: "22", name: "Complain Management", permission: ["2202", "2203", "2204", "2205", "2206", "2207", "2208", "2209"] },
        { code: "23", name: "Comment Management(Thread)", permission: ["2302", "2303", "2304", "2305", "2306", "2307", "2308", "2309"] },
        { code: "24", name: "Professional's Visit", permission: ["2402", "2403", "2404", "2405", "2406", "2407", "2408", "2409"] },
        { code: "25", name: "Society Invoices", permission: ["2502", "2503", "2504", "2505", "2506", "2507", "2508", "2509"] },
        { code: "26", name: "Purchase Invoices", permission: ["2602", "2603", "2604", "2605", "2606", "2607", "2608", "2609"] },
        { code: "27", name: "Collection", permission: ["2702", "2703", "2704", "2705", "2706", "2707", "2708", "2709"] },
        { code: "28", name: "Payment", permission: ["2802", "2803", "2804", "2805", "2806", "2807", "2808", "2809"] },
        { code: "29", name: "Transfer Funds", permission: ["2902", "2903", "2904", "2905", "2906", "2907", "2908", "2909"] },
        { code: "30", name: "Roles & Capabilities", permission: ["3002", "3003", "3004", "3005", "3006", "3007", "3008", "3009"] },
        { code: "31", name: "Reports", permission: ["3102", "3103", "3104", "3105", "3106", "3107", "3108", "3109"] },
    ];

    public static beforeMinutes = ["15", "30", "45", "60", "75"];
    public static daysInterval = ["30", "15", "7", "3", "2", "1"];
    public static maxBookingDays = ["1", "2", "3", "4", "5"];
}