import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../ui.service';
import { CommunityService } from '../api-client/community.service';
import { ToastrService } from 'ngx-toastr';
import { CommonMethods } from '../common-methods';
import { APP_CONST } from '../app-constants';
import { RoleService } from '../api-client/role.service';

@Component({
  selector: 'app-capabilities',
  templateUrl: './capabilities.component.html',
  styleUrls: ['./capabilities.component.css']
})
export class CapabilitiesComponent implements OnInit, AfterViewInit {

  roles = [];
  roleName = null;
  roleId = null;
  isLogin = 0;
  isAdmistrativeMember = 0;
  capabilitySetting = CommonMethods.capabilitySetting;
  permissions = [];
  deleteRoleParams: any = {
    itemId: 0,
    itemName: null,
    deleteText: "Are you sure, you want to delete this role?"
  };

  saveButton: boolean = false;
  roleData: any;
  APP_CONST = APP_CONST;
  constructor(private _router: Router,
    private _route: ActivatedRoute,
    private _uiService: UiService,
    private _communityService: CommunityService,
    private _roleService: RoleService,
    private _tosterService: ToastrService) { }

  ngOnInit() {
    this.getRoles();
  }

  ngAfterViewInit() {
    this.disableAll();
  }

  saveRole() {
    if (this.roleName == "" || this.roleName == null) {
      this._tosterService.error("Role name can not be blank");
      return;
    }
    var data = { communityId: CommonMethods.getCommunityId(), name: this.roleName, id: this.roleId, isDefault: 0, isAllowedBackendLogin: this.isLogin,isAdmistrativeMember: this.isAdmistrativeMember };
    this._roleService.save(data).subscribe((result: any) => {
      if (result) {
        this._tosterService.success("Role save");
        this.getRoles();
        this.resetForm();
      }
    })
  }

  getRoles() {
    var data = { communityId: CommonMethods.getCommunityId() };
    this._roleService.all(data).subscribe((result: any) => {
      this.roles = result;
    })
  }

  details(item: any) {
    this._roleService.details(item.id).subscribe((result: any) => {
      if (result) {
        this.roleData = null;
        this.saveButton = false;
        this.permissions = [];
        this.disableAll();
        this.roleName = result.name;
        this.roleId = result.id;
        this.isLogin = result.isAllowedBackendLogin;
        this.isAdmistrativeMember = result.isAdmistrativeMember;
      }
    });
  }

  async showCapability(item: any) {
    await this._roleService.details(item.id).toPromise().then((result: any) => {
      if (result) {
        this.roleName = null;
        this.roleId = null;
        this.isLogin = result.isAllowedBackendLogin;
        this.isAdmistrativeMember = result.isAdmistrativeMember;
        this.roleData = result;
        this.getPrivileges();
        if (result.isDefault) {
          this.saveButton = false;
          this.disableAll();
        } else {
          this.saveButton = true;
          this.enableAll();
        }
      }
    });
  }

  redirectTo(item: any) {
    this._router.navigateByUrl("capabilities");
  }

  deleteRole() {
    //console.log(this.deleteParams.itemId);
    if (this.deleteRoleParams.pressBtn == "Ok") {
      this._roleService.delete(this.deleteRoleParams.itemId).subscribe((result: any) => {
        this._tosterService.success("Role Deleted");
        this.getRoles();
        this.resetForm();
        this.disableAll();
        return;
      });
    }
  }

  deleteRolePopUp(item: any) {
    this.deleteRoleParams.itemId = item.id;
    this.deleteRoleParams.itemName = item.name;
    this._uiService.deleteItemData = this.deleteRoleParams;
    this._uiService.deleteItemCallBack = this.deleteRole.bind(this);
    this._router.navigate([{
      outlets: { centerPopup: ['delete-item'] }
    }]);
  }

  changeArray(ele: any) {
    if (ele.target.checked) {
      this.permissions.push(ele.target.value);
    } else {
      const index = this.permissions.indexOf(ele.target.value);
      if (index > -1) {
        this.permissions.splice(index, 1);
      }
    }
  }

  selectCheckBox(item: any) {
    if (this.permissions.includes(item)) {
      return true;
    } else {
      return false;
    }
  }

  async savePrivileges() {
    if (!this.roleData) return;
    if (this.permissions.length == 0) {
      this._tosterService.error("Please check permissions");
      return;
    }
    var privilegeData = { communityId: CommonMethods.getCommunityId(), roleId: this.roleData.id, capabilities: this.permissions };
    await this._roleService.savePrivileges(privilegeData).toPromise().then((result: any) => {
      if (result) {
        this._tosterService.success("Privilege save");
        var data = { communityId: CommonMethods.getCommunityId(), name: this.roleData.name, id: this.roleData.id, isDefault: 0, isAllowedBackendLogin: this.isLogin,isAdmistrativeMember:this.isAdmistrativeMember };
        this._roleService.save(data).subscribe((result: any) => {
          if (result) {
            this.getRoles();
          }
        })
        return;
      }
    })
  }

  async getPrivileges() {
    var data = { communityId: CommonMethods.getCommunityId(), roleId: this.roleData.id };
    await this._roleService.privileges(data).toPromise().then((result: any) => {
      if (result) {
        console.log(result.capabilities);
        this.permissions = result.capabilities;
      }
    })
  }

  enableAll() {
    document.querySelectorAll<HTMLInputElement>("tbody input[type='checkbox']").forEach((item: any) => {
      item.disabled = false;
    });
  }

  disableAll() {
    document.querySelectorAll<HTMLInputElement>("tbody input[type='checkbox']").forEach((item: any) => {
      item.disabled = true;
    });
  }

  checkUncheckIndividual(ele: any) {
    var key = 0;
    var checked: boolean = false;
    if (ele.target.value) {
      checked = ele.target.checked;
      key = ele.target.value;
    }
    if (checked) {
      this.capabilitySetting.forEach((item: any) => {
        if (key) {
          if (!this.permissions.includes(item.permission[(key - 1)])) {
            this.permissions.push(item.permission[(key - 1)]);
          }
        }
      });
    } else {
      this.capabilitySetting.forEach((item: any) => {
        if (key) {
          if (this.permissions.includes(item.permission[(key - 1)])) {
            const index = this.permissions.indexOf(item.permission[(key - 1)]);
            if (index > -1) {
              this.permissions.splice(index, 1);
            }
          }
        }
      });
    }
  }

  checkAll() {
    var checkAll = [];
    this.capabilitySetting.forEach((item: any) => {
      item.permission.forEach((ele: any) => {
        checkAll.push(ele);
      });
    });
    this.permissions = checkAll;
  }

  unCheckAll() {
    this.permissions = [];
  }

  async copyRoleCapability() {
    var roleData = { communityId: CommonMethods.getCommunityId(), name: this.roleData.name + " copy", id: null, isDefault: 0, isAllowedBackendLogin: this.roleData.isAllowedBackendLogin,isAdmistrativeMember:this.roleData.isAdmistrativeMember  };
    await this._roleService.save(roleData).toPromise().then((result: any) => {
      if (result) {
        var privilegeData = { communityId: CommonMethods.getCommunityId(), roleId: result.id, capabilities: this.permissions };
        this._roleService.savePrivileges(privilegeData).toPromise().then((result: any) => {
          if (result) {
            this._tosterService.success("Data copied");
            this.getRoles();
            this.resetForm();
            return;
          }
        })
      }
    })
  }

  resetForm() {
    this.roleName = null;
    this.roleId = null;
    this.roleData = null;
    this.permissions = [];
    this.saveButton = false;
  }

  redirectionType() {
    this._router.navigateByUrl("/dashboard"); return;
  }

}
