import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { LedgerService } from '../api-client/ledger.service';
import { VoucherService } from '../api-client/voucher.service';
import { IDayCalendarConfig } from 'ng2-date-picker';

@Component({
  selector: 'app-statements-report',
  templateUrl: './statements-report.component.html',
  styleUrls: ['./statements-report.component.css']
})
export class StatementsReportComponent implements OnInit {

  reportType: number = 0;
  communityId: number = parseInt(CommonMethods.getCommunityId());
  ledgerId: number;
  accountSelectedText: string = null;
  dateFrom: any;
  dateTo: any;
  accountGroup: string;
  public config = <IDayCalendarConfig>{
    format: "DD-MM-YYYY"
  };

  constructor(private _route: ActivatedRoute,
    private _ledgerService: LedgerService,
    private _voucherService: VoucherService) { }

  ngOnInit(): void {
    this._route.data.subscribe(params => {
      this.reportType = parseInt(params.type);
      if(this.reportType == 1){
        this.accountGroup = 'Income';
      }else{
        this.accountGroup = 'Expenses';
      }
    });
  }

  /*---Triggered when account changed from filter---*/
  accountChanged(): void {
    if(this.ledgerId > 0){
      this._ledgerService.details(this.ledgerId).subscribe((result:any)=>{
        this.accountSelectedText = result.ledgerName;
      }, error => {
        this.accountSelectedText = null;
      });
    }else{
      this.accountSelectedText = null;
    }
  }

  clearDateFrom(): void {
    this.dateFrom = null;
  }

  clearDateTo(): void {
    this.dateTo = null;
  }

  /*---Clear all selected data---*/
  clearSelection(): void {
    this.ledgerId = null;
    this.accountSelectedText = null;
    this.dateFrom = null;
    this.dateTo = null;
  }

  /*---Export statement---*/
  export(): void {
    let data = {
      communityId: this.communityId,
      ledgerId: this.ledgerId?this.ledgerId:null,
      fromDate: this.dateFrom?CommonMethods.dbToUsDate(this.dateFrom):null,
      toDate: this.dateTo?CommonMethods.dbToUsDate(this.dateTo):null,
      type: this.accountGroup
    }
    this._voucherService.exportStatement(data);
  }

}
