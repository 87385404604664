import { Component, OnInit } from '@angular/core';
import { CommonMethods } from '../common-methods';
import { APP_CONST } from '../app-constants';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { PropertyService } from '../api-client/property.service';
import { UiService } from '../ui.service';
import { ToastrService } from 'ngx-toastr';
import { BookingService } from '../api-client/booking.service';

@Component({
  selector: 'app-booking-avalibility',
  templateUrl: './booking-avalibility.component.html',
  styleUrls: ['./booking-avalibility.component.css']
})
export class BookingAvalibilityComponent implements OnInit {

  itemName = "--Select Property--";
  routeParams: any;
  propertyType: any;
  routeExtras: NavigationExtras;
  startDate: any;
  endDate: any;
  propertyId:number;
  numberOfAdult:number;
  numberOfChild:number;
  public config = <IDayCalendarConfig>{
    format: "YYYY-MM-DD HH:mm:ss"
  };
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _propertyService: PropertyService,
    private _tosterService: ToastrService,
    private _uiService: UiService,
    private _bookingService: BookingService,
  ) { this.routeExtras = this._router.getCurrentNavigation().extras; }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this.routeParams = params;
    });
    this.getPropertyThroughState();
    //this.propertyType = this.routeExtras.state.propertyType;
    // this._route.params.
  }


  closeItself() {
    this._router.navigate(
      [
        "../",
        {
          outlets: {
            rightMainPopup: null
          }
        }
      ],
      {
        relativeTo: this._route
      }
    );
  }

  async getPropertyThroughState() {
    if (this.routeExtras.state && this.routeExtras.state.propertyType) {
      if(this.routeExtras.state.propertyId > 0 ){
        await this._propertyService.details(this.routeExtras.state.propertyId).toPromise().then((result:any)=>{
          this.itemName = result.unitNo;
        });
      }
      console.log('1111',this.routeExtras.state);
      this.propertyType = this.routeExtras.state.propertyType;
      this.startDate = this.routeExtras.state.startDate;
      this.endDate = this.routeExtras.state.endDate;
      this.numberOfAdult = this.routeExtras.state.numberOfAdult;
      this.numberOfChild = this.routeExtras.state.numberOfChild;
    }
  }

  async applyFilter() {
    //to do add validation
    if (this.propertyId == null) {
      this._tosterService.error("Select Property");
      return false;
    }
    if (this.startDate == null || this.startDate == "") {
      this._tosterService.error("Start date can not be blank");
      return false;
    }
    if (this.endDate == null || this.endDate == "") {
      this._tosterService.error("End date can not be blank");
      return false;
    }
    if (this.numberOfAdult == null) {
      this._tosterService.error("Adult count can not be blank");
      return false;
    }
    if (isNaN(this.numberOfAdult)) {
      this._tosterService.error("Adult count number must be number.");
      return false;
    }
    if (this.numberOfChild == null) {
      this._tosterService.error("Child count can not be blank");
      return false;
    }
    if (isNaN(this.numberOfChild)) {
      this._tosterService.error("Child count number must be number.");
      return false;
    }
    var params = { startDate: this.startDate, endDate: this.endDate,numberOfAdult:this.numberOfAdult,numberOfChild:this.numberOfChild, propertyId: this.propertyId, communityId: CommonMethods.getCommunityId() };
    console.log(params);
    await this._bookingService.availibility(params).toPromise().then((result: any) => {
      if (result.lineItems.length > 0) {
        result['startDate'] = this.startDate;
        result['endDate'] = this.endDate;
        result['propertyType'] = this.propertyType;
        result['unitNumber'] = document.querySelector<HTMLInputElement>("div.linkItemId input[type='text']").value //add property name
        if (this.routeExtras.state.selectedBookingItems.length > 0) {
          var data = this.routeExtras.state.selectedBookingItems.filter((x: any) => x.propertyId == this.propertyId);
          if (data.length == 0) {
            this.routeExtras.state.selectedBookingItems.push(result);
          }
        } else {
          this.routeExtras.state.selectedBookingItems.push(result);
        }
      } else {
        this._tosterService.error("No line items found");
      }
      console.log(result);
      setTimeout(() => {
        this._uiService.closeLeftPopUp();
      }, 1000);
    });
  }

}
