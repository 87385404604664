import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ResourceModel } from './../Model/resource.model';
import { CustomValidator } from '../customvalidation';

@Injectable({
    providedIn: 'root'
})

export class ResourceFormBuilder {
    private form;
    private validationRules = {
    buildingId: ['',''],  
    communityBlockId: ['',''],  
    resourceName: ['',Validators.required],
    resourceDescription: ['',''],
    measurementUnitId: ['',''],
    propertyId: ['',''],
    quantity: ['',''],
    currentValuation: ['',CustomValidator.numeric],

    serialNumber: ['',''],
    manufacturer: ['',''], 
    model: ['',''], 
    purchaseOn: ['',''], 
    isActive: ['',''], 
    installOn: ['',''], 
    deactivatedOn: ['',''], 
    deactivationReason: ['',''],  

    }

    constructor(private fb: FormBuilder) {
        this.form = fb.group(this.validationRules);
    }

    getForm() {
        return this.form;
    }

    setValues(response: ResourceModel) {
        this.form.patchValue({
            id: response.id,
            buildingId: response.buildingId,
            communityBlockId: response.communityBlockId,
            resourceName: response.resourceName,
            resourceDescription: response.resourceDescription,
            measurementUnitId: response.measurementUnitId,
            propertyId: response.propertyId,
            quantity: response.quantity,
            currentValuation: response.currentValuation,
            serialNumber: response.serialNumber,
            manufacturer: response.manufacturer,
            model: response.model,
            purchaseOn: response.purchaseOn,
            isActive: response.isActive,
            installOn: response.installOn,
            deactivatedOn: response.deactivatedOn, 
            deactivationReason: response.deactivationReason,
        });
    }
}