let APP_CONSTANTS = {
	"PROPERTY_TYPE_FLAT": 101,
    "PROPERTY_TYPE_OFFICES": 102,
    "PROPERTY_TYPE_SHOPS": 103,
    "PROPERTY_TYPE_PARKING_SPACES": 104,
    "PROPERTY_TYPE_GARAGES_TWO_WHEELER": 105,
    "PROPERTY_TYPE_GARAGES_FOUR_WHEELER": 106,
    "PROPERTY_TYPE_AMENITY": 107,
    "PROPERTY_TYPE_QUARTER": 108,
    "PROPERTY_TYPE_PARKING_SPACES_TWO_WHEELER": 109,
    "PROPERTY_TYPE_PARKING_SPACES_FOUR_WHEELER": 110,
    "PROPERTY_TYPE_BLOCKS": 177,
    "PROPERTY_TYPE_ASSETS": 188,
    "PROPERTY_TYPE_BUILDINGS": 199,

    "INVOICE_TYPE_SOCIETY": 201,
    "INVOICE_TYPE_RENT": 202,
    "INVOICE_TYPE_PURCHASE": 203,
    "INVOICE_TYPE_COLLECTION": 204,
    "INVOICE_TYPE_CREDIT_NOTES": 205,
    "INVOICE_TYPE_DEBIT_NOTES": 206,
    "INVOICE_TYPE_TRANSFERS": 207,
    "INVOICE_TYPE_PAYMENTS": 208,

    "TODO": 301,

    "THREAD_TYPE_RULES": 401,
    "THREAD_TYPE_NOTICE": 402,
    "THREAD_TYPE_COMPLAINT": 403,
    "THREAD_TYPE_DISCUSSION": 404,

    "ACCOUNT_TYPE_CASH": 501,
    "ACCOUNT_TYPE_BANK": 502,
    "ACCOUNT_TYPE_OWNER": 503,
    "ACCOUNT_TYPE_TENANT": 504,
    "ACCOUNT_TYPE_VENDOR": 505,
    "ACCOUNT_TYPE_CUSTOMER": 506,
    "ACCOUNT_TYPE_GENERAL": 507,
    
    /** Capabilities */
    "PROFILE_READ": "0202",
    "PROFILE_RESTRICTED_READ": "0203",
    "PROFILE_WRITE": "0204",
    "PROFILE_RESTRICTED_WRITE": "0205",
    "PROFILE_TRASH": "0206",
    "PROFILE_RESTRICTED_TRASH": "0207",
    "PROFILE_REMOVE": "0208",
    "PROFILE_RESTRICTED_REMOVE": "0209",
    
    "COMMUNITY_READ": "0502",
    "COMMUNITY_RESTRICTED_READ": "0503",
    "COMMUNITY_WRITE": "0504",
    "COMMUNITY_RESTRICTED_WRITE": "0505",
    "COMMUNITY_TRASH": "0506",
    "COMMUNITY_RESTRICTED_TRASH": "0507",
    "COMMUNITY_REMOVE": "0508",
    "COMMUNITY_RESTRICTED_REMOVE": "0509",
    
    "BLOCK_READ": "0602",
    "BLOCK_RESTRICTED_READ": "0603",
    "BLOCK_WRITE": "0604",
    "BLOCK_RESTRICTED_WRITE": "0605",
    "BLOCK_TRASH": "0606",
    "BLOCK_RESTRICTED_TRASH": "0607",
    "BLOCK_REMOVE": "0608",
    "BLOCK_RESTRICTED_REMOVE": "0609",
    "BUILDING_READ": "0702",
    "BUILDING_RESTRICTED_READ": "0703",
    "BUILDING_WRITE": "0704",
    "BUILDING_RESTRICTED_WRITE": "0705",
    "BUILDING_TRASH": "0706",
    "BUILDING_RESTRICTED_TRASH": "0707",
    "BUILDING_REMOVE": "0708",
    "BUILDING_RESTRICTED_REMOVE": "0709",
    "UNIT_COMMONAREA_READ": "0802",
    "UNIT_COMMONAREA_RESTRICTED_READ": "0803",
    "UNIT_COMMONAREA_WRITE": "0804",
    "UNIT_COMMONAREA_RESTRICTED_WRITE": "0805",
    "UNIT_COMMONAREA_TRASH": "0806",
    "UNIT_COMMONAREA_RESTRICTED_TRASH": "0807",
    "UNIT_COMMONAREA_REMOVE": "0808",
    "UNIT_COMMONAREA_RESTRICTED_REMOVE": "0809",
    "UNIT_READ": "0902",
    "UNIT_RESTRICTED_READ": "0903",
    "UNIT_WRITE": "0904",
    "UNIT_RESTRICTED_WRITE": "0905",
    "UNIT_TRASH": "0906",
    "UNIT_RESTRICTED_TRASH": "0907",
    "UNIT_REMOVE": "0908",
    "UNIT_RESTRICTED_REMOVE": "0909",
    "RESOURCE_READ": "1002",
    "RESOURCE_RESTRICTED_READ": "1003",
    "RESOURCE_WRITE": "1004",
    "RESOURCE_RESTRICTED_WRITE": "1005",
    "RESOURCE_TRASH": "1006",
    "RESOURCE_RESTRICTED_TRASH": "1007",
    "RESOURCE_REMOVE": "1008",
    "RESOURCE_RESTRICTED_REMOVE": "1009",

    "MEMBER_READ": "1402",
    "MEMBER_RESTRICTED_READ": "1403",
    "MEMBER_WRITE": "1404",
    "MEMBER_RESTRICTED_WRITE": "1405",
    "MEMBER_TRASH": "1406",
    "MEMBER_RESTRICTED_TRASH": "1407",
    "MEMBER_REMOVE": "1408",
    "MEMBER_RESTRICTED_REMOVE": "1409",

    "CATEGORY_READ": "1502",
    "CATEGORY_RESTRICTED_READ": "1503",
    "CATEGORY_WRITE": "1504",
    "CATEGORY_RESTRICTED_WRITE": "1505",
    "CATEGORY_TRASH": "1506",
    "CATEGORY_RESTRICTED_TRASH": "1507",
    "CATEGORY_REMOVE": "1508",
    "CATEGORY_RESTRICTED_REMOVE": "1509",
    "DOCUMENTS_READ": "1602",
    "DOCUMENTS_RESTRICTED_READ": "1603",
    "DOCUMENTS_WRITE": "1604",
    "DOCUMENTS_RESTRICTED_WRITE": "1605",
    "DOCUMENTS_TRASH": "1606",
    "DOCUMENTS_RESTRICTED_TRASH": "1607",
    "DOCUMENTS_REMOVE": "1608",
    "DOCUMENTS_RESTRICTED_REMOVE": "1609",
    "TODO_READ": "1702",
    "TODO_RESTRICTED_READ": "1703",
    "TODO_WRITE": "1704",
    "TODO_RESTRICTED_WRITE": "1705",
    "TODO_TRASH": "1706",
    "TODO_RESTRICTED_TRASH": "1707",
    "TODO_REMOVE": "1708",
    "TODO_RESTRICTED_REMOVE": "1709",
    "TODO_COMMENT_READ": "1802",
    "TODO_COMMENT_RESTRICTED_READ": "1803",
    "TODO_COMMENT_WRITE": "1804",
    "TODO_COMMENT_RESTRICTED_WRITE": "1805",
    "TODO_COMMENT_TRASH": "1806",
    "TODO_COMMENT_RESTRICTED_TRASH": "1807",
    "TODO_COMMENT_REMOVE": "1808",
    "TODO_COMMENT_RESTRICTED_REMOVE": "1809",

    "NOTICE_READ": "1902",
    "NOTICE_RESTRICTED_READ": "1903",
    "NOTICE_WRITE": "1904",
    "NOTICE_RESTRICTED_WRITE": "1905",
    "NOTICE_TRASH": "1906",
    "NOTICE_RESTRICTED_TRASH": "1907",
    "NOTICE_REMOVE": "1908",
    "NOTICE_RESTRICTED_REMOVE": "1909",
    "GUIDELINE_READ": "2002",
    "GUIDELINE_RESTRICTED_READ": "2003",
    "GUIDELINE_WRITE": "2004",
    "GUIDELINE_RESTRICTED_WRITE": "2005",
    "GUIDELINE_TRASH": "2006",
    "GUIDELINE_RESTRICTED_TRASH": "2007",
    "GUIDELINE_REMOVE": "2008",
    "GUIDELINE_RESTRICTED_REMOVE": "2009",
    "DISCUSSION_READ": "2102",
    "DISCUSSION_RESTRICTED_READ": "2103",
    "DISCUSSION_WRITE": "2104",
    "DISCUSSION_RESTRICTED_WRITE": "2105",
    "DISCUSSION_TRASH": "2106",
    "DISCUSSION_RESTRICTED_TRASH": "2107",
    "DISCUSSION_REMOVE": "2108",
    "DISCUSSION_RESTRICTED_REMOVE": "2109",
    "COMPLAINT_READ": "2202",
    "COMPLAINT_RESTRICTED_READ": "2203",
    "COMPLAINT_WRITE": "2204",
    "COMPLAINT_RESTRICTED_WRITE": "2205",
    "COMPLAINT_TRASH": "2206",
    "COMPLAINT_RESTRICTED_TRASH": "2207",
    "COMPLAINT_REMOVE": "2208",
    "COMPLAINT_RESTRICTED_REMOVE": "2209",
    "THREAD_COMMENT_READ": "2302",
    "THREAD_COMMENT_RESTRICTED_READ": "2303",
    "THREAD_COMMENT_WRITE": "2304",
    "THREAD_COMMENT_RESTRICTED_WRITE": "2305",
    "THREAD_COMMENT_TRASH": "2306",
    "THREAD_COMMENT_RESTRICTED_TRASH": "2307",
    "THREAD_COMMENT_REMOVE": "2308",
    "THREAD_COMMENT_RESTRICTED_REMOVE": "2309",

    "PROFESSIONALS_VISIT_READ": "2402",
    "PROFESSIONALS_VISIT_RESTRICTED_READ": "2403",
    "PROFESSIONALS_VISIT_WRITE": "2404",
    "PROFESSIONALS_VISIT_RESTRICTED_WRITE": "2405",
    "PROFESSIONALS_VISIT_TRASH": "2406",
    "PROFESSIONALS_VISIT_RESTRICTED_TRASH": "2407",
    "PROFESSIONALS_VISIT_REMOVE": "2408",
    "PROFESSIONALS_VISIT_RESTRICTED_REMOVE": "2409",
    
    "ACCOUNTING_SETUP_READ": "1202",
    "ACCOUNTING_SETUP_RESTRICTED_READ": "1203",
    "ACCOUNTING_SETUP_WRITE": "1204",
    "ACCOUNTING_SETUP_RESTRICTED_WRITE": "1205",
    "ACCOUNTING_SETUP_TRASH": "1206",
    "ACCOUNTING_SETUP_RESTRICTED_TRASH": "1207",
    "ACCOUNTING_SETUP_REMOVE": "1208",
    "ACCOUNTING_SETUP_RESTRICTED_REMOVE": "1209",
    "BILLING_CHARGES_SETUP_READ": "1302",
    "BILLING_CHARGES_SETUP_RESTRICTED_READ": "1303",
    "BILLING_CHARGES_SETUP_WRITE": "1304",
    "BILLING_CHARGES_SETUP_RESTRICTED_WRITE": "1305",
    "BILLING_CHARGES_SETUP_TRASH": "1306",
    "BILLING_CHARGES_SETUP_RESTRICTED_TRASH": "1307",
    "BILLING_CHARGES_SETUP_REMOVE": "1308",
    "BILLING_CHARGES_SETUP_RESTRICTED_REMOVE": "1309",
    "SOCIETY_INVOICE_READ": "2502",
    "SOCIETY_INVOICE_RESTRICTED_READ": "2503",
    "SOCIETY_INVOICE_WRITE": "2504",
    "SOCIETY_INVOICE_RESTRICTED_WRITE": "2505",
    "SOCIETY_INVOICE_TRASH": "2506",
    "SOCIETY_INVOICE_RESTRICTED_TRASH": "2507",
    "SOCIETY_INVOICE_REMOVE": "2508",
    "SOCIETY_INVOICE_RESTRICTED_REMOVE": "2509",
    "PURCHASE_INVOICE_READ": "2602",
    "PURCHASE_INVOICE_RESTRICTED_READ": "2603",
    "PURCHASE_INVOICE_WRITE": "2604",
    "PURCHASE_INVOICE_RESTRICTED_WRITE": "2605",
    "PURCHASE_INVOICE_TRASH": "2606",
    "PURCHASE_INVOICE_RESTRICTED_TRASH": "2607",
    "PURCHASE_INVOICE_REMOVE": "2608",
    "PURCHASE_INVOICE_RESTRICTED_REMOVE": "2609",
    "COLLECTION_READ": "2702",
    "COLLECTION_RESTRICTED_READ": "2703",
    "COLLECTION_WRITE": "2704",
    "COLLECTION_RESTRICTED_WRITE": "2705",
    "COLLECTION_TRASH": "2706",
    "COLLECTION_RESTRICTED_TRASH": "2707",
    "COLLECTION_REMOVE": "2708",
    "COLLECTION_RESTRICTED_REMOVE": "2709",
    "PAYMENT_READ": "2802",
    "PAYMENT_RESTRICTED_READ": "2803",
    "PAYMENT_WRITE": "2804",
    "PAYMENT_RESTRICTED_WRITE": "2805",
    "PAYMENT_TRASH": "2806",
    "PAYMENT_RESTRICTED_TRASH": "2807",
    "PAYMENT_REMOVE": "2808",
    "PAYMENT_RESTRICTED_REMOVE": "2809",
    "TRANSFER_READ": "2902",
    "TRANSFER_RESTRICTED_READ": "2903",
    "TRANSFER_WRITE": "2904",
    "TRANSFER_RESTRICTED_WRITE": "2905",
    "TRANSFER_TRASH": "2906",
    "TRANSFER_RESTRICTED_TRASH": "2907",
    "TRANSFER_REMOVE": "2908",
    "TRANSFER_RESTRICTED_REMOVE": "2909",
    "CAPABILITY_READ": "3002",
    "CAPABILITY_RESTRICTED_READ": "3003",
    "CAPABILITY_WRITE": "3004",
    "CAPABILITY_RESTRICTED_WRITE": "3005",
    "CAPABILITY_TRASH": "3006",
    "CAPABILITY_RESTRICTED_TRASH": "3007",
    "CAPABILITY_REMOVE": "3008",
    "CAPABILITY_RESTRICTED_REMOVE": "3009",
    "REPORT_READ": "3102",
    "REPORT_RESTRICTED_READ": "3103",
    "REPORT_WRITE": "3104",
    "REPORT_RESTRICTED_WRITE": "3105",
    "REPORT_TRASH": "3106",
    "REPORT_RESTRICTED_TRASH": "3107",
    "REPORT_REMOVE": "3108",
    "REPORT_RESTRICTED_REMOVE": "3109",
}

export const APP_CONST = APP_CONSTANTS;