import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject,Observable, of  } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../../app/config';
import { UserModel } from '../Model/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  

  constructor(private _http: HttpClient) {
  }

  all(data:any){
    return this._http
      .post(config.apiEndPointBase + "/user/list", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  save(data:UserModel): Observable<UserModel> {
    if(data.id){
      return this._http.put(config.apiEndPointBase + "/user",data, {responseType: 'text'})
      .pipe(map((response:any) => {
        return new UserModel().deserialize(response);
      }));
    }else{
      return this._http.post(config.apiEndPointBase + "/user",data, {responseType: 'text'})
      .pipe(map((response:any) => {
        return new UserModel().deserialize(response);
      }));
    }
  }

  details(userId:any){
    return this._http.get(config.apiEndPointBase + `/user/${userId}`);
  }

  delete(userId:any){
    return this._http.get(config.apiEndPointBase + `/user/${userId}`,{responseType: 'text'});
  }

  members(data:any){
    console.log(data);
    return this._http
      .post(config.apiEndPointBase + "/user/web/list", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }
}
