import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../../app/config';
import { ThreadModel } from './../Model/thread.model';

@Injectable({
  providedIn: 'root'
})
export class ThreadService {

  constructor(private _http: HttpClient) {
  }

  all(data: any) {
    return this._http
      .post(config.apiEndPointBase + "/thread/list", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  save(data: ThreadModel): Observable<ThreadModel> {
    if (data.id) {
      return this._http.put(config.apiEndPointBase + "/thread", data, { responseType: 'text' })
        .pipe(map((response: any) => {
          return new ThreadModel().deserialize(response);
        }));
    } else {
      return this._http.post(config.apiEndPointBase + "/thread", data, { responseType: 'text' })
        .pipe(map((response: any) => {
          return new ThreadModel().deserialize(response);
        }));
    }
  }

  delete(id: number) {
    return this._http.delete(config.apiEndPointBase + `/thread/${id}`, { responseType: 'text' });
  }

  details(id: number) {
    return this._http.get(config.apiEndPointBase + `/thread/${id}`);
  }

  saveComment(data: any) {
    if (data.id) {
      return this._http.put(config.apiEndPointBase + "/thread/updateComment", data, { responseType: 'text' })
        .pipe(map((response: any) => {
          return response;
        }));
    } else {
      return this._http.post(config.apiEndPointBase + "/thread/createComment", data, { responseType: 'text' })
        .pipe(map((response: any) => {
          return response;
        }));
    }
  }

  deleteComment(id: number) {
    return this._http.delete(config.apiEndPointBase + `/thread/deleteComment/${id}`, { responseType: 'text' });
  }

  saveMedia(data: any) {
    return this._http.post(config.apiEndPointBase + "/thread/saveMedia", data, { responseType: 'text' })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  deleteMedia(id: number) {
    return this._http.delete(config.apiEndPointBase + `/thread/deleteMedia/{id}/${id}`, { responseType: 'text' });
  }

  saveCommentMedia(data: any) {
    return this._http.post(config.apiEndPointBase + "/thread/saveCommentMedia", data, { responseType: 'text' })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  updateStatus(data: any) {
    return this._http.post(config.apiEndPointBase + "/thread/updateStatus", data, { responseType: 'text' })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  typeWiseCount(data: any) {
    return this._http.get(config.apiEndPointBase + `/thread/threadsCount/${data.id}/${data.type}`);
  }
}
