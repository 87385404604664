import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../../app/config';
import { PropertyModel } from '../Model/property.model';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {

  constructor(private _http: HttpClient) {
  }

  all(data: any) {
    return this._http
      .post(config.apiEndPointBase + "/property/listByType", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  list(data: any) {
    return this._http
      .post(config.apiEndPointBase + "/property/list", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  details(id: number) {
    return this._http.get(config.apiEndPointBase + `/property/${id}`);
  }


  types() {
    return this._http.get(config.apiEndPointBase + `/property_types`);
  }

  type(id: any) {
    return this._http.get(config.apiEndPointBase + `/property_type/${id}`);
  }

  getBlockByPropertyId(data: any) {
    return this._http
      .post(config.apiEndPointBase + "/property/getBlock", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getDetailsById(blockId: number, buildingId: number, propertyType: number) {
    return this._http.get(config.apiEndPointBase + `/property/getDetails/${blockId}/${buildingId}/${propertyType}`);
  }

  save(data: PropertyModel): Observable<PropertyModel> {
    if (data.id) {
      return this._http.put(config.apiEndPointBase + "/property", data, { responseType: 'text' })
        .pipe(map((response: any) => {
          return new PropertyModel().deserialize(response);
        }));
    } else {
      return this._http.post(config.apiEndPointBase + "/property", data, {})
        .pipe(map((response: any) => {
          return new PropertyModel().deserialize(response);
        }));
    }
  }

  assignLedger(data: any) {
    return this._http.post(config.apiEndPointBase + "/property/assignLedger", data, { responseType: 'text' })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  unAssignLedger(data: any) {
    return this._http.post(config.apiEndPointBase + "/property/unassignLedger", data, { responseType: 'text' })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  associateLedger(data: any) {
    return this._http.post(config.apiEndPointBase + "/property/associateLedger", data)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  unassignLedger(data: any) {
    return this._http.post(config.apiEndPointBase + "/property/unassignLedger", data, { responseType: 'text' })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  delete(id: number) {
    return this._http.delete(config.apiEndPointBase + `/property/${id}`, { responseType: 'text' });
  }

  getContracts(resourceId: number) {
    return this._http.get(config.apiEndPointBase + `/property/${resourceId}`);
  }

  getAssets(id: number) {
    return this._http.get(config.apiEndPointBase + `/property/${id}/assets`);
  }

  getBalance(id:number) {
    return this._http.get(config.apiEndPointBase + `/property/${id}/balances`);
  }  

  getCount(blockId: number, buildingId: number) {
    return this._http.get(config.apiEndPointBase + `/property/getPropertyCountById/${blockId}/${buildingId}`);
  }

  getByLedger(id: number) {
    return this._http.get(config.apiEndPointBase + `/property/of_ledger/${id}`);
  }

  assignOwner(data: any) {
    return this._http.post(config.apiEndPointBase + "/property/assignOwner", data, { responseType: 'text' })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getLastPaidPeriod(id: number) {
    return this._http.get(config.apiEndPointBase + `/property/${id}/last_paid_period`);
  }
  
}
