import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { UiService } from '../ui.service';
import { APP_CONST } from '../app-constants';
import { ToastrService } from 'ngx-toastr';
import { PropertyService } from '../api-client/property.service';
import { BuildingService } from '../api-client/building.service';
import { ResourceService } from '../api-client/resource.service';
import { BlockService } from '../api-client/block.service';
import { LedgerService } from '../api-client/ledger.service';
import { VoucherService } from '../api-client/voucher.service';
import { config } from '../config';
import { filter, map } from 'rxjs/operators';
import { IDayCalendarConfig } from 'ng2-date-picker';
@Component({
  selector: 'app-dues-report',
  templateUrl: './dues-report.component.html',
  styleUrls: ['./dues-report.component.css']
})
export class DuesReportComponent implements OnInit {
  public config = <IDayCalendarConfig>{
    format: "DD-MM-YYYY"
  };
  societyInvoiceType: number = APP_CONST.INVOICE_TYPE_SOCIETY;
  assetPropertyType: number = APP_CONST.PROPERTY_TYPE_ASSETS;
  buildingPropertyType: number = APP_CONST.PROPERTY_TYPE_BUILDINGS;
  reportType: number = 0;
  propertyUrl: any;
  propertyTypes: any;
  communityId: number = parseInt(CommonMethods.getCommunityId());
  filterParams: any = {
    selectedPropertyType: null,
    propertyId: null,
    propertySelectedText: '',
    invoiceFrom: null,
    invoiceTo: null,
    invoiceStatus: null,
    ledgerToId: null,
    ledgerFromId: null,
    keyword: null,
    invoiceType: null,
    accountSelectedText: '',
    blockId: null,
    blockSelectedText: '',
    buildingId: null,
    buildingSelectedText: '',
    voucherFromDate: null,
    voucherToDate: null,
  };



  constructor(private _uiService: UiService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _tosterService: ToastrService,
    private _propertyService: PropertyService,
    private _buildingService: BuildingService,
    private _resourceService: ResourceService,
    private _blockService: BlockService,
    private _ledgerService: LedgerService,
    private _voucherService: VoucherService) { }

  ngOnInit(): void {
    this._route.data.subscribe(params => {
      this.reportType = parseInt(params.type);
    });
    this.fillPropertyTypes();
    this.filterParams.invoiceStatus = 'Pending';
    this.filterParams.invoiceType = this.societyInvoiceType;
  }

  /*---Get all Property Types from DB---*/
  fillPropertyTypes(): void {
    this._propertyService.types().subscribe((result: any) => {
      this.propertyTypes = result;
    });
  }

  /*---Triggered when property type changed---*/
  propertyTypeChanged(): void {
    if (this.filterParams.selectedPropertyType > 0) {
      if (this.filterParams.selectedPropertyType == this.assetPropertyType) {
        this.propertyUrl = { "mode": "ResourceSelections" };
      } else if (this.filterParams.selectedPropertyType == this.buildingPropertyType) {
        this.propertyUrl = { "mode": "BuildingSelections" };
      } else {
        this.propertyUrl = { "mode": "PropertySelections", "type": this.filterParams.selectedPropertyType };
      }
    }
  }

  /*---Triggered when account changed from filter---*/
  accountChanged(): void {
    if (this.filterParams.ledgerToId > 0) {
      this._ledgerService.details(this.filterParams.ledgerToId).subscribe((result: any) => {
        this.filterParams.accountSelectedText = result.ledgerName;
      }, error => {
        this.filterParams.accountSelectedText = null;
      });
    } else {
      this.filterParams.accountSelectedText = null;
    }
  }

  /*---Triggered when property changed from filter---*/
  propertyChanged(): void {
    if (this.filterParams.propertyId > 0) {
      if (this.filterParams.selectedPropertyType == this.assetPropertyType) {
        this._resourceService.details(this.filterParams.propertyId).subscribe((result: any) => {
          this.filterParams.propertySelectedText = result.resourceName;
        }, error => {
          this.filterParams.propertySelectedText = null;
        });
      } else if (this.filterParams.selectedPropertyType == this.buildingPropertyType) {
        this._buildingService.details(this.filterParams.propertyId).subscribe((result: any) => {
          this.filterParams.propertySelectedText = result.name;
        }, error => {
          this.filterParams.propertySelectedText = null;
        });
      } else {
        this._propertyService.details(this.filterParams.propertyId).subscribe((result: any) => {
          this.filterParams.propertySelectedText = result.unitNo;
        }, error => {
          this.filterParams.propertySelectedText = null;
        });
      }
    } else {
      this.filterParams.propertySelectedText = null;
    }
  }

  /*---Triggered when block changed from filter---*/
  blockChanged(): void {
    if (this.filterParams.blockId > 0) {
      this._blockService.details(this.filterParams.blockId).subscribe((result: any) => {
        this.filterParams.blockSelectedText = result.communityBlockName;
      }, error => {
        this.filterParams.blockSelectedText = null;
      });
    } else {
      this.filterParams.blockSelectedText = null;
    }
  }

  /*---Triggered when building changed from filter---*/
  buildingChanged(): void {
    if (this.filterParams.buildingId > 0) {
      this._buildingService.details(this.filterParams.buildingId).subscribe((result: any) => {
        this.filterParams.buildingSelectedText = result.name;
      }, error => {
        this.filterParams.buildingSelectedText = null;
      });
    } else {
      this.filterParams.buildingSelectedText = null;
    }
  }

  /*---Close Filter Panel---*/
  closeItself(): void {
    this._router.navigate(
      [
        "../",
        {
          outlets: {
            leftMainPopup: null
          }
        }
      ],
      {
        relativeTo: this._route
      }
    );
  }

  /*---Clear filter data---*/
  clearFilter(): void {
    this.filterParams.selectedPropertyType = null;
    this.filterParams.propertyId = null,
      this.filterParams.propertySelectedText = '';
    this.filterParams.ledgerToId = null;
    this.filterParams.accountSelectedText = '';
    this.filterParams.blockId = null;
    this.filterParams.blockSelectedText = '';
    this.filterParams.buildingId = null;
    this.filterParams.buildingSelectedText = '';
    this.filterParams.voucherFromDate = null;
    this.filterParams.voucherToDate = null;
  }

  /*---Export---*/
  export(): void {
    let data = {
      communityId: this.communityId,
      voucherType: this.filterParams.invoiceType,
      propertyTypeId: this.filterParams.selectedPropertyType ? this.filterParams.selectedPropertyType : null,
      propertyId: (this.filterParams.selectedPropertyType && this.filterParams.propertyId) ? this.filterParams.propertyId : null,
      keyWord: null,
      accountId: this.filterParams.ledgerToId ? this.filterParams.ledgerToId : null,
      status: this.filterParams.invoiceStatus ? this.filterParams.invoiceStatus : null,
      buildingId: this.filterParams.buildingId ? this.filterParams.buildingId : null,
      blockId: this.filterParams.blockId ? this.filterParams.blockId : null,
      voucherFromDate: this.filterParams.voucherFromDate ? CommonMethods.dbToUsDate(this.filterParams.voucherFromDate) : null,
      voucherToDate: this.filterParams.voucherToDate ? CommonMethods.dbToUsDate(this.filterParams.voucherToDate) : null,


    };
    if (this.reportType == 1) {
      this._voucherService.exportLedgerDues(data);
    } else {
      this._voucherService.exportPropertyDues(data);
    }
  }

  /*---Send Manual Notifications---*/
  sendNotification(): void {
    console.log("notification clicked");
  }

  clearDateFrom(): void {
    this.filterParams.voucherFromDate = null;
  }

  clearDateTo(): void {
    this.filterParams.voucherToDate = null;
  }
}
