import { Deseriazable } from './deserializable.model';

export class TodoModel implements Deseriazable
{
    id: number;
    communityId: number;
    task: string;
    description: string;
    dueDate: string;
    priority: string;
    linkItemTypeId: string;
    linkItemId: string;
    linkItemName: string;
    assignToName: string;
    assignToDesignation: string;
    assignToMobile: string;
    category: string;
    status: string;
    recurringInterval: string;
    recurringIntervalUnit: string;
    recurringMaxRepeat: string;
    completeDate: string;
    
    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }
}