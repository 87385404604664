import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { LedgerService } from '../api-client/ledger.service';
import { UserService } from '../api-client/user.service';
import { LocationService } from '../api-client/location.service';
import { AccountGroupService } from '../api-client/account-group.service';
import { ToastrService } from 'ngx-toastr';
import { LedgerFormBuilder } from './ledger.form-builder';
import { UiService } from '../ui.service';
import { LedgerModel } from '../Model/ledger.model';
import { CommonMethods } from '../common-methods';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { PropertyService } from '../api-client/property.service';
import { exists } from 'fs';
import { APP_CONST } from '../app-constants';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-ledger-details',
  templateUrl: './ledger-details.component.html',
  styleUrls: ['./ledger-details.component.css']
})
export class LedgerDetailsComponent implements OnInit {

  ledgerDetails: any;
  ledgerForm: any;
  ledgerName: any;
  submitted = false;
  model = new LedgerModel();
  ledgerId = null;
  linkedUserId: any;
  accountTypes: any;
  accountGroupName = "--select--";
  linkedUserName = "--select--";
  countryName = "--select--";
  stateName = "--select--";
  countryId: any;
  routeExtras: NavigationExtras;
  accountType: any;
  accountTypeName: any;
  properties = [];
  totalPayble: number = 0.00;
  transactions = [];
  ledgerCurrentBalance: any;
  accountTypeBank: boolean = false;
  deleteParams: any = {
    itemId: 0,
    itemName: null,
    deleteText: "Are you sure, you want to delete this ledger?"
  }
  linkItemType:any;
  linkItemId:any;

  public config = <IDayCalendarConfig>{
    locale: "al",
    format: "YYYY-MM-DD HH:mm:ss"
  };
  error = [];
  constructor(private _ledgerService: LedgerService,
    private _userService: UserService,
    private _propertyService: PropertyService,
    private _uiService: UiService,
    private _tosterService: ToastrService,
    private _locationService: LocationService,
    private _accountGroupService: AccountGroupService,
    private _formBuilder: LedgerFormBuilder,
    private _router: Router,
    private _route: ActivatedRoute) {
    this.routeExtras = this._router.getCurrentNavigation().extras;
  }

  ngOnInit() {
    this._uiService.leftMenuItems.next([
      { code: 'F4', name: 'Save', action: this.save.bind(this), class: 'green lighten-1', privilege: APP_CONST.ACCOUNTING_SETUP_WRITE },
      { code: 'F5', name: 'Save & Add New', action: this.saveAndNew.bind(this), privilege: APP_CONST.ACCOUNTING_SETUP_WRITE },
      { code: 'F7', name: 'Delete', action: this.confirmationPopUp.bind(this), class: 'red lighten-1', privilege: APP_CONST.ACCOUNTING_SETUP_REMOVE }
    ]);
    this.ledgerForm = this._formBuilder.getForm();
    this.getAccountTypes();
    this.ledgerDetails = {};
    this._route.params.subscribe(params => {
      if (params.id > 0) {
        this.details(params.id);
        this.propertyDetails(params.id);
      } else {
        this.resetForm();
      }
    });
    setTimeout(() => {
      this.onChanges();
      /* if (this.accountTypeBank) {
        console.log("yes");
        this.ledgerForm.get('accountTypeId').setValidators([Validators.required]);
        this.ledgerForm.get('accountTypeId').updateValueAndValidity();
      } else {
        console.log("no");
        this.ledgerForm.get('accountTypeId').clearValidators();
        this.ledgerForm.get('accountTypeId').updateValueAndValidity();
      } */
    }, 1000);
    
  }

  get f() { return this.ledgerForm.controls; }

  details(id: number) {
    this._ledgerService.details(id).subscribe((result: any) => {
      this._formBuilder.setValues(result);
      this.ledgerId = result.id;
      this.ledgerCurrentBalance = result.ledgerCurrentBalance;
      this.accountType = result.accountTypeId;
      this.ledgerName = result.ledgerName;
      this.countryId = result.countryId;
      if(result.linkedUserId > 0) {
        this.linkedUserId = result.linkedUserId;
      }     
      this.deleteParams.itemId = result.id;
      this.deleteParams.itemName = result.ledgerName;
      // if (result.linkedUserId) {
      //   this._userService.details(result.linkedUserId).subscribe((result: any) => {
      //     this.linkedUserName = result.name;
      //   });
      // } else {
      //   this.linkedUserName = "--select--";
      // }
      if (result.countryId) {
        this._locationService.countryDetails(result.countryId).subscribe((result: any) => {
          this.countryName = result.countryName;
        });
      } else {
        this.countryName = "--select--";
      }
      if (result.stateId) {
        this._locationService.stateDetails(result.stateId).subscribe((result: any) => {
          this.stateName = result.stateName;
        });
      } else {
        this.stateName = "--select--";
      }

      if (result.accountGroupId) {
        this._accountGroupService.details(result.accountGroupId).subscribe((result: any) => {
          this.accountGroupName = result.groupName;
        });
      } else {
        this.ledgerDetails.accountGroup = null;
      }
      if (result.bankAccountType) {
        this.accountTypeBank = true;
      } else {
        this.accountTypeBank = false;
      }
      this.transactionsByLedgerId();
    })
  }

  async onSubmit() {
    this.error = [];
    this.submitted = true;
    if (this.ledgerForm.invalid) {
      return;
    }
    this.model.deserialize(this.ledgerForm.value);

    this.model.ledgerCommunityId = Number(CommonMethods.getCommunityId());
    //this.model.communityId = Number(CommonMethods.getCommunityId());
    //this.model.startsFrom = CommonMethods.dbToUsDate(this.ledgerForm.get('startsFrom').value);
    this.model.id = this.ledgerId;
    await this._ledgerService.save(this.model)
      .toPromise().then(
        (response: LedgerModel) => {
          if(response){
            this._tosterService.success("Ledger save");
            if (!this.ledgerId) {
              this.ledgerId = response.id;            
            }
          }else{
            this.error.push("server error");
          }
        },
        error => {
          this._tosterService.error(error.message);
          return;
        }
      )
  }

  async save(){
    await this.onSubmit();
    if(this.error.length>0) return;
    setTimeout(()=>{
      if(this.ledgerId>0){
        this._router.navigateByUrl(`ledger/${this.ledgerId}`);
        return;
      }
    },1000);    
  }

  async saveAndNew() {
    await this.onSubmit();
    if(this.error.length>0) return;
    if (!this.ledgerForm.invalid) {      
      this.resetForm();
    }
  }

  confirmationPopUp() {
    if (this.ledgerId > 0) {
      this._uiService.deleteItemData = this.deleteParams;
      this._uiService.deleteItemCallBack = this.delete.bind(this);
      this._router.navigate([{
        outlets: { centerPopup: ['delete-item'] }
      }]);
    }
  }

  delete() {
    if (this.deleteParams.pressBtn == "Ok") {
      this._ledgerService.delete(this.deleteParams.itemId).subscribe((result: any) => {
        this._tosterService.success("Ledger Deleted");
        this.resetForm();
        return;
      });
    }
  }
  resetForm() {
    this.submitted = false;
    this.ledgerId = null;
    this.linkedUserId = null;
    this.ledgerCurrentBalance = null;
    this.error = [];
    this.accountGroupName = null;
    this.ledgerForm.reset();
    this.populateAddress();
    setTimeout(() => {
      this._router.navigateByUrl("ledger/0");
    }, 100)
  }

  getAccountTypes() {
    this._ledgerService.accountTypes().subscribe((result: any) => {
      this.accountTypes = result;
    })
  }

  onChanges(): void {
    this.ledgerForm.get('countryId').valueChanges.subscribe((val: any) => {
      this.countryId = val;
    });

    this.ledgerForm.get('accountTypeId').valueChanges.subscribe((val: any) => {
      this.accountTypeBank = false;
      if (val == APP_CONST.ACCOUNT_TYPE_BANK) {
        this.accountTypeBank = true;
      }
    });
    if (this.routeExtras && this.routeExtras.state) {
      if (this.routeExtras.state.type) {
        console.log(this.routeExtras.state.type);
        this.ledgerForm.get('accountTypeId').setValue(this.routeExtras.state.type);
        this.accountType = this.routeExtras.state.type;
      }
      if (this.routeExtras.state.group) {
        this.ledgerForm.get('accountGroupId').setValue(this.routeExtras.state.group);
      }

    }
  }

  propertyDetails(id: number) {
    this.totalPayble = 0;
    this._propertyService.getByLedger(id).subscribe((result: any) => {
      for (let i = 0; i < result.length; i++) {
        this.totalPayble += (result[i].totalDueAmount + result[i].totalAccumulatedCharge) - result[i].totalDiscountAmount;
      }
      this.properties = result;
    })
  }

  redirectToProperty(item: any) {
    var propertyType = CommonMethods.propertyTypes.filter((x: any) => x.id == item.propertyType);
    if (propertyType.length > 0) {
      this._router.navigateByUrl(`${propertyType[0].name}/${item.id}`);
    }
  }

  redirectionType(mode: any, displayType: any) {
    let params: any;
    if (displayType == 'listing') {
      if (mode == "ledgers") {
        if (this.accountType > 0) {
          var accountTypeName = CommonMethods.accountTypes.filter((x: any) => x.id == this.accountType);
          if (accountTypeName.length > 0) {
            params = [{ "outlets": { "rightMainPopup": ["ledgers", accountTypeName[0].type] } }];
          }
        } else {
          params = [{ "outlets": { "rightMainPopup": ["ledgers", "general"] } }];
        }
      } else if (mode == "dashboard") {
        this._router.navigateByUrl("/dashboard"); return;
      }
      this._router.navigate(params); return;
    }
  }

  redirectToLedgerList() {
    let groupName: any;
    groupName = this.routeExtras.state.group;
    this._router.navigate([{
      outlets: { rightMainPopup: ['ledgers/', groupName] }
    }]);
  }

  transactionsByLedgerId() {
    if (!this.ledgerId) return;
    var data = { communityId: CommonMethods.getCommunityId(), id: this.ledgerId };
    this._ledgerService.transactions(data).subscribe((result: any) => {
      this.transactions = result.data;
    })
  }

  voucherDetails(item: any) {
    var url = CommonMethods.invoiceType.filter((x: any) => x.id == item.voucher_type);
    if (url.length > 0) {
      this._router.navigateByUrl(`${url[0].name}/${item.id}`);
      return;
    }
  }

  async populateAddress() {
    var address = CommonMethods.getCommunityDetails();
    address.name = "";
    address.id = null;
    address.openingBalance = null;
    address.mobile = null;
    address.email = null;
    this._formBuilder.setValues(address);
    if (address.countryId) {
      await this._locationService.countryDetails(address.countryId).toPromise().then((result: any) => {
        this.countryName = result.countryName;
      });
    }
    if (address.stateId) {
      await this._locationService.stateDetails(address.stateId).toPromise().then((result: any) => {
        this.stateName = result.stateName;
      });
    }
  }

  ngOnDestroy(): void {
    this._uiService.leftMenuItems.next([]);
  }

}
