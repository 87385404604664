import { Component, OnInit, Input } from '@angular/core';
import { CommonMethods } from '../common-methods';
import { MaintenanceContractService } from '../api-client/maintenancecontract.service';
import { UiService } from '../ui.service';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_CONST } from '../app-constants';

@Component({
  selector: 'app-maintenance-contracts',
  templateUrl: './maintenance-contracts.component.html',
  styleUrls: ['./maintenance-contracts.component.css']
})
export class MaintenanceContractsComponent implements OnInit {

  @Input() linkItemType: any;
  @Input() linkItemId: any;
  @Input() tagName: any;
  maintenanceContractItems=[];
  APP_CONST = APP_CONST;
  constructor(
    private _maintenanceContractService: MaintenanceContractService,    
    private _uiService: UiService,
    private _router: Router,
    private _route: ActivatedRoute,) { }
  
  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    console.log(this.linkItemId,this.linkItemType);
    this.contracts();
  }

  ngOnInit() {
    this._uiService.maintenanceDataReload.subscribe((result:any)=>{
      this.contracts();
      //this.contract();
      console.log(123,result);
    })
  }

  async contracts(){
    if(!this.linkItemType && !this.tagName) return;
    var data = {communityId: CommonMethods.getCommunityId(),linkItemTypeId: this.linkItemType,linkItemId:this.linkItemId,searchData:this.tagName }
    await this._maintenanceContractService.all(data).toPromise().then((result:any)=>{
      var result = result.data;
      for(var i=0;i<result.length;i++){
        var startDateTime = result[i].startDate.split(" ");
        var startDate = startDateTime[0].split("-");
        result[i].startDay = CommonMethods.daySuffix(startDate[2]);
        var endDateTime = result[i].expiryDate.split(" ");
        var endDate = endDateTime[0].split("-");
        result[i].endDay = CommonMethods.daySuffix(endDate[2]);
      }
      this.maintenanceContractItems = result;
    })
  }

  redirectTo(item: any) {
    this._router.navigate(["maintenance-contract", item.id]);
    return;
  }

  allContracts(){
    this._router.navigateByUrl("all-contract");
    return;
  }


}
