import { Component, OnInit } from '@angular/core';
import { CommonMethods } from '../common-methods';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { CommunityService } from '../api-client/community.service';
import { LocationService } from '../api-client/location.service';
import { LedgerService } from '../api-client/ledger.service';
import { UiService } from '../ui.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { APP_CONST } from '../app-constants';

@Component({
  selector: 'app-community-settings',
  templateUrl: './community-settings.component.html',
  styleUrls: ['./community-settings.component.css']
})
export class CommunitySettingsComponent implements OnInit {

  communityDetails: any;
  communityForm: any;
  countries: any;
  countryId: any;
  selectedCountryName = "Select country";
  selectedStateName = "Select state";
  settingData: any;


  test: boolean = true;
  live: boolean = false;
  id: number;
  autoBillingAt = 0;
  billingDueAt: null;
  billingMonth: null;
  cancelReason: null;
  cgstPercentage: null;
  commercialPerSqft: null;
  communityId = CommonMethods.getCommunityId();
  currencyCode = "INR";
  currencyFormat = "#,##,###,####";
  currencySymbol = "₹";
  enableAdvancePayment = 0;
  enablePartPayment = 0;
  displayPropertyDesc = 0;
  showLineItem = 0;
  forcefullyCancelPaidInvoice: null;
  garagePerSqft: null;
  igstPercentage: null;
  latePaymentPenalty: 0;
  minPaymentAmount: 0;
  paymentApiKey: any;
  paymentApiSecretKey: any;
  paymentChargesPercentage: any;
  paymentGatewayMode = "0";
  paymentLedgerId: null;
  penaltyAmount: null;
  penaltyAppliedAfterDays: 0;
  penaltyType: any;
  prodPaymentApiKey: null;
  prodPaymentApiSecretKey: null;
  prodPaymentChargesPercentage: null;
  prodPaymentLedgerId: null;
  residentialPerSqft: null;
  sgstPercentage: null;
  startAutoBilling: null;
  taxAppliedAbove: null;
  webhookSecretKey: any;
  emailSetting: any = {
    from_email: null,
    from_name: null,
    mailer: null,
    smtp: {
      host: null,
      autotls: null,
      encryption: null,
      authentication: null,
      port: null,
      username: null,
      password: null
    },
    pepipost: {
      api_key: null
    },
    sendinblue: {
      api_key: null
    },
    mailgun: {
      api_key: null,
      domain: null
    },
    sendgrid: {
      api_key: null
    }
  };
  smsSetting: any = {
    gateway: null,
    msg91: {
      auth_key: null,
      sender: null
    }
  };
  reminderPreference: any = {
    societyInvoiceGenerated: null,
    paymentDue: {
      statusUpdate: null,
      billGenerated: null,
      beforeDays: [{ id: 1, mode: null, days: null }, { id: 2, mode: null, days: null }, { id: 3, mode: null, days: null }, { id: 4, mode: null, days: null }, { id: 5, mode: null, days: null }],
      afterDays: [{ id: 1, mode: null, days: null }, { id: 2, mode: null, days: null }, { id: 3, mode: null, days: null }, { id: 4, mode: null, days: null }, { id: 5, mode: null, days: null }],
    },
    todo: {
      statusChange: null,
      beforeMinutes: [{ id: 1, mode: null, minutes: null }, { id: 2, mode: null, minutes: null }, { id: 3, mode: null, minutes: null }, { id: 4, mode: null, minutes: null }, { id: 5, mode: null, minutes: null }],
    }
  };
  billingInstructions : any = {
    terms:null
  };
  days = CommonMethods.daysInterval;
  minutes = CommonMethods.beforeMinutes;
  minutesData = [];
  fixedDueDay: any;


  paymentLedgerName = "--select--";
  prodPaymentLedgerName = "--select--";
  public config = <IDayCalendarConfig>{
    format: "YYYY-MM-DD HH:mm:ss"
  };

  constructor(
    private _communityService: CommunityService,
    private _locationService: LocationService,
    private _ledgerService: LedgerService,
    private _uiService: UiService,
    private _router: Router,
    private _tosterService: ToastrService
  ) {
  }

  ngOnInit() {
    this._uiService.leftMenuItems.next([
      { code: 'F4', name: 'Save', action: this.saveSetting.bind(this), class: 'green lighten-1', privilege: APP_CONST.COMMUNITY_WRITE },
      { code: 'F5', name: 'Community Details', action: this.redirectToCommunity.bind(this), class: 'blue lighten-1', privilege: APP_CONST.COMMUNITY_WRITE }
    ]);
    this.communityDetails = {};
    this.getSettings();
  }


  changeMode(arg: any) {
    if (arg) {
      this.live = true;
      this.test = false;
    } else {
      this.live = false;
      this.test = true;
    }
  }

  async getSettings() {
    await this._communityService.getSettings(CommonMethods.getCommunityId()).toPromise().then((result: any) => {
      this.changeMode(result.paymentGatewayMode);

      this.id = result.id;
      this.autoBillingAt = result.autoBillingAt;
      //this.billingDueAt = result.billingDueAt;
      this.billingMonth = result.billingMonth;
      this.cancelReason = result.cancelReason;
      this.cgstPercentage = result.cgstPercentage;
      this.commercialPerSqft = result.commercialPerSqft;
      this.communityId = CommonMethods.getCommunityId();
      this.currencyCode = result.currencyCode;
      this.currencyFormat = result.currencyFormat;
      this.currencySymbol = result.currencySymbol;
      this.enableAdvancePayment = result.enableAdvancePayment;
      this.enablePartPayment = result.enablePartPayment;
      this.displayPropertyDesc = result.displayPropertyDesc;
      this.showLineItem = result.showLineItem;
      this.forcefullyCancelPaidInvoice = result.forcefullyCancelPaidInvoice;
      this.garagePerSqft = result.garagePerSqft;
      this.igstPercentage = result.igstPercentage;
      this.latePaymentPenalty = result.latePaymentPenalty;
      this.minPaymentAmount = result.minPaymentAmount;
      this.paymentApiKey = result.paymentApiKey;
      this.paymentApiSecretKey = result.paymentApiSecretKey;
      this.paymentChargesPercentage = result.paymentChargesPercentage;
      this.paymentGatewayMode = result.paymentGatewayMode;
      this.paymentLedgerId = result.paymentLedgerId;
      this.penaltyAmount = result.penaltyAmount;
      this.penaltyAppliedAfterDays = result.penaltyAppliedAfterDays;
      this.penaltyType = result.penaltyType;
      this.prodPaymentApiKey = result.prodPaymentApiKey;
      this.prodPaymentApiSecretKey = result.prodPaymentApiSecretKey;
      this.prodPaymentChargesPercentage = result.prodPaymentChargesPercentage;
      this.prodPaymentLedgerId = result.prodPaymentLedgerId;
      this.residentialPerSqft = result.residentialPerSqft;
      this.sgstPercentage = result.sgstPercentage;
      this.startAutoBilling = result.startAutoBilling;
      this.taxAppliedAbove = result.taxAppliedAbove;
      this.webhookSecretKey = result.webhookSecretKey;
      this.emailSetting = JSON.parse(result.emailSetting);
      this.smsSetting = JSON.parse(result.smsSetting);
      this.reminderPreference = JSON.parse(result.reminderPreference);
      if(result.billingInstructions){
        this.billingInstructions = JSON.parse(result.billingInstructions);
      }


      if (result.paymentLedgerId) {
        this.paymentLedgerId = result.paymentLedgerId;
        this.ledgerDetails(result.paymentLedgerId, 'live');
      }
      if (result.prodPaymentLedgerId) {
        this.prodPaymentLedgerId = result.prodPaymentLedgerId;
        this.ledgerDetails(result.prodPaymentLedgerId, 'test');
      }
      if(result.fixedDueDay) {
        this.fixedDueDay = result.fixedDueDay;
      }
    })
  }

  async ledgerDetails(ledgerId: number, mode: any) {
    await this._ledgerService.details(ledgerId).toPromise().then((result: any) => {
      if (mode == 'live') {
        this.paymentLedgerName = result.ledgerName;
      }
      if (mode == 'test') {
        this.prodPaymentLedgerName = result.ledgerName;
      }
    })
  }

  saveSetting() {
    var beforeDayArr = [];
    var afterDayArr = [];
    var beforeMinutsArr = [];
    if (this.reminderPreference.paymentDue.beforeDays.length > 0) {
      for (var i = 0; i < this.reminderPreference.paymentDue.beforeDays.length; i++) {
        if (this.reminderPreference.paymentDue.beforeDays[i].days) {
          if (!beforeDayArr.includes(this.reminderPreference.paymentDue.beforeDays[i].days)) {
            beforeDayArr.push(this.reminderPreference.paymentDue.beforeDays[i].days);
          } else {
            this._tosterService.error(`You can not add same before ${this.reminderPreference.paymentDue.beforeDays[i].days} days`);
            return false;
          }
        }
      }
    }
    if (this.reminderPreference.paymentDue.afterDays.length > 0) {
      for (var i = 0; i < this.reminderPreference.paymentDue.afterDays.length; i++) {
        if (this.reminderPreference.paymentDue.afterDays[i].days) {
          if (!afterDayArr.includes(this.reminderPreference.paymentDue.afterDays[i].days)) {
            afterDayArr.push(this.reminderPreference.paymentDue.afterDays[i].days);
          } else {
            this._tosterService.error(`You can not add same after ${this.reminderPreference.paymentDue.afterDays[i].days} days`);
            return false;
          }
        }
      }
    }
    if (this.reminderPreference.todo.beforeMinutes.length > 0) {
      for (var i = 0; i < this.reminderPreference.todo.beforeMinutes.length; i++) {
        if (this.reminderPreference.todo.beforeMinutes[i].minutes) {
          if (!beforeMinutsArr.includes(this.reminderPreference.todo.beforeMinutes[i].minutes)) {
            beforeMinutsArr.push(this.reminderPreference.todo.beforeMinutes[i].minutes);
          } else {
            this._tosterService.error(`You can not add same before ${this.reminderPreference.todo.beforeMinutes[i].minutes} minutes`);
            return false;
          }
        }
      }
    }
    //console.log(this.reminderPreference); return;
    //this.reminderPreference.todo.before_minutes = this.minutesData;
    var data = {
      id: this.id,
      autoBillingAt: this.autoBillingAt,
      //billingDueAt: this.billingDueAt,
      billingMonth: this.billingMonth,
      cancelReason: this.cancelReason,
      cgstPercentage: this.cgstPercentage,
      commercialPerSqft: this.commercialPerSqft,
      communityId: CommonMethods.getCommunityId(),
      currencyCode: this.currencyCode,
      currencyFormat: this.currencyFormat,
      currencySymbol: this.currencySymbol,
      enableAdvancePayment: (this.enableAdvancePayment) ? 1 : 0,
      enablePartPayment: (this.enablePartPayment) ? 1 : 0,
      displayPropertyDesc: (this.displayPropertyDesc) ? 1 : 0,
      showLineItem : (this.showLineItem) ? 1 : 0,
      forcefullyCancelPaidInvoice: this.forcefullyCancelPaidInvoice,
      garagePerSqft: this.garagePerSqft,
      igstPercentage: this.igstPercentage,
      latePaymentPenalty: this.latePaymentPenalty,
      minPaymentAmount: this.minPaymentAmount,
      paymentApiKey: this.paymentApiKey,
      paymentApiSecretKey: this.paymentApiSecretKey,
      paymentChargesPercentage: this.paymentChargesPercentage,
      paymentGatewayMode: this.paymentGatewayMode,
      paymentLedgerId: this.paymentLedgerId,
      penaltyAmount: this.penaltyAmount,
      penaltyAppliedAfterDays: this.penaltyAppliedAfterDays,
      penaltyType: this.penaltyType,
      prodPaymentApiKey: this.prodPaymentApiKey,
      prodPaymentApiSecretKey: this.prodPaymentApiSecretKey,
      prodPaymentChargesPercentage: this.prodPaymentChargesPercentage,
      prodPaymentLedgerId: this.prodPaymentLedgerId,
      residentialPerSqft: this.residentialPerSqft,
      sgstPercentage: this.sgstPercentage,
      startAutoBilling: this.startAutoBilling,
      taxAppliedAbove: this.taxAppliedAbove,
      webhookSecretKey: this.webhookSecretKey,
      emailSetting: this.emailSetting,
      smsSetting: this.smsSetting,
      reminderPreference: this.reminderPreference,
      billingInstructions: this.billingInstructions,
      fixedDueDay: this.fixedDueDay ? this.fixedDueDay:null
    };
    //console.log(data); return;
    this._communityService.saveSettings(data).subscribe((result: any) => {
      if (result) {
        this._tosterService.success(`Setting save`);
        return;
      }
    })
  }

  changeTab(item: any) {
    /* this._uiService.leftMenuItems.next([]);
    if (item == "setting") {
      this.selectedTab = "setting";
      this._uiService.leftMenuItems.next([
        { code: 'F4', name: 'Save', action: this.saveSetting.bind(this), class: 'green lighten-1', privilege: APP_CONST.COMMUNITY_WRITE }
      ])
    } else if (item == "gateway") {
      this.selectedTab = "gateway";
      this._uiService.leftMenuItems.next([
        { code: 'F4', name: 'Save', action: this.saveSetting.bind(this), class: 'green lighten-1', privilege: APP_CONST.COMMUNITY_WRITE }
      ])
    } else {
      this.selectedTab = "info";
      this._uiService.leftMenuItems.next([
        { code: 'F4', name: 'Save', action: this.saveSetting.bind(this), class: 'green lighten-1', privilege: APP_CONST.COMMUNITY_WRITE }
      ])
    } */
  }

  beforeMinutes(ele: any) {
    //console.log(ele.target.checked,ele.target.value);return;
    if (ele.target.checked) {
      this.minutesData.push(ele.target.value);
    } else {
      const index = this.minutesData.indexOf(ele.target.value);
      if (index > -1) {
        this.minutesData.splice(index, 1);
      }
    }
  }

  selectCheckBox(item: any) {
    if (this.minutesData.includes(item)) {
      return true;
    } else {
      return false;
    }
  }

  sendNow(section: any, ele: any, when: any) {
    if (ele.mode == null || ele.mode == "") {
      this._tosterService.error("Send via can not be blank");
      return;
    }
    if (section == "payment") {
      if (ele.days == null || ele.days == "") {
        this._tosterService.error("Time can not be blank");
        return;
      }
    }
    if (section == "todo") {
      if (ele.minutes == null || ele.minutes == "") {
        this._tosterService.error("Time can not be blank");
        return;
      }
    }

    console.log(ele);
  }

  redirectToCommunity() {
    this._router.navigateByUrl(`community-details`);
    return;
  }

  redirectToDashboard() {
    this._router.navigateByUrl(`dashboard`);
    return;
  }

  ngOnDestroy(): void {
    this._uiService.leftMenuItems.next([]);
  }

}
