import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../api-client/user.service';
import { UiService } from '../ui.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { LocationService } from '../api-client/location.service';

@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.css']
})
export class CountriesComponent implements OnInit {

  countries: any;
  routeParams: any;
  filteredCountries: any;
  constructor(
    private _tosterService: ToastrService,
    private _userService: UserService,
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _locationService: LocationService,
    private _router: Router) { }

  ngOnInit() {
    this.list();
  }

  list() {
    this._locationService.allCountry().subscribe((result: any) => {
      this.countries = result;
      this.filteredCountries = this.countries;
    })
  }

  filterCountry(eve: any) {
    var code = eve.keyCode;
    if(code == 37 || code == 38 || code == 39 || code == 40) {
        return;
    }
    var ele = eve.target;
    var items: any;
    if (ele.value) {
      this.filteredCountries = this.countries.filter((x: any) => x.countryName.toLowerCase().includes(ele.value.toLowerCase()));
    } else {
      // this.list();
    }
  }

  details(item: any) {
    //this._uiService.changeBlockDetails.next(item);
    //debugger;
    /* switch (this.routeParams.mode) {
      case 'CountrySelections':
        let params = Object.assign({blockId:item.id},this.routeParams);
        console.log(params);
        this._router.navigate([{
          outlets: { rightMainPopup: ['countries', params] }
        }]);
        break;
      default:
        this._router.navigateByUrl("country/" + item.id);

    }
    return false; */
    if (this._uiService.activeListCallBack) {
      this._uiService.activeListCallBack(item.countryId, item.countryName);
      this._uiService.activeListCallBack = null;
      this._uiService.closeItself();
    }
  }

}
