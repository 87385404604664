import { Component, OnInit } from '@angular/core';
import { UiService } from '../ui.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { TodoFormBuilder } from '../todo-details/todo.form-builder';
import { TodoService } from '../api-client/todo.service';
import { PropertyService } from '../api-client/property.service';
import { CommonMethods } from '../common-methods';

@Component({
  selector: 'app-todo-comments',
  templateUrl: './todo-comments.component.html',
  styleUrls: ['./todo-comments.component.css']
})
export class TodoCommentsComponent implements OnInit {

  comment:any;
  todoId = 0;
  todos: any;
  commentId = null;
  deleteTodoCommentParams: any = {
    itemId: 0,
    itemName: null,
    deleteText: "Are you sure, you want to delete this comment?"
  }
  config = CommonMethods.ckEditorConfig;

  constructor(private _uiService: UiService,
    private _tosterService: ToastrService,
    private _router: Router,
    private _formBuilder: TodoFormBuilder,
    private _todoService: TodoService,
    private _route: ActivatedRoute) { }

  ngOnInit() {
    this._route.params.subscribe(params => {
      if (params.id > 0) {
        this.todoId = params.id;
        document.querySelector(".dynamicWidth").classList.remove("w-25");
        document.querySelector(".dynamicWidth").classList.add("w-50");
        this.comments();
      } 
    });
  }

  comments(){
    if(!this.todoId) return;
    var data = { communityId: CommonMethods.getCommunityId(), id: this.todoId };
    this._todoService.details(data).subscribe((result:any)=>{
      this.todos = result;
    })
  }

  send(){
    if(this.comment=="" || this.comment==null){
      this._tosterService.error("Comment can not be blank");
      return;
    }
    var data = {communityId: CommonMethods.getCommunityId(),taskId:this.todoId,comment:this.comment,id:this.commentId};
    this._todoService.comment(data).subscribe((result:any)=>{
      this._tosterService.success("Comment added");
      this.comment = "";
      this.comments();
      return;
    });
  }

  editComment(item:any){
    if(!item.id) return;
    this.commentId = item.id;
    this.comment = item.comment;
  }

  deleteComment() {
    //console.log(this.deleteParams.itemId);
    this._todoService.deleteComment(this.deleteTodoCommentParams.itemId).subscribe((result: any) => {
      this._tosterService.success("Comment Deleted");
      this.comments();
      return;
    })
  }

  deleteCommentPopUp(item: any) {
    this.deleteTodoCommentParams.itemId = item.id;
    this.deleteTodoCommentParams.itemName = (item.comment.length>60)?item.comment.slice(0,60)+"....":item.comment;;
    this._uiService.deleteItemData = this.deleteTodoCommentParams;
    this._uiService.deleteItemCallBack = this.deleteComment.bind(this);
    this._router.navigate([{
      outlets: { centerPopup: ['delete-item'] }
    }]);
  }

}
