import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { UiService } from '../ui.service';
import { CommonMethods } from '../common-methods';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-booking-calender',
  templateUrl: './booking-calender.component.html',
  styleUrls: ['./booking-calender.component.css']
})
export class BookingCalenderComponent implements OnInit {

  weekWiseDates:any = {};
  startDate:any;
  lastDate:any;
  daysItems:any;
  settings: any;
  filterVal = 0;
  showWeeendsOnly = false;
  monthNames:any;
  yearsRange:any;
  selectedMonth:any;
  selectedYear:any;
  totalBookings:number = 0;
  showYear:number;
  totalBookingsInYear:number = 0;
  routeExtras: NavigationExtras;

  @Output() edit = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() blockDay = new EventEmitter();
  @Output() delete = new EventEmitter();
  
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _uiService: UiService,
    private _tosterService: ToastrService,
  )
  {
    this.routeExtras = this._router.getCurrentNavigation().extras;
  }

   ngOnInit() {
    this.monthNames = CommonMethods.monthNamesArray;
    let currentDate = new Date();
    this.filterVal = 0;
    this.startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    let daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 0).getDate();
    this.lastDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), daysInMonth);
    this.fillWeekWiseDateRenge();
   }

  showPrevMonth(){
    let yesterdayOfStart = this.startDate;
    yesterdayOfStart.setDate(this.startDate.getDate() - 1);
    this.startDate = new Date(yesterdayOfStart.getFullYear(), yesterdayOfStart.getMonth(), 1);
    let daysInMonth = new Date(yesterdayOfStart.getFullYear(), yesterdayOfStart.getMonth()+1, 0).getDate();
    this.lastDate = new Date(yesterdayOfStart.getFullYear(), yesterdayOfStart.getMonth(), daysInMonth);
    this.fillWeekWiseDateRenge();
  }

  showNextMonth(){
    let tomorrowOfEnd = this.lastDate;
    tomorrowOfEnd.setDate(this.lastDate.getDate() + 1);
    this.startDate = new Date(tomorrowOfEnd.getFullYear(), tomorrowOfEnd.getMonth(), 1);
    let daysInMonth = new Date(tomorrowOfEnd.getFullYear(), tomorrowOfEnd.getMonth()+1, 0).getDate();
    this.lastDate = new Date(tomorrowOfEnd.getFullYear(), tomorrowOfEnd.getMonth(), daysInMonth);
    this.fillWeekWiseDateRenge();
  }

  jumpToMonthYear(){
    this.startDate = new Date(this.selectedYear, this.selectedMonth, 1);
    this.lastDate = new Date(this.startDate.getFullYear(),this.startDate.getMonth() + 1, 0);
    this.fillWeekWiseDateRenge();
  }

  fillWeekWiseDateRenge(){
    this.yearsRange = [];
    for(let y=this.startDate.getFullYear() - 5; y<=this.startDate.getFullYear() + 5; y++){
      this.yearsRange.push(y);
      if(y==this.startDate.getFullYear()){
        this.selectedYear = y;
      }
    }
    this.selectedMonth = this.startDate.getMonth();
    this.fillAfterCheckSettings({available_week_days:[1,2,4,8,16,32,64]});

    // this.vendorService
    //   .getAvailability()
    //   .subscribe(
    //     (response:AvailabilityModel) => {
    //       this.fillAfterCheckSettings(response);
    //     },
    //     error => {
    //       this.toastr.error(error.message);
    //   }
    //) 
  }

  fillAfterCheckSettings(settings:any){
    this.weekWiseDates = {};
    this.daysItems = {};
    let weekNumer = this.startDate.getDay()==0?0:1;
    /*let isFilter = 0;
    if(this.filterVal){
      isFilter = this.filterVal;
    }*/
    for(let i=new Date(this.startDate); i<=this.lastDate; i.setDate(i.getDate() + 1)){
      if(i.getDay() == 1){
        weekNumer++;
      }else if(weekNumer==0){
        weekNumer = 1;
      }
      let dateClone = new Date(i);
      let dateStr = i.toDateString();

      if(!this.weekWiseDates[weekNumer]){
        this.weekWiseDates[weekNumer] = {start:new Date(i), end:dateClone, dates:[]};
      }
      
      //console.log(settings.available_week_days, Config.daysArray[i.getDay()].id);
      if(settings.available_week_days.indexOf(CommonMethods.daysArray[i.getDay()].id) < 0){
        //this.daysItems[dateStr].isSettingsBlocked = true;
        //this.daysItems[dateStr].isBlocked = true;
      }else{
        this.daysItems[dateStr] = {date:dateClone, items:[], isFullDay:false, isBlocked:false, 
          isSettingsBlocked:false, isWeekend:[0,6,5].indexOf(dateClone.getDay())>=0};
        this.weekWiseDates[weekNumer].dates.push(this.daysItems[dateStr]);
        this.weekWiseDates[weekNumer].end = dateClone;
      }
    }
    console.log(this.weekWiseDates);
    //{"year":this.startDate.getFullYear(),"month":this.startDate.getMonth()}
    this.showYear = this.startDate.getFullYear();
    console.log(this.startDate.getFullYear(),this.startDate.getMonth());
    let year = this.startDate.getFullYear();
    let month = this.startDate.getMonth()+1;
    if (month < 10) month = '0' + month;
    /*this.vendorService
      .getBookings({year:year,month:month})
      .subscribe(
        (response:any) => {
          var total_records = response.total_records;
          var response = response.data;
          
          //this.totalBookings = response.length;
          this.totalBookingsInYear = total_records;
          var k = 0;
          for(let i=0; i<response.length; i++){
            let dateStr = new Date(response[i].booking_date_time +'T00:00:00').toDateString();
            if(this.daysItems[dateStr]){              
              if(response[i].is_blocked){
                this.daysItems[dateStr].isBlocked = true;
              }else if(response[i].is_fullday){
                  this.daysItems[dateStr].isFullDay = true;
                  this.daysItems[dateStr].items.push(response[i]);
              }else{
                this.daysItems[dateStr].items.push(response[i]);
                k++;
              }
              this.daysItems[dateStr].isPartial = false;
              if(this.daysItems[dateStr].items.length>=settings.booking_per_day){
                this.daysItems[dateStr].reachMaxBooking = true;
              }else{
                this.daysItems[dateStr].reachMaxBooking = false;
                this.daysItems[dateStr].isPartial = this.daysItems[dateStr].items.length>0;
              }
            }
          }
          this.totalBookings = k;
        },
        error => {
          this.toastr.error(error.message);
        }
      );*/
  }

  onAddBooking(date:any){
    this.add.emit(date);
  }
  onEditBooking(bookingDetail:any){
    this.edit.emit(bookingDetail);
  }

  onDeleteBooking(bookingDetail:any){
    this.delete.emit(bookingDetail);
  }

  onBlockDay(date:any, event:any){
    setTimeout(()=>{
      this.blockDay.emit({date:date, isBlocked:!event.target.checked});  
    }, 10);    
  }

  filterDates(filter:number){
    //console.log(filter);
    this.filterVal = filter;
  }
   }