import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../../app/config';
import { CategoryModel } from './../Model/category.model';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {


  constructor(private _http: HttpClient) {
  }

  all(data: any) {
    return this._http
      .post(config.apiEndPointBase + "/category/list", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  save(data: CategoryModel): Observable<CategoryModel> {
    if (data.id) {
      return this._http.put(config.apiEndPointBase + "/category", data, { responseType: 'text' })
        .pipe(map((response: any) => {
          return new CategoryModel().deserialize(response);
        }));
    } else {
      return this._http.post(config.apiEndPointBase + "/category", data, { responseType: 'text' })
        .pipe(map((response: any) => {
          return new CategoryModel().deserialize(response);
        }));
    }
  }

  details(id: number) {
    return this._http.get(config.apiEndPointBase + `/category/${id}`);
  }

  delete(id: number) {
    return this._http.delete(config.apiEndPointBase + `/category/${id}`, { responseType: 'text' });
  }
}
