import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { UiService } from '../ui.service';
import { LedgerService } from '../api-client/ledger.service';

@Component({
  selector: 'app-ledgers',
  templateUrl: './ledgers.component.html',
  styleUrls: ['./ledgers.component.css']
})
export class LedgersComponent implements OnInit {

  ledgers: any;
  accountType: string;
  accountTypeId: Number;
  group: string;
  ledgerType: string;
  data: any;
  filterLedgers:any;

  constructor(
    private _ledgerService: LedgerService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _uiService: UiService,
  ) { }

  ngOnInit() {
    this.list();
  }

  list() {
    this._route.params.subscribe(params => {
      if(params.type){
        this.accountType = params.type.charAt(0).toUpperCase() + params.type.slice(1);
        this.accountTypeId = this._ledgerService.accountTypeIdByName(this.accountType);
        this.data = { communityId: CommonMethods.getCommunityId(), accountTypeId: this.accountTypeId };
        this._ledgerService.all(this.data).subscribe((result: any) => {
          this.ledgers = result;
          this.filterLedgers = this.ledgers;
        });
      } else if(params.group){
        this.group = params.group;
        this.data = { communityId: CommonMethods.getCommunityId(), type: this.group };
        this._ledgerService.itemByDefaultGroup(this.data).subscribe((result: any) => {
          this.ledgers = result;
          this.filterLedgers = this.ledgers;
        });
      } else if(params.ledgerType){
        this.ledgerType = params.ledgerType;
        this.data = { communityId: CommonMethods.getCommunityId() };
        if(this.ledgerType == 'owners'){
          this._ledgerService.listOwnerLedgers(this.data).subscribe((result: any) => {
            this.ledgers = result;
            this.filterLedgers = this.ledgers;
          });
        } else{
          this._ledgerService.listVendorLedgers(this.data).subscribe((result: any) => {
            this.ledgers = result;
            this.filterLedgers = this.ledgers;
          });
        }
      } else {
        this.data = { communityId: CommonMethods.getCommunityId() };
        this._ledgerService.listAccountables().subscribe((result: any) => {
          this.ledgers = result;
          this.filterLedgers = this.ledgers;
        });
      }
    })
  }

  filterLedger(eve: any) {
    var code = eve.keyCode;
    if(code == 37 || code == 38 || code == 39 || code == 40) {
        return;
    }
    var ele = eve.target;
    var items: any;
    if (ele.value) {
      this.filterLedgers = this.ledgers.filter((x: any) => x.ledgerName.toLowerCase().includes(ele.value.toLowerCase()));

    } else {
     // this.list();
    }
  }

  details(item: any) {
    if (this._uiService.activeListCallBack) {
      this._uiService.activeListCallBack(item.id, item.ledgerName);
      this._uiService.activeListCallBack = null;
      this._uiService.closeItself();
    } else {
      this._router.navigateByUrl("ledger/" + item.id);
    }
  }
  
  redirectToLedger(){
    this._router.navigate(["ledger",0], { state: { "type": this.accountTypeId,"group":this.group } });
    setTimeout(()=>{
      this._uiService.closeItself();
    },100);
    return;
  }

}
