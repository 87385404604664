import { Component, OnInit } from '@angular/core';
import { BlockService } from '../api-client/block.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../api-client/user.service';
import { UiService } from '../ui.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DiscountTemplateService } from '../api-client/discount-template.service';

@Component({
  selector: 'app-discount-templates',
  templateUrl: './discount-templates.component.html',
  styleUrls: ['./discount-templates.component.css']
})
export class DiscountTemplatesComponent implements OnInit {

  templates:any;
  filterDiscount:any;

  constructor(
    private _blockService: BlockService,
    private _tosterService: ToastrService,
    private _userService: UserService,
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _discountTemplateService: DiscountTemplateService
  ) { }

  ngOnInit() {
    this.list();
  }

  list(){
    this._discountTemplateService.all().subscribe((result:any)=>{
        this.templates = result;
        this.filterDiscount = this.templates;
    })
  }

  filtertemplate(ele: any) {
    var items: any;
    if (ele.value) {
      this.filterDiscount = this.templates.filter((x: any) => x.name.toLowerCase().includes(ele.value.toLowerCase()));

    } else {
      //this.list();
    }
  }

  redirectTo(item:any){
    if (this._uiService.activeListCallBack) {
      this._uiService.activeListCallBack(item.id, item.name);
      this._uiService.activeListCallBack = null;
      this._uiService.closeItself();
    } else {
      this._router.navigateByUrl(`discount-details/${item.id}`);
      return false;
    }
  }

}
