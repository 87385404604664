import { Component, OnInit } from '@angular/core';
import { VoucherService } from '../api-client/voucher.service';
import { ToastrService } from 'ngx-toastr';
import { UiService } from '../ui.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-measurement-units',
  templateUrl: './measurement-units.component.html',
  styleUrls: ['./measurement-units.component.css']
})
export class MeasurementUnitsComponent implements OnInit {

  units:any;
  filterUnits:any;
  constructor(
    private _voucherService: VoucherService,
    private _tosterService: ToastrService,
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _router: Router) { }

  ngOnInit() {
    this.list();
  }

  list() {
    this._voucherService.measurementUnits().subscribe((result: any) => {
      this.units = result;
      this.filterUnits = this.units;
    })    
  }

  filterUnit(ele: any) {
    var items: any;
    if (ele.value) {
      this.filterUnits = this.units.filter((x: any) => x.name.toLowerCase().includes(ele.value.toLowerCase()));
    } else {
      //this.list();
    }
  }

  details(item: any) {
    if (this._uiService.activeListCallBack) {
      this._uiService.activeListCallBack(item.id, item.name);
      this._uiService.activeListCallBack = null;
      this._uiService.closeItself();
      return;
    }
  }

}
