import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { PropertyService } from '../api-client/property.service';
import { UiService } from '../ui.service';

@Component({
  selector: 'app-member-filter',
  templateUrl: './member-filter.component.html',
  styleUrls: ['./member-filter.component.css']
})
export class MemberFilterComponent implements OnInit {

  filterParams: any = {
    searchData:'', 
    isBlock: 2  
  };
  
  public config = <IDayCalendarConfig>{
    format: "YYYY-MM-DD"
  };
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _propertyService: PropertyService,
    private _uiService: UiService) {
  }  

  ngOnInit() {
    if(!this._uiService.activeFilterData){
      this._uiService.closeLeftPopUp();
      return;
    }
    this.filterParams = this._uiService.activeFilterData;   
  }

  applyFilter() {
    this._uiService.activeFilterCallBack();    
    this._uiService.closeLeftPopUp();
  }
  

  clearFilter(){
    this.filterParams.searchData = '';
  }

}
