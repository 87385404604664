import { Component, OnInit } from '@angular/core';
import { PropertyService } from '../api-client/property.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../api-client/user.service';
import { UiService } from '../ui.service';
import { APP_CONST } from '../app-constants';
import { CommonMethods } from '../common-methods';
import { VoucherTemplateService } from '../api-client/voucher-template.service';
import { BlockService } from '../api-client/block.service';
import { LedgerService } from '../api-client/ledger.service';
import { VoucherService } from '../api-client/voucher.service';
import { CategoryService } from '../api-client/category.service';
import { async } from '@angular/core/testing';
import { IDayCalendarConfig } from 'ng2-date-picker';

@Component({
  selector: 'app-billing-charges-details',
  templateUrl: './billing-charges-details.component.html',
  styleUrls: ['./billing-charges-details.component.css']
})
export class BillingChargesDetailsComponent implements OnInit {

  types: any;
  selectedBlockName = "Select block";
  isVoucherTypeDisabled = true;
  isPropertyTypeDisabled = true;
  ownerTenant = false;

  id = null;
  name: any;
  isActive: any;
  voucherType: any;
  appliedForBlockId: any;
  propertyType: any;
  isPropertyType: any;
  creditPeriodDays: any;
  lateFeeTemplateIdadType: number;
  sendToOwners: any;
  sendToTenant: any;
  category = [];

  ledgerDefaultGroup = "";
  billingLineItems = [];

  templatePeriodId = null;
  startsOn: any;
  expriesOn: any;
  periods = [];
  activePeriod = 0;
  units: any;
  tags = [];
  public config = <IDayCalendarConfig>{
    format: "DD-MM-YYYY"
  };
  lineItems: any;
  isFormsSubmited: boolean = false;

  societyInvoiceType: number = APP_CONST.INVOICE_TYPE_SOCIETY;
  rentInvoiceType: number = APP_CONST.INVOICE_TYPE_RENT;
  purchaseInvoiceType: number = APP_CONST.INVOICE_TYPE_PURCHASE;
  deleteParams: any = {
    itemId: 0,
    itemName: null,
    btnOk: "Delete",
    btnCancel: "Close",
    deleteText: "Are you sure, you want to delete this template?"
  }
  dropdownSettings: any = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  constructor(
    private _propertyService: PropertyService,
    private _voucherTemplateService: VoucherTemplateService,
    private _tosterService: ToastrService,
    private _uiService: UiService,
    private _blockService: BlockService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _ledgerService: LedgerService,
    private _voucherService: VoucherService,
    private _categoryService: CategoryService,
  ) { }

  ngOnInit() {
    this._uiService.leftMenuItems.next([
      { code: 'F4', name: 'Save', action: this.save.bind(this), class: 'green lighten-1', privilege: APP_CONST.BILLING_CHARGES_SETUP_WRITE },
      { code: 'F5', name: 'Save & Add New', action: this.saveAndNew.bind(this), privilege: APP_CONST.BILLING_CHARGES_SETUP_WRITE },
      { code: 'F7', name: 'Delete', action: this.confirmationPopUp.bind(this), class: 'red lighten-1', privilege: APP_CONST.BILLING_CHARGES_SETUP_REMOVE }
    ]);
    this.categories();
    this.propertyTypes();
    this.getUits();
    this._route.params.subscribe(data => {

      if (data.id > 0) {
        this.id = data.id;
        this.getTemplate(data.id);
      } else {
        this.id = null;
      }
    })
  }

  propertyTypes() {
    this._propertyService.types().subscribe((result: any) => {
      this.types = result;
    })
  }

  changePropertyType(element: any) {
    if (element.target.checked) {
      this.isPropertyTypeDisabled = false;
    } else {
      this.isPropertyTypeDisabled = true;
    }
  }

  showOwnerTanant(val: any) {
    /* if(this.billingLineItems.length>0){
      this.billingLineItems = [];
    } */
    if (val == APP_CONST.INVOICE_TYPE_SOCIETY) {
      this.ledgerDefaultGroup = "Income";
      this.ownerTenant = true;
    } else {
      this.ownerTenant = false;
      this.ledgerDefaultGroup = "Expenses";
    }
  }

  categories() {
    var data = { communityId: CommonMethods.getCommunityId() };
    this._categoryService.all(data).subscribe((result: any) => {
      for (var i = 0; i < result.length; i++) {
        result[i].id = result[i].name;
      }
      this.tags = result;
    });
  }

  newLineItems() {
    this.billingLineItems = [];

  }

  addNewRow() {
    if (this.voucherType == "" || this.voucherType == null) {
      this._tosterService.error("Invoice type can not be blank");
      return;
    }
    var item = {
      chargesType: 2,
      chargesUnitId: 0,
      amountEvalFormula: "",
      debitToLedgerId: 0,
      creditToLedgerId: 0
    }
    this.billingLineItems.push(item);
  }

  deleteItem(item: any) {
    this.billingLineItems.splice(this.billingLineItems.indexOf(item), 1);
  }

  async saveTemplate() {
    if (this.voucherType == null || this.voucherType == "") {
      this._tosterService.error("Invoice type can not be blank");
      return false;
    }
    if (this.name == null || this.name == "") {
      this._tosterService.error("Name can not be blank");
      return false;
    }
    /* if (this.appliedForBlockId == null || this.appliedForBlockId == "") {
      this._tosterService.error("Block can not be blank");
      return false;
    } */
    if (this.isPropertyTypeDisabled != true && (this.propertyType == null || this.propertyType == "")) {
      this._tosterService.error("Property type can not be blank");
      return false;
    }
    if (this.creditPeriodDays == null || this.creditPeriodDays == "") {
      this._tosterService.error("Credit period can not be blank");
      return false;
    }

    if (isNaN(this.creditPeriodDays)) {
      this._tosterService.error("Credit period must be number.");
      return false;
    }

    if (this.startsOn == null || this.startsOn == "") {
      this._tosterService.error("From date can not be blank");
      return false;
    }
    if (this.expriesOn == null || this.expriesOn == "") {
      this._tosterService.error("To date can not be blank");
      return false;
    }
    var startsOn = new Date(CommonMethods.dbToUsDate(this.startsOn)).getTime();
    var expriesOn = new Date(CommonMethods.dbToUsDate(this.expriesOn)).getTime();
    if (startsOn > expriesOn) {
      this._tosterService.error("From date not greater than To date.");
      return false;
    }
    if (this.billingLineItems.length == 0) {
      this._tosterService.error("Please add line items");
      return false;
    } else {
      var checkCreditledger = [];
      for (var i = 0; i < this.billingLineItems.length; i++) {
        if (this.billingLineItems[i].creditToLedgerId == null || this.billingLineItems[i].creditToLedgerId == "") {
          this._tosterService.error("Line item:" + (i + 1) + " Please add Particulars");
          return false;
        }
        if (!checkCreditledger.includes(this.billingLineItems[i].creditToLedgerId)) {
          checkCreditledger.push(this.billingLineItems[i].creditToLedgerId);
        } else {
          this._tosterService.error("Line item:" + (i + 1) + " You can not add same particulars");
          return false;
        }
        if (this.billingLineItems[i].amountEvalFormula == null || this.billingLineItems[i].amountEvalFormula == "") {
          this._tosterService.error("Line item:" + (i + 1) + " Please add Formula");
          return false;
        }
        if (this.billingLineItems[i].chargesUnitId == null || this.billingLineItems[i].chargesUnitId == "") {
          this._tosterService.error("Please add unit");
          return false;
        }
      }
    }
    var tags = [];
    if(this.category.length>0){
      var result = this.category;      
      for(var i=0;i<result.length;i++){
        tags.push(result[i].name);
      }
    }

    var templateData = {
      name: this.name,
      id: this.id,
      communityId: CommonMethods.getCommunityId(),
      isActive: (this.isActive) ? true : false,
      voucherType: this.voucherType,
      appliedForBlockId: this.appliedForBlockId,
      propertyType: (this.propertyType) ? this.propertyType : 0,
      creditPeriodDays: (this.creditPeriodDays) ? this.creditPeriodDays : 0,
      lateFeeTemplateId: 0,
      sendToOwners: (this.sendToOwners) ? true : false,
      sendToTenant: (this.sendToTenant) ? true : false,
      propertyId: 0,
      category: tags.join(",")
    }
    //console.log(templateData);return;
    await this._voucherTemplateService.saveTemplate(templateData).toPromise().then((result: any) => {
      if (result && result.id) {
        this.id = result.id;
        this.saveTemplatePeriod(result.id);
      }
    },
      error => {
        this._tosterService.error(error.message);
      })
  }

  async saveTemplatePeriod(templateId: number) {
    var templatePeriodData = {
      id: this.templatePeriodId,
      communityId: CommonMethods.getCommunityId(),
      voucherTemplateId: templateId,
      startsOn: CommonMethods.dbToUsDate(this.startsOn),
      expriesOn: CommonMethods.dbToUsDate(this.expriesOn)
    }
    await this._voucherTemplateService.saveTemplatePeriod(templatePeriodData).toPromise().then((result: any) => {
      if (result && result.id) {
        //this._tosterService.success("Template period data saved");
        this.saveTemplateLineItems(result.id);
      }
    },
      error => {
        this._tosterService.error(error.message);
      })
  }

  async saveTemplateLineItems(templatePeriodId: number) {
    var templateLineItemsData = {
      communityId: Number(CommonMethods.getCommunityId()),
      voucherTemplatePeriodId: templatePeriodId,
      lineItems: this.billingLineItems
    }
    //console.log(templateLineItemsData);return;
    await this._voucherTemplateService.saveTemplateLineItems(templateLineItemsData).toPromise().then((result: any) => {
      if (result) {
        this._tosterService.success("Billing charges data saved");
        this.isFormsSubmited = true;
      }
      /* this.getTemplate(this.id);
      this._router.navigateByUrl(`billing-charges-details/${this.id}`); */
    },
      error => {
        this._tosterService.error(error.message);
      })
  }

  periodsBytemplate(templateId: number) {
    this._voucherTemplateService.periods(templateId).subscribe((result: any) => {
      this.periods = result;
      this.selectPeriod(result[0]);
      this.activePeriod = result[0].id;
    })
  }

  getTemplate(id: any) {
    this._voucherTemplateService.details(id).subscribe((result: any) => {
      this.name = result.name;
      this.id = result.id;
      this.isActive = result.isActive;
      this.voucherType = result.voucherType;
      this.appliedForBlockId = result.appliedForBlockId;
      this.propertyType = result.propertyType;
      if(result.category){
        var tags = [];
        var category = result.category.split(",");
        for(var i=0;i<category.length;i++){
          tags.push({id:category[i],name:category[i]});
        }
        this.category = tags;
      }
      this.deleteParams.itemId = result.id;
      this.deleteParams.itemName = result.name;
      if (result.propertyType > 0) {
        this.isPropertyType = 1;
        this.isPropertyTypeDisabled = false;
      }
      this.creditPeriodDays = result.creditPeriodDays;
      this.sendToOwners = result.sendToOwners;
      this.sendToTenant = result.sendToTenant;
      if(result.appliedForBlockId){
        this._blockService.details(result.appliedForBlockId).subscribe((result: any) => {
          this.selectedBlockName = result.communityBlockName;
        });
      }      
      this.showOwnerTanant(result.voucherType);
      this.periodsBytemplate(result.id);
    })
  }

  async selectPeriod(period: any) {
    this.startsOn = '';
    this.expriesOn = '';
    this.templatePeriodId = '';
    if (period.id > 0) {
      this.templatePeriodId = period.id;
      this.startsOn = CommonMethods.usDateToDb(period.startsOn);
      this.expriesOn = CommonMethods.usDateToDb(period.expriesOn);
      await this.lineItemsdata(period.id);
      if (!this.billingLineItems) return;
      for (var i = 0; i < this.billingLineItems.length; i++) {
        try {
          var unitDetails = this.units.filter((x: any) => x.id == this.billingLineItems[i].chargesUnitId);
          if (unitDetails.length > 0) {
            this.billingLineItems[i].unitName = unitDetails[0].name;
          }
          await this._ledgerService.details(this.billingLineItems[i].creditToLedgerId).toPromise().then((result: any) => {
            this.billingLineItems[i].ledgerName = result.ledgerName;
          });
        } catch (e) {

        }
      }
      //this.billingLineItems = this.lineItems;
    } else {
      this.billingLineItems = [];
    }
  }

  getUits() {
    this._voucherService.measurementUnits().subscribe((result: any) => {
      this.units = result;
    })
  }

  async lineItemsdata(id: any) {
    await this._voucherTemplateService.period(id).toPromise().then((result: any) => {
      this.billingLineItems = result.lineItems;
    });
  }

  confirmationPopUp() {
    if (this.id > 0) {
      this._uiService.deleteItemData = this.deleteParams;
      this._uiService.deleteItemCallBack = this.delete.bind(this);
      this._router.navigate([{
        outlets: { centerPopup: ['delete-item'] }
      }]);
    }
  }

  delete() {
    if (this.deleteParams.pressBtn == "Ok") {
      this._voucherTemplateService.delete(this.deleteParams.itemId).subscribe((result: any) => {
        this._tosterService.success("Template Deleted");
        this.resetForm();
        return;
      });
    }
  }

  async save() {
    try {
      await this.saveTemplate();
      setTimeout(() => {
        if (!this.isFormsSubmited) return;
        if (this.id > 0) {
          this.isFormsSubmited = false;
          this.getTemplate(this.id);
          this._router.navigateByUrl(`billing-charges-details/${this.id}`);
          return false;
        }
      }, 3000);
    }
    catch (err) {
    }
  }

  async saveAndNew() {
    try {
      await this.saveTemplate();
      setTimeout(() => {
        if (!this.isFormsSubmited) return;
        if (this.isFormsSubmited) {
          this.isFormsSubmited = false;
          this.resetForm();
        }
      }, 3000);
    }
    catch (err) {
    }
  }

  async resetForm() {
    this.types = [];
    this.selectedBlockName = "Select block";
    this.isVoucherTypeDisabled = true;
    this.isPropertyTypeDisabled = true;
    this.ownerTenant = false;

    this.id = null;
    this.name = "";
    this.isActive = "";
    this.voucherType = "";
    this.appliedForBlockId = "";
    this.isPropertyType = "";
    this.creditPeriodDays = "";
    this.lateFeeTemplateIdadType = 0;
    this.sendToOwners = "";
    this.sendToTenant = "";

    this.ledgerDefaultGroup = "";
    this.billingLineItems = [];

    this.templatePeriodId = null;
    this.startsOn = "";
    this.expriesOn = "";
    this.periods = [];
    this.tags = [];
    this.activePeriod = 0;
    this.units = "";
    this.category = null;
    this.propertyTypes();
    setTimeout(() => {
      this._router.navigateByUrl("billing-charges-details/0");
    }, 100)
  }

  redirectionType(mode: any, displayType: any) {
    let params: any;
    if (displayType == 'listing') {
      if (mode == "billing_charge") {
        params = [{ outlets: { rightMainPopup: ["billing-charges"] } }];
      } else if (mode == "dashboard") {
        this._router.navigateByUrl("/dashboard"); return;
      }
      this._router.navigate(params); return;
    }
  }

  ngOnDestroy(): void {
    this._uiService.leftMenuItems.next([]);
  }

}
