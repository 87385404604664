import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../../app/config';
import { APP_CONST } from '../../app/app-constants';

@Injectable({
  providedIn: 'root'
})
export class LedgerService {


  constructor(private _http: HttpClient) {
  }

  itemsByGroup(data: any) {
    return this._http
      .post(config.apiEndPointBase + "/ledger/listByGroup", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  all(data: any) {
    return this._http
      .post(config.apiEndPointBase + "/ledger/list", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  save(data: any) {
    if (data.id) {
      return this._http
        .put(config.apiEndPointBase + "/ledger", data, { responseType: 'text' })
        .pipe(map((response: any) => {
          return response;
        }));
    } else {
      return this._http
        .post(config.apiEndPointBase + "/ledger", data, {})
        .pipe(map((response: any) => {
          return response;
        }));
    }
  }

  delete(id: any) {
    return this._http.delete(config.apiEndPointBase + `/ledger/${id}`, { responseType: 'text' });
  }

  itemByDefaultGroup(data: any) {
    return this._http
      .post(config.apiEndPointBase + "/ledger/itemByDefaultGroup", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  listOwnerLedgers(data: any) {
    return this._http
      .post(config.apiEndPointBase + "/ledger/listOwnerLedgers", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  listVendorLedgers(data: any) {
    return this._http
      .post(config.apiEndPointBase + "/ledger/listVendorLedgers", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  listAccountables() {
    return this._http.get(config.apiEndPointBase + `/ledger/general_ledgers`, {});
  }

  details(id: any) {
    return this._http.get(config.apiEndPointBase + `/ledger/${id}`);
  }

  accountTypes() {
    return this._http.get(config.apiEndPointBase + "/account_types");
  }

  accountTypeByTag(tag: any) {
    return this._http.get(config.apiEndPointBase + `/account_type/${tag}`);
  }
  
  accountTypeIdByName(name: string) {
    switch (name) {
      case 'Owners':
        var id = APP_CONST.ACCOUNT_TYPE_OWNER;
        break;
      case 'Tenants':
        var id = APP_CONST.ACCOUNT_TYPE_TENANT;
        break;
      case 'Bank':
        var id = APP_CONST.ACCOUNT_TYPE_BANK;
        break;
      case 'Cash':
        var id = APP_CONST.ACCOUNT_TYPE_CASH;
        break;
      case 'Vendors':
        var id = APP_CONST.ACCOUNT_TYPE_VENDOR;
        break;
      case 'Outsiders':
        var id = APP_CONST.ACCOUNT_TYPE_CUSTOMER;
        break;
      default:
        var id = APP_CONST.ACCOUNT_TYPE_GENERAL;
    }
    return id;
  }

  accountTypeNameById(id: Number) {
    switch (id) {
      case APP_CONST.ACCOUNT_TYPE_OWNER:
        var name = 'Owners';
        break;
      case APP_CONST.ACCOUNT_TYPE_TENANT:
        var name = 'Tenants';
        break;
      case APP_CONST.ACCOUNT_TYPE_BANK:
        var name = 'Bank';
        break;
      case APP_CONST.ACCOUNT_TYPE_CASH:
        var name = 'Cash';
        break;
      case APP_CONST.ACCOUNT_TYPE_VENDOR:
        var name = 'Vendors';
        break;
      case APP_CONST.ACCOUNT_TYPE_CUSTOMER:
        var name = 'Outsiders';
        break;
      default:
        var name = 'General Ledger';
    }
    return name;
  }

  linkUser(id: any) {
    return this._http.get(config.apiEndPointBase + `/ledger/link_user_details/${id}`);
  }

  inviteMember(data: any) {
    return this._http.post(config.apiEndPointBase + "/ledger/invite_member", data, { responseType: 'text' })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  cancelInvitation(data: any) {
    return this._http.put(config.apiEndPointBase + "/ledger/cancel_invitation", data, { responseType: 'text' })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  transactions(data:any){
    return this._http.post(config.apiEndPointBase + "/ledger/transaction", data)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  unAssignUser(data:any){
    return this._http.post(config.apiEndPointBase + "/ledger/unassign_user", data)
      .pipe(map((response: any) => {
        return response;
      }));
  }
}
