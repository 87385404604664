import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LedgerModel } from './../Model/ledger.model';
import { MaintenanceLogModel } from '../Model/maintenance-log.model';
import { MaintenanceContractModel } from '../Model/maintenance-contract.model';
import { TodoModel } from '../Model/todo.model';

@Injectable({
    providedIn: 'root'
})

export class TodoFormBuilder {
    private form;
    private validationRules = {
        task: ['', Validators.required],
        description: ['', ''],
        dueDate: ['', Validators.required],
        priority: ['', Validators.required],
        linkItemTypeId: ['', ''],
        linkItemId: ['', ''],
        linkItemName: ['', ''],
        assignToName: ['', Validators.required],
        assignToDesignation: ['', Validators.required],
        assignToMobile: ['', Validators.required],
        category: ['', Validators.required],
        status: ['', Validators.required],
        recurringInterval: ['', ''],
        recurringIntervalUnit: ['', ''],
        recurringMaxRepeat: ['', ''],
        completeDate: ['', '']
    }

    constructor(private fb: FormBuilder) {
        this.form = fb.group(this.validationRules);
    }

    getForm() {
        return this.form;
    }

    setValues(response: TodoModel) {
        this.form.patchValue({
            id: response.id,
            task: response.task,
            description: response.description,
            dueDate: response.dueDate,
            priority: response.priority,
            linkItemTypeId: response.linkItemTypeId,
            linkItemId: response.linkItemId,
            linkItemName: response.linkItemName,
            assignToName: response.assignToName,
            assignToDesignation: response.assignToDesignation,
            assignToMobile: response.assignToMobile,
            category: response.category,
            status: response.status,
            recurringInterval: response.recurringInterval,
            recurringIntervalUnit: response.recurringIntervalUnit,
            recurringMaxRepeat: response.recurringMaxRepeat,
            completeDate: response.completeDate
        });
    }
}