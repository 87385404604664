import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CommunityModel } from '../Model/community.model';

@Injectable({
    providedIn: 'root'
})
export class CommunityFormBuilder {

    private form;
    private validationRules = {
        name: ['', Validators.required],
        registrationType: ['',''],
        registrationNumber: ['',''],
        communityStatus: ['',''],
        houseNumber: ['', Validators.required],
        street: ['', Validators.required],
        email: ['', ''],
        mobile: ['', ''],
        block: ['', ''],
        landmark: ['', ''],
        postOffice: ['', Validators.required],
        policeStation: ['', Validators.required],
        town: ['', Validators.required],
        district: ['', Validators.required],
        subDistrict: ['', ''],
        pinCode: ['', Validators.required],
        stateId: ['', Validators.required],
        countryId: ['', Validators.required],
        image: ['',''],
        numberOfBlocks: ['',''],
        numberOfBuildings: ['',''],
        numberOfResources: ['',''],
        numberOfCommercialUnits: ['',''],
        numberOfResidentialUnits: ['',''],
        numberOfGarages: ['',''],
        numberOfParkingSpaces: ['',''],
        numberOfMembers: ['',''],
        numberOfActiveMembers: ['',''],
        communityPayable: ['',''],
        communityReceivable: ['',''],
        isSample: ['',''],
        centrexNo: ['','']
    }


    constructor(private fb: FormBuilder) {
        this.form = fb.group(this.validationRules);
    }

    getForm() {
        return this.form;
    }

    setValues(response: CommunityModel) {
        this.form.patchValue({
            id: response.id,
            name: response.name,
            registrationType: response.registrationType,
            registrationNumber: response.registrationNumber,
            mobile: response.mobile,
            email: response.email,
            communityStatus: response.communityStatus,
            houseNumber: response.houseNumber,
            street: response.street,
            block: response.block,
            landmark: response.landmark,
            postOffice: response.postOffice,
            policeStation: response.policeStation,
            town: response.town,
            district: response.district,
            subDistrict: response.subDistrict,
            pinCode: response.pinCode,
            stateId: response.stateId,
            countryId: response.countryId,
            image: response.image,
            numberOfBlocks: response.numberOfBlocks,
            numberOfBuildings: response.numberOfBuildings,
            numberOfResources: response.numberOfResources,
            numberOfCommercialUnits: response.numberOfCommercialUnits,
            numberOfResidentialUnits: response.numberOfResidentialUnits,
            numberOfGarages: response.numberOfGarages,
            numberOfParkingSpaces: response.numberOfParkingSpaces,
            numberOfMembers: response.numberOfMembers,
            numberOfActiveMembers: response.numberOfActiveMembers,
            communityPayable: response.communityPayable,
            communityReceivable: response.communityReceivable,
            isSample: response.isSample,
            centrexNo: response.centrexNo
        });
    }
}