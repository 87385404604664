import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CategoryModel} from '../Model/category.model';

@Injectable({
    providedIn: 'root'
})

export class CategoryFormBuilder {
    private form;
    private validationRules = {
        name: ['', Validators.required]
    }

    constructor(private fb: FormBuilder) {
        this.form = fb.group(this.validationRules);
    }

    getForm() {
        return this.form;
    }

    setValues(response: CategoryModel) {
        this.form.patchValue({
            id: response.id,
            communityId: response.communityId,
            name: response.name
        });
    }
}