import { Deseriazable } from './deserializable.model';

export class ResourceModel implements Deseriazable
{
    id: number;
    communityId: number;
    communityName: string;
    resourceName: string;
    resourceDescription: string;
    buildingId: number;
    communityBlockId: number;
    propertyId: number;
    measurementUnitId: number;
    quantity: number;
    currentValuation: any;
    serialNumber: string;
    manufacturer: string;
    model: string;
    installOn: string;
    purchaseOn: string;
    isActive: any;
    deactivatedOn:any;
    deactivationReason: any;


    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }
}