import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { UiService } from '../ui.service';
import { APP_CONST } from '../app-constants';
import { ToastrService } from 'ngx-toastr';
import { CommunityService } from '../api-client/community.service';
import { VoucherService } from '../api-client/voucher.service';
import { LedgerService } from '../api-client/ledger.service';
import { PropertyService } from '../api-client/property.service';
import { BuildingService } from '../api-client/building.service';
import { ResourceService } from '../api-client/resource.service';
import { PaymentService } from '../api-client/payment.service';
import { LateFeeTemplateService } from '../api-client/late-fee-template.service';
import { DiscountTemplateService } from '../api-client/discount-template.service';
import { config } from '../config';
import { IDayCalendarConfig } from 'ng2-date-picker';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.css']
})
export class PaymentDetailsComponent implements OnInit, OnDestroy {

  invoiceType: number;
  paymentTypeString: string;
  payType: number;
  payableInvoices = [];
  partyAccountId: number;
  partyAccountName: string = "--Select--";;
  debitAccountId: number;
  defaultDebitAccountName: string = 'Select Account';
  debitAccountName: string = "";
  accountChangeType: string = 'from';
  defaultAccountSelectedText: any = 'Select Party Account';
  accountSelectedText: any = '';
  accountSelectionType: any = 'owners';
  ledgerDefaultGroup = null;
  itemLedgerDefaultGroup = "";
  discountLedgerDefaultGroup = "";
  billingLineItems = [];
  readonlyLineItems = [];
  isPeriodic: number = 0;
  communityName: string;
  communityAddress: string;
  propertyTypes: any;
  propertyObj: any = { id: null, name: "" };
  accountObj: any = { id: 0, name: "" };
  invoiceTotal: number = 0;
  generalTotal: number = 0;
  lateFeeTotal: number = 0;
  discountTotal: number = 0;
  defaultPropertySelectedText: any = 'Select Property / Asset';
  propertySelectedText: any = '';
  lineItemDescriptions = [];
  templateId: number = null;
  templateSelectedText: any = '--Select--';
  selectedBlockId: number = null;
  propertyUrl: any;
  units: any;
  submitted: boolean = false;
  paymentMethod: any = '';
  transactionId: any = null;
  chequeNumber: any = null;
  bankName: any = null;
  bankBranch: any = null;
  chequeDate: any = null;
  chequeClearanceDate: any = null;
  paymentDescription: any = null;
  linkedPaymentId: number;
  id: number;
  voucherNumber: string = null;
  voucherType: number;
  voucherFromName: string = '';
  voucherFromAddress: string = '';
  voucherToName: string = '';
  voucherToAddress: string = '';
  narration: string = '';
  voucherDate: string = CommonMethods.usDateToDb(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
  paymentType: string;
  status: string = "Pending";
  ledgerToId: number = 0;
  ledgerFromId: number = 0;
  totalGrossAmount: number;
  totalNetAmount: number = 0;
  pendingAmount: number = 0;
  propertyId: number = null;
  propertyTypeId: number = 0;
  communityId: number;
  manualDueDate: string = '';
  lateFeeTemplateId: number;
  discountTemplateId: number;
  defaultLateFeeSelectedText: string = 'Select Late Fee Template';
  lateFeeSelectedText: string = '';
  defaultDiscountSelectedText: string = 'Select Discount Template';
  discountSelectedText: string = '';
  latefeeLineItems = [];
  discountLineItems = [];
  latefeeTemplatePeriodId = null;
  latefeeStartsOn: any;
  latefeeExpriesOn: any;
  latefeePeriods = [];
  latefeeActivePeriod = 0;
  public config = <IDayCalendarConfig>{
    format: "DD-MM-YYYY"
  };
  listName: string = "";
  selectedTypeName: string = "";
  particularsSelectedText:any = 'Select Particular';
  unitsSelectedText:any = 'Select Unit';
  allowedAccountableLedger:number = null;
  societyInvoiceType: number = APP_CONST.INVOICE_TYPE_SOCIETY;
  rentInvoiceType: number = APP_CONST.INVOICE_TYPE_RENT;
  purchaseInvoiceType: number = APP_CONST.INVOICE_TYPE_PURCHASE;
  assetPropertyType: number = APP_CONST.PROPERTY_TYPE_ASSETS;
  buildingPropertyType: number = APP_CONST.PROPERTY_TYPE_BUILDINGS;
  collectionInvoice: number = APP_CONST.INVOICE_TYPE_COLLECTION;

  readPrivilege:string;
  writePrivilege:string;
  trashPrivilege:string;
  removePrivilege:string;

  constructor(private _uiService: UiService,
    private _router: Router,
    private _route: ActivatedRoute,
    private datePipe: DatePipe,
    private _tosterService: ToastrService,
    private _communityService: CommunityService,
    private _voucherService: VoucherService,
    private _ledgerService: LedgerService,
    private _propertyService: PropertyService,
    private _buildingService: BuildingService,
    private _resourceService: ResourceService,
    private _paymentService: PaymentService,
    private _lateFeeTemplateService: LateFeeTemplateService, 
    private _discountTemplateService: DiscountTemplateService) {
    /*---Get invoices if selected any---*/
    if (this._router.getCurrentNavigation().extras.state) {
      var state = this._router.getCurrentNavigation().extras.state;
      var invoices = [];
      if (state.invoices) {
        invoices = Object.values(state.invoices);
        this.payableInvoices = invoices;
      }
    }
  }

  ngOnInit(): void {
    this.getPropertyTypes();
    this.propertyTypeChanged();
    this.getUnits();
    this._route.data.subscribe(params => {
      this.invoiceType = parseInt(params.invoiceType);
      this.communityId = parseInt(CommonMethods.getCommunityId());
      this._communityService.details(this.communityId).subscribe((result: any) => {
        this.communityName = result.name;
        this.communityAddress = result.address;
        if (this.invoiceType == this.societyInvoiceType || this.invoiceType == this.rentInvoiceType) {
          this.paymentTypeString = "Collection";
          this.payType = 1;
          //this.ledgerDefaultGroup = "Cash";
          this.accountSelectionType = "Income";
          this.itemLedgerDefaultGroup = "Income";
          this.discountLedgerDefaultGroup = "Expenses";
          this.voucherToName = this.communityName;
          this.voucherToAddress = this.communityAddress;
          this.ledgerToId = 0;
          this.accountChangeType = 'from';
        } else {
          this.paymentTypeString = "Expenses";
          this.payType = 2;
          //this.ledgerDefaultGroup = "Cash";
          this.accountSelectionType = "vendors";
          this.itemLedgerDefaultGroup = "Expenses";
          this.discountLedgerDefaultGroup = "Income";
          this.voucherFromName = this.communityName;
          this.voucherFromAddress = this.communityAddress;
          this.ledgerFromId = 0;
          this.accountChangeType = 'to';
        }
        if (this.payableInvoices.length > 0) {
          this.getAccount();
          this.getAdditionalLineItems();
        }
      }, error => {
        this._tosterService.error(error.message);
      });
      this.createLineItemsFromInvoices();
    });
    this._route.params.subscribe(params => {
      if (params.id) {
        this.getPayment(params.id);
        this.id = params.id;
      }
    });
    if (this.invoiceType == this.societyInvoiceType) {
      this.listName = "Collections";
      this.selectedTypeName = "Collection";
      this.readPrivilege = APP_CONST.COLLECTION_READ;
      this.writePrivilege = APP_CONST.COLLECTION_WRITE;
      this.trashPrivilege = APP_CONST.COLLECTION_TRASH;
      this.removePrivilege = APP_CONST.COLLECTION_REMOVE;
    } else if (this.invoiceType == this.rentInvoiceType) {
      this.listName = "Collections";
      this.selectedTypeName = "Collection";
      this.readPrivilege = APP_CONST.COLLECTION_READ;
      this.writePrivilege = APP_CONST.COLLECTION_WRITE;
      this.trashPrivilege = APP_CONST.COLLECTION_TRASH;
      this.removePrivilege = APP_CONST.COLLECTION_REMOVE;
    } else {
      this.listName = "Payments";
      this.selectedTypeName = "Payment";
      this.readPrivilege = APP_CONST.PAYMENT_WRITE;
      this.writePrivilege = APP_CONST.PAYMENT_WRITE;
      this.trashPrivilege = APP_CONST.PAYMENT_TRASH;
      this.removePrivilege = APP_CONST.PAYMENT_REMOVE;
    }
    if (!this.id) {
      this._uiService.leftMenuItems.next([
        { code: 'F2', name: this.listName, action: this.goToList.bind(this), privilege: this.readPrivilege },
        { code: 'F4', name: 'Save', action: this.onSubmit.bind(this), class: 'green lighten-1', privilege: this.writePrivilege },
        { code: 'F5', name: 'Save & New', action: this.onSubmitAndNew.bind(this), privilege: this.writePrivilege }
      ]);
    }
  }

  /*---Get all Property Types from DB---*/
  getPropertyTypes(): void {
    this._propertyService.types().subscribe((result: any) => {
      this.propertyTypes = result;
    }, error => {
      this._tosterService.error(error.message);
    });
  }

  /*---Get all Units from DB---*/
  getUnits(): void {
    this._voucherService.measurementUnits().subscribe((result: any) => {
      this.units = result;
    }, error => {
      this._tosterService.error(error.message);
    });
  }

  /*---Initialize new line item---*/
  newLineItems(): void {
    this.billingLineItems = [];
  }

  /*---Add new line item row---*/
  addNewRow(): void {
    var item = {
      rate: 0,
      quantity: 0,
      unit: "",
      description: "",
      itemGrossAmount: 0,
      debitToAccountId: 0,
      creditToAccountId: 0,
      account: { id: 0, name: "" },
      unitObj: { id: 0, name: "" }
    }
    this.billingLineItems.push(item);
  }

  /*---Delete line item row and calculate invoice total---*/
  deleteItem(item: any): void {
    this.billingLineItems.splice(this.billingLineItems.indexOf(item), 1);
    this.invoiceTotalChange();
  }

  /*---Triggered when Property Type is changed---*/
  propertyTypeChanged(): void {
    if (this.propertyTypeId == this.assetPropertyType) {
      this.propertyUrl = { "mode": "ResourceSelections" };
    } else if (this.propertyTypeId == this.buildingPropertyType) {
      this.propertyUrl = { "mode": "BuildingSelections" };
    } else {
      this.propertyUrl = { "mode": "PropertySelections", "type": this.propertyTypeId };
    }
  }

  /*---Calculate Invoice Total Amount---*/
  invoiceTotalChange(): void {
    this.invoiceTotal = 0;
    this.generalTotal = 0;
    this.lateFeeTotal = 0;
    this.discountTotal = 0;
    this.billingLineItems.forEach(obj => {
      obj.rate = isNaN(obj.rate)?0:parseFloat(obj.rate);
      obj.quantity = isNaN(obj.quantity)?0:parseInt(obj.quantity);
      obj.itemGrossAmount = parseInt(obj.quantity) * parseFloat(obj.rate);
      obj.itemGrossAmount = isNaN(obj.itemGrossAmount)?0:obj.itemGrossAmount;
      this.generalTotal += parseFloat(obj.itemGrossAmount);
      this.invoiceTotal += parseFloat(obj.itemGrossAmount);
    });
    this.latefeeLineItems.forEach(obj => {
      obj.rate = isNaN(obj.rate)?0:parseFloat(obj.rate);
      obj.quantity = isNaN(obj.quantity)?0:parseInt(obj.quantity);
      obj.itemGrossAmount = parseInt(obj.quantity) * parseFloat(obj.rate);
      obj.itemGrossAmount = isNaN(obj.itemGrossAmount)?0:obj.itemGrossAmount;
      this.lateFeeTotal += parseFloat(obj.itemGrossAmount);
      this.invoiceTotal += parseFloat(obj.itemGrossAmount);
    });
    this.discountLineItems.forEach(obj => {
      obj.rate = isNaN(obj.rate)?0:parseFloat(obj.rate);
      obj.quantity = isNaN(obj.quantity)?0:parseInt(obj.quantity);
      obj.itemGrossAmount = parseInt(obj.quantity) * parseFloat(obj.rate);
      obj.itemGrossAmount = isNaN(obj.itemGrossAmount)?0:obj.itemGrossAmount;
      this.discountTotal += parseFloat(obj.itemGrossAmount);
      this.invoiceTotal -= parseFloat(obj.itemGrossAmount);
    });
    this.invoiceTotal = (this.invoiceTotal<0 || isNaN(this.invoiceTotal))?0:this.invoiceTotal;
  }

  /*---Get Account details---*/
  getAccount(): void {
    if (this.invoiceType == this.purchaseInvoiceType) {
      this._voucherService.getVoucher(this.payableInvoices[0]).subscribe((result: any) => {
        this.ledgerToId = result.ledgerFromId;
        this.changeAccount({ id: this.ledgerToId }, "to");
        if(result.propertyId && result.propertyTypeId){
          this.propertyTypeId = result.propertyTypeId;
          this.propertyId = result.propertyId;
          this.getPropertyDetail();
        }
      }, error => {
        this._tosterService.error(error.message);
      });
    } else {
      this._voucherService.getVoucher(this.payableInvoices[0]).subscribe((result: any) => {
        this.ledgerFromId = result.ledgerToId;
        this.changeAccount({ id: this.ledgerFromId }, "from");
        if(result.propertyId && result.propertyTypeId){
          this.propertyTypeId = result.propertyTypeId;
          this.propertyId = result.propertyId;
          this.getPropertyDetail();
        }
      }, error => {
        this._tosterService.error(error.message);
      });
    }
  }

  /*---Triggered when Account changed---*/
  changeAccount(elem: any, type: any): void {
    if(elem.id > 0){
      if (type == "to") {
        this._ledgerService.details(elem.id).subscribe((result: any) => {
          this.voucherToName = result.ledgerName;
          this.voucherToAddress = result.address;
          this.accountSelectedText = result.ledgerName;
          this.ledgerToId = elem.id;
        }, error => {
          this._tosterService.error(error.message);
        });
      } else {
        this._ledgerService.details(elem.id).subscribe((result: any) => {
          this.voucherFromName = result.ledgerName;
          this.voucherFromAddress = result.address;
          this.accountSelectedText = result.ledgerName;
          this.ledgerFromId = elem.id;
        }, error => {
          this._tosterService.error(error.message);
        });
      }
    }else{
      if (type == "to") {
        this.voucherToName = null;
        this.voucherToAddress = null;
        this.accountSelectedText = '';
        this.ledgerToId = 0;
      }else{
        this.voucherFromName = null;
        this.voucherFromAddress = null;
        this.accountSelectedText = null;
        this.ledgerFromId = 0;
      }
    }
  }

  /*---Triggered when Property changed---*/
  changeProperty(): void {
    if (this.propertyObj.id > 0) {
      this.propertyId = this.propertyObj.id;
      this.getBlock();
      if(this.invoiceType == this.purchaseInvoiceType){
        this.allowedAccountableLedger = null;
      }else{
        this.getAssociateLedger();
      }
    }else{
      this.propertyId = null;
      this.selectedBlockId = null;
    }
  }

  /*---Get block id from selected property---*/
  getBlock(): void {
    if (this.propertyId > 0) {
      var data: any;
      if (this.propertyTypeId == this.assetPropertyType) {
        data = { resourceId: this.propertyId };
        this._resourceService.getBlockByResourceId(data).subscribe((result: any) => {
          this.selectedBlockId = result.id;
        }, error => {
          this._tosterService.error(error.message);
        });
      } else if (this.propertyTypeId == this.buildingPropertyType) {
        data = { buildingId: this.propertyId };
        this._buildingService.getBlockByBuildingId(data).subscribe((result: any) => {
          this.selectedBlockId = result.id;
        }, error => {
          this._tosterService.error(error.message);
        });
      } else {
        data = { propertyId: this.propertyId };
        this._propertyService.getBlockByPropertyId(data).subscribe((result: any) => {
          this.selectedBlockId = result.id;
        }, error => {
          this._tosterService.error(error.message);
        });
      }
    }
  }

  /*---Get Associate Ledger from selected property---*/
  getAssociateLedger(): void {
    let data:any;
    if(this.propertyId > 0 ){
      data = {propertyId:this.propertyId};
      this._propertyService.associateLedger(data).subscribe((result:any)=>{
        this.allowedAccountableLedger = result.ledgerId;
      }, error => {
        this._tosterService.error(error.message);
        this.allowedAccountableLedger = null;
      });
    }
  }

  /*---Triggered when Payment method changed---*/
  paymentMethodChanged(): void {
    this.debitAccountName = null;
    this.debitAccountId = null;
    if(this.paymentMethod != ""){
      if (this.paymentMethod == "Cash") {
        this.ledgerDefaultGroup = "Cash";
        this.transactionId = null;
        this.chequeNumber = null;
        this.bankName = null;
        this.bankBranch = null;
        this.chequeDate = null;
        this.chequeClearanceDate = null;
      } else {
        this.ledgerDefaultGroup = "Bank";
        if(this.paymentMethod == "NEFT" || this.paymentMethod == "UPI"){
          this.chequeNumber = null;
          this.chequeDate = null;
          this.chequeClearanceDate = null;
        }else if(this.paymentMethod == "Bank"){
          this.transactionId = null;
          this.chequeNumber = null;
          this.chequeDate = null;
          this.chequeClearanceDate = null;
        }else{
          this.chequeNumber = null;
          this.chequeDate = null;
          this.chequeClearanceDate = null;
        }
      }
    }else{
      this.chequeNumber = null;
      this.chequeDate = null;
      this.chequeClearanceDate = null;
      this.ledgerDefaultGroup = null;
    }
    this.autoSelectAccount(this.ledgerDefaultGroup);
  }

  /*--Auto select debit/credit account according to payment method--*/ 
  autoSelectAccount(accountType: string): void {
    if(accountType){
      let accountTypeId = this._ledgerService.accountTypeIdByName(accountType);
      let data = { communityId: CommonMethods.getCommunityId(), accountTypeId: accountTypeId };
      this._ledgerService.all(data).subscribe((result: any) => {
        if(result.length > 0){
          this.debitAccountId = result[0].id;
          this.debitAccountName = result[0].ledgerName;
        }else{
          this.debitAccountId = null;
          this.debitAccountName = "";
        }
      });
    }else{
      this.debitAccountId = null;
      this.debitAccountName = "";
    }
  }

  /*---Get Property details from DB of selected property---*/
  getPropertyDetail(): void {
    if (this.propertyId > 0) {
      this._propertyService.details(this.propertyId).subscribe((result: any) => {
        this.propertyObj = { id: this.propertyId, name: result.unitNo };
        this.propertySelectedText = result.unitNo;
      }, error => {
        this._tosterService.error(error.message);
      });
    }
  }

  /*---Get line items from payment if already saved / if new payment, get from selected invoices, if no invoice selected then create empty row---*/
  createLineItemsFromInvoices(): void {
    if (this.id > 0) {
      this.getPayment(this.id);
    } else {
      if (this.payableInvoices.length > 0) {
        if (this.invoiceType == this.purchaseInvoiceType) {
          this.partyAccountId = this.ledgerToId;
          this.partyAccountName = this.voucherToName;
        } else {
          this.partyAccountId = this.ledgerFromId;
          this.partyAccountName = this.voucherFromName;
        }
        var countInvoices = this.payableInvoices.length;
        var num = 1;
        this.narration = 'Paid invoice ';
        this.payableInvoices.forEach(obj => {
          this._voucherService.getVoucher(obj).subscribe((result: any) => {
            this.narration += result.voucherNumber+' ';
            if(result.periodStartFrom != null && result.periodEndOn != null){
              this.narration += 'of period '+CommonMethods.usDateToDb(this.datePipe.transform(result.periodStartFrom, "yyyy-MM-dd"))+' - '+ CommonMethods.usDateToDb(this.datePipe.transform(result.periodEndOn, "yyyy-MM-dd"));
            }
            if(result.propertyName != null){
              this.narration += ' for '+result.propertyName+', ';
            }else{
              this.narration += ', ';
            }
            
            var item = {
              rate: result.pendingAmount,
              quantity: 1,
              unit: "Piece(s)",
              description: result.narration,
              itemGrossAmount: result.pendingAmount,
              debitToAccountId: 0,
              creditToAccountId: this.partyAccountId,
              account: { id: this.partyAccountId, name: this.partyAccountName },
              unitObj: { id: 24, name: "Piece(s)" }
            }
            this.billingLineItems.push(item);
            this.readonlyLineItems.push(result);
            this.invoiceTotalChange();
          }, error => {
            this._tosterService.error(error.message);
          });
          num++;
        });
      } else {
        this.addNewRow();
      }
    }
  }

  /*---Validate all parameters---*/
  validateParameters(): boolean {
    if (this.invoiceTotal <= 0) {
      this._tosterService.error("Payment amount must be always more than 0.");
      return false;
    }
    if (this.invoiceType == this.purchaseInvoiceType && (!this.ledgerToId || this.ledgerToId == 0 || this.ledgerToId == null)) {
      this._tosterService.error("Please select an account to pay.");
      return false;
    }
    if (this.invoiceType != this.purchaseInvoiceType && (!this.ledgerFromId || this.ledgerFromId == 0 || this.ledgerFromId == null)) {
      this._tosterService.error("Please select an account to pay.");
      return false;
    }
    if(this.invoiceType != this.purchaseInvoiceType && this.propertyId > 0 && this.allowedAccountableLedger > 0 && this.ledgerFromId != this.allowedAccountableLedger){
      this._tosterService.error("Selected account is not associated with the selected property.");
      return false;
    }
    if (this.invoiceType == this.purchaseInvoiceType && (!this.voucherNumber || this.voucherNumber == "" || this.voucherNumber == null)) {
      this._tosterService.error("Invoice Number can not be blank.");
      return false;
    }
    if (!this.voucherDate || this.voucherDate == "" || this.voucherDate == null) {
      this._tosterService.error("Payment Date can not be blank.");
      return false;
    }
    if (!this.narration || this.narration == "" || this.narration == null) {
      this._tosterService.error("Narration can not be blank.");
      return false;
    }
    if (!this.debitAccountId || this.debitAccountId == 0 || this.debitAccountId == null) {
      if(this.invoiceType == this.purchaseInvoiceType){
        this._tosterService.error("Please select an account to credit.");
      }else{
        this._tosterService.error("Please select an account to debit.");
      }
      return false;
    }
    if (!this.paymentMethod || this.paymentMethod == "" || this.paymentMethod == null) {
      this._tosterService.error("Please select a payment method.");
      return false;
    } else {
      if (this.paymentMethod == "Bank" || this.paymentMethod == "NEFT" || this.paymentMethod == "UPI") {
        if (!this.transactionId || this.transactionId == "") {
          this._tosterService.error("Please input Transaction ID.");
          return false;
        }
      } else if (this.paymentMethod == "Cheque") {
        if (!this.chequeNumber || this.chequeNumber == "" || this.chequeNumber == null) {
          this._tosterService.error("Please input Cheque Number.");
          return false;
        }
        if (!this.chequeDate || this.chequeDate == "" || this.chequeDate == null) {
          this._tosterService.error("Please input Date of Cheque.");
          return false;
        }
        // if (!this.chequeClearanceDate || this.chequeClearanceDate == "" || this.chequeClearanceDate == null) {
        //   this._tosterService.error("Please input Date of Cheque Clearance.");
        //   return false;
        // }
        // if(new Date(CommonMethods.dbToUsDate(this.chequeDate)).getTime() > new Date(CommonMethods.dbToUsDate(this.chequeClearanceDate)).getTime()){
        //   this._tosterService.error("Cheque date can not be after of cheque clearance date.");
        //   return false;
        // }
        if (!this.bankName || this.bankName == "" || this.bankName == null) {
          this._tosterService.error("Please input Bank Name.");
          return false;
        }
        if (!this.bankBranch || this.bankBranch == "" || this.bankBranch == null) {
          this._tosterService.error("Please input Bank Branch Name.");
          return false;
        }
        this.status = "Processing";
      }
    }
    return true;
  }

  /*---Validate all line items---*/
  validateLineItems(): boolean {
    let error: boolean = false;
    let errorMsg: string = "";
    let allIndex: number = 0;
    
    var allLineItems = [];
    allLineItems.push(this.billingLineItems);
    allLineItems.push(this.latefeeLineItems);
    allLineItems.push(this.discountLineItems);

    allLineItems.forEach(lineItem => {
      if (lineItem.length > 0) {
        this.lineItemDescriptions = [];
        lineItem.forEach(obj => {
          if (allIndex == 0) {
            if (this.payableInvoices.length > 0) {
              if (this.invoiceType == this.purchaseInvoiceType) {
                obj.debitToAccountId = this.ledgerToId;
              } else {
                obj.creditToAccountId = this.ledgerFromId;
              }
            } else {
              if (obj.account) {
                if (this.invoiceType == this.purchaseInvoiceType) {
                  obj.debitToAccountId = obj.account.id;
                  obj.description = obj.account.name;
                } else {
                  obj.creditToAccountId = obj.account.id;
                  obj.description = obj.account.name;
                }
              }
            }
          } else {
            if (obj.account) {
              if (this.invoiceType == this.purchaseInvoiceType) {
                obj.debitToAccountId = obj.account.id;
                obj.description = obj.account.name;
              } else {
                obj.creditToAccountId = obj.account.id;
                obj.description = obj.account.name;
              }
            }
          }
          if (this.invoiceType == this.purchaseInvoiceType) {
            obj.creditToAccountId = this.debitAccountId;
          } else {
            obj.debitToAccountId = this.debitAccountId;
          }
          if (allIndex == 0 && this.payableInvoices.length > 0) {

          } else {
            if (this.invoiceType == this.purchaseInvoiceType) {
              if (this.lineItemDescriptions.includes(obj.debitToAccountId)) {
                error = true;
                errorMsg = "Same line item can not be added multiple times.";
              } else {
                this.lineItemDescriptions.push(obj.debitToAccountId);
              }
              if (obj.debitToAccountId == 0) {
                error = true;
                errorMsg = "Item Particulars can not be blank";
              }
            } else {
              if (this.lineItemDescriptions.includes(obj.creditToAccountId)) {
                error = true;
                errorMsg = "Same line item can not be added multiple times.";
              } else {
                this.lineItemDescriptions.push(obj.creditToAccountId);
              }
              if (obj.creditToAccountId == 0) {
                error = true;
                errorMsg = "Item Particulars can not be blank";
              }
            }
          }

          if (obj.unitObj) {
            obj.unit = obj.unitObj.name;
          }
          if (obj.rate == 0 || obj.rate == '') {
            error = true;
            errorMsg = "Item Rate can not be blank.";
          }
          if (obj.quantity == 0 || obj.quantity == '') {
            error = true;
            errorMsg = "Item Quantity can not be blank.";
          }
          if (obj.unit == '') {
            error = true;
            errorMsg = "Item Unit can not be blank.";
          }
          obj.rate = parseFloat(obj.rate);
          obj.quantity = parseInt(obj.quantity);
          obj.itemGrossAmount = parseFloat(obj.itemGrossAmount);
          obj.itemGrossAmount = isNaN(obj.itemGrossAmount)?0:obj.itemGrossAmount;
          obj.netAmount = obj.itemGrossAmount;
          if (obj.itemGrossAmount == 0 || obj.itemGrossAmount == '') {
            error = true;
            errorMsg = "Item Amount can not be blank.";
          }
        });
      }
      allIndex++;
    });

    if (error) {
      this._tosterService.error(errorMsg);
      return false;
    }
    return true;
  }

  /*---Validate all---*/
  async validateAll(): Promise<boolean> {
    /*return await this.validateLateFeeTemplate().then(async (lt:any)=>{
      if(lt){
        return await this.validateDiscountTemplate().then(async (dis:any)=>{
          if(dis){
            if(!this.validateParameters() || !this.validateLineItems()){
              console.log("param false");
              return false;
            }else{
              return true;
            }
          }else{
            console.log("disc false");
            return false;
          }
        });
      }else{
        console.log("lt false");
        return false;
      }
    }); */
    if(!this.validateParameters() || !this.validateLineItems()){
      return false;
    }else{
      return true;
    } 
  }

  /*---Reset all parameters---*/
  resetAll(): boolean {
    this.payableInvoices = [];
    this.partyAccountId = null;
    this.partyAccountName = "--Select--";
    this.debitAccountId = null;
    this.debitAccountName = "";
    this.accountSelectedText = '';
    this.billingLineItems = [];
    this.readonlyLineItems = [];
    this.isPeriodic = 0;
    this.propertyObj = null;
    this.accountObj = null;
    this.invoiceTotal = 0;
    this.propertySelectedText = '';
    this.lineItemDescriptions = [];
    this.templateId = null;
    this.selectedBlockId = null;
    this.propertyUrl = null;
    this.submitted = false;
    this.paymentMethod = '';
    this.transactionId = null;
    this.chequeNumber = null;
    this.bankName = null;
    this.bankBranch = null;
    this.chequeDate = null;
    this.chequeClearanceDate = null;
    this.paymentDescription = null;
    this.linkedPaymentId = null;
    this.id = null;
    this.voucherNumber = '';
    this.voucherType = null;
    this.voucherFromName = '';
    this.voucherFromAddress = '';
    this.voucherToName = '';
    this.voucherToAddress = '';
    this.narration = '';
    this.voucherDate = CommonMethods.usDateToDb(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
    this.paymentType = null;
    this.status = "Pending";
    this.ledgerToId = 0;
    this.ledgerFromId = 0;
    this.totalGrossAmount = 0;
    this.totalNetAmount = 0;
    this.pendingAmount = 0;
    this.propertyId = null;
    this.propertyTypeId = 0;
    this.manualDueDate = '';
    this.lateFeeTemplateId = null;
    this.discountTemplateId = null;
    this.lateFeeSelectedText = '';
    this.discountSelectedText = '';
    this.latefeeLineItems = [];
    this.discountLineItems = [];
    this.latefeeTemplatePeriodId = null;
    this.latefeeStartsOn = null;
    this.latefeeExpriesOn = null;
    this.latefeePeriods = [];
    this.latefeeActivePeriod = 0;
    if (this.invoiceType == this.societyInvoiceType || this.invoiceType == this.rentInvoiceType) {
      this.paymentTypeString = "Collection";
      this.payType = 1;
      this.ledgerDefaultGroup = "Cash";
      this.accountSelectionType = "Income";
      this.itemLedgerDefaultGroup = "Income";
      this.discountLedgerDefaultGroup = "Expenses";
      this.voucherToName = this.communityName;
      this.voucherToAddress = this.communityAddress;
      this.ledgerToId = 0;
      this.accountChangeType = 'from';
    } else {
      this.paymentTypeString = "Expenses";
      this.payType = 2;
      this.ledgerDefaultGroup = "Cash";
      this.accountSelectionType = "vendors";
      this.itemLedgerDefaultGroup = "Expenses";
      this.discountLedgerDefaultGroup = "Income";
      this.voucherFromName = this.communityName;
      this.voucherFromAddress = this.communityAddress;
      this.ledgerFromId = 0;
      this.accountChangeType = 'to';
    }
    if (this.invoiceType == this.societyInvoiceType) {
      this.listName = "Collections";
      this.selectedTypeName = "Collection";
    } else if (this.invoiceType == this.rentInvoiceType) {
      this.listName = "Collections";
      this.selectedTypeName = "Collection";
    } else {
      this.listName = "Payments";
      this.selectedTypeName = "Payment";
    }
    this.addNewRow();
    return true;
  }

  /*---Save payment and create new---*/
  async onSubmitAndNew(): Promise<void> {
    await this.onSubmit(true);
  }

  /*---Save payment---*/
  async onSubmit(followedByNew: boolean = false): Promise<boolean> {
    this.submitted = true;
    let validated = await this.validateAll();
    if(validated == false){
      return false;
    }
    var data: { [k: string]: any } = {
      communityId: this.communityId,
      vouchers: this.payableInvoices,
      paymentLedgerFromId: this.ledgerFromId,
      paymentLedgerToId: this.ledgerToId,
      paymentMethod: this.paymentMethod,
      paymentDate: CommonMethods.dbToUsDate(this.voucherDate),
      billToName: this.invoiceType == this.purchaseInvoiceType ? this.voucherToName : this.voucherFromName,
      billToAddress: this.invoiceType == this.purchaseInvoiceType ? this.voucherToAddress : this.voucherFromAddress,
      transactionId: this.transactionId,
      chequeNumber: this.chequeNumber,
      bankName: this.bankName,
      bankBranch: this.bankBranch,
      chequeDate: this.chequeDate != null ? CommonMethods.dbToUsDate(this.chequeDate) : '',
      chequeClearanceDate: this.chequeClearanceDate != null ? CommonMethods.dbToUsDate(this.chequeClearanceDate) : '',
      paymentAmount: this.invoiceTotal,
      paymentDescription: this.paymentDescription,
      narration: this.narration,
      type: this.payType,
      invoiceNumber: this.voucherNumber != null ? this.voucherNumber : null,
      status: 1,
      paymentPropertyTypeId: this.propertyTypeId,
      paymentPropertyId: this.propertyId,
      paymentClearanceDate: (this.paymentMethod != 'Cheque' && this.paymentMethod != 'MobilePay')?CommonMethods.dbToUsDate(this.voucherDate):null,
    };
    if (this.payableInvoices.length > 0) {
      var invoiceLineItems = [];
      var item = {
        rate: this.generalTotal,
        quantity: 1,
        unit: "Piece(s)",
        description: "Paid invoice " + this.payableInvoices.join(),
        itemGrossAmount: this.generalTotal,
        netAmount: this.generalTotal,
        debitToAccountId: this.invoiceType == this.purchaseInvoiceType ? this.ledgerToId : this.debitAccountId,
        creditToAccountId: this.invoiceType == this.purchaseInvoiceType ? this.debitAccountId : this.ledgerFromId
      }
      invoiceLineItems.push(item);
      data.paymentLineItems = [...invoiceLineItems, ...this.latefeeLineItems, ...this.discountLineItems];
    } else {
      data.paymentLineItems = [...this.billingLineItems, ...this.latefeeLineItems, ...this.discountLineItems];
    }
    this._paymentService.save(data).subscribe((response: any) => {
      this._tosterService.success("Payment Acknowledged Successfully.");
      if (followedByNew) {
        if(this.resetAll()){
          if (this.invoiceType == this.purchaseInvoiceType) {
            this._router.navigateByUrl("payment", { replaceUrl: true });
          } else {
            this._router.navigateByUrl("collection", { replaceUrl: true });
          }
        }
      }else{
        if (this.invoiceType == this.purchaseInvoiceType) {
          this._router.navigateByUrl("payment/"+response.id, { replaceUrl: true });
        } else {
          this._router.navigateByUrl("collection/"+response.id, { replaceUrl: true });
        }
      }
    }, error => {
      this._tosterService.error(error.message);
    });
  }

  /*---Get payment details---*/
  getPayment(id: number): void {
    this._voucherService.getVoucher(id).subscribe((result: any) => {
      this.id = result.id;
      this.voucherNumber = result.voucherNumber;
      this.voucherType = result.voucherType;
      this.voucherFromName = result.voucherFromName;
      this.voucherFromAddress = result.voucherFromAddress;
      this.voucherToName = result.voucherToName;
      this.voucherToAddress = result.voucherToAddress;
      this.narration = result.narration;
      this.voucherDate = CommonMethods.usDateToDb(this.datePipe.transform(result.voucherDate, "yyyy-MM-dd"));
      this.paymentType = result.paymentType;
      this.status = result.status;
      this.ledgerToId = result.ledgerToId;
      this.ledgerFromId = result.ledgerFromId;
      this.totalGrossAmount = result.totalGrossAmount;
      this.totalNetAmount = result.totalNetAmount;
      this.pendingAmount = result.pendingAmount;
      this.propertyId = result.propertyId;
      this.propertyTypeId = result.propertyTypeId;
      this.getBlock();
      this.paymentMethod = result.paymentMethod;
      this.transactionId = result.transactionId;
      this.chequeNumber = result.chequeNumber;
      this.bankName = result.bankName;
      this.bankBranch = result.bankBranch;
      this.chequeDate = CommonMethods.usDateToDb(this.datePipe.transform(result.chequeDate, "yyyy-MM-dd"));
      this.chequeClearanceDate = CommonMethods.usDateToDb(this.datePipe.transform(result.chequeClearanceDate, "yyyy-MM-dd"));
      this.paymentDescription = result.paymentDescription;
      this.linkedPaymentId = result.linkedPaymentId;
      if (this.propertyId > 0) {
        this.getPropertyDetail();
      }
      this.communityId = result.communityId;
      this.manualDueDate = this.datePipe.transform(result.manualDueDate, "yyyy-MM-dd");
      if (result.lineItems.length > 0) {
        this.billingLineItems = [];
        this.latefeeLineItems = [];
        this.discountLineItems = [];
        result.lineItems.forEach(obj => {
          if (obj.mode == "General") {
            this.billingLineItems.push(obj);
          }
          if (obj.mode == "Latefee") {
            this.latefeeLineItems.push(obj);
          }
          if (obj.mode == "Discount") {
            this.discountLineItems.push(obj);
          }
        })
      }
      if (this.billingLineItems.length > 0) {
        this.billingLineItems.forEach(obj => {
          this.debitAccountId = obj.debitToAccountId;
        });
        this._ledgerService.details(this.debitAccountId).subscribe((result: any) => {
          this.debitAccountName = result.ledgerName;
        }, error => {
          this._tosterService.error(error.message);
        });
      }
      if (this.invoiceType == this.purchaseInvoiceType) {
        this._ledgerService.details(this.ledgerToId).subscribe((result: any) => {
          this.accountSelectedText = result.ledgerName;
          this.accountObj = { id: this.ledgerToId, name: result.ledgerName };
        }, error => {
          this._tosterService.error(error.message);
        });
      } else {
        this._ledgerService.details(this.ledgerFromId).subscribe((result: any) => {
          this.accountSelectedText = result.ledgerName;
          this.accountObj = { id: this.ledgerFromId, name: result.ledgerName };
        }, error => {
          this._tosterService.error(error.message);
        });
      }
      this.invoiceTotal = this.totalNetAmount;
      if (this.status == "Cancelled") {
        this._uiService.leftMenuItems.next([
          { code: 'F2', name: this.listName, action: this.goToList.bind(this), privilege:this.readPrivilege }
        ]);
      } else {
        this._uiService.leftMenuItems.next([
          { code: 'F2', name: this.listName, action: this.goToList.bind(this), privilege:this.readPrivilege },
          { code: 'F7', name: 'Cancel', action: this.cancel.bind(this), class: 'red lighten-1', privilege:this.writePrivilege }
        ]);
      }
    }, error => {
      this._tosterService.error(error.message);
    });
  }

  /*---Cancel payment---*/
  cancel(): void {
    var data = { communityId: this.communityId };
    this._paymentService.cancel(this.id, data).subscribe((result: any) => {
      this._tosterService.success("Payment cancelled successfully.");
      this.getPayment(this.id);
      if (this.invoiceType == this.purchaseInvoiceType) {
        this._router.navigateByUrl('payment/' + this.id, { replaceUrl: true });
      } else {
        this._router.navigateByUrl('collection/' + this.id, { replaceUrl: true });
      }
    }, error => {
      this._tosterService.error(error.message);
    });
  }

  /*---Add new late fee line item row---*/
  addNewLateFeeRow(): void {
    var item = {
      rate: 0,
      quantity: 0,
      unit: "",
      description: "",
      itemGrossAmount: 0,
      debitToAccountId: 0,
      creditToAccountId: 0,
      account: { id: 0, name: "" },
      unitObj: { id: 0, name: "" },
      mode: "Latefee"
    }
    this.latefeeLineItems.push(item);
  }

  /*---Delete late fee line item row---*/
  deleteLateFeeItem(item: any): void {
    this.latefeeLineItems.splice(this.latefeeLineItems.indexOf(item), 1);
    this.invoiceTotalChange();
  }

  /*---Add new discount line item row---*/
  addNewDiscountRow(): void {
    var item = {
      rate: 0,
      quantity: 0,
      unit: "",
      description: "",
      itemGrossAmount: 0,
      debitToAccountId: 0,
      creditToAccountId: 0,
      account: { id: 0, name: "" },
      unitObj: { id: 0, name: "" },
      mode: "Discount"
    }
    this.discountLineItems.push(item);
  }

  /*---Delete discount line item row---*/
  deleteDiscountItem(item: any): void {
    this.discountLineItems.splice(this.discountLineItems.indexOf(item), 1);
    this.invoiceTotalChange();
  }

  /*---Change late fee template---*/
  async changeLateFeeTemplate(): Promise<void> {
    if(this.lateFeeTemplateId > 0){
      await this.getLateFeeTemplate(this.lateFeeTemplateId);
    }
  }

  async validateLateFeeTemplate(): Promise<boolean> {
    if(this.lateFeeTemplateId > 0){
      return await this.getLateFeeTemplate(this.lateFeeTemplateId);
    }else{
      return true;
    }
  }

  /*---Get late fee template---*/
  async getLateFeeTemplate(id: number): Promise<boolean> {
    if(id > 0){
      return await this._lateFeeTemplateService.details(id).toPromise().then(async (res: any) => {
        if(this.payableInvoices.length > 0){
          let voucherDataArray = [];
          let error: boolean = false;
          this.payableInvoices.forEach(async obj => {
            await this._voucherService.getVoucher(obj).toPromise().then(async (result:any)=>{
              if(res.isActive != 1){
                this._tosterService.error("Selected Late Fee Template is not enabled.");
                error = true;
              }
              if(res.voucherType != result.voucherType){
                this._tosterService.error("Selected Late Fee Template Type does not match.");
                error = true;
              }
              if(res.propertyType > 0 && res.propertyType != result.propertyTypeId){
                this._tosterService.error("Selected Late Fee Template Property Type does not match.");
                error = true;
              }
              if((res.sendToOwners == 1 && res.sendToTenant == 1) && result.voucherType != this.societyInvoiceType && result.voucherType != this.rentInvoiceType){
                this._tosterService.error("Selected Late Fee Template is only applicable for Property Owners or Tenants.");
                error = true;
              }
              else if((res.sendToTenant == 1 && res.sendToOwners != 1) && result.voucherType != this.rentInvoiceType){
                this._tosterService.error("Selected Late Fee Template is only applicable for Tenants.");
                error = true;
              }else if((res.sendToOwners == 1 && res.sendToTenant != 1) && result.voucherType != this.societyInvoiceType){
                this._tosterService.error("Selected Late Fee Template is only applicable for Owners.");
                error = true;
              }
              if(!error){
                var voucherData: {[k: string]: any} = {
                  id: result.id,
                  voucherNumber: result.voucherNumber,
                  voucherType: result.voucherType,
                  voucherFromName: result.voucherFromName,
                  voucherFromAddress: result.voucherFromAddress,
                  voucherToName: result.voucherToName,
                  voucherToAddress: result.voucherToAddress,
                  narration: result.narration,
                  voucherDate: result.voucherDate,
                  status: result.status,
                  ledgerToId: result.ledgerToId,
                  ledgerFromId: result.ledgerFromId,
                  totalGrossAmount: result.totalGrossAmount,
                  applicableOnceInPeriod: result.applicableOnceInPeriod,
                  periodStartFrom:result.periodStartFrom,
                  periodEndOn:result.periodEndOn,
                  totalNetAmount: result.totalNetAmount,
                  pendingAmount: result.pendingAmount,
                  paymentType: result.paymentType,
                  maintenanceYear: result.maintenanceYear,
                  maintenanceMonth: result.maintenanceMonth,
                  tdsAmount: result.tdsAmount,
                  tdsn: result.tdsn,
                  cancelReason: result.cancelReason,
                  metaData: result.metaData,
                  propertyId: result.propertyId,
                  propertyTypeId: result.propertyTypeId,
                  templateId: result.templateId,
                  latefeeTemplateId:id,
                  discountTemplateId:result.discountTemplateId,
                  communityId: result.communityId,
                  manualDueDate: result.manualDueDate,
                  lineItems: result.lineItems
                };
                voucherDataArray.push(voucherData);
              }
            });
          });
          if(!error && voucherDataArray.length == this.payableInvoices.length){
            let v:number = 0;
            voucherDataArray.forEach(async vdata=>{
              await this._voucherService.update(vdata).toPromise().then(async (result:any)=>{
                v++;
              });
            });
            if(v == voucherDataArray.length){
              this.getAdditionalLineItems(true);
              return true;
            }
          }else{
            this._tosterService.error('No late fee is available for the Selected Late Fee Template.');
            return false;
          }
        }else{
          this._tosterService.error("Selected Late Fee Template is not applicable for custom payments.");
          return false;
        }
      });
    }else{
      return true;
    }
  }

  /*---Change discount template---*/
  async changeDiscountTemplate(): Promise<void> {
    if(this.discountTemplateId > 0){
      await this.getDiscountTemplate(this.discountTemplateId);
    }
  }

  /*---Validate discount template---*/
  async validateDiscountTemplate(): Promise<boolean> {
    if(this.discountTemplateId > 0){
      return await this.getDiscountTemplate(this.discountTemplateId);
    }else{
      return true;
    }
  }

  /*---Get discount template---*/
  async getDiscountTemplate(id: number): Promise<boolean> {
    if(id > 0){
      return await this._discountTemplateService.details(id).toPromise().then(async (res: any) => {
        if(this.payableInvoices.length > 0){
          let voucherDataArray = [];
          let error: boolean = false;
          await this.payableInvoices.forEach(async obj => {
            return await this._voucherService.getVoucher(obj).toPromise().then(async (result:any)=>{
              if(res.isActive != 1){
                this._tosterService.error("Selected Discount Template is not enabled.");
                error = true;
              }
              if(res.voucherType != result.voucherType){
                this._tosterService.error("Selected Discount Template Type does not match.");
                error = true;
              }
              if(res.propertyType > 0 && res.propertyType != result.propertyTypeId){
                this._tosterService.error("Selected Discount Template Property Type does not match.");
                error = true;
              }
              if((res.sendToOwners == 1 && res.sendToTenant == 1) && result.voucherType != this.societyInvoiceType && result.voucherType != this.rentInvoiceType){
                this._tosterService.error("Selected Discount Template is only applicable for Property Owners or Tenants.");
                error = true;
              }
              else if((res.sendToTenant == 1 && res.sendToOwners != 1) && result.voucherType != this.rentInvoiceType){
                this._tosterService.error("Selected Discount Template is only applicable for Tenants.");
                error = true;
              }else if((res.sendToOwners == 1 && res.sendToTenant != 1) && result.voucherType != this.societyInvoiceType){
                this._tosterService.error("Selected Discount Template is only applicable for Owners.");
                error = true;
              }
              if(res.discountType == 2){
                if((!result.periodStartFrom || !result.periodEndOn)){
                  this._tosterService.error("Selected Discount Template is only applicable for yearly invoices.");
                  error = true;
                }else{
                  let monthDiff = ((new Date(CommonMethods.dbToUsDate(result.periodEndOn)).getTime() - new Date(CommonMethods.dbToUsDate(result.periodStartFrom)).getTime()) / 1000)/(60 * 60 * 24 * 7 * 4);
                  if(monthDiff<11 || monthDiff>13){
                    this._tosterService.error("Selected Discount Template is only applicable for yearly invoices.");
                    error = true;
                  }
                } 
              }
              if(!error){
                var voucherData: {[k: string]: any} = {
                  id: result.id,
                  voucherNumber: result.voucherNumber,
                  voucherType: result.voucherType,
                  voucherFromName: result.voucherFromName,
                  voucherFromAddress: result.voucherFromAddress,
                  voucherToName: result.voucherToName,
                  voucherToAddress: result.voucherToAddress,
                  narration: result.narration,
                  voucherDate: result.voucherDate,
                  status: result.status,
                  ledgerToId: result.ledgerToId,
                  ledgerFromId: result.ledgerFromId,
                  totalGrossAmount: result.totalGrossAmount,
                  applicableOnceInPeriod: result.applicableOnceInPeriod,
                  periodStartFrom:result.periodStartFrom,
                  periodEndOn:result.periodEndOn,
                  totalNetAmount: result.totalNetAmount,
                  pendingAmount: result.pendingAmount,
                  paymentType: result.paymentType,
                  maintenanceYear: result.maintenanceYear,
                  maintenanceMonth: result.maintenanceMonth,
                  tdsAmount: result.tdsAmount,
                  tdsn: result.tdsn,
                  cancelReason: result.cancelReason,
                  metaData: result.metaData,
                  propertyId: result.propertyId,
                  propertyTypeId: result.propertyTypeId,
                  templateId: result.templateId,
                  latefeeTemplateId:result.latefeeTemplateId,
                  discountTemplateId:id,
                  communityId: result.communityId,
                  manualDueDate: result.manualDueDate,
                  lineItems: result.lineItems
                };
                voucherDataArray.push(voucherData);
              }
              if(!error && voucherDataArray.length == this.payableInvoices.length){
                let v:number = 0;
                voucherDataArray.forEach(async vdata=>{
                  await this._voucherService.update(vdata).toPromise().then(async (result:any)=>{
                    v++;
                  });
                });
                if(v == voucherDataArray.length){
                  this.getAdditionalLineItems(true);
                  this._tosterService.success('Discount Template added successfully.');
                  return true;
                }
              }else{
                this._tosterService.error('No discount is available for the Selected Discount Template.');
                return false;
              }
            });
          });
        }else{
          this._tosterService.error("Selected Discount Template is not applicable for custom payments.");
          return false;
        }
      });
    }else{
      return true;
    }
  }

  /*---Get late fee line items from DB---*/
  getAdditionalLineItems(manual:boolean = false): void {
    var data = {
      communityId: parseInt(CommonMethods.getCommunityId()),
      vouchers: this.payableInvoices,
      paymentDate: CommonMethods.dbToUsDate(this.voucherDate)
    };
    this._paymentService.getAdditionalLineItems(data).subscribe(async (result: any) => {
      if(!result.hasError){
        if(!manual){
          if(result.data.lateFeeTemplateId > 0){
            this.lateFeeTemplateId = result.data.lateFeeTemplateId;
            this._lateFeeTemplateService.details(result.data.lateFeeTemplateId).subscribe(async (latefee:any)=>{
              this.lateFeeSelectedText = latefee.name;
            });
          }
          if(result.data.discountTemplateId > 0){
            this.discountTemplateId = result.data.discountTemplateId;
            this._discountTemplateService.details(result.data.discountTemplateId).subscribe(async (discount:any)=>{
              this.discountSelectedText = discount.name;
            });
          }
        }
        if(result.data.lateFee && result.data.lateFee.length > 0){
          var temp = [];
          var ltotal:number = 0;
          result.data.lateFee.forEach(async element=>{
            if(element.total_amount > 0 && element.line_items && element.line_items.length > 0){
              for(let m=0;m<element.line_items.length;m++){
                let elem = element.line_items[m];
                var item = {
                  rate: elem.rate,
                  quantity: elem.quantity,
                  unit: elem.unit,
                  description: elem.description,
                  itemGrossAmount: elem.itemGrossAmount,
                  debitToAccountId: elem.debitToAccountId,
                  creditToAccountId: elem.creditToAccountId,
                  mode: "Latefee"
                }
                ltotal += elem.itemGrossAmount;
                temp.push(item);
              }
            }
          });
          if(temp.length > 0){
            var unique = [];
            temp.forEach(async el=>{
              var existing = unique.filter(i=> i.creditToAccountId === el.creditToAccountId)[0];
              if (!existing){
                unique.push(el);
              }else{
                existing.rate += el.rate;
                existing.itemGrossAmount += el.itemGrossAmount;
              }
            });
            this.latefeeLineItems = [];
            this.latefeeLineItems = unique;
          }else{
            this.latefeeLineItems = [];
          }
        }else{
          this.latefeeLineItems = [];
        }
        if(result.data.discount && result.data.discount.length > 0){
          var tempDisc = [];
          result.data.discount.forEach(async element=>{
            if(element.total_amount > 0 && element.line_items && element.line_items.length > 0){
              for(let n=0;n<element.line_items.length;n++){
                let elem = element.line_items[n];
                var item = {
                  rate: elem.rate,
                  quantity: elem.quantity,
                  unit: elem.unit,
                  description: elem.description,
                  itemGrossAmount: elem.itemGrossAmount,
                  debitToAccountId: elem.debitToAccountId,
                  creditToAccountId: elem.creditToAccountId,
                  mode: "Discount"
                }
                tempDisc.push(item);
              }
            }
          });
          if(tempDisc.length > 0){
            var uniqueDisc = [];
            tempDisc.forEach(async el=>{
              var existingDisc = uniqueDisc.filter(i=> i.creditToAccountId === el.creditToAccountId)[0];
              if (!existingDisc){
                uniqueDisc.push(el);
              }else{
                existingDisc.rate += el.rate;
                existingDisc.itemGrossAmount += el.itemGrossAmount;
              }
            });
            this.discountLineItems = [];
            this.discountLineItems = uniqueDisc;
          }else{
            this.discountLineItems = [];
          }
        }else{
          this.discountLineItems = [];
        }
        this.invoiceTotalChange();
      }
      if(!this.discountTemplateId){
        this._paymentService.getDiscountSuggestion(data).subscribe((res:any)=>{
          if(res){
            this._tosterService.success(res.name +" is applicable for this payment. Please choose this discount template if you want to avail discount.");
          }
        });
      }
    }, error => {
      this._tosterService.error(error.message);
    });
  }

  /*---Navigate to list page---*/
  goToList(): void {
    let listUrl: string = "";
    if (this.invoiceType == this.societyInvoiceType) {
      listUrl = "collections";
    } else if (this.invoiceType == this.rentInvoiceType) {
      listUrl = "collections";
    } else {
      listUrl = "payments";
    }
    this._router.navigate([listUrl]);
  }

  /*---Breadcrumb navigation---*/
  breadcrumbNavigate(mode: any): void { 
    if (mode == "list") {
      this.goToList();
    } else if (mode == "dashboard") {
      this._router.navigateByUrl("/dashboard");
    } 
  }

  /*---Download payment voucher---*/
  downloadInvoice(invoice: any): void {
    var authkey = localStorage.getItem("authKey");
    var udid = localStorage.getItem("UDID");
    var communityId = CommonMethods.getCommunityId();
    var is_duble_invoice = 1;
    window.open(`${config.apiEndPointBase}/voucher/download/${invoice.id}?authkey=${authkey}&udid=${udid}&communityId=${communityId}&is_duble_invoice=${is_duble_invoice}`);
    return;
  }

  ngOnDestroy(): void {
    this._uiService.leftMenuItems.next([]);
  }

}
