import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { CommonMethods } from '../common-methods';
import { UiService } from '../ui.service';
import { APP_CONST } from '../app-constants';
import { config } from '../config';
import { ToastrService } from 'ngx-toastr';
import { CommunityService } from '../api-client/community.service';
import { LedgerService } from '../api-client/ledger.service';
import { VoucherService } from '../api-client/voucher.service';
import { PropertyService } from '../api-client/property.service';
import { VoucherTemplateService } from '../api-client/voucher-template.service';
import { LateFeeTemplateService } from '../api-client/late-fee-template.service';
import { DiscountTemplateService } from '../api-client/discount-template.service';
import { IDayCalendarConfig } from 'ng2-date-picker';

@Component({
  selector: 'app-bulk-invoice',
  templateUrl: './bulk-invoice.component.html',
  styleUrls: ['./bulk-invoice.component.css'],
  animations: [
    trigger('smoothCollapse', [
      state('initial', style({
        height:'0',
        overflow:'hidden',
        opacity:'0'
      })),
      state('final', style({
        overflow:'visible',
        opacity:'1'
      })),
      transition('initial=>final', animate('5000ms')),
      transition('final=>initial', animate('5000ms'))
    ]),
  ]
})
export class BulkInvoiceComponent implements OnInit {

  invoiceType: any;
  submitted:boolean = false;
  started:boolean = false;
  stopped:boolean = false;
  completed:boolean = false;
  cancellationStarted:boolean = false;
  voucherType: number;
  narration: string = '';
  todaysDate: any = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  voucherDate: string = CommonMethods.usDateToDb(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
  totalGrossAmount: number;
  applicableOnceInPeriod: number = 0;
  periodStartFrom: string = null;
  periodEndOn: string = null;
  totalNetAmount: number = 0;
  pendingAmount: number = 0;
  propertyTypeId: number = 0;
  communityId: number;
  manualDueDate: string = CommonMethods.usDateToDb(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
  ledgerDefaultGroup = "";
  billingLineItems = [];
  isPeriodic: number = 0;
  communityName: string;
  communityAddress: string;
  propertyTypes: any;
  accountSelectedText: any = '';
  accountSelectionType: any = 'owners';
  accountObj:any = {id: 0, name: ""};
  invoiceTotal: number = 0;
  lineItemDescriptions = [];
  templateId: number = null;
  templateSelectedText: any = '';
  lateFeeTemplateId:number = null;
  latefeeSelectedText: any = '';
  discountTemplateId:number = null;
  discountSelectedText: any = '';
  selectedBlockId:number = null;
  templateCreditPeriodDays: any;
  templateSendToOwners:number = 1;
  templateSendToTenants:number = 1;
  templatePropertyType:number = null;
  templatePeriods = [];
  templateLineItems: any = [];
  eligiblePeriods:any = [];
  units: any;
  public config = <IDayCalendarConfig>{
    format: "DD-MM-YYYY"
  };
  listName:string = "Society Invoices";
  createType:number = 0;
  invoices:any = [];
  existingInvoices:any = [];
  allowedLedgers:any = [];
  doneLedgers:any = [];
  allowedProperties:any = [];
  doneProperties:any = [];
  generateCount:number = 0;
  generatedPercentage:number = 0;
  roundedGeneratedPercentage:number = 0;
  progressBarStyle:any = "0%";
  generatedInvoices:any = [];
  newInvoices:any = [];
  generatedInvoiceStartIndex:number = 0;
  generatedInvoiceRecordPerPage:number = 40;
  generatedInvoiceTotalRecords:number = 0;
  selectedInvoices:any = [];
  allInvoices:any = [];
  existingInvoiceStartIndex:number = 0;
  existingInvoiceRecordPerPage:number = 40;
  existingInvoiceTotalRecords:number = 0;
  invoiceGenerationWorker:any;
  invoiceCancellationWorker:any;
  communitySettings: any = null;
  num:number = 0;
  currentStep:number = 1;
  stepWiseLeftMenu = {
    1: [
      {code:'F2', name:this.listName, action:this.goToList.bind(this), privilege: APP_CONST.SOCIETY_INVOICE_READ},
      {code:'F4', name:'Set Charges & Discounts', action:this.checkPeriod.bind(this), class:'green lighteen-1', privilege: APP_CONST.SOCIETY_INVOICE_WRITE}
    ],
    2: [
      {code:'F2', name:this.listName, action:this.goToList.bind(this), privilege: APP_CONST.SOCIETY_INVOICE_READ},
      {code:'F3', name:'Modify Parameters', action:this.openStepOne.bind(this), privilege: APP_CONST.SOCIETY_INVOICE_WRITE},
      {code:'F5', name:'Check Existing Invoices', action:this.checkBulkInvoice.bind(this), class:'green lighteen-1', privilege: APP_CONST.SOCIETY_INVOICE_READ}
    ],
    3: [
      {code:'F2', name:this.listName, action:this.goToList.bind(this), privilege: APP_CONST.SOCIETY_INVOICE_READ},
      {code:'F3', name:'Modify Parameters', action:this.openStepOne.bind(this), privilege: APP_CONST.SOCIETY_INVOICE_WRITE},
      {code:'F6', name:'Proceed to Generate', action:this.openStepFour.bind(this), class:'green lighteen-1', privilege: APP_CONST.SOCIETY_INVOICE_WRITE},
      {code:'F7', name:'Cancel Selected Invoices', action:this.cancelSelected.bind(this), privilege: APP_CONST.SOCIETY_INVOICE_WRITE},
      {code:'F8', name:'Cancel All Invoices', action:this.cancelAll.bind(this), privilege: APP_CONST.SOCIETY_INVOICE_WRITE},
    ],
    4: [
      {code:'F2', name:this.listName, action:this.goToList.bind(this), privilege: APP_CONST.SOCIETY_INVOICE_READ},
      {code:'F3', name:'Modify Parameters', action:this.openStepOne.bind(this), privilege: APP_CONST.SOCIETY_INVOICE_WRITE},
      {code:'F5', name:'Check Existing Invoices', action:this.openStepTwo.bind(this), privilege: APP_CONST.SOCIETY_INVOICE_READ},
      {code:'F8', name:'Cancel Generated Invoices', action:this.cancelAllGenerated.bind(this), privilege: APP_CONST.SOCIETY_INVOICE_WRITE},
      {code:'F9', name:'Start Process', action:this.startGeneration.bind(this), class:'green lighteen-1', privilege: APP_CONST.SOCIETY_INVOICE_WRITE },
      {code:'F10', name:'Stop Process', action:this.stopGeneration.bind(this), class:'red lighten-1', privilege: APP_CONST.SOCIETY_INVOICE_WRITE},
    ]
  };
  societyInvoiceType:number = APP_CONST.INVOICE_TYPE_SOCIETY;
  rentInvoiceType:number = APP_CONST.INVOICE_TYPE_RENT;
  assetPropertyType:number = APP_CONST.PROPERTY_TYPE_ASSETS;
  buildingPropertyType:number = APP_CONST.PROPERTY_TYPE_BUILDINGS;

  constructor(private _uiService: UiService,
    private _router: Router,
    private datePipe: DatePipe,
    private _tosterService: ToastrService,
    private _communityService: CommunityService,
    private _ledgerService: LedgerService,
    private _propertyService: PropertyService,
    private _voucherService: VoucherService,
    private _voucherTemplateService: VoucherTemplateService,
    private _lateFeeTemplateService: LateFeeTemplateService,
    private _discountTemplateService: DiscountTemplateService) { 
      /*---Get invoice template if selected any---*/
      if(this._router.getCurrentNavigation().extras.state){
        var state = this._router.getCurrentNavigation().extras.state;
        if(state.templateId){
          this.templateId = parseInt(state.templateId);
          this.populateTemplateData();
        }
        this._uiService.activeListCallBack = null;
        this._uiService.closeItself();
      }
  }

  ngOnInit(): void {
    this.getPropertyTypes();
    this.getUnits();
    this.getCommunitySettings();
    this.invoiceType = this.societyInvoiceType;
    this.voucherType = this.invoiceType;
    this.communityId = parseInt(CommonMethods.getCommunityId());
    this._communityService.details(this.communityId).subscribe((result:any)=>{
      this.communityName = result.name;
      this.communityAddress = result.address;
      this.ledgerDefaultGroup = "Income";
      this.accountSelectionType = "owners";
    });
    this.addNewRow();
    this.openStepOne();
  }

  /*---Get community settings from DB---*/
  getCommunitySettings(){
    var communityId = parseInt(CommonMethods.getCommunityId());
    this._communityService.getSettings(communityId).subscribe((result)=>{
      this.communitySettings = result;
    }, error => {
      this._tosterService.error(error.message);
    });
  }

  /*---Get all Property Types from DB---*/
  getPropertyTypes(): void {
    this._propertyService.types().subscribe((result: any) => {
      this.propertyTypes = result;
    }, error => {
      this._tosterService.error(error.message);
    });
  }

  /*---Get all Units from DB---*/
  getUnits(): void {
    this._voucherService.measurementUnits().subscribe((result: any) => {
      this.units = result;
    }, error => {
      this._tosterService.error(error.message);
    });
  }

  /*---Opens Step 1---*/
  openStepOne(): void {
    if(this.started && (!this.stopped && !this.completed)){
      this._tosterService.error("Invoice generation is in process, please stop the process before modifying parameters.");
    }else{
      this.currentStep = 1;
      this._uiService.leftMenuItems.next(this.stepWiseLeftMenu[this.currentStep]);
    }
  }

  /*---Opens Step 2---*/
  openStepTwo(): void {
    if(this.started && (!this.stopped && !this.completed)){
      this._tosterService.error("Invoice generation is in process, please stop the process before checking existing invoices.");
    }else{
      this.currentStep = 2;
      this._uiService.leftMenuItems.next(this.stepWiseLeftMenu[this.currentStep]);
    }
  }

  /*---Opens Step 3---*/
  openStepThree(): void {
    this.currentStep = 3;
    this._uiService.leftMenuItems.next(this.stepWiseLeftMenu[this.currentStep]);
  }

  /*---Opens Step 4---*/
  openStepFour(): void {
    this.currentStep = 4;
    this._uiService.leftMenuItems.next(this.stepWiseLeftMenu[this.currentStep]);
  }

  /*---Initialize new line item---*/
  newLineItems(): void {
    this.billingLineItems = [];
  }

  /*---Add new line item row---*/
  addNewRow(): void {
    var item = {
      rate: 0,
      quantity: 0,
      unit: "",
      description: "",
      itemGrossAmount: 0,
      debitToAccountId: 0,
      creditToAccountId: 0,
      account: {id: 0, name: ""},
      unitObj: {id: 0, name: ""}
    }
    this.billingLineItems.push(item);
  }

  /*---Delete line item row and calculate invoice total---*/
  deleteItem(item: any): void {
    this.billingLineItems.splice(this.billingLineItems.indexOf(item), 1);
    this.invoiceTotalChange();
  }

  /*---Triggered when Send To is changed, deselect Property Type---*/
  changeCreateType(): void {
    //If Send To Owners/Tenants/Both selected, deselect property type
    if(this.createType == 1 || this.createType == 2 || this.createType == 5){
      this.propertyTypeId = 0;
    }
  }

  /*---Triggered when Once In A Period checkbox clicked, validate template---*/
  changeIsPeriodic(elem: any): void {
    if(elem.checked){
      this.isPeriodic = 1;
    } else{
      this.isPeriodic = 0;
    }
    //this.populateTemplateData();
  }

  clearPeriodStart(): void {
    this.periodStartFrom = null;
  }

  clearPeriodEnd(): void {
    this.periodEndOn = null;
  }

  /*---Calculate Invoice Total Amount---*/
  invoiceTotalChange(): void {
    this.invoiceTotal = 0;
    this.billingLineItems.forEach(obj=>{
      obj.rate = isNaN(obj.rate)?0:parseFloat(obj.rate);
      obj.quantity = isNaN(obj.quantity)?0:parseInt(obj.quantity);
      obj.itemGrossAmount = parseInt(obj.quantity) * parseFloat(obj.rate);
      obj.itemGrossAmount = isNaN(obj.itemGrossAmount)?0:obj.itemGrossAmount;
      this.invoiceTotal += parseFloat(obj.itemGrossAmount);
    });  
  }

  /*---Validate Step 1 Parameters---*/
  validateParams(): boolean {
    if(!this.createType || this.createType == 0){
      this._tosterService.error("Please select to whom you want to send invoice.");
      this.openStepOne();
      return false;
    }
    if(this.voucherDate == ''){
      this._tosterService.error("Date can not be blank.");
      this.openStepOne();
      return false;
    }
    if(this.manualDueDate == ''){
      this._tosterService.error("Due Date can not be blank.");
      this.openStepOne();
      return false;
    }
    if((this.isPeriodic == 1 || this.templateId > 0) && (this.periodStartFrom == null || this.periodEndOn == null)){
      this._tosterService.error("Please select Period Range.");
      this.openStepOne();
      return false;
    }
    if((this.periodStartFrom && this.periodEndOn) && (new Date(CommonMethods.dbToUsDate(this.periodStartFrom)).getTime() > new Date(CommonMethods.dbToUsDate(this.periodEndOn)).getTime())){
      this._tosterService.error("Period end date can not be less than Period start date.");
      this.openStepOne();
      return false;
    }
    return true;
  }

  /*---Validate Step 2 Parameters---*/
  validateCharges(): boolean {
    this.totalGrossAmount = 0;
    this.lineItemDescriptions = [];
    let errorMsg:string;
    let error:boolean = false;
    this.billingLineItems.forEach(obj=>{
      if(obj.account){
        obj.creditToAccountId = obj.account.id;
        obj.description = obj.account.name;
      }
      if(obj.creditToAccountId == 0){
        errorMsg = "Item Particulars can not be blank.";
        error = true;
      }
      if(this.lineItemDescriptions.includes(obj.creditToAccountId)){
        errorMsg = "Same line item cant be added multiple times.";
        error = true;
      }else{
        this.lineItemDescriptions.push(obj.creditToAccountId);
      }
      if(obj.unitObj){
        obj.unit = obj.unitObj.name;
      }
      if(obj.rate == 0 || obj.rate == ''){
        errorMsg = "Item Rate can not be blank.";
        error = true;
      }
      if(obj.quantity == 0 || obj.quantity == ''){
        errorMsg = "Item Quantity can not be blank.";
        error = true;
      }
      if(obj.unit == ''){
        errorMsg = "Item Unit can not be blank.";
        error = true;
      }
      if(obj.itemGrossAmount == 0 || obj.itemGrossAmount == ''){
        errorMsg = "Item Amount can not be blank.";
        error = true;
      }
      obj.rate = isNaN(obj.rate)?0:parseFloat(obj.rate);
      obj.quantity = isNaN(obj.quantity)?0:parseInt(obj.quantity);
      obj.itemGrossAmount = parseInt(obj.quantity) * parseFloat(obj.rate);
      obj.itemGrossAmount = isNaN(obj.itemGrossAmount)?0:obj.itemGrossAmount;
      obj.netAmount = obj.itemGrossAmount;
      this.totalGrossAmount += parseFloat(obj.netAmount);
    });
    this.totalNetAmount = this.totalGrossAmount;
    this.pendingAmount = this.totalGrossAmount;
    if(error){
      this._tosterService.error(errorMsg);
      return false;
    }
    this.applicableOnceInPeriod = this.isPeriodic;
    if(this.narration == ''){
      this._tosterService.error("Narration can not be blank.");
      return false;
    }
    if(this.totalNetAmount <= 0){
      this._tosterService.error("Total Amount must be more than 0.");
      return false;
    }
    return true;
  }

  /*---Validate Step 1 & 2 Parameters---*/
  validateAll(): boolean {
    if(!this.validateParams()){
      return false;
    }
    if(!this.validateCharges()){
      return false;
    }
    return true;
  }

  /*---Check Step 1 Parameters,opens Step 2---*/
  checkPeriod(): void {
    if(!this.validateParams()){
      return;
    }
    if(this.templateId > 0){
      this.getPeriodsBytemplate(this.templateId);
    }else{
      this.openStepTwo();
    }
  }

  /*---Populate invoice template data, if selected any invoice template---*/
  populateTemplateData(): void {
    if(this.templateId > 0){
      this.selectTemplate(this.templateId);
    }
  }

  /*---Populate invoice template data---*/
  selectTemplate(id: number = 0): void {
    if(id>0 && this.voucherDate){
      this._voucherTemplateService.details(id).subscribe((result: any) => {
        if(result.sendToOwners == 0){
          this.templateSendToOwners = 0;
        }
        if(result.sendToTenant == 0){
          this.templateSendToTenants = 0;
        }
        this.selectedBlockId = result.appliedForBlockId;
        this.templatePropertyType = result.propertyType;
        this.propertyTypeId = result.propertyType;
        if(this.propertyTypeId > 0 && (this.createType == 1 || this.createType == 2 || this.createType == 5)){
          this.createType = null;
          this._tosterService.error("The selected template has specific property type. Please select again to whom you want to send invoice.");
          this.openStepOne();
        }
        this.templateCreditPeriodDays = result.creditPeriodDays > 0 ? (result.creditPeriodDays-1):0;
        this.populateDueDate();
      }, error => {
        this._tosterService.error(error.message);
      });
    }
  }

  populateDueDate(): void {
    if(this.periodStartFrom){
      var manualDueDateObj = new Date(new Date(CommonMethods.dbToUsDate(this.periodStartFrom)+ ' 00:00:00').getTime() + this.templateCreditPeriodDays*24*60*60*1000);
      var manualMonth:any = manualDueDateObj.getMonth()+1;
      var manualDay:any = manualDueDateObj.getDate();
      if(manualMonth < 10){
        manualMonth = '0'+manualMonth;
      }
      // Check if due day has been fixed from settings
      if(this.communitySettings && this.communitySettings.fixedDueDay && this.communitySettings.fixedDueDay > 0){
        let diff = this.communitySettings.fixedDueDay - manualDay;
        manualDay = manualDay+diff;
      }
      if(manualDay < 10){
        manualDay = '0'+manualDay;
      }
      this.manualDueDate = CommonMethods.usDateToDb(manualDueDateObj.getFullYear()+'-'+manualMonth+'-'+manualDay);
    }
  }

  /*---Validate invoice period with template period ranges, autofill line items from template---*/
  getPeriodsBytemplate(templateId: number = 0): void {
    this._voucherTemplateService.periods(templateId).subscribe((result: any) => {
      this.templatePeriods = result;
      var matchedPeriods = 0;
      this.templatePeriods.forEach(elem => {
        if(new Date(elem.startsOn).getTime() <= new Date(CommonMethods.dbToUsDate(this.periodStartFrom) +' 00:00:00').getTime() && new Date(elem.expriesOn).getTime() >= new Date(CommonMethods.dbToUsDate(this.periodEndOn) +' 00:00:00').getTime()){
          this.eligiblePeriods.push(elem.id);
        }
        if((new Date(elem.startsOn).getTime() <= new Date(CommonMethods.dbToUsDate(this.periodStartFrom) +' 00:00:00').getTime() && new Date(elem.expriesOn).getTime() >= new Date(CommonMethods.dbToUsDate(this.periodStartFrom) +' 00:00:00').getTime()) || (new Date(elem.startsOn).getTime() <= new Date(CommonMethods.dbToUsDate(this.periodEndOn) +' 00:00:00').getTime() && new Date(elem.expriesOn).getTime() >= new Date(CommonMethods.dbToUsDate(this.periodEndOn) +' 00:00:00').getTime())){
          matchedPeriods++;
        }
      });
      if(matchedPeriods > 1){
        this._tosterService.error("Template not applicable as multiple period range have been matched.");
      }else{
        if(this.eligiblePeriods.length > 0){
          this.lineItemsdata(this.eligiblePeriods[0]);
          this.openStepTwo();
        }else{
          this._tosterService.error("Template not applicable as period range does not matched.");
        }
      }  
    }, error => {
      this._tosterService.error(error.message);
    });
  }

  /*---Autofill line items from template---*/
  async lineItemsdata(id: number = 0): Promise<void> {
    this._voucherTemplateService.period(id).toPromise().then(async (result: any) => {
      this.templateLineItems = result.lineItems;
      this.billingLineItems = [];
      let itemUnits:any = [];
      for(var i = 0; i < this.templateLineItems.length; i++) {
        try {
          var unitDetails = this.units.filter((x: any) => x.id == this.templateLineItems[i].chargesUnitId);
          if (unitDetails.length > 0) {
            if(i > 0){
              if(!itemUnits.includes(this.templateLineItems[i].chargesUnitId)){
                throw new Error("Multiple units can not be added while bulk invoice generation.");
              }
            }
            itemUnits.push(this.templateLineItems[i].chargesUnitId);
            this.templateLineItems[i].unitName = unitDetails[0].name;
            await this._ledgerService.details(this.templateLineItems[i].creditToLedgerId).toPromise().then((res: any) => {
              var item = {
                rate: parseFloat(this.templateLineItems[i].amountEvalFormula),
                quantity: 1,
                unit: this.templateLineItems[i].unitName,
                description: res.ledgerName,
                itemGrossAmount: parseFloat(this.templateLineItems[i].amountEvalFormula),
                debitToAccountId: 0,
                creditToAccountId: this.templateLineItems[i].creditToLedgerId,
                account: {id: this.templateLineItems[i].creditToLedgerId, name: res.ledgerName},
                unitObj: {id: this.templateLineItems[i].chargesUnitId, name: this.templateLineItems[i].unitName}
              }
              this.billingLineItems.push(item);
            });
            this.invoiceTotalChange();
          }
        } catch (e) {
          this.billingLineItems = [];
          this.addNewRow();
          this.templateId = null;
          this.templateSendToOwners = 1;
          this.templateSendToTenants = 1;
          this._tosterService.error(e);
        }
      }
    });
  }

  /*---Triggered when Discount Template is selected---*/
  changeDiscountTemplate(elem: any): void {
    if(elem > 0){
      this.getDiscountTemplate(elem);
    }
  }

  /*---Triggered when Late Fee Template is selected---*/
  changeLateFeeTemplate(elem: any): void {
    if(elem > 0){
      this.getLateFeeTemplate(elem);
    }
  }

  /*---Validate selected Discount Template---*/
  getDiscountTemplate(id: number = 0): void {
    if(id > 0){
      this._discountTemplateService.details(id).subscribe((result: any) => {
        if(result.isActive != 1){
          this._tosterService.error("Template is not enabled.");
          return;
        }
        if(result.voucherType != this.voucherType){
          this._tosterService.error("Template Voucher Type does not match.");
          return;
        }
        if(result.appliedForBlockId > 0 && result.appliedForBlockId != this.selectedBlockId){
          this._tosterService.error("Template Block does not matched.");
          return;
        }
        if(result.propertyType > 0 && result.propertyType != this.propertyTypeId){
          this._tosterService.error("Template Voucher Property Type does not match.");
          return;
        }
        if((result.sendToOwners == 1 && result.sendToTenant == 1) && this.voucherType != this.societyInvoiceType && this.voucherType != this.rentInvoiceType){
          this._tosterService.error("Template Voucher is only applicable for Property Owners or Tenants.");
          return;
        }
        else if((result.sendToTenant == 1 && result.sendToOwners != 1) && this.voucherType != this.rentInvoiceType){
          this._tosterService.error("Template Voucher is only applicable for Tenants.");
          return;
        }else if((result.sendToOwners == 1 && result.sendToTenant != 1) && this.voucherType != this.societyInvoiceType){
          this._tosterService.error("Template Voucher is only applicable for Owners.");
          return;
        }
        if(result.discountType == 2){
          if((!this.periodStartFrom || !this.periodEndOn)){
            this._tosterService.error("Template Voucher is only applicable for yearly invoices.");
            return;
          }else{
            let monthDiff = ((new Date(CommonMethods.dbToUsDate(this.periodEndOn)).getTime() - new Date(CommonMethods.dbToUsDate(this.periodStartFrom)).getTime()) / 1000)/(60 * 60 * 24 * 7 * 4);
            if(monthDiff<11 || monthDiff>13){
              this._tosterService.error("Template Voucher is only applicable for yearly invoices.");
              return;
            }
          } 
        }
      }, error => {
        this._tosterService.error(error.message);
      });
    }
  }

  /*---Validate selected Late Fee Template---*/
  getLateFeeTemplate(id: number = 0): void {
    if(id > 0){
      this._lateFeeTemplateService.details(id).subscribe((result: any) => {
        if(result.isActive != 1){
          this._tosterService.error("Template is not enabled.");
          return;
        }
        if(result.voucherType != this.voucherType){
          this._tosterService.error("Template Voucher Type does not match.");
          return;
        }
        if(result.appliedForBlockId > 0 && result.appliedForBlockId != this.selectedBlockId){
          this._tosterService.error("Template Block does not matched.");
          return;
        }
        if(result.propertyType > 0 && result.propertyType != this.propertyTypeId){
          this._tosterService.error("Template Voucher Property Type does not match.");
          return;
        }
        if((result.sendToOwners == 1 && result.sendToTenant == 1) && this.voucherType != this.societyInvoiceType && this.voucherType != this.rentInvoiceType){
          this._tosterService.error("Template Voucher is only applicable for Property Owners or Tenants.");
          return;
        }
        else if((result.sendToTenant == 1 && result.sendToOwners != 1) && this.voucherType != this.rentInvoiceType){
          this._tosterService.error("Template Voucher is only applicable for Tenants.");
          return;
        }else if((result.sendToOwners == 1 && result.sendToTenant != 1) && this.voucherType != this.societyInvoiceType){
          this._tosterService.error("Template Voucher is only applicable for Owners.");
          return;
        }
      }, error => {
        this._tosterService.error(error.message);
      });
    }
  }

  /*---Check if existing invoices are there, opens Step 3---*/
  async checkBulkInvoice(): Promise<void> {
    if(this.validateAll()){
      let data = { 
        communityId: CommonMethods.getCommunityId(),
        type: this.createType,
        periodStartFrom: this.periodStartFrom?CommonMethods.dbToUsDate(this.periodStartFrom):this.periodStartFrom,
        periodEndOn: this.periodEndOn?CommonMethods.dbToUsDate(this.periodEndOn):this.periodEndOn,
        blockId: this.selectedBlockId,
        propertyTypeId: this.propertyTypeId,
        lineItems: this.billingLineItems
      };
      this._voucherService.checkBulkInvoice(data).subscribe(async (result:any)=>{
        if(result.ledgers.length > 0){
          this.allowedLedgers = result.ledgers;
        }else{
          this.allowedLedgers = [];
        }
        if(result.properties.length > 0){
          this.allowedProperties = result.properties;
        }else{
          this.allowedProperties = [];
        }
        if(result.vouchers.length > 0){
          this.invoices = this.santitizeItems(result.vouchers);
          let temp = this.invoices;
          this.existingInvoices = temp.slice(this.existingInvoiceStartIndex, this.existingInvoiceRecordPerPage);
          this.existingInvoiceTotalRecords = result.vouchers.length;
        }else{
          this.invoices = [];
          this.existingInvoices = [];
        }
        this.openStepThree();
      }, error => {
        this._tosterService.error(error.message);
      });
    }
  }

  /*---Sanitize existing invoices data---*/
  santitizeItems(items:any): any {
    this.allInvoices = [];
    items.forEach(element => {
      this.allInvoices.push(element.id);
      if(this.createType == 1 || this.createType == 2 || this.createType == 5){
        this.allowedLedgers.splice(this.allowedLedgers.indexOf(element.ledgerToId), 1);
      }else{
        this.allowedProperties.splice(this.allowedProperties.indexOf(element.propertyId), 1);
      }
      element.formattedVoucherDate = CommonMethods.dateToIndianFormat(element.voucherDate);
      if(element.manualDueDate){
        element.formattedManualDueDate = CommonMethods.dateToIndianFormat(element.manualDueDate);
      }
      if(element.lastPaymentDate){
        element.formattedLastPaymentDate = CommonMethods.dateToIndianFormat(element.lastPaymentDate);
      }else{
        element.formattedLastPaymentDate = "NA";
      }
    });
    return items;
  }

  /*---Triggered when Existing invoice page changed---*/
  onExistingInvoicePageChange(startIndex:number): void {
    this.existingInvoiceStartIndex = startIndex;
    let perPage = this.existingInvoiceRecordPerPage+this.existingInvoiceStartIndex;
    let temp = this.invoices;
    this.existingInvoices = temp.slice(this.existingInvoiceStartIndex, perPage);
  }

  /*---View Invoice details on another tab---*/
  details(item: any): void {
    this._router.navigate([]).then(result => {  window.open("society-invoice/" + item.id, '_blank'); });
  }

  /*---Select/Deselect existing invoices---*/
  selectInvoice(elem:any): void {
    var val = parseInt(elem.value);
    if(elem.checked){
      this.selectedInvoices.push(val);
    } else{
      var index: number = this.selectedInvoices.indexOf(val);
      if (index !== -1) {
        this.selectedInvoices.splice(index, 1);
      }
    }
  }

  /*---Cancel all existing invoices---*/
  async cancelAll() {
    /*let error = false;
    let n:number = 0;*/
    if(this.allInvoices.length > 0){
      if(typeof Worker !== 'undefined' && this.cancellationStarted === false) {
        this.invoiceCancellationWorker = new Worker('../workers/bulk-invoice-cancellation.worker', { name: 'bulk-invoice-cancellation', type: 'module' });
        let reqHeaders = CommonMethods.getHeaderTokenRaw();
        reqHeaders['communityId'] = this.communityId;
        var data = { communityId : this.communityId };
        let params: {[k: string]: any} = {
          invoices: this.allInvoices,
          apiEndPointBase:config.apiEndPointBase,
          requestHeaders: reqHeaders,
          invoiceData: data
        };
        this.cancellationStarted = true;
        this.startCancellationWorker(this.invoiceCancellationWorker,params);
      }else{
        this._tosterService.error("Bulk invoice cancellation is not supported.");
      }  
      /*await this.allInvoices.forEach(async sel=>{
        try{
          await this.cancel(sel).then(()=>{
            n++;
          });
        }catch(e){
          error = true;
          this._tosterService.error(e);
        }
      });
      if(n == this.allInvoices.length && error == false){
        await this.checkBulkInvoice().then(()=>{
          this._tosterService.success("All invoices have been cancelled.");
        });
      }*/
    }else{
      this._tosterService.error("No invoice found to cancel.");
    }
  }
  
  /*---Cancel all selected existing invoices---*/
  async cancelSelected() {
    /*let error:boolean = false;
    let n:number = 0;*/
    if(this.selectedInvoices.length > 0){
      if(typeof Worker !== 'undefined' && this.cancellationStarted === false) {
        this.invoiceCancellationWorker = new Worker('../workers/bulk-invoice-cancellation.worker', { name: 'bulk-invoice-cancellation', type: 'module' });
        let reqHeaders = CommonMethods.getHeaderTokenRaw();
        reqHeaders['communityId'] = this.communityId;
        var data = { communityId : this.communityId };
        let params: {[k: string]: any} = {
          invoices: this.selectedInvoices,
          apiEndPointBase:config.apiEndPointBase,
          requestHeaders: reqHeaders,
          invoiceData: data
        };
        this.cancellationStarted = true;
        this.startCancellationWorker(this.invoiceCancellationWorker,params);
      }else{
        this._tosterService.error("Bulk invoice cancellation is not supported / allowed.");
      }
      /*await this.selectedInvoices.forEach(async sel=>{
        try{
          await this.cancel(sel).then(()=>{
            n++;
          });
        }catch(e){
          error = true;
          this._tosterService.error(e);
        }
      });
      if(n == this.selectedInvoices.length && error == false){
        await this.checkBulkInvoice().then(()=>{
          this._tosterService.success("Selected invoices have been cancelled.");
        });
      }*/
    }else{
      this._tosterService.error("Please select atleast one invoice to cancel.");
    }
  }

  /*---Cancel invoice service called---*/
  /*async cancel(id:number = 0): Promise<void> {
    var data = { communityId : this.communityId };
    await this._voucherService.cancelVoucher(id,data).toPromise().then(() => this.num = this.num+1);
    await this.delay(2000);
  }*/

  async startCancellationWorker(worker:Worker, initParams:any, cancelGenerated:boolean = false){
    return new Promise((resolve, reject) => {
      worker.postMessage(JSON.stringify(initParams));
      worker.onmessage = (event:any) => {
        /*1. progress update
          2. error
          3. success
        */
        switch(event.data.type){
          case 1:
            resolve(event.data);
            break;
          case 2:
            this._tosterService.error(event.data.data);
            reject(event.data);
            worker.terminate();
            this.cancellationStarted = false;
            break;
          case 3:
            this._tosterService.success(event.data.data);
            resolve(event.data);
            worker.terminate();
            if(cancelGenerated){
              this.generatedInvoices = [];
              this.newInvoices = [];
            }else{
              this.checkBulkInvoice();
            }
            this.cancellationStarted = false;
            break;
        }
      };
      worker.onerror = (event:any) => {
        worker.terminate();
        this.cancellationStarted = false;
      };
    });
  }

  /*---Generate invoices through a loop---*/
  async startGeneration(): Promise<void> {
    if(!this.stopped && !this.completed){
      if(this.validateAll()){
        if(this.allowedLedgers.length > 0 || this.allowedProperties.length > 0){
          if (typeof Worker !== 'undefined') {
            this.submitted = true;
            this.invoiceGenerationWorker = new Worker('../workers/bulk-invoice-generation.worker', { name: 'bulk-invoice-generation', type: 'module' });
            let voucherData: {[k: string]: any} = {
              voucherNumber: null,
              voucherType: this.societyInvoiceType,
              voucherFromName: this.communityName,
              voucherFromAddress: this.communityAddress,
              voucherToName: null,
              voucherToAddress: null,
              narration: this.narration,
              voucherDate: CommonMethods.dbToUsDate(this.voucherDate),
              status: "Pending",
              ledgerToId: null,
              ledgerFromId: 0,
              totalGrossAmount: this.totalGrossAmount,
              applicableOnceInPeriod: this.applicableOnceInPeriod,
              totalNetAmount: this.totalNetAmount,
              pendingAmount: this.pendingAmount,
              paymentType: 'Full',
              maintenanceYear: null,
              maintenanceMonth: null,
              tdsAmount: null,
              tdsn: null,
              cancelReason: null,
              metaData: null,
              propertyId: null,
              propertyTypeId: this.propertyTypeId,
              templateId: this.templateId,
              latefeeTemplateId:this.lateFeeTemplateId,
              discountTemplateId:this.discountTemplateId,
              communityId: this.communityId,
              manualDueDate: CommonMethods.dbToUsDate(this.manualDueDate),
              lineItems: null
            };
            if(this.periodStartFrom){
              voucherData.periodStartFrom = CommonMethods.dbToUsDate(this.periodStartFrom);
            }
            if(this.periodEndOn){
              voucherData.periodEndOn = CommonMethods.dbToUsDate(this.periodEndOn);
            }
            let reqHeaders = CommonMethods.getHeaderTokenRaw();
            reqHeaders['communityId'] = this.communityId;
            let params: {[k: string]: any} = {
              createType: this.createType,
              allowedLedgers: this.allowedLedgers,
              allowedProperties: this.allowedProperties,
              billingLineItems: this.billingLineItems,
              invoiceData: voucherData,
              apiEndPointBase:config.apiEndPointBase,
              requestHeaders: reqHeaders 
            };
            this.started = true;
            this.startGenerationWorker(this.invoiceGenerationWorker,params);
          }else{
            this._tosterService.error("Bulk invoice generation is not supported.");
          }
        }else{
          this._tosterService.error("No results found to generate invoice.");
        }
      }
    }else{
      this._tosterService.error("Process has been completed or stopped. Please start a fresh generation.");
    }  
  }

  startGenerationWorker(worker:Worker, initParams:any): Promise<any> {
    return new Promise((resolve, reject) => {
      worker.postMessage(JSON.stringify(initParams));
      worker.onmessage = (event:any) => {
        /*1. progress update
          2. error
          3. success
        */
        switch(event.data.type){
          case 1:
            if(this.createType == 1 || this.createType == 2 || this.createType == 5){
              this.generatedInvoices.push(event.data.data);
              this.generatedInvoices = this.santitizeGeneratedItems(this.generatedInvoices);
              let temp = this.generatedInvoices;
              this.newInvoices = temp.slice(this.generatedInvoiceStartIndex, this.generatedInvoiceRecordPerPage);
              this.generatedInvoiceTotalRecords = this.generatedInvoices.length;
              this.doneLedgers.push(event.data.data.id);
              this.generatedPercentage = (this.doneLedgers.length/this.allowedLedgers.length)*100;
              this.progressBarStyle = this.generatedPercentage +"%";
              this.roundedGeneratedPercentage = Math.round(this.generatedPercentage);
            }else{
              this.generatedInvoices.push(event.data.data);
              this.generatedInvoices = this.santitizeGeneratedItems(this.generatedInvoices);
              let temp = this.generatedInvoices;
              this.newInvoices = temp.slice(this.generatedInvoiceStartIndex, this.generatedInvoiceRecordPerPage);
              this.generatedInvoiceTotalRecords = this.generatedInvoices.length;
              this.doneProperties.push(event.data.data.id);
              this.generatedPercentage = (this.doneProperties.length/this.allowedProperties.length)*100;
              this.progressBarStyle = this.generatedPercentage +"%";
              this.roundedGeneratedPercentage = Math.round(this.generatedPercentage);
            }
            resolve(event.data);
            break;
          case 2:
            this._tosterService.error(event.data.data);
            reject(event.data);
            worker.terminate();
            break;
          case 3:
            this._tosterService.success(event.data.data);
            this.completed = true;
            resolve(event.data);
            worker.terminate();
            break;
        }
      };
      worker.onerror = (event:any) => {
        worker.terminate();
      };
    });
  }

  /*---Sanitize generated invoices data---*/
  santitizeGeneratedItems(items:any): any {
    items.forEach(element => {
      if(element.voucherDate){
        element.formattedVoucherDate = CommonMethods.dateToIndianFormat(element.voucherDate);
      }
      if(element.manualDueDate){
        element.formattedManualDueDate = CommonMethods.dateToIndianFormat(element.manualDueDate);
      }
      if(element.lastPaymentDate){
        element.formattedLastPaymentDate = CommonMethods.dateToIndianFormat(element.lastPaymentDate);
      }else{
        element.formattedLastPaymentDate = "NA";
      }
    });
    return items;
  }

  /*---Stop Invoice Generation process forcefully---*/
  stopGeneration(): void {
    if(this.started){
      this.stopped = true;
      this.invoiceGenerationWorker.terminate();
    }else{
      this._tosterService.error("Process has not been started yet.");
    }
  }

  /*---Triggered when Genberated Invoices page changed---*/
  onGeneratedInvoicePageChange(startIndex:number): void {
    this.generatedInvoiceStartIndex = startIndex;
    let perPage = this.generatedInvoiceRecordPerPage+this.generatedInvoiceStartIndex;
    let temp = this.generatedInvoices;
    this.newInvoices = temp.slice(this.generatedInvoiceStartIndex, perPage);
  }

  /*---Cancel all generated invoices---*/
  async cancelAllGenerated() {
    if(this.generatedInvoices.length > 0){
      if(!this.stopped && !this.completed){
        this._tosterService.error("Invoice generation is in process, stop the process to cancel all generated invoices.")
      }else{
        let genInvoices = [];
        this.generatedInvoices.forEach(obj=>{
          genInvoices.push(obj.id);
        });
        if(typeof Worker !== 'undefined' && this.cancellationStarted === false) {
          this.invoiceCancellationWorker = new Worker('../workers/bulk-invoice-cancellation.worker', { name: 'bulk-invoice-cancellation', type: 'module' });
          let reqHeaders = CommonMethods.getHeaderTokenRaw();
          reqHeaders['communityId'] = this.communityId;
          var data = { communityId : this.communityId };
          let params: {[k: string]: any} = {
            invoices: genInvoices,
            apiEndPointBase:config.apiEndPointBase,
            requestHeaders: reqHeaders,
            invoiceData: data
          };
          this.cancellationStarted = true;
          this.startCancellationWorker(this.invoiceCancellationWorker,params,true);
        }else{
          this._tosterService.error("Bulk invoice cancellation is not supported / allowed.");
        }
        /*let n:number = 0;
        let error:boolean = false;
        await this.generatedInvoices.forEach(async obj=>{
          console.log(obj.id);
          try{
            await this.cancel(obj.id).then(()=>{
              n++;
            });
          }catch(e){
            this._tosterService.error(e);
            error = true;
          }
          await this.delay(3000);
        });
        if(n == this.generatedInvoices.length && error == false){
          this.generatedInvoices = [];
          this.newInvoices = [];
          this._tosterService.success("All generated invoices have been cancelled.");
        }*/
      }
    }else{
      this._tosterService.error("No invoice has been generated yet.");
    }
  }

  /*async delay(amount: number) {
    return new Promise((resolve) => {
      console.log("delayed for "+ amount);
      setTimeout(resolve, amount);
    });
  }*/

  /*---Go back to Society Invoice list---*/
  goToList(): void {
    if(this.started && (!this.stopped && !this.completed)){
      this._tosterService.error("Invoice generation is in process, please stop the process before leaving this page.");
    }else{
      this._router.navigate(["society-invoices"]);
    }
  }

  /*---Breadcrumb navigation---*/
  breadcrumbNavigate(mode: any): void {  
    if(this.started && (!this.stopped && !this.completed)){
      this._tosterService.error("Invoice generation is in process, please stop the process before leaving this page.");
    }else{
      if (mode == "list") {
        this.goToList();
      } else if (mode == "dashboard") {
        this._router.navigateByUrl("/dashboard");
      }
    }
  }

  ngOnDestroy(): void {
    this._uiService.leftMenuItems.next([]);
  }

}
