import { Deseriazable } from './deserializable.model';

export class MaintenanceContractModel implements Deseriazable
{
    id: number;
    communityId: number;
    vendorId: string;
    contractType: string;
    mode: string;
    rate: string;
    quantity: string;
    amount: string;
    startDate: string;
    expiryDate: string;
    remarks: string;
    linkItemId: string;
    linkItemType:string;
    linkItem: Array<string>;    
    status: number;
    category: string;
    cancelReason: string;

    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }
}