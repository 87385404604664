import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LedgerModel } from './../Model/ledger.model';
import { CustomValidator } from '../customvalidation';

@Injectable({
    providedIn: 'root'
})

export class LedgerFormBuilder {
    private form;
    private validationRules = {
    accountCode: ['',Validators.required],
    ledgerName: ['',Validators.required],
    accountNumber: ['',''],
    branchName: ['',''],
    ifscCode: ['',''],
    mobile: ['',[Validators.required,CustomValidator.numeric]],
    email: ['',Validators.email],
    houseNumber: ['',''],
    street: ['',''],
    block: ['',''],
    landmark: ['',''],
    postOffice: ['',''],
    policeStation: ['',''],
    town: ['',''],
    district: ['',''],
    subDistrict: ['',''],
    pinCode: ['',''],
    profession: ['',''],
    stateId: ['',''],
    countryId: ['',''],
    pan: ['',''],
    gstin: ['',''],
    description: ['',''],
    hasTransactions: ['',''],
    ledgerCurrentBalance: ['',''],
    openingBalance: ['',[Validators.required,CustomValidator.numeric]],
    startsFrom: ['',Validators.required],
    ledgerType: ['',''],
    profileUpdatedOn: ['',''],
    accountGroupId: ['',Validators.required],
    linkedUserId: ['',''],
    referenceId: ['',''],
    is_active: ['',''],
    accountTypeId: ['',Validators.required],
    bankAccountType: ['','']
    }

    constructor(private fb: FormBuilder) {
        this.form = fb.group(this.validationRules);
    }

    getForm() {
        return this.form;
    }

    setValues(response: LedgerModel) {
        this.form.patchValue({
            id: response.id,
            accountCode: response.accountCode,
            ledgerName: response.ledgerName,
            accountNumber: response.accountNumber,
            branchName: response.branchName,
            ifscCode: response.ifscCode,
            mobile: response.mobile,
            email: response.email,
            pan: response.pan,
            gstin: response.gstin,
            description: response.description,
            openingBalance: response.openingBalance,
            ledgerCurrentBalance: response.ledgerCurrentBalance,
            startsFrom: response.startsFrom,
            ledgerType: response.ledgerType,
            profileUpdatedOn: response.profileUpdatedOn,
            accountGroupId: response.accountGroupId,
            linkedUserId: response.linkedUserId,
            referenceId: response.referenceId,
            //communityId: response.communityId,
            hasTransactions: response.hasTransactions,
            accountCommunityId: response.accountCommunityId,
            accountTypeId: response.accountTypeId,
            ledgerCommunityId: response.ledgerCommunityId,
            houseNumber: response.houseNumber,
            street: response.street,
            block: response.block,
            landmark: response.landmark,
            postOffice: response.postOffice,
            policeStation: response.policeStation,
            town: response.town,
            district: response.district,
            subDistrict: response.subDistrict,
            pinCode: response.pinCode,
            stateId: response.stateId,
            countryId: response.countryId,
            address: response.address,
            is_active: response.is_active,
            bankAccountType: response.bankAccountType
        });
    }
}