import { Component, OnInit } from '@angular/core';
import { CommunityService } from '../api-client/community.service';
import { ToastrService } from 'ngx-toastr';
import { UiService } from '../ui.service';
import { CommonMethods } from '../common-methods';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-community-members',
  templateUrl: './community-members.component.html',
  styleUrls: ['./community-members.component.css']
})
export class CommunityMembersComponent implements OnInit {

  members: any;
  routeParams: any;

  constructor(
    private _communityService: CommunityService,
    private _tosterService: ToastrService,
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit() {
    this.list();
  }

  list() {
    var data = {communityId:CommonMethods.getCommunityId()};
    this._communityService.members(data).subscribe((result: any) => {
      this.members = result;
    })
    this._route.params.subscribe(data => {
      console.log(data);
      this.routeParams = data;
    })
  }

  filterMember(eve: any) {
    var code = eve.keyCode;
    if (code == 37 || code == 38 || code == 39 || code == 40) {
      return;
    }
    var ele = eve.target;
    var items: any;
    if (ele.value) {
      items = this.members.filter((x: any) => x.name.toLowerCase().includes(ele.value.toLowerCase()));
      this.members = items;
    } else {
      this.list();
    }
  }

  details(item: any) {
    if (this._uiService.activeListCallBack) {
      this._uiService.activeListCallBack(item.id, item.name);
      this._uiService.activeListCallBack = null;
      this._uiService.closeItself();
      return;
    }
  }

}
