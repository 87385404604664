import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { UiService } from '../ui.service';
import { CategoryService } from '../api-client/category.service';
import { CommonMethods } from '../common-methods';
import { CategoryFormBuilder } from './category.form-builder';
import { CategoryModel} from '../Model/category.model';
import { APP_CONST } from '../app-constants';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  routeExtras: NavigationExtras;
  categoryForm: any;
  model = new CategoryModel();
  submitted = false;
  communityId: any;
  categoryId: number;
  name: "";
  categories: any;
  args: any;
  APP_CONST = APP_CONST;
  deleteCategoryParams: any = {
    itemId: 0,
    itemName: null,
    deleteText: "Are you sure, you want to delete this category?"
  };
  linkItemType:any;
  linkItemId:any;
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _uiService: UiService,
    private _categoryService: CategoryService,
    private _formBuilder: CategoryFormBuilder,
    private _tosterService: ToastrService
  ) {
    this.routeExtras = this._router.getCurrentNavigation().extras;
  }

  ngOnInit() {
    this.communityId = 0;
    this.categoryForm = this._formBuilder.getForm();
    this._route.params.subscribe(params => {
      if(params.id){
        //this.details(params.id);
      }
    });
    this.tags();
  }

  get f() { return this.categoryForm.controls; }


  tags() {
    var data = {communityId:CommonMethods.getCommunityId()};
    this._categoryService.all(data).subscribe((result: any) => {
      this.categories = result;
    })
  }

  details(id:number){
    this._categoryService.details(id).subscribe((result: any) => {
      this.communityId = result.communityId;
      this.categoryId = result.id;
      this.name = result.name;
      this._formBuilder.setValues(result);
    })
  }

  onSubmit() {
    this.submitted = true;
    if (this.categoryForm.invalid) {
      return;
    }
    this.model.id = this.categoryId;
    this.model.deserialize(this.categoryForm.value);
    this.model.communityId = Number(CommonMethods.getCommunityId());
    //console.log(this.model);return;
    this._categoryService.save(this.model)
      .subscribe(
        (response: any) => {
          this._tosterService.success("Category save");
          this.tags();
          //if (!this.categoryId) {
            this.submitted = false;
            this.resetForm();
         // }
          //return false;
        },
        error => {
          this._tosterService.error(error.message);
        }
      )
  }  

  resetForm() {
    this.categoryId = null;
    this.name = "";
    this.categoryForm.reset();
  }
  

  redirectTo(item:any){
    this._router.navigateByUrl("categories/");
  }

  deleteCategory() {
    //console.log(this.deleteParams.itemId);
    if(this.deleteCategoryParams.pressBtn=="Ok"){
      this._categoryService.delete(this.deleteCategoryParams.itemId).subscribe((result: any) => {
        this._tosterService.success("Category Deleted");
        this.tags();
        return;
      });
    }    
  }

  deleteCategoryPopUp(item: any) {
    this.deleteCategoryParams.itemId = item.id;
    this.deleteCategoryParams.itemName = item.name;
    this._uiService.deleteItemData = this.deleteCategoryParams;
    this._uiService.deleteItemCallBack = this.deleteCategory.bind(this);
    this._router.navigate([{
      outlets: { centerPopup: ['delete-item'] }
    }]);
  }

  redirectionType(){
    this._router.navigateByUrl("/dashboard"); return;
  }

}
