import { Deseriazable } from './deserializable.model';

export class BuildingModel implements Deseriazable
{
    id: number;
    communityId: number;
    communityBlockId: number;
    name: string;
    houseNumber: string;
    street: string;
    block: string;
    landmark: string;
    postOffice: string;
    policeStation: string;
    town: string;
    district: string;
    subDistrict: string;
    pinCode: number;
    stateId: number;
    countryId: number;
    residentialUnitsCount: number;
    commercialUnitsCount: number;
    parkingSpacesCount: number;
    garagesCount: number;
    noOfUnits: number;
    floorNames: string;
    latitude: string;
    longitude: string;


    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }
}