import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../../app/config';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private _http: HttpClient) {
  }

  all(data: any) {
    return this._http
      .post(config.apiEndPointBase + "/role/list", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  save(data: any): Observable<any> {
    if (data.id) {
      return this._http.put(config.apiEndPointBase + "/role", data, {})
        .pipe(map((response: any) => {
          return response;
        }));
    } else {
      return this._http.post(config.apiEndPointBase + "/role", data, {})
        .pipe(map((response: any) => {
          return response;
        }));
    }
  }

  delete(id: number) {
    return this._http.delete(config.apiEndPointBase + `/role/${id}`, { responseType: 'text' });
  }

  details(id: number) {
    return this._http.get(config.apiEndPointBase + `/role/${id}`);
  }

  privileges(data: any) {
    return this._http.post(config.apiEndPointBase + "/role/capabilities/list", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  savePrivileges(data: any) {
    return this._http.post(config.apiEndPointBase + "/role/capabilities", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }
}
