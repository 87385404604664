import { Component, OnInit } from '@angular/core';
import { UiService } from '../ui.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { APP_CONST } from '../app-constants';
import { BookingService } from '../api-client/booking.service';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.css']
})
export class BookingsComponent implements OnInit {

  filterParams: any = {
    searchData: null,
    bookingStatus: null,
    index: null,
    pageSize: null,
    fromDate: null,
    toDate: null,
    category: null,
  };
  bookingDetails: any;
  filteredSubjects = [];
  startIndex: number = 0;
  recordPerPage: number = 40;
  totalRecords: number = 0;
  routeExtras: NavigationExtras;

  constructor(
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _bookingService: BookingService
  ) { this.routeExtras = this._router.getCurrentNavigation().extras; }

  ngOnInit() {
    this._route.data.subscribe(params => {
    this._uiService.leftMenuItems.next([
      { code: 'F3', name: 'Filter', action: this.openFilter.bind(this)},
      { code: 'F4', name: 'Add New', action: this.redirectTo.bind(this)},
    ]);
    this.startIndex = 0;
    this.getBookings();
  });
  }

  async getBookings() {
    let data = {
      communityId: CommonMethods.getCommunityId(),
      searchData: this.filterParams.searchData,
      fromDate: this.filterParams.fromDate,
      toDate: this.filterParams.toDate,
      bookingStatus: this.filterParams.bookingStatus,
      startIndex: this.startIndex
    };
    await this._bookingService.all(data).toPromise().then((result: any) => {
      this.bookingDetails = result.data;
      this.filteredSubjects = this.bookingDetails;
      this.totalRecords = result.total_records;
    })
  }

  openFilter() {
    this._router.navigate([{
      outlets: { leftMainPopup: ["booking-filter"] }
    }]);
    return;
  }

  redirectTo() {
    this._router.navigate([{
      outlets: { leftMainPopup: ["booking-details", 0] }
    }]);
    return;
  }

  onPageChange(startIndex: number) {
    this.startIndex = startIndex;
    this.getBookings();
  }

}
