import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PropertyService } from '../api-client/property.service';
import { LedgerService } from '../api-client/ledger.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../ui.service';
import { async } from '@angular/core/testing';
import { PaymentService } from '../api-client/payment.service';
import { APP_CONST } from '../app-constants';

@Component({
  selector: 'app-associate-ledger',
  templateUrl: './associate-ledger.component.html',
  styleUrls: ['./associate-ledger.component.css']
})
export class AssociateLedgerComponent implements OnInit, OnChanges {

  isOwner: any;
  ledgerId: any;
  ledgerName = "--select--";
  propertyType: any;
  totalDue: number;
  totalPenalty: number;
  totalPayble: number;
  totalDiscount: number;
  todaysDate: any;
  ledgerDetails: any;
  @Input() unitId: any;
  disableLedger: boolean = false;
  deleteParams: any = {
    itemId: 0,
    itemName: null,
    deleteText: "Are you sure, you want to unassign this ledger?"
  }
  unitDetails:any;
  APP_CONST = APP_CONST;
  constructor(private _tosterService: ToastrService,
    private _propertyService: PropertyService,
    private _ledgerService: LedgerService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _paymentService: PaymentService,
    private _uiService: UiService, ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (!this.unitId) return;
    this.propertyDetails(this.unitId);
  }

  assignLedger() {
    if (this.unitId == "" || this.unitId == null) {
      this._tosterService.error("Ledger will be added with respective property");
      return;
    }
    if (this.isOwner == "0" || this.isOwner == "1") {

    } else {
      this._tosterService.error("Ledger type can not be blank.");
      return;
    }
    if (this.ledgerId == "" || this.ledgerId == null) {
      this._tosterService.error("Ledger can not be blank.");
      return;
    }
    var data = { propertyId: this.unitId, ledgerId: this.ledgerId, isOwner: this.isOwner, type: this.propertyType, status: 1 };
    this._propertyService.assignLedger(data).subscribe((result: any) => {
      if (result) {
        this.propertyDetails(this.unitId);
        this._tosterService.success("Ledger assigned");
      }
    })
  }

  async propertyDetails(id: number) {
    this.associateLedger({ propertyId: id });
    await this._propertyService.details(id).toPromise().then((result: any) => {
      this.unitDetails = result;
      this.propertyType = result.propertyType;
    })
    await this._propertyService.getBalance(id).toPromise().then((data: any) => {
      this.todaysDate = Date.now();
      this.totalDue = Math.round(data.totalDueAmount);
      this.totalPenalty = Math.round(data.totalAccumulatedCharge);
      this.totalDiscount = data.totalDiscountAmount;
      this.totalPayble = (this.totalDue + this.totalPenalty) - this.totalDiscount;
    })
  }

  async associateLedger(data: any) {
    await this._propertyService.associateLedger(data).toPromise().then((result: any) => {
      if (!result) return;
      this.disableLedger = true;
      this.isOwner = result.isOwner;
      this.ledgerId = result.ledgerId;
      if (result.ledgerId) {
        this.changeLedger(result.ledgerId);
      }
    })
  }

  async changeLedger(ledgerId: any) {
    await this._ledgerService.details(ledgerId).toPromise().then((result: any) => {
      this.ledgerDetails = result;
      this.ledgerId = ledgerId;
      this.ledgerName = result.ledgerName;
    });
  }

  unAssignLedger() {
    if (this.disableLedger && this.deleteParams.pressBtn == "Ok") {
      var data = { propertyId: this.unitId, ledgerId: this.ledgerId, isOwner: this.isOwner, type: this.propertyType, status: 1 };
      this._propertyService.unAssignLedger(data).subscribe((result: any) => {
        if (result) {
          this.disableLedger = false;
          this._tosterService.success("Ledger unassigned");
          setTimeout(() => {
            this.resetForm();
          }, 1000);
        }
      })
    }
  }

  confirmationPopUp() {
    if (this.disableLedger) {
      if (this.unitId == "" || this.unitId == null) {
        this._tosterService.error("Ledger will be added with respective property");
        return;
      }
      if (this.isOwner == "0" || this.isOwner == "1") {

      } else {
        this._tosterService.error("Ledger type can not be blank.");
        return;
      }
      if (this.ledgerId == "" || this.ledgerId == null) {
        this._tosterService.error("Ledger can not be blank.");
        return;
      }
      this.deleteParams.itemName = this.ledgerName;
      this._uiService.deleteItemData = this.deleteParams;
      this._uiService.deleteItemCallBack = this.unAssignLedger.bind(this);
      this._router.navigate([{
        outlets: { centerPopup: ['delete-item'] }
      }]);
    }
  }

  toLedger(item: any) {
    this._router.navigateByUrl(`ledger/${item.id}`);
    return;
  }

  toCollection(){
    if(!this.unitId) return;    
    var data = { propertyTypeId: this.propertyType,propertyId: this.unitId,propertyName:this.unitDetails.unitNo, ledgerId: this.ledgerId, paymentType: APP_CONST.INVOICE_TYPE_COLLECTION }; 
    var param = ["collections"];
    this._router.navigate(param, { state: data }); 
    return;
  }
  resetForm() {
    this.isOwner = null;
    this.ledgerDetails = {};
    this.ledgerId = null;
    this.ledgerName = "--select--";
  }
}
