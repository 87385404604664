import { Deseriazable } from './deserializable.model';

export class VoucherModel implements Deseriazable
{
    id: number;
    voucherNumber: string;
    voucherType: string;
    voucherFromName: string;
    voucherFromAddress: string;
    voucherToName: string;
    voucherToAddress: string;
    narration: string;
    voucherDate: string;
    paymentType: string;
    status: string;
    ledgerToId: number;
    ledgerFromId: number;
    totalGrossAmount: number;
    maintenanceYear: string;
    maintenanceMonth: string;
    applicableOnceInPeriod: number;
    periodStartFrom: number;
    periodEndOn: number;
    totalNetAmount: number;
    pendingAmount: number;
    tdsAmount: number;
    tdsn: string;
    cancelReason: string;
    metaData: string;
    propertyId: number;
    propertyTypeId: number;
    communityId: number;
    manualDueDate: string;
    templateId: number;
    
    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }
}