import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { TodoService } from '../api-client/todo.service';
import { UiService } from '../ui.service';
import { APP_CONST } from '../app-constants';

@Component({
  selector: 'app-todos',
  templateUrl: './todos.component.html',
  styleUrls: ['./todos.component.css']
})
export class TodosComponent implements OnInit,OnChanges {

  @Input() linkItemType: any;
  @Input() linkItemId: any;
  @Input() linkItemName: any;
  @Input() tagName: any;
  todos = [];
  APP_CONST = APP_CONST;

  constructor(private _router: Router,
    private _route: ActivatedRoute,
    private _uiService:UiService,
    private _toService:TodoService
    ) { }

  ngOnInit() {
    this._uiService.todoDataReload.subscribe((result:any)=>{            
      this.list();
    })
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    this.list();
  }

  redirectTo(item: any) {
    var state = {};
    if(item.id>0){
      state = {};
    }else{
      state = { state: { id:this.linkItemId,name:this.linkItemName,type:this.linkItemType } }
    }
    this._router.navigate([{
      outlets: { leftMainPopup: ["todo-details", item.id] }
    }],state);
    //this._router.navigateByUrl(`${arg}/${item.id}`);
    return;
  }

  async list(){
    if(!this.linkItemType && !this.tagName) return;
    var data = {communityId: CommonMethods.getCommunityId(),linkItemTypeId: this.linkItemType,linkItemId:this.linkItemId,searchData:this.tagName }
    await this._toService.all(data).toPromise().then((result:any)=>{
      this.todos = result.data;
      this.todosBindDate();    
    })
  }

  async todosBindDate(){
    if(!this.todos) return;
    for(var i=0;i<this.todos.length;i++){
      var today = new Date();
      var dueDate = new Date(this.todos[i].dueDate);
      this.todos[i].dueDateExit = false;
      if(today.getTime()>dueDate.getTime() && this.todos[i].status==1){
        this.todos[i].dueDateExit = true;
      }
      var dateTime = this.todos[i].dueDate.split(" ");
      var date = dateTime[0].split("-");
      this.todos[i].day = CommonMethods.daySuffix(date[2]);
      var priority = CommonMethods.todoPriority.filter((x:any)=>x.id==this.todos[i].priority) ;
      if(priority.length>0){
        this.todos[i].priority = priority[0].name;
      }
      var status = CommonMethods.todoStatus.filter((x:any)=>x.id==this.todos[i].status);
      if(status.length>0){
        this.todos[i].status = status[0].name;
      }
      this.todos[i].categories = this.todos[i].category.split(",");
    }
    console.log(this.todos);
  }

}
