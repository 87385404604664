import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { AccountGroupService } from '../api-client/account-group.service';
import { UiService } from '../ui.service';

@Component({
  selector: 'app-account-group',
  templateUrl: './account-group.component.html',
  styleUrls: ['./account-group.component.css']
})
export class AccountGroupComponent implements OnInit {

  groups: any;
  filterGroups: any;
  constructor(
    private _accountGroupService: AccountGroupService,
    private _route: ActivatedRoute,
    private _uiService: UiService,
    private _router: Router
  ) { }

  ngOnInit() {
    this.list();
  }

  list() {
    var data = { communityId: CommonMethods.getCommunityId() };
    this._accountGroupService.all(data).subscribe((result: any) => {
      this.groups = result;
      this.filterGroups = this.groups;
    })
  }

  filterGroup(eve: any) {
    var code = eve.keyCode;
    if(code == 37 || code == 38 || code == 39 || code == 40) {
        return;
    }
    var ele = eve.target;
    var items: any;
    if (ele.value) {
      this.filterGroups = this.groups.filter((x: any) => x.groupName.toLowerCase().includes(ele.value.toLowerCase()));
    } else {
      // this.list();
    }
  }

  details(item: any) {
    if (this._uiService.activeListCallBack) {
      this._uiService.activeListCallBack(item.id, item.groupName);
      this._uiService.activeListCallBack = null;
      this._uiService.closeItself();
    } else {
    this._router.navigateByUrl("account-group/" + item.id);
    }
  }

  redirectTo(item: any) {
    this._router.navigateByUrl("account-group/" + item.id);
  }
}
