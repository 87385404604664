import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject,Observable, of  } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../../app/config';
import { PropertyModel } from '../Model/property.model';
import { MaintenanceLogModel } from '../Model/maintenance-log.model';
import { TodoModel } from '../Model/todo.model';

@Injectable({
  providedIn: 'root'
})
export class TodoService {  

  constructor(private _http: HttpClient) {
  }

  all(data:any){
    return this._http
      .post(config.apiEndPointBase + "/todo/list", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }
 

  details(data:any){
    return this._http.get(config.apiEndPointBase + `/todo/${data.id}`,{});
  }
  
  save(data:TodoModel): Observable<TodoModel> {
    if(data.id){
      return this._http.put(config.apiEndPointBase + "/todo",data, {responseType: 'text'})
      .pipe(map((response:any) => {
        return new TodoModel().deserialize(response);
      }));
    }else{
      return this._http.post(config.apiEndPointBase + "/todo",data, {responseType: 'text'})
      .pipe(map((response:any) => {
        return new TodoModel().deserialize(response);
      }));
    }
  }

  comment(data:any){
    if(data.id){
      return this._http
      .put(config.apiEndPointBase + "/todo/comment", data, {responseType: 'text'})
      .pipe(map((response: any) => {
        return response;
      }));
    }else{
      return this._http
      .post(config.apiEndPointBase + "/todo/comment", data, {responseType: 'text'})
      .pipe(map((response: any) => {
        return response;
      }));
    }
    
  }

  comments(data:any){
    return this._http
      .post(config.apiEndPointBase + "/todo/comments", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  deleteComment(id:number){
    return this._http.delete(config.apiEndPointBase + `/todo/comment/${id}`,{responseType: 'text'});
  }

  delete(id:number){
    return this._http.delete(config.apiEndPointBase + `/todo/${id}`,{responseType: 'text'});
  }

  saveDependentTasks(data:any){
    return this._http
      .post(config.apiEndPointBase + "/todo/dependencies", data, {responseType: 'text'})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  dependentTasks(id:any){
    return this._http.get(config.apiEndPointBase + `/todo/${id}/dependencies`,{});
  }
  
}
