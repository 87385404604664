import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../config';
import { CommonMethods } from '../common-methods';

@Injectable({
  providedIn: 'root'
})
export class LateFeeTemplateService {

  constructor(private _http: HttpClient) {
  }

  all() {
    return this._http
      .post(config.apiEndPointBase + "/late_fee_template/list", { communityId: CommonMethods.getCommunityId() }, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  template(id: any) {
    return this._http
      .post(config.apiEndPointBase + "/late_fee_template/list", { communityId: CommonMethods.getCommunityId() }, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  saveTemplate(data: any) {
    if (data.id) {
      return this._http
        .put(config.apiEndPointBase + "/late_fee_template", data, {})
        .pipe(map((response: any) => {
          return response;
        }));
    } else {
      return this._http
        .post(config.apiEndPointBase + "/late_fee_template", data, {})
        .pipe(map((response: any) => {
          return response;
        }));
    }

  }

  saveTemplatePeriod(data: any) {
    if (data.id) {
      return this._http
        .put(config.apiEndPointBase + "/late_fee_template/period", data, {})
        .pipe(map((response: any) => {
          return response;
        }));
    } else {
      return this._http
        .post(config.apiEndPointBase + "/late_fee_template/period", data, {})
        .pipe(map((response: any) => {
          return response;
        }));
    }
  }

  saveTemplateLineItems(data: any) {
    return this._http
      .post(config.apiEndPointBase + "/late_fee_template/items", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  periods(templateId: any) {
    return this._http.get(config.apiEndPointBase + `/late_fee_template/${templateId}/period`);
  }

  delete(id: any) {
    return this._http.delete(config.apiEndPointBase + `/late_fee_template/${id}`,{responseType: 'text'});
  }

  period(templateId: any) {
    return this._http.get(config.apiEndPointBase + `/late_fee_template/${templateId}/items`);
  }

  details(id: number) {
    return this._http.get(config.apiEndPointBase + `/late_fee_template/${id}`);
  }
}
