import { Component, OnInit } from '@angular/core';
import { CommonMethods } from '../common-methods';
import { NavigationExtras, ActivatedRoute, Router } from '@angular/router';
import { VoucherService } from '../api-client/voucher.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../api-client/user.service';
import { UiService } from '../ui.service';

@Component({
  selector: 'app-vouchers',
  templateUrl: './vouchers.component.html',
  styleUrls: ['./vouchers.component.css']
})
export class VouchersComponent implements OnInit {

  vouchers: any;
  filteredVouchers: any;
  routeParams: any;
  routeExtras: NavigationExtras;
  multiSelect: false;

  constructor(
    private _voucherService: VoucherService,
    private _tosterService: ToastrService,
    private _userService: UserService,
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this.routeExtras = this._router.getCurrentNavigation().extras;
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      if (params.multiSelect) {
        this.multiSelect = params.multiSelect;
      }
      var data = { communityId: CommonMethods.getCommunityId(), voucherType: params.type };
      this.list(data);
    });

  }

  list(data: any) {
    this._voucherService.filterVoucher(data).subscribe((result: any) => {
      this.vouchers = result.data;
      this.filteredVouchers = this.vouchers;
    })
    this._route.params.subscribe(data => {
      this.routeParams = data;
    })
  }

  filterVoucher(eve: any) {
    var code = eve.keyCode;
    if (code == 37 || code == 38 || code == 39 || code == 40) {
      return;
    }
    var ele = eve.target;
    var items: any;
    if (ele.value) {
      this.filteredVouchers = this.vouchers.filter((x: any) => x.voucherNumber.toLowerCase().includes(ele.value.toLowerCase()));
    } else {
      //this.list();
    }
  }

  details(item: any) {
    if (this._uiService.activeListCallBack) {
      this._uiService.activeListCallBack(item.id, item.voucherNumber);
      this._uiService.activeListCallBack = null;
      this._uiService.closeItself();
      return;
    }
    //this._router.navigateByUrl("block/" + item.id);
    return false;
  }

  findMatchingItemInSelectedItems(id:any){
    if (this.routeExtras && this.routeExtras.state && this.routeExtras.state.selectedItems) {
      let items = this.routeExtras.state.selectedItems.filter((x:any)=>x.id==id);
      if(items.length>0){
        return items[0];
      }
    }
  }

  itemSelectChange(eve: any, voucher: any) {
    if (this.routeExtras && this.routeExtras.state && this.routeExtras.state.selectedItems) {
      if (eve.target.checked) {
        this.routeExtras.state.selectedItems.push({id:voucher.id, name:voucher.voucherNumber});
      }else{
        let item = this.findMatchingItemInSelectedItems(voucher.id);
        if(item){
          let index = this.routeExtras.state.selectedItems.indexOf(item);
          this.routeExtras.state.selectedItems.splice(index, 1);
        }
      }
    }
  }

}
