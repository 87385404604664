import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonMethods } from '../common-methods';
import { StaffService } from '../api-client/staff.service';

@Injectable({
  providedIn: 'root'
})
export class CapabilityGuardService implements CanActivate {

  capabilities: any;
  constructor(
    private _router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (next.data && next.data.capability) {
      if (CommonMethods.getCapability().includes(next.data.capability)) {
        return true;
      }
    }
    return false;
  }

}
