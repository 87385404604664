import { Component, OnInit } from '@angular/core';
import { PropertyService } from '../api-client/property.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../api-client/user.service';
import { UiService } from '../ui.service';
import { CommonMethods } from '../common-methods';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-property-types',
  templateUrl: './property-types.component.html',
  styleUrls: ['./property-types.component.css']
})
export class PropertyTypesComponent implements OnInit {

  types: any;
  routeParams: any;
  filterPropertyps: any

  constructor(
    private _propertyService: PropertyService,
    private _tosterService: ToastrService,
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit() {
    this.list();
  }

  list() {
    var data = { communityId: CommonMethods.getCommunityId() };
    this._propertyService.types().subscribe((result: any) => {
      this.types = result;
      this.filterPropertyps = this.types;
    })
    this._route.params.subscribe(data => {
      console.log(data);
      this.routeParams = data;
    })
  }

  filterTypes(eve: any) {
    var code = eve.keyCode;
    if (code == 37 || code == 38 || code == 39 || code == 40) {
      return;
    }
    var ele = eve.target;
    var items: any;
    if (ele.value) {
      this.filterPropertyps = this.types.filter((x: any) => x.name.toLowerCase().includes(ele.value.toLowerCase()));
    } else {
      //this.list();
    }
  }

  details(item: any) {
    switch (this.routeParams.mode) {
      case 'RentInvoiceCreation':
        console.log(item);
        let params = Object.assign({ "type": item.id }, this.routeParams);
        this._router.navigate([{
          outlets: { rightMainPopup: ['blocks', params] }
        }]);
        break;
      default:
        if (this._uiService.activeListCallBack) {
          this._uiService.activeListCallBack(item.id, item.name);
          this._uiService.activeListCallBack = null;
          this._uiService.closeItself();
          return;
        }
    }
  }

}
