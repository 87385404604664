import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject,Observable, of  } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../../app/config';
import { PropertyModel } from '../Model/property.model';
import { MaintenanceLogModel } from '../Model/maintenance-log.model';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceLogService {  

  constructor(private _http: HttpClient) {
  }

  all(data:any){
    return this._http
      .post(config.apiEndPointBase + "/maintenance/log/list", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

 filterLog(data:any){
    return this._http
      .post(config.apiEndPointBase + "/maintenance/log/list", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  details(data:any){
    return this._http.get(config.apiEndPointBase + `/maintenance/${data.logId}`,{});
  }
  
  save(data:MaintenanceLogModel): Observable<MaintenanceLogModel> {
    if(data.id){
      return this._http.put(config.apiEndPointBase + "/maintenance/log",data, {responseType: 'text'})
      .pipe(map((response:any) => {
        return new MaintenanceLogModel().deserialize(response);
      }));
    }else{
      return this._http.post(config.apiEndPointBase + "/maintenance/log",data, {responseType: 'text'})
      .pipe(map((response:any) => {
        return new MaintenanceLogModel().deserialize(response);
      }));
    }
  }

  delete(data:any){
    return this._http
      .put(config.apiEndPointBase + `/maintenance/update-status`, data, {responseType: 'text'})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getContracts(resourceId:number){
    return this._http.get(config.apiEndPointBase + `/property/${resourceId}`);
  }
  
}
