import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { UiService } from '../ui.service';
import { APP_CONST } from '../app-constants';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from '../api-client/payment.service';
import { VoucherService } from '../api-client/voucher.service';
import { config } from '../config';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit, OnDestroy {

  payments: any;
  listMode: any = null;
  selectedInvoices = [];
  paymentTypeString: string;
  startIndex: number = 0;
  recordPerPage: number = 40;
  totalRecords: number = 0;
  filterParams: any = {
    selectedPropertyType: '',
    propertyId: null,
    propertySelectedText: '',
    invoiceFrom: null,
    invoiceTo: null,
    paymentMethod: '',
    ledgerToId: null,
    ledgerFromId: null,
    keyword: '',
    invoiceType: null,
    paymentType: null,
    accountSelectedText: '',
    blockId: null,
    blockSelectedText: '',
    buildingId: null,
    buildingSelectedText: ''
  };
  routeExtras: NavigationExtras;
  communityId: number = parseInt(CommonMethods.getCommunityId());
  societyInvoiceType: number = APP_CONST.INVOICE_TYPE_SOCIETY;
  rentInvoiceType: number = APP_CONST.INVOICE_TYPE_RENT;
  purchaseInvoiceType: number = APP_CONST.INVOICE_TYPE_PURCHASE;
  transferInvoiceType: number = APP_CONST.INVOICE_TYPE_TRANSFERS;
  collectionInvoice: number = APP_CONST.INVOICE_TYPE_COLLECTION;

  readPrivilege:string;
  writePrivilege:string;
  trashPrivilege:string;
  removePrivilege:string;

  constructor(private _uiService: UiService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _tosterService: ToastrService,
    private _paymentService: PaymentService,
    private _voucherService: VoucherService) {
    this.routeExtras = this._router.getCurrentNavigation().extras;
  }

  ngOnInit() {
    this._route.data.subscribe(params => {
      let invoiceType = parseInt(params.invoiceType);
      if (invoiceType == this.societyInvoiceType || invoiceType == this.rentInvoiceType) {
        this.readPrivilege = APP_CONST.COLLECTION_READ;
        this.writePrivilege = APP_CONST.COLLECTION_WRITE;
        this.trashPrivilege = APP_CONST.COLLECTION_TRASH;
        this.removePrivilege = APP_CONST.COLLECTION_REMOVE;
      } else if (invoiceType == this.purchaseInvoiceType) {
        this.readPrivilege = APP_CONST.PAYMENT_READ;
        this.writePrivilege = APP_CONST.PAYMENT_WRITE;
        this.trashPrivilege = APP_CONST.PAYMENT_TRASH;
        this.removePrivilege = APP_CONST.PAYMENT_REMOVE;
      } else {
        this.readPrivilege = APP_CONST.TRANSFER_READ;
        this.writePrivilege = APP_CONST.TRANSFER_WRITE;
        this.trashPrivilege = APP_CONST.TRANSFER_TRASH;
        this.removePrivilege = APP_CONST.TRANSFER_REMOVE;
      }
    });
    this._uiService.leftMenuItems.next([
      { code: 'F4', name: 'Add New', action: this.createNew.bind(this), class: 'green lighten-1', privilege: this.writePrivilege },
      { code: 'F5', name: 'Apply Filter', action: this.openFilter.bind(this), privilege: this.readPrivilege },
      { code: 'F6', name: 'Mark As Paid', action: this.markAsPaid.bind(this), privilege: this.writePrivilege },
    ]);
    this.listPayments();
    setTimeout(()=>{
      this.changeFilterThroughState();
    },1000)
    
  }

  /*---Get list of all payments from DB---*/
  listPayments(): void {
    this._route.data.subscribe(params => {
      this.filterParams.invoiceType = parseInt(params.invoiceType);
      if (this.filterParams.invoiceType == this.societyInvoiceType || this.filterParams.invoiceType == this.rentInvoiceType) {
        this.paymentTypeString = "Collection";
      } else if (this.filterParams.invoiceType == this.purchaseInvoiceType) {
        this.paymentTypeString = "Expense";
      } else {
        this.paymentTypeString = "Transfer";
      }
      this.filterParams.paymentType = this.paymentTypeString;
      this.filterPayment();
    })
  }

  /*---Sanitize all payments---*/
  santitizeItems(items: any = []): any {
    if (items && items.length > 0) {
      items.forEach((element: any) => {
        element.formattedVoucherDate = element.voucherDate ? CommonMethods.dateToIndianFormat(element.voucherDate) : "NA";
        element.formattedManualDueDate = element.manualDueDate ? CommonMethods.dateToIndianFormat(element.manualDueDate) : "NA";
        element.formattedLastPaymentDate = element.lastPaymentDate ? CommonMethods.dateToIndianFormat(element.lastPaymentDate) : "NA";
      });
    }
    return items;
  }

  /*---Open filter for payments---*/
  openFilter(): void {
    this._uiService.activeFilterCallBack = this.filterPayment.bind(this);
    this._uiService.activeFilterData = this.filterParams;
    this._router.navigate([{
      outlets: { leftMainPopup: ['payment-filter'] }
    }]);
  }

  /*---Filter all payments from DB---*/
  filterPayment(): void {
    let data = {
      communityId: this.communityId,
      paymentType: this.paymentTypeString,
      propertyTypeId: this.filterParams.selectedPropertyType ? this.filterParams.selectedPropertyType : null,
      propertyId: (this.filterParams.selectedPropertyType && this.filterParams.propertyId) ? this.filterParams.propertyId : null,
      keyWord: this.filterParams.keyword ? this.filterParams.keyword : null,
      accountId: this.filterParams.ledgerToId ? this.filterParams.ledgerToId : this.filterParams.ledgerFromId ? this.filterParams.ledgerFromId : null,
      paymentFromDate: this.filterParams.invoiceFrom ? CommonMethods.dbToUsDate(this.filterParams.invoiceFrom) : null,
      paymentToDate: this.filterParams.invoiceTo ? CommonMethods.dbToUsDate(this.filterParams.invoiceTo) : null,
      paymentMethod: this.filterParams.paymentMethod ? this.filterParams.paymentMethod : null,
      buildingId: this.filterParams.buildingId ? this.filterParams.buildingId : null,
      blockId: this.filterParams.blockId ? this.filterParams.blockId : null,
      index: this.startIndex
    };
    this._paymentService.filter(data).subscribe((result: any) => {
      this.payments = this.santitizeItems(result.data);
      this.totalRecords = result.total_records;
    });
  }

  exportSummary(): void {
    let data = {
      communityId: this.communityId,
      paymentType: this.paymentTypeString,
      propertyTypeId: this.filterParams.selectedPropertyType ? this.filterParams.selectedPropertyType : null,
      propertyId: (this.filterParams.selectedPropertyType && this.filterParams.propertyId) ? this.filterParams.propertyId : null,
      keyWord: this.filterParams.keyword ? this.filterParams.keyword : null,
      accountId: this.filterParams.ledgerToId ? this.filterParams.ledgerToId : this.filterParams.ledgerFromId ? this.filterParams.ledgerFromId : null,
      paymentFromDate: this.filterParams.invoiceFrom ? CommonMethods.dbToUsDate(this.filterParams.invoiceFrom) : null,
      paymentToDate: this.filterParams.invoiceTo ? CommonMethods.dbToUsDate(this.filterParams.invoiceTo) : null,
      paymentMethod: this.filterParams.paymentMethod ? this.filterParams.paymentMethod : null,
      buildingId: this.filterParams.buildingId ? this.filterParams.buildingId : null,
      blockId: this.filterParams.blockId ? this.filterParams.blockId : null
    };
    this._paymentService.exportPaymentsSummary(data);
  }

  exportDetails(): void {
    let data = {
      communityId: this.communityId,
      paymentType: this.paymentTypeString,
      propertyTypeId: this.filterParams.selectedPropertyType ? this.filterParams.selectedPropertyType : null,
      propertyId: (this.filterParams.selectedPropertyType && this.filterParams.propertyId) ? this.filterParams.propertyId : null,
      keyWord: this.filterParams.keyword ? this.filterParams.keyword : null,
      accountId: this.filterParams.ledgerToId ? this.filterParams.ledgerToId : this.filterParams.ledgerFromId ? this.filterParams.ledgerFromId : null,
      paymentFromDate: this.filterParams.invoiceFrom ? CommonMethods.dbToUsDate(this.filterParams.invoiceFrom) : null,
      paymentToDate: this.filterParams.invoiceTo ? CommonMethods.dbToUsDate(this.filterParams.invoiceTo) : null,
      paymentMethod: this.filterParams.paymentMethod ? this.filterParams.paymentMethod : null,
      buildingId: this.filterParams.buildingId ? this.filterParams.buildingId : null,
      blockId: this.filterParams.blockId ? this.filterParams.blockId : null
    };
    this._paymentService.exportPaymentsDetails(data);
  }

  /*---Select invoices---*/
  selectInvoice(elem: any): void {
    var val = parseInt(elem.value);
    if (elem.checked) {
      this.selectedInvoices.push(val);
    } else {
      var index: number = this.selectedInvoices.indexOf(val);
      if (index !== -1) {
        this.selectedInvoices.splice(index, 1);
      }
    }
  }

  /*---Mark processing payments as paid---*/
  markAsPaid(): void {
    if (this.selectedInvoices.length > 0) {
      var statusError = false;
      this.payments.filter((x: any) => {
        this.selectedInvoices.forEach(obj => {
          if (obj == x.id) {
            if (x.status != 'Processing') {
              statusError = true;
            }
          }
        })
      });
      if (!statusError) {
        let data = { "communityId": CommonMethods.getCommunityId(), "invoices": this.selectedInvoices };
        this._voucherService.markPaid(data).subscribe((res: any) => {
          this._tosterService.success("Marked As Paid Successfully.");
          this.listPayments();
        }, error => {
          this._tosterService.error(error.message);
        });
      } else {
        this._tosterService.error("Only Processing " + this.paymentTypeString + " Invoices are allowed to mark them as Paid.");
      }
    } else {
      this._tosterService.error("Select " + this.paymentTypeString + " Invoices to mark them as Paid.");
    }
  }

  /*---Get filter vals from different page by state and show payments---*/
  changeFilterThroughState(): void {
    if (this.routeExtras && this.routeExtras.state) {
      if (this.routeExtras.state.propertyTypeId) {
        this.filterParams.selectedPropertyType = this.routeExtras.state.propertyTypeId;
      }
      if (this.routeExtras.state.propertyId) {
        this.filterParams.propertyId = this.routeExtras.state.propertyId;
      }
      if (this.routeExtras.state.propertyName) {
        this.filterParams.propertySelectedText = this.routeExtras.state.propertyName;
      }
      if (this.routeExtras.state.ledgerId) {
        this.filterParams.ledgerToId = this.routeExtras.state.ledgerId;
      }
      if (this.routeExtras.state.paymentType) {
        this.filterParams.paymentType = "Collection";
      }
      this.filterPayment();
    }
  }

  /*---Navigate to Detail page of a payment---*/
  details(item: any): void {
    if (this.filterParams.invoiceType == this.societyInvoiceType || this.filterParams.invoiceType == this.rentInvoiceType) {
      this._router.navigateByUrl("collection/" + item.id);
    } else if (this.filterParams.invoiceType == this.purchaseInvoiceType) {
      this._router.navigateByUrl("payment/" + item.id);
    } else {
      this._router.navigateByUrl("transfer/" + item.id);
    }
  }

  /*---Navigate to create new payment page---*/
  createNew(): void {
    if (this.filterParams.invoiceType == this.societyInvoiceType || this.filterParams.invoiceType == this.rentInvoiceType) {
      this._router.navigateByUrl("collection");
    } else if (this.filterParams.invoiceType == this.purchaseInvoiceType) {
      this._router.navigateByUrl("payment");
    } else {
      this._router.navigateByUrl("transfer");
    }
  }

  /*---Triggered when page changed in pagination---*/
  onPageChange(startIndex: number): void {
    this.startIndex = startIndex;
    this.filterPayment();
  }

  /*---Breadcrumb navigation---*/
  breadcrumbNavigate(): void {
    this._router.navigateByUrl("/dashboard");
  }

  /*---Download payment voucher---*/
  downloadInvoice(invoice: any): void {
    var authkey = localStorage.getItem("authKey");
    var udid = localStorage.getItem("UDID");
    var communityId = CommonMethods.getCommunityId();
    var is_duble_invoice = 1;
    //console.log(`${config.apiEndPointBase}/voucher/download/${invloce.id}?authkey=${authkey}&udid=${udid}&communityId=${communityId}`);return;
    window.open(`${config.apiEndPointBase}/voucher/download/${invoice.id}?authkey=${authkey}&udid=${udid}&communityId=${communityId}&is_duble_invoice=${is_duble_invoice}`);
    return;
  }

  ngOnDestroy(): void {
    this._uiService.leftMenuItems.next([]);
  }

}
