import { Component, OnInit } from '@angular/core';
import { UiService } from '../ui.service';
import { ThreadService } from '../api-client/thread.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { APP_CONST } from '../app-constants';

@Component({
  selector: 'app-discussions',
  templateUrl: './discussions.component.html',
  styleUrls: ['./discussions.component.css']
})
export class DiscussionsComponent implements OnInit {

  filterParams: any = {
    searchData: null,
    threadMode: null,
    threadStatus: null,
    threadType: null,
    closingDate: null,
    reporterId: null,
    linkItemTypeId: null,
    linkItemId: null,
    fromDate: null,
    toDate: null,
    category: null,
  };
  generalDiscussions: any;
  complains = [];
  discussionId: any;
  routeExtras: NavigationExtras;
  threadType: number;
  filteredSubjects = [];
  startIndex: number = 0;
  recordPerPage: number = 40;
  totalRecords: number = 0;
  threadTypeName: string;
  writePrivileges = null;
  readPrivileges = null;
  deletePrivileges = null;
  constructor(
    private _uiService: UiService,
    private _threadService: ThreadService,
    private _route: ActivatedRoute,
    private _router: Router
  ) { this.routeExtras = this._router.getCurrentNavigation().extras; }

  ngOnInit() {
    this._route.data.subscribe(params => {
      this.threadType = params.type;
      if (params.type == APP_CONST.THREAD_TYPE_DISCUSSION) {
        this.writePrivileges = APP_CONST.DISCUSSION_WRITE;
        this.readPrivileges = APP_CONST.DISCUSSION_READ;
        this.deletePrivileges = APP_CONST.DISCUSSION_REMOVE;
      } else if (params.type == APP_CONST.THREAD_TYPE_COMPLAINT) {
        this.writePrivileges = APP_CONST.COMPLAINT_WRITE;
        this.readPrivileges = APP_CONST.COMPLAINT_READ;
        this.deletePrivileges = APP_CONST.COMPLAINT_REMOVE;
      } else if (params.type == APP_CONST.THREAD_TYPE_NOTICE) {
        this.writePrivileges = APP_CONST.NOTICE_WRITE;
        this.readPrivileges = APP_CONST.NOTICE_READ;
        this.deletePrivileges = APP_CONST.NOTICE_REMOVE;
      } else if (params.type == APP_CONST.THREAD_TYPE_RULES) {
        this.writePrivileges = APP_CONST.GUIDELINE_WRITE;
        this.readPrivileges = APP_CONST.GUIDELINE_READ;
        this.deletePrivileges = APP_CONST.GUIDELINE_REMOVE;
      }
      
      if (!CommonMethods.getCapability().includes(this.readPrivileges)) {
        this._router.navigateByUrl(`dashboard`);
        return;
      }
      this._uiService.leftMenuItems.next([
        { code: 'F3', name: 'Filter', action: this.openFilter.bind(this), privilege: this.readPrivileges },
        { code: 'F4', name: 'Add New', action: this.redirectTo.bind(this), privilege: this.writePrivileges },
        { code: 'F5', name: CommonMethods.threadTypes[3].name, action: this.discussionMenu.bind(this), class: 'blue lighten-1', privilege: APP_CONST.DISCUSSION_READ },
        { code: 'F6', name: CommonMethods.threadTypes[2].name, action: this.issueMenu.bind(this), class: 'blue lighten-1', privilege: APP_CONST.COMPLAINT_READ },
        { code: 'F7', name: CommonMethods.threadTypes[1].name, action: this.noticeMenu.bind(this), class: 'blue lighten-1', privilege: APP_CONST.NOTICE_READ },
        { code: 'F8', name: CommonMethods.threadTypes[0].name, action: this.guidelineMenu.bind(this), class: 'blue lighten-1', privilege: APP_CONST.GUIDELINE_READ },

      ]);
      this.startIndex = 0;
      this.getDiscussions();
    });
    this._uiService.threadDataReload.subscribe((result: any) => {
      console.log("call");
      this.getDiscussions();
    });
  }

  discussionMenu() {
    this._router.navigateByUrl(`discussions`);
    return;
  }

  issueMenu() {
    this._router.navigateByUrl(`complaints`);
    return;
  }

  guidelineMenu() {
    this._router.navigateByUrl(`rules`);
    return;
  }

  noticeMenu() {
    this._router.navigateByUrl(`notices`);
    return;
  }


  async getDiscussions() {
    if (!this.threadType) return;
    this._uiService.discussionList = false;
    this.discussionView({ id: 0 });
    var threadTypeName = CommonMethods.threadTypes.filter((x: any) => x.id == this.threadType);
    if (threadTypeName.length > 0) {
      this.threadTypeName = threadTypeName[0].name;
    }
    //var data = { communityId: CommonMethods.getCommunityId(), threadType: this.threadType };
    let data = {
      communityId: CommonMethods.getCommunityId(),
      searchData: this.filterParams.searchData,
      threadMode: this.filterParams.threadMode,
      linkItemTypeId: this.filterParams.linkItemTypeId,
      linkItemId: this.filterParams.linkItemId,
      fromDate: this.filterParams.fromDate,
      toDate: this.filterParams.toDate,
      closingDate: this.filterParams.closingDate,
      threadType: this.threadType,
      reporterId: this.filterParams.reporterId,
      threadStatus: this.filterParams.threadStatus,
      startIndex: this.startIndex
    };
    await this._threadService.all(data).toPromise().then((result: any) => {
      this.generalDiscussions = result.data;
      this.filteredSubjects = this.generalDiscussions;
      this.bindCommentCountInThread();
      this.totalRecords = result.total_records;
      if (result.data.length > 0) {
        this._uiService.discussionList = true;
        this.discussionView(result.data[0]);
      }
    })
  }

  discussionView(item: any) {
    this.discussionId = item.id;
    console.log(this.discussionId);
    for (var i = 0; i < this.filteredSubjects.length; i++) {
      if (this.filteredSubjects[i].id == item.id) {
        this.filteredSubjects[i].activeClass = "threadActive";
      } else {
        this.filteredSubjects[i].activeClass = null;
      }
    }
  }

  async bindCommentCountInThread() {
    if (!this.filteredSubjects) return;
    for (var i = 0; i < this.filteredSubjects.length; i++) {
      /*  await this._threadService.details(this.filteredSubjects[i].id).toPromise().then((result: any) => {
         this.filteredSubjects[i].count = result.threadComments.length;
       }); */
      this.filteredSubjects[i].commentsCount = this.filteredSubjects[i].commentsCount ? this.filteredSubjects[i].commentsCount : 0;
      if (this.filteredSubjects[i].relatedItemSummary.length > 0) {
        var todo = this.filteredSubjects[i].relatedItemSummary.filter((x: any) => x.relatedItemType == APP_CONST.TODO);
        if (todo.length > 0) {
          this.filteredSubjects[i].todo = `${todo[0].completedCount}/${todo[0].relatedItemCount}`;
        } else {
          this.filteredSubjects[i].todo = null;
        }
      } else {
        this.filteredSubjects[i].todo = null;
      }
      if (i == 0) {
        this.filteredSubjects[i].activeClass = "threadActive";
      } else {
        this.filteredSubjects[i].activeClass = null;
      }
    }
  }

  redirectTo() {
    this._router.navigate([{
      outlets: { leftMainPopup: ["discussion-details", 0] }
    }], { state: { "threadType": this.threadType } });
    return;
  }

  redirectToDashboard() {
    this._router.navigateByUrl("/dashboard"); return;
  }

  openFilter() {
    this._uiService.activeFilterCallBack = this.getDiscussions.bind(this);
    this._uiService.activeFilterData = this.filterParams;
    this._router.navigate([{
      outlets: { leftMainPopup: ["discussion-filter"] }
    }], { state: { "threadType": this.threadType } });
    return;
  }

  onPageChange(startIndex: number) {
    this.startIndex = startIndex;
    this.getDiscussions();
  }

  filterSubject(eve: any) {
    var code = eve.keyCode;
    if (code == 37 || code == 38 || code == 39 || code == 40) {
      return;
    }
    var ele = eve.target;
    var items: any;
    if (ele.value) {
      this.filteredSubjects = this.generalDiscussions.filter((x: any) => x.threadTitle.toLowerCase().includes(ele.value.toLowerCase()));
    } else {
      //this.list();
    }
  }

}
