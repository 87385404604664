import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { LinkProfileModel } from '../Model/link-profile.model';
import { LedgerService } from '../api-client/ledger.service';
import { UserService } from '../api-client/user.service';
import { PropertyService } from '../api-client/property.service';
import { UiService } from '../ui.service';
import { ToastrService } from 'ngx-toastr';
import { LinkProfileFormBuilder } from './link-profile.form-builder';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonMethods } from '../common-methods';

@Component({
  selector: 'app-link-profile',
  templateUrl: './link-profile.component.html',
  styleUrls: ['./link-profile.component.css']
})
export class LinkProfileComponent implements OnInit, OnChanges {

  @Input() ledgerId: any;
  @Input() linkedUserId: any;
  linkDetails: any;
  linkForm: any;
  submitted = false;
  model = new LinkProfileModel();
  properties = [];
  invitationId = null;

  constructor(private _ledgerService: LedgerService,
    private _userService: UserService,
    private _propertyService: PropertyService,
    private _uiService: UiService,
    private _tosterService: ToastrService,
    private _formBuilder: LinkProfileFormBuilder,
    private _router: Router,
    private _route: ActivatedRoute) {
  }

  ngOnInit() {
    this.linkForm = this._formBuilder.getForm();
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    //console.log("123456",this.ledgerId);
    if (!this.ledgerId) return;
    this.linkForm.enable();
    this.propertiesByLedgerId();
    this.getLinkedProfile();
    if(this.linkedUserId > 0) {
      this.linkedUserDetails();
    }   
  }

  get f() { return this.linkForm.controls; }

  details(id: number) {
  }

  onSubmit() {
    this.submitted = true;
    if (this.linkForm.invalid) {
      return;
    }
    if (this.properties.length == 0) {
      this._tosterService.error("Sorry! no property assign in this ledger yet");
      return;
    }
    this.model.deserialize(this.linkForm.value);
    this.model.communityId = CommonMethods.getCommunityId();
    this.model.ledgerId = this.ledgerId;
    this.model.status = 1;
    this._ledgerService.inviteMember(this.model)
      .subscribe(
        (response: LinkProfileModel) => {
          this._tosterService.success("Invitation sent");
          this.submitted = false;
          this.resetForm();
          this.getLinkedProfile();
          return false;
        },
        error => {
          this._tosterService.error(error.message);
        }
      )
  }

  async propertiesByLedgerId() {
    await this._propertyService.getByLedger(this.ledgerId).toPromise().then((result: any) => {
      this.properties = result;
    })
  }

  async getLinkedProfile() {
    if (!this.ledgerId) return;
    await this._ledgerService.linkUser(this.ledgerId).toPromise().then((result: any) => {
      if (result && result.length>0) {
        this.invitationId = result[0].id;
        this._formBuilder.setValues(result[0]);
        this.linkForm.disable();
      }
    })
  }

  async linkedUserDetails() {
    if ((!this.ledgerId) && (this.linkedUserId > 0)) return;
    await this._userService.details(this.linkedUserId).toPromise().then((result:any) => {
        this._formBuilder.setValues(result);
        this.linkForm.disable();
      
    })
  }

  async cancelInvitation() {
    if (!this.invitationId) return;
    var data = { invitationId: this.invitationId };
    await this._ledgerService.cancelInvitation(data).toPromise().then((result: any) => {
      if (result) {
        this._tosterService.success("Member Unlink");
        this.invitationId = null;
        this.resetForm();
      }
    })
  }

  async unAssignUser() {
    if ((!this.ledgerId) && (!this.linkedUserId)) return;
    var data = {
      userId: this.linkedUserId,
      ledgerId: this.ledgerId,
      communityId: CommonMethods.getCommunityId(),
    };
    await this._ledgerService.unAssignUser(data).toPromise().then((result: any) => {
      if (result) {
        this._tosterService.success("User unassigned");
        this.resetForm();
      }
    })
  }

  resetForm() {
    this.linkForm.reset();
    this.linkForm.enable();
  }

}
