import { Component, OnInit } from '@angular/core';
import { BuildingService } from '../api-client/building.service';
import { ToastrService } from 'ngx-toastr';
import { UiService } from '../ui.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { BlockService } from '../api-client/block.service';

@Component({
  selector: 'app-community-buildings',
  templateUrl: './community-buildings.component.html',
  styleUrls: ['./community-buildings.component.css']
})
export class CommunityBuildingsComponent implements OnInit {

  buildings: any;
  blockWiseBuildings:any;
  routeParams: any;
  blocks:any;

  constructor(
    private _buildingService: BuildingService,
    private _tosterService: ToastrService,
    private _blockService: BlockService,
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit() {
    this.allBblocks();
    setTimeout(()=>{
      this.list();
    },100);
    
  }

  list() {
    var data = { communityId: CommonMethods.getCommunityId() };
    this._buildingService.buildingsBycommunity(data).subscribe((result: any) => {
      for (var i = 0; i < result.length; i++) {
      var block = this.blocks.filter((x:any)=>x.id==result[i].communityBlockId);
        if(block.length>0){
          result[i].blockName = block[0].communityBlockName;
        }else{
          result[i].blockName = null;
        }
      }
      this.buildings = result;

     // console.log(result);return;
     /* this.buildings = [];
      for (var i = 0; i < result.length; i++) {
        if (!this.buildings[result[i].communityBlockId]) {
          this.buildings[result[i].communityBlockId] = [];
        }
        this.buildings[result[i].communityBlockId].push(result[i]);
      }
      var buildings = [];
      this.buildings.forEach((element:any,i:number) => {
        var block = this.blocks.filter((x:any)=>x.id==i);
        if(block.length>0){
          buildings.push({"block":block[0].communityBlockName,"children":element})
        }
      });
      this.blockWiseBuildings = buildings; */
    })
    this._route.params.subscribe(data => {
      //console.log(data);
      this.routeParams = data;
    })
  }

  filterBuildings(eve: any) {
    var code = eve.keyCode;
    if (code == 37 || code == 38 || code == 39 || code == 40) {
      return;
    }
    var ele = eve.target;
    var items: any;
    if (ele.value) {
      items = this.buildings.filter((x: any) => x.name.toLowerCase().includes(ele.value.toLowerCase()));
      this.buildings = items;
    } else {
      this.list();
    }
  }

  details(item: any) {
    if (this._uiService.activeListCallBack) {
      this._uiService.activeListCallBack(item.id, item.name);
      this._uiService.activeListCallBack = null;
      this._uiService.closeItself();
      return;
    }
  }

  allBblocks(){
    var data = {communityId: CommonMethods.getCommunityId()};
    this._blockService.all(data).subscribe((result)=>{
      this.blocks = result;
      //console.log(result);
    })
  }

}
