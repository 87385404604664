import { Deseriazable } from './deserializable.model';

export class LedgerModel implements Deseriazable
{
    id: number;
    accountCode: string;
    ledgerName: string;
    accountNumber: string;
    branchName: string;
    ifscCode: string;
    mobile: string;
    email: string;
    pan: string;
    gstin: string;
    description: string;
    openingBalance: number;
    currentBalance: number;
    startsFrom: string;
    ledgerType: string;
    profileUpdatedOn: string;
    accountGroupId: number;
    linkedUserId: number;
    referenceId: number;
    //communityId: number;
    accountTypeId: number;
    ledgerCommunityId: number;
    houseNumber: string;
    street: string;
    block: string;
    landmark: string;
    postOffice: string;
    policeStation: string;
    town: string;
    district: string;
    subDistrict: string;
    pinCode: string;
    profession: string;
    stateId: number;
    countryId: number; 
    ledgerCurrentBalance: number;
    hasTransactions: number;
    accountCommunityId: number;
    address: string;
    is_active: any;
    bankAccountType: string;
    
    

    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }
}