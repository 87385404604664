import { Component, OnInit } from '@angular/core';
import { TodoService } from '../api-client/todo.service';
import { ToastrService } from 'ngx-toastr';
import { UiService } from '../ui.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { APP_CONST } from '../app-constants';

@Component({
  selector: 'app-todo-list',
  templateUrl: './todo-list.component.html',
  styleUrls: ['./todo-list.component.css']
})
export class TodoListComponent implements OnInit {

  todos: any;
  filteredTodos: any;
  routeParams: any;
  multiSelect: false;
  routeExtras: NavigationExtras;
  todoId = 0;

  constructor(
    private _todoService: TodoService,
    private _tosterService: ToastrService,
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this.routeExtras = this._router.getCurrentNavigation().extras;
  }

  ngOnInit() {
    this.list();
  }

  list() {
    this._route.params.subscribe(data => {
      this.routeParams = data;
      if (data.mode == "TodoSelections" && data.multiSelect) {
        this.multiSelect = data.multiSelect;
        this.todoId = data.todoId;
      }
      var args = { communityId: CommonMethods.getCommunityId() };
      this._todoService.all(args).subscribe((result: any) => {
        var todos = [];
        for(var i=0;i<result.data.length;i++){
          if(result.data[i].id!=this.todoId){
            todos.push(result.data[i]);
          }
        }
        this.todos = todos;
        this.filteredTodos = this.todos;
      });
    });


  }

  filterTodo(eve: any) {
    var code = eve.keyCode;
    if (code == 37 || code == 38 || code == 39 || code == 40) {
      return;
    }
    var ele = eve.target;
    var items: any;
    if (ele.value) {
      this.filteredTodos = this.todos.filter((x: any) => x.task.toLowerCase().includes(ele.value.toLowerCase()));
    } else {
      //this.list();
    }
  }

  details(item: any) {
    if (this._uiService.activeListCallBack) {
      this._uiService.activeListCallBack(item.id, item.task);
      this._uiService.activeListCallBack = null;
      this._uiService.closeItself();
      return;
    }
    //this._router.navigateByUrl("block/" + item.id);
    return false;
  }

  redirectTo(item: any) {
    this._router.navigateByUrl("block/" + item.id);
  }

  findMatchingItemInSelectedItems(id: any) {
    if (this.routeExtras && this.routeExtras.state && this.routeExtras.state.selectedItems) {
      let items = this.routeExtras.state.selectedItems.filter((x: any) => x.id == id);
      if (items.length > 0) {
        return items[0];
      }
    }
  }

  itemSelectChange(eve: any, todo: any) {
    if (this.routeExtras && this.routeExtras.state && this.routeExtras.state.selectedItems) {
      if (eve.target.checked) {
        this.routeExtras.state.selectedItems.push({ id: todo.id, name: todo.task });
      } else {
        let item = this.findMatchingItemInSelectedItems(todo.id);
        if (item) {
          let index = this.routeExtras.state.selectedItems.indexOf(item);
          this.routeExtras.state.selectedItems.splice(index, 1);
        }
      }
    }
  }

}
