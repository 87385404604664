import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BlockModel } from './../Model/block.model';

@Injectable({
    providedIn: 'root'
})

export class BlockFormBuilder{
    private form;
    private validationRules = {
    communityBlockCode: ['',Validators.required],
    communityBlockName: ['',Validators.required],
    communityBlockRepresentative: ['','']
    }

    constructor(private fb: FormBuilder) {
        this.form = fb.group(this.validationRules);
    }

    getForm() {
        return this.form;
    }

    setValues(response: BlockModel) {
        this.form.patchValue({
            id: response.id,
            communityId: response.communityId,
            communityBlockCode: response.communityBlockCode,
            communityBlockName: response.communityBlockName,
            communityBlockRepresentative: response.communityBlockRepresentative
        });
    }
}