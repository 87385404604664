import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../api-client/user.service';
import { UiService } from '../ui.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { CommunityService } from '../api-client/community.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  users: any;
  filteredUsers: any;
  routeParams: any;
  routeExtras: NavigationExtras;
  multiSelect: false;

  constructor(
    private _tosterService: ToastrService,
    private _communityService: CommunityService,
    private _userService: UserService,
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _router: Router
  ) { this.routeExtras = this._router.getCurrentNavigation().extras; }

  ngOnInit() {
    this.list();
  }

  list() {
    var data = {communityId:CommonMethods.getCommunityId()};
    this._communityService.members(data).subscribe((result: any) => {
      this.users = result;
      this.filteredUsers = this.users;
    })
    this._route.params.subscribe(data => {
      this.routeParams = data;
      if (data.mode == "UserSelections" && data.multiSelect) {
        this.multiSelect = data.multiSelect;
      }
    })
  }

  filterUser(eve: any) {
    var code = eve.keyCode;
    if (code == 37 || code == 38 || code == 39 || code == 40) {
      return;
    }
    var ele = eve.target;
    var items: any;
    if (ele.value) {
      this.filteredUsers = this.users.filter((x: any) => x.name.toLowerCase().includes(ele.value.toLowerCase()));
    } else {
      //this.list();
    }
  }

  details(item: any) {
    if (this._uiService.activeListCallBack) {
      this._uiService.activeListCallBack(item.id, item.name);
      this._uiService.activeListCallBack = null;
      this._uiService.closeItself();
      return;
    }
    this._router.navigateByUrl("user/" + item.id);
  }

  redirectTo(item: any) {
    this._router.navigateByUrl("user/" + item.id);
  }

  findMatchingItemInSelectedItems(id: any) {
    if (this.routeExtras && this.routeExtras.state && this.routeExtras.state.selectedItems) {
      let items = this.routeExtras.state.selectedItems.filter((x: any) => x.id == id);
      if (items.length > 0) {
        return items[0];
      }
    }
  }

  itemSelectChange(eve: any, user: any) {
    if (this.routeExtras && this.routeExtras.state && this.routeExtras.state.selectedItems) {
      if (eve.target.checked) {
        this.routeExtras.state.selectedItems.push({ id: user.id, name: user.name });
      } else {
        let item = this.findMatchingItemInSelectedItems(user.id);
        if (item) {
          let index = this.routeExtras.state.selectedItems.indexOf(item);
          this.routeExtras.state.selectedItems.splice(index, 1);
        }
      }
    }
  }

}
