import { Component, OnInit } from '@angular/core';
import { BlockService } from '../api-client/block.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../api-client/user.service';
import { UiService } from '../ui.service';
import { APP_CONST } from '../app-constants';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { VoucherTemplateService } from '../api-client/voucher-template.service';

@Component({
  selector: 'app-billing-charges',
  templateUrl: './billing-charges.component.html',
  styleUrls: ['./billing-charges.component.css']
})
export class BillingChargesComponent implements OnInit {

  templates:any;
  filterBillingCharges:any;
  routeParams:any;

  purchaseInvoiceType:number = APP_CONST.INVOICE_TYPE_PURCHASE;
  rentInvoiceType:number = APP_CONST.INVOICE_TYPE_RENT;

  constructor(
    private _blockService: BlockService,
    private _tosterService: ToastrService,
    private _userService: UserService,
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _vouchertemplateService: VoucherTemplateService
  ) { }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this.routeParams = params;
    });
    this.list();
  }

  list(){
    var data: {[k: string]: any} = { communityId: CommonMethods.getCommunityId() };
    if(this.routeParams.type){
      data.voucherType = this.routeParams.type;
      data.custom = 1;
    }
    this._vouchertemplateService.all(data).subscribe((result:any)=>{
        this.templates = result;
        this.filterBillingCharges = this.templates;
    })
  }

  filtertemplate(eve: any) {
    var code = eve.keyCode;
    if(code == 37 || code == 38 || code == 39 || code == 40) {
        return;
    }
    var ele = eve.target;

    var items: any;
    if (ele.value) {
      this.filterBillingCharges = this.templates.filter((x: any) => x.name.toLowerCase().includes(ele.value.toLowerCase()));
    } else {
     // this.list();
    }
  }

  redirectTo(item:any){
    switch (this.routeParams.mode) {
      case 'InvoiceCreation':
        let invoiceUrl:string = "";
        if(this.routeParams.type){
          if(this.routeParams.type == this.purchaseInvoiceType){
            invoiceUrl = "purchase-invoice";
          }else{
            invoiceUrl = "society-invoice";
          }
        }else{
          invoiceUrl = "society-invoice";
        }
        if(item.voucherType == this.rentInvoiceType){
          this._router.navigate([{
            outlets: { rightMainPopup: ['property-types', { "mode": "RentInvoiceCreation" }] }
          }]);
        }else{
          if(this.routeParams.propertyId){
            this._router.navigate([invoiceUrl],{ state: { "templateId": item.id , "propertyId":this.routeParams.propertyId}});
          }else{
            this._router.navigate([invoiceUrl],{ state: { "templateId": item.id }});
          }
         
        }
        break;
      case 'BulkInvoiceCreation':
        this._router.navigate(["bulk-invoice"],{ state: { "templateId": item.id }});
        break;
      default:
        if (this._uiService.activeListCallBack) {
          this._uiService.activeListCallBack(item.id, item.name);
          this._uiService.activeListCallBack = null;
          this._uiService.closeItself();
        } else {
          this._router.navigateByUrl(`billing-charges-details/${item.id}`);
          return false;
        }
    }
  }

}
