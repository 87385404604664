import { Component, OnInit } from '@angular/core';
import { CommonMethods } from '../common-methods';
import { PropertyService } from '../api-client/property.service';
import { DiscountTemplateService } from '../api-client/discount-template.service';
import { ToastrService } from 'ngx-toastr';
import { UiService } from '../ui.service';
import { APP_CONST } from '../app-constants';
import { BlockService } from '../api-client/block.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LedgerService } from '../api-client/ledger.service';
import { VoucherService } from '../api-client/voucher.service';
import { IDayCalendarConfig } from 'ng2-date-picker';

@Component({
  selector: 'app-discount-template-details',
  templateUrl: './discount-template-details.component.html',
  styleUrls: ['./discount-template-details.component.css']
})
export class DiscountTemplateDetailsComponent implements OnInit {

  types: any;
  selectedBlockName = "Select block";
  isVoucherTypeDisabled = true;
  isPropertyTypeDisabled = true;
  ownerTenant = false;

  id = null;
  name: any;
  isActive: any;

  appliedForBlockId: any;
  propertyType: any;
  isPropertyType: any;
  discountType: any;
  lateFeeTemplateIdadType: number;
  sendToOwners: any;
  sendToTenant: any;

  ledgerDefaultGroup = "Expenses";
  billingLineItems = [];

  templatePeriodId = null;
  startsOn: any;
  expriesOn: any;
  periods = [];
  activePeriod = 0;
  units: any;

  public config = <IDayCalendarConfig>{
    format: "DD-MM-YYYY"
  };
  lineItems: any;
  isFormsSubmited: boolean = false;

  societyInvoiceType: number = APP_CONST.INVOICE_TYPE_SOCIETY;
  rentInvoiceType: number = APP_CONST.INVOICE_TYPE_RENT;
  purchaseInvoiceType: number = APP_CONST.INVOICE_TYPE_PURCHASE;
  deleteParams: any = {
    itemId: 0,
    itemName: null,
    btnOk: "Delete",
    btnCancel: "Close",
    deleteText: "Are you sure, you want to delete this template?"
  }

  voucherType = this.societyInvoiceType;
  error = [];
  constructor(
    private _propertyService: PropertyService,
    private _discountTemplateService: DiscountTemplateService,
    private _tosterService: ToastrService,
    private _uiService: UiService,
    private _blockService: BlockService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _ledgerService: LedgerService,
    private _voucherService: VoucherService
  ) { }

  ngOnInit() {
    this._uiService.leftMenuItems.next([
      { code: 'F4', name: 'Save', action: this.save.bind(this), class: 'green lighten-1', privilege: APP_CONST.BILLING_CHARGES_SETUP_WRITE },
      { code: 'F5', name: 'Save & Add New', action: this.saveAndNew.bind(this), privilege: APP_CONST.BILLING_CHARGES_SETUP_WRITE },
      { code: 'F7', name: 'Delete', action: this.confirmationPopUp.bind(this), class: 'red lighten-1', privilege: APP_CONST.BILLING_CHARGES_SETUP_REMOVE }
    ]);
    this.propertyTypes();
    this.getUits();
    this._route.params.subscribe(data => {

      if (data.id > 0) {
        this.id = data.id;
        this.getTemplate(data.id);
      } else {
        this.id = null;
        this.resetForm();
      }
    })
  }

  propertyTypes() {
    this._propertyService.types().subscribe((result: any) => {
      this.types = result;
    })
  }

  changePropertyType(element: any) {
    if (element.target.checked) {
      this.isPropertyTypeDisabled = false;
    } else {
      this.isPropertyTypeDisabled = true;
    }
    console.log("aaaa", this.isPropertyTypeDisabled);
  }

  showOwnerTanant(val: any) {
    /* if(this.billingLineItems.length>0){
      this.billingLineItems = [];
    } */
    if (val == 1) {
      this.ledgerDefaultGroup = "Income";
      this.ownerTenant = true;
    } else {
      this.ownerTenant = false;
      this.ledgerDefaultGroup = "Expenses";
    }
  }

  newLineItems() {
    this.billingLineItems = [];

  }

  addNewRow() {
    /*  if(this.voucherType=="" || this.voucherType==null){
       this._tosterService.error("Invoice type can not be blank");
       return;
     } */
    var item = {
      chargesType: 0,
      percentageOrAmount: "",
      debitToLedgerId: 0,
      creditToLedgerId: 0
    }
    this.billingLineItems.push(item);
  }

  deleteItem(item: any) {
    this.billingLineItems.splice(this.billingLineItems.indexOf(item), 1);
  }

  async saveTemplate() {
    if (this.name == null || this.name == "") {
      this._tosterService.error("Name can not be blank");
      return false;
    }
    /* if (this.appliedForBlockId == null || this.appliedForBlockId == "") {
      this._tosterService.error("Block can not be blank");
      return false;
    } */
    /* if (this.voucherType == null || this.voucherType == "") {
      this._tosterService.error("Invoice type can not be blank");
      return false;
    } */

    if (this.isPropertyTypeDisabled != true && (this.propertyType == null || this.propertyType == "")) {
      this._tosterService.error("Property type can not be blank");
      return false;
    }
    if (this.discountType == null || this.discountType == "") {
      this._tosterService.error("Discount type can not be blank");
      return false;
    }

    if (this.startsOn == null || this.startsOn == "") {
      this._tosterService.error("From date can not be blank");
      return false;
    }
    if (this.expriesOn == null || this.expriesOn == "") {
      this._tosterService.error("To date can not be blank");
      return false;
    }

    var startsOn = new Date(CommonMethods.dbToUsDate(this.startsOn)).getTime();
    var expriesOn = new Date(CommonMethods.dbToUsDate(this.expriesOn)).getTime();

    if (startsOn > expriesOn) {
      this._tosterService.error("From date not greater than To date.");
      return false;
    }
    if (this.billingLineItems.length == 0) {
      this._tosterService.error("Please add line items");
      return false;
    } else {
      var checkCreditledger = [];
      for (var i = 0; i < this.billingLineItems.length; i++) {
        if (this.billingLineItems[i].chargesType == 0) {
          this._tosterService.error("Please add charges type");
          return false;
        }
        if (this.billingLineItems[i].creditToLedgerId == null || this.billingLineItems[i].creditToLedgerId == "") {
          this._tosterService.error("Line item:" + (i + 1) + " Please add Particulars");
          return false;
        }
        if (!checkCreditledger.includes(this.billingLineItems[i].creditToLedgerId)) {
          checkCreditledger.push(this.billingLineItems[i].creditToLedgerId);
        } else {
          this._tosterService.error("Line item:" + (i + 1) + " You can not add same particulars");
          return false;
        }
        if (this.billingLineItems[i].percentageOrAmount == null || this.billingLineItems[i].percentageOrAmount == "") {
          this._tosterService.error("Line item:" + (i + 1) + " Please add Percent/Value");
          return false;
        }
        if (isNaN(this.billingLineItems[i].percentageOrAmount)) {
          this._tosterService.error("Line item:" + (i + 1) + " Percent/Value must be numeric");
          return false;
        }
      }
    }

    var templateData = {
      name: this.name,
      id: this.id,
      communityId: CommonMethods.getCommunityId(),
      isActive: (this.isActive) ? true : false,
      voucherType: this.voucherType,
      appliedForBlockId: this.appliedForBlockId,
      propertyType: (this.propertyType) ? this.propertyType : 0,
      discountType: (this.discountType) ? this.discountType : 0,
      lateFeeTemplateId: 0,
      sendToOwners: (this.sendToOwners) ? true : false,
      sendToTenant: (this.sendToTenant) ? true : false,
    }

    await this._discountTemplateService.saveTemplate(templateData).toPromise().then((result: any) => {
      if (result && result.id) {
        this.id = result.id;
        this.saveTemplatePeriod(result.id);
      }else{
        this.error.push("error 1");
        return;
      }
    },
      error => {
        this._tosterService.error(error.message);
        this.error.push(error.message);
        return;
      })
  }

  async saveTemplatePeriod(templateId: number) {
    var templatePeriodData = {
      id: this.templatePeriodId,
      communityId: CommonMethods.getCommunityId(),
      discountTemplateId: templateId,
      startsOn: CommonMethods.dbToUsDate(this.startsOn),
      expriesOn: CommonMethods.dbToUsDate(this.expriesOn)
    }
    await this._discountTemplateService.saveTemplatePeriod(templatePeriodData).toPromise().then((result: any) => {
      if (result && result.id) {
        //this._tosterService.success("Template period data saved");
        this.saveTemplateLineItems(result.id);
      }else{
        this.error.push("error 2");
        return;
      }
    },
      error => {
        this._tosterService.error(error.message);
        this.error.push(error.message);
        return;
      })
  }

  async saveTemplateLineItems(templatePeriodId: number) {
    var templateLineItemsData = {
      communityId: Number(CommonMethods.getCommunityId()),
      discountPeriodId: templatePeriodId,
      lineItems: this.billingLineItems
    }
    //console.log(templateLineItemsData);return;
    await this._discountTemplateService.saveTemplateLineItems(templateLineItemsData).toPromise().then((result: any) => {
      if(result){
        this._tosterService.success("Discount data saved");
        this.isFormsSubmited = true;
        this.error = [];
      }else{
        this.error.push("error 3");
        return;
      }
      
      /* if(!this.id){
        this.resetForm();
      } */
      /* this.getTemplate(this.id);
      this._router.navigateByUrl(`discount-details/${this.id}`);
      return false; */
    },
      error => {
        this._tosterService.error(error.message);
        this.error.push(error.message);
        return;
      })
  }

  periodsBytemplate(templateId: number) {
    this._discountTemplateService.periods(templateId).subscribe((result: any) => {
      this.periods = result;
      this.selectPeriod(result[0]);
      this.activePeriod = result[0].id;
    })
  }

  getTemplate(id: any) {
    this._discountTemplateService.details(id).subscribe((result: any) => {
      this.name = result.name;
      this.id = result.id;
      this.isActive = result.isActive;
      this.voucherType = result.voucherType;
      this.appliedForBlockId = result.appliedForBlockId;
      this.propertyType = result.propertyType;
      this.deleteParams.itemId = result.id;
      this.deleteParams.itemName = result.name;
      if (result.propertyType > 0) {
        this.isPropertyType = 1;
        this.isPropertyTypeDisabled = false;
      }
      this.discountType = result.discountType;
      this.sendToOwners = result.sendToOwners;
      this.sendToTenant = result.sendToTenant;
      if(result.appliedForBlockId){
        this._blockService.details(result.appliedForBlockId).subscribe((result: any) => {
          this.selectedBlockName = result.communityBlockName;
        });
      }
      
      //this.showOwnerTanant(result.voucherType);
      this.periodsBytemplate(result.id);
      console.log(result);
    })
  }

  async selectPeriod(period: any) {
    this.startsOn = '';
    this.expriesOn = '';
    this.templatePeriodId = '';
    if (period.id > 0) {
      this.templatePeriodId = period.id;
      this.startsOn = CommonMethods.usDateToDb(period.startsOn);
      this.expriesOn = CommonMethods.usDateToDb(period.expriesOn);
      await this.lineItemsdata(period.id);
      if (!this.billingLineItems) return;
      for (var i = 0; i < this.billingLineItems.length; i++) {
        try {
          var unitDetails = this.units.filter((x: any) => x.id == this.billingLineItems[i].chargesUnitId);
          if (unitDetails.length > 0) {
            this.billingLineItems[i].unitName = unitDetails[0].name;
          }
          await this._ledgerService.details(this.billingLineItems[i].creditToLedgerId).toPromise().then((result: any) => {
            this.billingLineItems[i].ledgerName = result.ledgerName;
          });
        } catch (e) {

        }
      }
      //this.billingLineItems = this.lineItems;
    } else {
      this.billingLineItems = [];
    }
  }

  getUits() {
    this._voucherService.measurementUnits().subscribe((result: any) => {
      this.units = result;
    })
  }

  async lineItemsdata(id: any) {
    await this._discountTemplateService.period(id).toPromise().then((result: any) => {
      this.billingLineItems = result.lineItems;
    });
  }

  confirmationPopUp() {
    if (this.id > 0) {
      this._uiService.deleteItemData = this.deleteParams;
      this._uiService.deleteItemCallBack = this.delete.bind(this);
      this._router.navigate([{
        outlets: { centerPopup: ['delete-item'] }
      }]);
    }
  }

  delete() {
    if (this.deleteParams.pressBtn == "Ok") {
      this._discountTemplateService.delete(this.deleteParams.itemId).subscribe((result: any) => {
        this._tosterService.success("Template Deleted");
        this.resetForm();
        return;
      });
    }
  }

  async save() {
    try {
      await this.saveTemplate();      
      setTimeout(() => {
        if(!this.isFormsSubmited) return;
        if (this.id > 0) {
          this.isFormsSubmited = false;
          this.getTemplate(this.id);
          this._router.navigateByUrl(`discount-details/${this.id}`);
          return false;
        }
      }, 3000);
    }
    catch (err) {
    }
  }

  async saveAndNew() {
    try {
      await this.saveTemplate();      
      setTimeout(() => {
        if(!this.isFormsSubmited) return;
        if (this.isFormsSubmited) {
          this.isFormsSubmited = false;
          this.resetForm();
        }
      }, 3000);
    }
    catch (err) {
    }
  }

  resetForm() {
    this.isFormsSubmited = false;
    this.types = [];
    this.selectedBlockName = "Select block";
    this.isVoucherTypeDisabled = true;
    this.isPropertyTypeDisabled = true;
    this.ownerTenant = false;

    this.id = null;
    this.name = "";
    this.isActive = "";
    this.voucherType = this.societyInvoiceType;
    this.appliedForBlockId = "";
    this.propertyType = "";
    this.isPropertyType = "";
    this.discountType = "";
    this.lateFeeTemplateIdadType = 0;
    this.sendToOwners = "";
    this.sendToTenant = "";

    this.ledgerDefaultGroup = "Expenses";
    this.billingLineItems = [];

    this.templatePeriodId = null;
    this.startsOn = "";
    this.expriesOn = "";
    this.periods = [];
    this.activePeriod = 0;
    this.units = "";
    this.error = [];
    this.propertyTypes();
    setTimeout(() => {
      this._router.navigateByUrl("discount-details/0");
    }, 100)
  }

  redirectionType(mode: any, displayType: any) {
    let params: any;
    if (displayType == 'listing') {
      if (mode == "discount") {
        params = [{ outlets: { rightMainPopup: ["discounts"] } }];
      } else if (mode == "dashboard") {
        this._router.navigateByUrl("/dashboard"); return;
      }
      this._router.navigate(params); return;
    }
  }

  ngOnDestroy(): void {
    this._uiService.leftMenuItems.next([]);
  }

}
