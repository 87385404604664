import { AfterContentInit, Directive, ElementRef, Input, OnInit, AfterViewInit } from '@angular/core';
import { CommonMethods } from './common-methods';

@Directive({
    selector: '[actionPrivilage]'
})
export class ActionPrivilegeDirective implements OnInit, AfterViewInit {

    @Input() appActionPrivilege: string;

    constructor(private el: ElementRef) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        //console.log(this.appActionPrivilege, this.el.nativeElement);
        var shortCode = this.el.nativeElement.getAttribute('short-code');
        if (this.appActionPrivilege && !CommonMethods.getCapability().includes(this.appActionPrivilege)) {
            if (shortCode) {
                this.el.nativeElement.setAttribute('short-code', '');
            }
            this.el.nativeElement.setAttribute('style', 'display:none !important');
        } else {
            if (shortCode) {
                this.el.nativeElement.setAttribute('short-code', shortCode);
            }
            this.el.nativeElement.setAttribute('style', 'display:block !important');
        }
    }
}