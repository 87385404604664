import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject,Observable, of  } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../../app/config';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {  

  constructor(private _http: HttpClient) {
  }

  save(data:any) {
    return this._http.post(config.apiEndPointBase + "/payment/acknowledge",data, {})
    .pipe(map((response:any) => {
        return response;
    }));
  }

  filter(data:any){
    return this._http.post(config.apiEndPointBase + "/payment/filter",data, {})
    .pipe(map((response:any) => {
        return response;
    }));
  }

  cancel(id:number,data:any){
    return this._http.post(config.apiEndPointBase + `/payment/${id}/cancel`,data, {responseType: 'text'})
    .pipe(map((response:any) => {
        return response;
    }));
  }

  getAdditionalLineItems(data:any){
    return this._http.post(config.apiEndPointBase + `/payment/getAdditionalCharges`,data, {})
    .pipe(map((response:any) => {
        return response;
    }));
  }

  getDiscountSuggestion(data:any){
    return this._http.post(config.apiEndPointBase + `/payment/getDiscountSuggestion`,data, {})
    .pipe(map((response:any) => {
        return response;
    }));
  }

  exportPaymentsSummary(data: any){
    return this._http
      .post(config.apiEndPointBase + `/payment/exportSummary`, data, { responseType: 'blob' })
      .subscribe((res: any) => {
        saveAs(res, `payment-summary.xls`);
      });
  }

  exportPaymentsDetails(data: any){
    return this._http
      .post(config.apiEndPointBase + `/payment/exportDetails`, data, { responseType: 'blob' })
      .subscribe((res: any) => {
        saveAs(res, `payment-details.xls`);
      });
  }
}
