import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../../app/config';
import { CommunityModel } from '../Model/community.model';

@Injectable({
  providedIn: 'root'
})
export class CommunityService {


  constructor(private _http: HttpClient) {
  }

  all() {
    return this._http
      .post(config.apiEndPointBase + "/community/list", {}, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  save(data: CommunityModel): Observable<CommunityModel> {
    if (data.id) {
      return this._http
        .put(config.apiEndPointBase + "/community", data, { responseType: 'text' })
        .pipe(map((response: any) => {
          return data;
          return new CommunityModel().deserialize(response);
        }));
    } else {
      throw 'Community id required';
    }
  }

  details(id: number) {
    return this._http.get(config.apiEndPointBase + `/community/${id}`);
  }

  members(data: any) {
    console.log(data);
    return this._http
      .post(config.apiEndPointBase + "/community/listMembers", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  excecutiveMembers(id: number) {
    return this._http.get(config.apiEndPointBase + `/community/${id}/listExecMembers`);
  }

  roles(id: number) {
    return this._http.get(config.apiEndPointBase + `/community/${id}/listRoles`);
  }

  changeRole(data: any) {
    return this._http.post(config.apiEndPointBase + "/community/changeRole", data, { responseType: 'text' })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  blockUser(data: any) {
    return this._http.post(config.apiEndPointBase + "/community/blockUser", data, { responseType: 'text' })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getSettings(communityId: any) {
    return this._http.get(config.apiEndPointBase + `/community/settings/${communityId}`);
  }

  saveSettings(data: any) {
    return this._http
      .put(config.apiEndPointBase + "/community/settings", data, { responseType: 'text' })
      .pipe(map((response: any) => {
        return data;
      }));
  }

}
