import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { error } from 'protractor';
import { Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { v1 as uuidv1 } from 'uuid';
import { CommonMethods } from '../common-methods';
import { UiService } from '../ui.service';


@Injectable()
export class WebServiceInterceptor implements HttpInterceptor {

    constructor(
        public router: Router,
        private toastr: ToastrService,
        private _uiService: UiService) { };

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        var headerValues = {};
        if (CommonMethods.getItem("UDID")) {
            headerValues['UDID'] = CommonMethods.getItem("UDID");
        }
        headerValues['Content-Type'] = 'application/json';
        if (CommonMethods.getItem("authKey")) {
            headerValues['authKey'] = CommonMethods.getItem("authKey");
        }
        if (CommonMethods.getItem("communityId")) {
            headerValues['communityId'] = CommonMethods.getItem("communityId");
        }

        let reqMethod = req.method;
        if (reqMethod != 'GET' && reqMethod != 'POST') {
            headerValues['X-HTTP-Method-Override'] = reqMethod;
            reqMethod = 'POST';
        }

        req = req.clone({
            setHeaders: headerValues,
            method: reqMethod
        });

        //console.log(req);return;
        document.getElementById("showLoader").style.display = "block";
        return next.handle(req).pipe(
            catchError(err => {
                if (err.status == 0) {
                    if (err.error instanceof ProgressEvent) {
                        this._uiService.unknownConnectionIssue.next('Unable to connect server.');
                    } else {
                        this._uiService.unknownConnectionIssue.next(err.error || err.statusText);
                    }
                } else if (err.status == 403) {
                    //localStorage.removeItem("token");
                    this.router.navigate(['login'], { queryParams: { returnUrl: this.router.url } });
                } else {
                    if (typeof err.error === "object") {
                        console.log(1);
                        for (var key in err.error) {
                            this.toastr.error(err.error[key]);
                        }
                    } else {
                        var error = JSON.parse(err.error);
                        if (typeof error === "object") {
                            console.log(2);
                            for (var key in error) {
                                for (var i = 0; i < error[key].length; i++) {
                                    this.toastr.error(`${error[key][i]}`);
                                }
                            }
                        } else {
                            console.log(3);
                            this.toastr.error(error);
                        }
                    }



                }
                return of(null);
            }),
            finalize(() => {
                document.getElementById("showLoader").style.display = "none";
            })
        );
    }
}
