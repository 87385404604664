import { Component, OnInit } from '@angular/core';
import { CommonMethods } from '../common-methods';
import { UiService } from '../ui.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MaintenanceContractService } from '../api-client/maintenancecontract.service';
import { ToastrService } from 'ngx-toastr';
import { VoucherService } from '../api-client/voucher.service';
import { LedgerService } from '../api-client/ledger.service';
import { APP_CONST } from '../app-constants';

@Component({
  selector: 'app-all-maintenance-contracts',
  templateUrl: './all-maintenance-contracts.component.html',
  styleUrls: ['./all-maintenance-contracts.component.css']
})
export class AllMaintenanceContractsComponent implements OnInit {

  filterParams: any = {
    searchData: '',
    //linkItemName: '',
    type: '',
    linkItemTypeId: 0,
    linkItemId:0,
    startDate: null,
    expiryDate: null,
    vendorId: null,
    mode:null,
    status:null
  };
  filterContractData:any;
  contractData = [];
  startIndex:number = 0;
  recordPerPage:number = 40;
  totalRecords:number = 0;

  constructor(
    private _uiService: UiService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _voucherService: VoucherService,
    private _ledgerService: LedgerService,
    private _maintenanceContractService: MaintenanceContractService,
    private _tosterService: ToastrService,

  ) { }

  ngOnInit() {
    this.filterContract();
    this._uiService.leftMenuItems.next([
      { code: 'F5', name: 'Apply Filter', action: this.openFilter.bind(this), privilege: APP_CONST.DOCUMENTS_READ }
    ]);
  }

  async filterContract() {
    let data = {
      communityId: CommonMethods.getCommunityId(),
      searchData: this.filterParams.searchData,
      type: this.filterParams.type,
      linkItemTypeId: this.filterParams.linkItemTypeId,
      linkItemId: this.filterParams.linkItemId,
      //linkItemName: this.filterParams.linkItemName,
      startDate: this.filterParams.startDate,
      expiryDate: this.filterParams.expiryDate,
      vendorId: this.filterParams.vendorId,
      mode: this.filterParams.mode,
      status: this.filterParams.status,
      index:this.startIndex
    };

    await this._maintenanceContractService.filterContract(data).toPromise().then((result: any) => {
      this.filterContractData = result;
      this.bindFilterContractData();
    });
  }

  async bindFilterContractData(){
    if(!this.filterContractData) return;
    var result = this.filterContractData.data;
    for(var i=0;i<result.length;i++){
      if(result[i].category){
        result[i].category = result[i].category.split(",");
      }else{
        result[i].category = [];
      }
      
      /* if(result[i].linkedVoucherId){
        await this._voucherService.details(result[i].linkedVoucherId).toPromise().then((response:any)=>{
          result[i].voucherName = response.voucherNumber;
        })
      }else{
        result[i].voucherName = null;
      } */
      if(result[i].vendorId){
        await this._ledgerService.details(result[i].vendorId).toPromise().then((response:any)=>{
          result[i].vendorName = response.ledgerName;
        })
      }else{
        result[i].vendorName = null;
      }
      for(var j=0;j<result[i].linkItem.length;j++){
        var linkItemType = CommonMethods.linkItemTypes.filter((x:any)=>x.id==result[i].linkItem[j].linkItemTypeId);
        if(linkItemType.length>0){
          result[i].linkItem[j].linkItemType = linkItemType[0].name;
        }
      }
    }      
    this.contractData = result;
    this.totalRecords = this.filterContractData.total_records;
  }

  openFilter() {
    this._uiService.activeFilterCallBack = this.filterContract.bind(this);
    this._uiService.activeFilterData = this.filterParams;
    this._router.navigate([{
      outlets: { leftMainPopup: ['contract-filter'] }
    }]);
  }

  details(item: any) {
    this._router.navigate(['maintenance-contract', item.id]);
  }

  onPageChange(startIndex:number){
    this.startIndex = startIndex;
    this.filterContract();
  }

  selectTag(item:any){
    this.filterParams.searchData = item;
    this.startIndex = 0;
    this.filterContract();
  }

  redirectTo() {
      this._router.navigateByUrl("/dashboard"); return;
  }

  ngOnDestroy(): void {
    this._uiService.leftMenuItems.next([]);
  }

}
