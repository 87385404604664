import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { AuthService } from '../api-client/auth.service';
import { UiService } from '../ui.service';
import { PreviousRouteService } from '../api-client/previous-route.service';
import { Subscription } from 'rxjs';
import { StaffService } from '../api-client/staff.service';
import { DashboardService } from '../api-client/dashboard.service';
import { APP_CONST } from '../app-constants';
import { BuildingService } from '../api-client/building.service';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.css']
})
export class TopNavComponent implements OnInit, OnDestroy {

  communityDetails: any;
  topMenuItems: any = [];
  profile:any;
  dashboardDetails:any;
  private _itemsChangeSubscriber: Subscription;

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _uiService: UiService,
    private _staffService: StaffService,
    private _previousRouteService: PreviousRouteService,
    private _dashboardService: DashboardService,
    private _buildingService: BuildingService
  ) {  }

  async ngOnInit() {
    this.communityDetails = {};
    this._uiService.changeCommunityDetails.subscribe((community: any) => {
      this.communityDetails = community;
    })
    if (CommonMethods.getCommunityDetails()) {
      this.communityDetails = CommonMethods.getCommunityDetails();
    }
    if(CommonMethods.getCommunityId()){
      await this.dynamicMenu();
    }

    /* this._itemsChangeSubscriber = this._uiService.topMenuItems.subscribe((items)=>{
      this.topMenuItems = items;
    }); */
    this.getProfile();
  }

  redirectTo(arg: any) {
    if(!arg) return;
    this._router.navigate([arg]);
  }

  async logout() {
    CommonMethods.removeItem("authKey");
    CommonMethods.removeItem("UDID");
    CommonMethods.removeItem("communityId");
    CommonMethods.removeItem("communityBlockId");
    CommonMethods.removeItem("communityDetails");
    CommonMethods.removeItem("capability");
    this._buildingService.removeSocietyFilterData();
    setTimeout(()=>{
      window.location.href="login"
    },100);
    /* await this._authService.logout().toPromise().then((result: any) => {
      //alert(result);
      if (result) {
        CommonMethods.removeItem("authKey");
        CommonMethods.removeItem("UDID");
        CommonMethods.removeItem("communityId");
        CommonMethods.removeItem("communityBlockId");
        this._router.navigate(["login"]);
      }
    }) */
  }

  back() {
    this._router.navigateByUrl(this._previousRouteService.getPreviousUrl());
    return false;
  }

  async getProfile(){
    if(CommonMethods.getCommunityId()){
      this._staffService.getMyProfile().toPromise().then((result:any)=>{
        this.profile = result;
      });
    }  
    
  }

  redirectToProfile(){
    this._router.navigateByUrl(`profile`);
    return false;
  }

  async dynamicMenu(){
    var communityId = Number(CommonMethods.getCommunityId());
    await this._dashboardService.details(communityId).toPromise().then((result: any) => {
      this.dashboardDetails = this.numberFormatter(result);
      this.topMenuItems = [
        { name:'To-Do', icon:'fa fa-tasks', number:this.dashboardDetails.toDoCount,action:"all-todo"},
        { name:'Discussions', icon:'fa fa-comments', number:this.dashboardDetails.discussionsCount,action:`discussions`},
        { name:'Services Visit Log', icon:'fa fa-th', number:this.dashboardDetails.maintenanceLogCount,action:"professional's-visits"},
        { name:'Documents & Agreements', icon:'fa fa-briefcase', number:this.dashboardDetails.contractCount,action:"all-contract"},       
        { name:'Notices', icon:'fa fa-bullhorn',number:this.dashboardDetails.noticesCount,action:`notices`},
        { name:'Guidelines', icon:'fas fa-book-reader', number:this.dashboardDetails.guidelinesCount,action:`rules`},
      ];
    })
  }

  numberFormatter(x: any) {
    if (isNaN(x)) return x;

    if (x < 9999) {
      return x;
    }

    if (x < 1000000) {
      return Math.round(x / 1000) + "K";
    }
    if (x < 10000000) {
      return (x / 1000000).toFixed(2) + "M";
    }

    if (x < 1000000000) {
      return Math.round((x / 1000000)) + "M";
    }

    if (x < 1000000000000) {
      return Math.round((x / 1000000000)) + "B";
    }

    return "1T+";
  }

  ngOnDestroy(): void {
    //this._itemsChangeSubscriber.unsubscribe();
  }
}
