import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { UiService } from '../ui.service';
import { UserService } from '../api-client/user.service';
import { LedgerService } from '../api-client/ledger.service';
import { BookingFormBuilder } from './booking.form-builder';
import { BookingModel } from '../Model/booking.model';
import { APP_CONST } from '../app-constants';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { CommonMethods } from '../common-methods';
import { BookingService } from '../api-client/booking.service';
import { timeStamp } from 'console';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.css']
})
export class BookingDetailsComponent implements OnInit {

  id = null;
  billingToLedger = null;
  bookingId: number = 0;
  routeExtras: NavigationExtras;
  APP_CONST = APP_CONST;
  selectedBookingItems = [];
  propertyDetails = [];
  bookingCharges: any;
  bookingPurpose: any;
  bookingDescription: any;
  startDate: any;
  endDate: any;
  numberOfAdult: any;
  numberOfChild:any;
  customerPhone:any;
  customerName:any;
  customerEmail:any;
  customerAddress:any;
  remarks:any;
  totalCharges: any;
  totalAmount: any;
  totalQuantity: any;

  public config = <IDayCalendarConfig>{
    format: "YYYY-MM-DD HH:mm:ss"
  };

  constructor(
    private _tosterService: ToastrService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _uiService: UiService,
    private _bookingService: BookingService
  ) {
    this.routeExtras = this._router.getCurrentNavigation().extras;
  }

  ngOnInit() {
    this._uiService.leftMenuItems.next([
      { code: 'F4', name: 'Save', action: this.save.bind(this), class: 'green lighten-1', privilege: APP_CONST.BILLING_CHARGES_SETUP_WRITE },
      // { code: 'F5', name: 'Save & Add New', action: this.saveAndNew.bind(this), privilege: APP_CONST.BILLING_CHARGES_SETUP_WRITE },
      // { code: 'F7', name: 'Delete', action: this.confirmationPopUp.bind(this), class: 'red lighten-1', privilege: APP_CONST.BILLING_CHARGES_SETUP_REMOVE }
    ]);
    this._route.params.subscribe(params => {
      console.log(params);
      if (params.id > 0) {
        this.bookingId = params.id;
        this.details(params.id);
      } else {
        //this.resetForm();
      }
    });
  }


  async details(id: number) {
    await this._bookingService.details(id).toPromise().then((result:any) =>{
      console.log('aaa',result);
      this.id = result.id;
      this.bookingPurpose = result.bookingPurpose;
      this.bookingDescription = result.bookingDescription;
      this.startDate = result.startDate;
      this.endDate = result.endDate;
      this.billingToLedger = result.billingToLedger;
      this.numberOfAdult = result.numberOfAdult;
      this.numberOfChild = result.numberOfChild;
      this.customerName = result.customerName;
      this.customerAddress = result.customerAddress;
      this.customerPhone = result.customerPhone;
      this.customerEmail = result.customerEmail;
      this.remarks = result.remarks;
      console.log(result.bookingCharges);
      for(let i=0;i<result.bookingCharges.length;i++) {
        var params = {
          startDate: result.bookingCharges[i].startDate, 
          endDate: result.bookingCharges[i].endDate,
          numberOfAdult: result.bookingCharges[i].numberOfAdult,
          numberOfChild: result.bookingCharges[i].numberOfChild, 
          propertyId: result.bookingCharges[i].propertyId, 
          communityId: CommonMethods.getCommunityId()
        }
         this._bookingService.availibility(params).subscribe((data: any) => {
          if (data.lineItems.length > 0) {
            this.selectedBookingItems.push(data);
          }
        })
      }
    })
  }

  async saveBooking() {
    this.bookingCharges = [];
    this.totalCharges = 0;
    this.totalQuantity = 0;
    this.totalAmount = 0;
    if (this.bookingPurpose == null || this.bookingPurpose == "") {
      this._tosterService.error("Purpose can not be blank");
      return false;
    }
    if (this.bookingDescription == null || this.bookingDescription == "") {
      this._tosterService.error("Description can not be blank");
      return false;
    }
    if (this.startDate == null || this.startDate == "") {
      this._tosterService.error("Start date can not be blank");
      return false;
    }
    if (this.endDate == null || this.endDate == "") {
      this._tosterService.error("End date can not be blank");
      return false;
    }
    if (this.numberOfAdult == null || this.numberOfAdult == "") {
      this._tosterService.error("Adult count can not be blank");
      return false;
    }
    if (isNaN(this.numberOfAdult)) {
      this._tosterService.error("Adult count number must be number.");
      return false;
    }
    if (this.numberOfChild == null || this.numberOfChild == "") {
      this._tosterService.error("Child count can not be blank");
      return false;
    }
    if (isNaN(this.numberOfChild)) {
      this._tosterService.error("Child count number must be number.");
      return false;
    }
    if (this.customerName == null || this.customerName == "") {
      this._tosterService.error("Customer name can not be blank");
      return false;
    }
    if (this.customerPhone == null || this.customerPhone == "") {
      this._tosterService.error("Customer phone number can not be blank");
      return false;
    }
    if (isNaN(this.customerPhone)) {
      this._tosterService.error("Customer phone number must be number.");
      return false;
    }
    if (this.selectedBookingItems.length == 0) {
      this._tosterService.error("Please Amenity or Parking");
      return false; 
    }
    if(this.propertyDetails.length > 0){
      for(var i=0; i<this.propertyDetails.length; i++){
        for(var k=0; k<this.propertyDetails[i].length; k++){
          // for(var p=0; p<this.propertyDetails[i][k].lineItems.length; p++){
          //   this.totalQuantity +=  this.propertyDetails[i][k].lineItems[p].quantity;
          //   this.totalCharges += this.propertyDetails[i][k].lineItems[p].itemRate;
          //   this.totalAmount += (this.propertyDetails[i][k].lineItems[p].itemRate * this.propertyDetails[i][k].lineItems[p].quantity);

          //   // console.log('aaaa',this.propertyDetails[i][k].lineItems[p].quantity);
          //   // console.log('bbbb',this.propertyDetails[i][k].lineItems[p].itemRate);
           
          // }
          this.bookingCharges.push({
                  propertyId:this.propertyDetails[i][k].propertyId, 
                  startDate:this.propertyDetails[i][k].startDate, 
                  endDate:this.propertyDetails[i][k].endDate,
                  numberOfAdult:this.numberOfAdult,
                  numberOfChild:this.numberOfChild
          });
        }
      }
    }
    var bookingData = {
      id: this.id,
      communityId: CommonMethods.getCommunityId(),
      bookingPurpose: this.bookingPurpose,
      bookingDescription: this.bookingDescription,
      startDate: this.startDate,
      endDate: this.endDate,
      billingToLedger: this.billingToLedger,
      numberOfAdult: this.numberOfAdult,
      numberOfChild: this.numberOfChild,
      customerName: this.customerName,
      customerPhone: this.customerPhone,
      customerEmail: this.customerEmail,
      customerAddress: this.customerAddress,
      remarks: this.remarks,
      totalAmount:this.totalAmount,
      bookingCharges: this.bookingCharges
      
    }
    console.log('ffff',bookingData);
    await this._bookingService.save(bookingData).toPromise().then((response: any) => {
      if (response) {       
        this._tosterService.success("Booking Saved");
      }
    },
      error => {
        this._tosterService.error(error.message);
      })
  }

  propertyType(type: any,propertyId:number,numberOfAdult:number,numberOfChild:number,startDate:string,endDate:string) {
    var params = [{ outlets: { leftMainPopup: ["booking-avalibility"] } }];
    //console.log(type);
    this._router.navigate(params, { state: {"selectedBookingItems":this.selectedBookingItems,"startDate": startDate, "endDate": endDate,"numberOfAdult":numberOfAdult,"numberOfChild":numberOfChild, "propertyType": type,"propertyId":propertyId} });
    this.propertyDetails.push(this.selectedBookingItems);
    return;
  }

  changeAmount(item:any){
    console.log('222222',this.selectedBookingItems);
    for(var i=0; i<this.selectedBookingItems.length;i++){
      for(var k=0;k<this.selectedBookingItems[i].lineItems.length;k++){
        //this.selectedBookingItems[i].lineItems[k].chargesRate = item.chargesRate;
        //this.selectedBookingItems[i].lineItems[k].qtn = item.qtn;
        if(this.selectedBookingItems[i].lineItems[k].itemRate>0 && this.selectedBookingItems[i].lineItems[k].quantity>0){
           this.selectedBookingItems[i].lineItems[k].amount = (this.selectedBookingItems[i].lineItems[k].itemRate * this.selectedBookingItems[i].lineItems[k].quantity).toFixed(2);
           this.totalAmount +=  this.selectedBookingItems[i].lineItems[k].amount;
        }
      } 
    }
    //console.log(this.selectedBookingItems);
  }
  
  async save() {
    try {
      await this.saveBooking();
      setTimeout(() => {
        if (this.id > 0) {
          this.details(this.id);
          this._router.navigateByUrl(`booking-details/${this.id}`);
          return false;
        }
      }, 3000);
    }
    catch (err) {
    }
  }

  editItem(item:any){
    console.log('3333',item);
    this.propertyType(item.propertyType,item.propertyId,this.numberOfAdult,this.numberOfChild,this.startDate,this.endDate);
  }

  deleteItem(item:any){
    var index = this.selectedBookingItems.indexOf(item);
    console.log(index);
    this.selectedBookingItems.splice(index,1);
  }

  resetForm() {

  }

}
