import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BuildingModel } from './../Model/building.model';

@Injectable({
    providedIn: 'root'
})

export class BuildingFormBuilder {
    private form;
    private validationRules = {
        communityBlockId: ['', Validators.required],
        name: ['', Validators.required],
        houseNumber: ['', Validators.required],
        street: ['', Validators.required],
        block: ['', ''],
        landmark: ['', ''],
        postOffice: ['', Validators.required],
        policeStation: ['', Validators.required],
        town: ['', Validators.required],
        district: ['', Validators.required],
        subDistrict: ['', ''],
        pinCode: ['', Validators.required],
        stateId: ['', Validators.required],
        countryId: ['', Validators.required],        
        floorNames: ['', ''],
        latitude: ['', ''],
        longitude: ['', '']
    }

    constructor(private fb: FormBuilder) {
        this.form = fb.group(this.validationRules);
    }

    getForm() {
        return this.form;
    }

    setValues(response: BuildingModel) {
        this.form.patchValue({
            id: response.id,
            communityBlockId: response.communityBlockId,
            communityId: response.communityId,
            name: response.name,
            houseNumber: response.houseNumber,
            street: response.street,
            block: response.block,
            landmark: response.landmark,
            postOffice: response.postOffice,
            town: response.town,
            district: response.district,
            policeStation: response.policeStation,
            subDistrict: response.subDistrict,
            pinCode: response.pinCode,
            stateId: response.stateId,
            countryId: response.countryId,            
            floorNames: response.floorNames,
            latitude: response.latitude,
            longitude: response.longitude
        });
    }
}