import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { UiService } from '../ui.service';
import { APP_CONST } from '../app-constants';
import { ToastrService } from 'ngx-toastr';
import { VoucherService } from '../api-client/voucher.service';
import { config } from '../config';
import { BuildingService } from '../api-client/building.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit, OnDestroy {

  societyInvoiceType: number = APP_CONST.INVOICE_TYPE_SOCIETY;
  rentInvoiceType: number = APP_CONST.INVOICE_TYPE_RENT;
  purchaseInvoiceType: number = APP_CONST.INVOICE_TYPE_PURCHASE;
  invoices: any;
  listMode: any = null;
  selectedInvoices = [];
  startIndex: number = 0;
  recordPerPage: number = 40;
  totalRecords: number = 0;
  todaysDate: any = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  filterParams: any = {
    selectedPropertyType: '',
    propertyId: null,
    propertySelectedText: '',
    invoiceFrom: null,
    invoiceTo: null,
    invoiceStatus: '',
    ledgerToId: null,
    ledgerFromId: null,
    keyword: '',
    invoiceType: null,
    accountSelectedText: '',
    blockId: null,
    blockSelectedText: '',
    buildingId: null,
    buildingSelectedText: ''
  };
  communityId: number = parseInt(CommonMethods.getCommunityId());
  readPrivilege:string;
  writePrivilege:string;
  trashPrivilege:string;
  removePrivilege:string;
  payPrivilege:string;
  assetPropertyType:number = APP_CONST.PROPERTY_TYPE_ASSETS;
  buildingPropertyType:number = APP_CONST.PROPERTY_TYPE_BUILDINGS;
  result:any;

  constructor(private _uiService: UiService,
    private _router: Router,
    private _route: ActivatedRoute,
    private datePipe: DatePipe,
    private _tosterService: ToastrService,
    private _voucherService: VoucherService,
    private _buildingService: BuildingService) { }

  ngOnInit(): void {
    this._buildingService.societyInvoice.subscribe((userData) => {
      if (userData) {
        this.result = userData;
        this.filterParams=this.result;
      }
    },
      (err) => {
        console.error(err);
      });
    this.listInvoices();
  }

  /*---Get list of all invoices from DB---*/
  listInvoices(): void {
    this._route.data.subscribe(params => {
      this.filterParams.invoiceType = parseInt(params.invoiceType);
      this.filterInvoice();
      if (this.filterParams.invoiceType == this.societyInvoiceType || this.filterParams.invoiceType == this.rentInvoiceType) {
        this.readPrivilege = APP_CONST.SOCIETY_INVOICE_READ;
        this.writePrivilege = APP_CONST.SOCIETY_INVOICE_WRITE;
        this.trashPrivilege = APP_CONST.SOCIETY_INVOICE_TRASH;
        this.removePrivilege = APP_CONST.SOCIETY_INVOICE_REMOVE;
        this.payPrivilege = APP_CONST.COLLECTION_WRITE;
        this._uiService.leftMenuItems.next([
          { code: 'F3', name: 'Generate Bulk Invoice', action: this.generateBulk.bind(this), privilege: this.writePrivilege },
          { code: 'F4', name: 'Add New', action: this.createNew.bind(this), class: 'green lighten-1', privilege: this.writePrivilege },
          { code: 'F5', name: 'Apply Filter', action: this.openFilter.bind(this), privilege: this.readPrivilege },
          { code: 'F6', name: 'Payment Acknowledgement', action: this.paySelected.bind(this), privilege: this.payPrivilege },
        ]);
      } else {
        this.readPrivilege = APP_CONST.PURCHASE_INVOICE_READ;
        this.writePrivilege = APP_CONST.PURCHASE_INVOICE_WRITE;
        this.trashPrivilege = APP_CONST.PURCHASE_INVOICE_TRASH;
        this.removePrivilege = APP_CONST.PURCHASE_INVOICE_REMOVE;
        this.payPrivilege = APP_CONST.PAYMENT_WRITE;
        this._uiService.leftMenuItems.next([
          { code: 'F4', name: 'Add New', action: this.createNew.bind(this), class: 'green lighten-1', privilege: this.writePrivilege },
          { code: 'F5', name: 'Apply Filter', action: this.openFilter.bind(this), privilege: this.readPrivilege },
          { code: 'F6', name: 'Payment Acknowledgement', action: this.paySelected.bind(this), privilege: this.payPrivilege },
        ]);
      }
    })
  }

  /*---Sanitize date formats of all invoices---*/
  santitizeItems(items: any = []): any {
    if (items && items.length > 0) {
      items.forEach((element: any) => {
        //element.propertyName = 'NA';
        element.propertyTypeName = 'NA';
        if(element.propertyId > 0 && element.propertyTypeId > 0){
          if(element.propertyTypeId == this.assetPropertyType){
            element.propertyTypeName = 'Asset';
          }else if(element.propertyTypeId == this.buildingPropertyType){
            element.propertyTypeName = 'Building';
          }else{
            if(element.propertyTypeId == APP_CONST.PROPERTY_TYPE_FLAT){
              element.propertyTypeName = 'Flat';
            }
            if(element.propertyTypeId == APP_CONST.PROPERTY_TYPE_OFFICES){
              element.propertyTypeName = 'Offices';
            }
            if(element.propertyTypeId == APP_CONST.PROPERTY_TYPE_SHOPS){
              element.propertyTypeName = 'Shops';
            }
            if(element.propertyTypeId == APP_CONST.PROPERTY_TYPE_PARKING_SPACES){
              element.propertyTypeName = 'Parking';
            }
            if(element.propertyTypeId == APP_CONST.PROPERTY_TYPE_GARAGES_TWO_WHEELER){
              element.propertyTypeName = 'Garage 2W';
            }
            if(element.propertyTypeId == APP_CONST.PROPERTY_TYPE_GARAGES_FOUR_WHEELER){
              element.propertyTypeName = 'Garage 4W';
            }
            if(element.propertyTypeId == APP_CONST.PROPERTY_TYPE_AMENITY){
              element.propertyTypeName = 'Amenity';
            }
            if(element.propertyTypeId == APP_CONST.PROPERTY_TYPE_QUARTER){
              element.propertyTypeName = 'Quarter';
            }
            if(element.propertyTypeId == APP_CONST.PROPERTY_TYPE_PARKING_SPACES_TWO_WHEELER){
              element.propertyTypeName = 'Parking 2W';
            }
            if(element.propertyTypeId == APP_CONST.PROPERTY_TYPE_PARKING_SPACES_FOUR_WHEELER){
              element.propertyTypeName = 'Parking 4W';
            }
          }
        }
        element.formattedVoucherDate = element.voucherDate ? CommonMethods.dateToIndianFormat(element.voucherDate) : "NA";
        element.formattedManualDueDate = element.manualDueDate ? CommonMethods.dateToIndianFormat(element.manualDueDate) : "NA";
        element.formattedLastPaymentDate = element.lastPaymentDate ? CommonMethods.dateToIndianFormat(element.lastPaymentDate) : "NA";
        element.formattedPeriod = element.periodStartFrom && element.periodEndOn ? CommonMethods.dateToIndianFormat(element.periodStartFrom)+' - '+CommonMethods.dateToIndianFormat(element.periodEndOn):null;
      });
    }
    return items;
  }

  /*---Open filter for invoices---*/
  openFilter(): void {
    this._uiService.activeFilterCallBack = this.filterInvoice.bind(this);
    this._uiService.activeFilterData = this.filterParams;
    this._router.navigate([{
      outlets: { leftMainPopup: ['voucher-filter'] }
    }]);
  }

  /*---Filter all invoices from DB---*/
  filterInvoice(): void {
    let data = {
      communityId: this.communityId,
      voucherType: this.filterParams.invoiceType,
      propertyTypeId: this.filterParams.selectedPropertyType ? this.filterParams.selectedPropertyType : null,
      propertyId: (this.filterParams.selectedPropertyType && this.filterParams.propertyId) ? this.filterParams.propertyId : null,
      keyWord: this.filterParams.keyword ? this.filterParams.keyword : null,
      accountId: this.filterParams.ledgerToId ? this.filterParams.ledgerToId : this.filterParams.ledgerFromId ? this.filterParams.ledgerFromId : null,
      voucherFromDate: this.filterParams.invoiceFrom ? CommonMethods.dbToUsDate(this.filterParams.invoiceFrom) : null,
      voucherToDate: this.filterParams.invoiceTo ? CommonMethods.dbToUsDate(this.filterParams.invoiceTo) : null,
      status: this.filterParams.invoiceStatus ? this.filterParams.invoiceStatus : null,
      buildingId: this.filterParams.buildingId ? this.filterParams.buildingId : null,
      blockId: this.filterParams.blockId ? this.filterParams.blockId : null,
      index: this.startIndex
    };
    this._voucherService.filterVoucher(data).subscribe((result: any) => {
      this.invoices = this.santitizeItems(result.data);
      this.totalRecords = result.total_records;
    }, error => {
      this._tosterService.error(error.message);
    });
  }

  exportSummary(): void {
    let data = {
      communityId: this.communityId,
      voucherType: this.filterParams.invoiceType,
      propertyTypeId: this.filterParams.selectedPropertyType ? this.filterParams.selectedPropertyType : null,
      propertyId: (this.filterParams.selectedPropertyType && this.filterParams.propertyId) ? this.filterParams.propertyId : null,
      keyWord: this.filterParams.keyword ? this.filterParams.keyword : null,
      accountId: this.filterParams.ledgerToId ? this.filterParams.ledgerToId : this.filterParams.ledgerFromId ? this.filterParams.ledgerFromId : null,
      voucherFromDate: this.filterParams.invoiceFrom ? CommonMethods.dbToUsDate(this.filterParams.invoiceFrom) : null,
      voucherToDate: this.filterParams.invoiceTo ? CommonMethods.dbToUsDate(this.filterParams.invoiceTo) : null,
      status: this.filterParams.invoiceStatus ? this.filterParams.invoiceStatus : null,
      buildingId: this.filterParams.buildingId ? this.filterParams.buildingId : null,
      blockId: this.filterParams.blockId ? this.filterParams.blockId : null,
    };
    this._voucherService.exportInvoicesSummary(data);
  }

  exportDetails(): void {
    let data = {
      communityId: this.communityId,
      voucherType: this.filterParams.invoiceType,
      propertyTypeId: this.filterParams.selectedPropertyType ? this.filterParams.selectedPropertyType : null,
      propertyId: (this.filterParams.selectedPropertyType && this.filterParams.propertyId) ? this.filterParams.propertyId : null,
      keyWord: this.filterParams.keyword ? this.filterParams.keyword : null,
      accountId: this.filterParams.ledgerToId ? this.filterParams.ledgerToId : this.filterParams.ledgerFromId ? this.filterParams.ledgerFromId : null,
      voucherFromDate: this.filterParams.invoiceFrom ? CommonMethods.dbToUsDate(this.filterParams.invoiceFrom) : null,
      voucherToDate: this.filterParams.invoiceTo ? CommonMethods.dbToUsDate(this.filterParams.invoiceTo) : null,
      status: this.filterParams.invoiceStatus ? this.filterParams.invoiceStatus : null,
      buildingId: this.filterParams.buildingId ? this.filterParams.buildingId : null,
      blockId: this.filterParams.blockId ? this.filterParams.blockId : null,
    };
    this._voucherService.exportInvoicesDetails(data);
  }

  /*---Navigate to Detail page of an invoice---*/
  details(item: any): void {
    if (this.filterParams.invoiceType == this.societyInvoiceType || this.filterParams.invoiceType == this.rentInvoiceType) {
      this._router.navigateByUrl("society-invoice/" + item.id);
    } else {
      this._router.navigateByUrl("purchase-invoice/" + item.id);
    }
  }

  /*---Navigate to create new invoice page---*/
  createNew(): void {
    this._router.navigate([{ "outlets": { "rightMainPopup": ["billing-charges", { "mode": "InvoiceCreation", "type": this.filterParams.invoiceType }] } }]);
  }

  /*---Navigate to bulk invoice page---*/
  generateBulk(): void {
    this._router.navigate([{ "outlets": { "rightMainPopup": ["billing-charges", { "mode": "BulkInvoiceCreation", "type": this.filterParams.invoiceType }] } }]);
  }

  /*---Select invoices---*/
  selectInvoice(elem: any): void {
    var val = parseInt(elem.value);
    if (elem.checked) {
      this.selectedInvoices.push(val);
    } else {
      var index: number = this.selectedInvoices.indexOf(val);
      if (index !== -1) {
        this.selectedInvoices.splice(index, 1);
      }
    }
  }

  /*---Navigate to payment page with selected invoices---*/
  paySelected(): void {
    if (this.selectedInvoices.length > 0) {
      var selectedLedgerId = 0;
      var error = false;
      var statusError = false;
      var paymentUrl: string = "";
      this.invoices.filter((x: any) => {
        this.selectedInvoices.forEach(obj => {
          if (obj == x.id) {
            if (this.filterParams.invoiceType == this.societyInvoiceType || this.filterParams.invoiceType == this.rentInvoiceType) {
              paymentUrl = "collection";
              if (selectedLedgerId > 0) {
                if (x.ledgerToId != selectedLedgerId) {
                  error = true;
                }
              } else {
                selectedLedgerId = x.ledgerToId;
              }
            } else {
              paymentUrl = "payment";
              if (selectedLedgerId > 0) {
                if (x.ledgerFromId != selectedLedgerId) {
                  error = true;
                }
              } else {
                selectedLedgerId = x.ledgerFromId;
              }
            }
            if (x.status != 'Pending' && x.status != 'Partially Paid') {
              statusError = true;
            }
          }
        })
      });
      if (!error) {
        if (!statusError) {
          this._router.navigate([paymentUrl], { state: { "invoices": this.selectedInvoices } });
        } else {
          this._tosterService.error("To pay you need to select only Pending or Partially Paid invoices.");
        }
      } else {
        this._tosterService.error("To pay multiple invoices, you need to select invoices of same account.");
      }
    } else {
      this._tosterService.error("To pay you need to select atleast of one invoice.");
    }
  }

  /*---Triggered when page changed in pagination---*/
  onPageChange(startIndex: number): void {
    this.startIndex = startIndex;
    this.filterInvoice();
  }

  /*---Breadcrumb navigation---*/
  breadcrumbNavigate(): void {
    this._router.navigateByUrl("/dashboard");
  }

  /*---Download voucher---*/
  downloadInvoice(invoice: any): void {
    var authkey = localStorage.getItem("authKey");
    var udid = localStorage.getItem("UDID");
    var communityId = CommonMethods.getCommunityId();
    var is_duble_invoice = 1;
    // console.log(`${config.apiEndPointBase}/voucher/download/${invoice.id}?authkey=${authkey}&udid=${udid}&communityId=${communityId}&is_duble_invoice=${is_duble_invoice}`);return;
    window.open(`${config.apiEndPointBase}/voucher/download/${invoice.id}?authkey=${authkey}&udid=${udid}&communityId=${communityId}&is_duble_invoice=${is_duble_invoice}`);
    return;
  }

  ngOnDestroy(): void {
    this._uiService.leftMenuItems.next([]);
  }
}
