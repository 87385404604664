import { Component, OnInit, Input } from '@angular/core';
import { CommonMethods } from '../common-methods';
import { APP_CONST } from '../app-constants';
import { UiService } from '../ui.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { TodoFormBuilder } from '../todo-details/todo.form-builder';
import { TodoService } from '../api-client/todo.service';
import { ThreadModel } from '../Model/thread.model';
import { ThreadService } from '../api-client/thread.service';

@Component({
  selector: 'app-todo-view',
  templateUrl: './todo-view.component.html',
  styleUrls: ['./todo-view.component.css']
})
export class TodoViewComponent implements OnInit {

  @Input() todoId: any;
  comment: any;
  categoryTags = [];
  todoDetails: any;
  todoData: any;
  commentId = null;
  dependentTasks = [];
  dependentData = [];
  linkItemType: any;
  linkItemId: any;
  hideShowAll = false;
  APP_CONST = APP_CONST;
  deleteTodoCommentParams: any = {
    itemId: 0,
    itemName: null,
    deleteText: "Are you sure, you want to delete this comment?"
  }
  relatedTo = 0;
  config = CommonMethods.ckEditorConfig;
  constructor(private _uiService: UiService,
    private _tosterService: ToastrService,
    private _router: Router,
    private _formBuilder: TodoFormBuilder,
    private _todoService: TodoService,
    private _threadService: ThreadService,
    private _route: ActivatedRoute) { }

  ngOnInit() {
    this._route.params.subscribe(params => {
      if (params.id > 0) {
        this.todoId = params.id;
        this.relatedTo = 1;
        document.querySelector(".dynamicWidth").classList.remove("w-25");
        document.querySelector(".dynamicWidth").classList.add("w-50");
        this.comments();
      }
    });
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if(!this._uiService.todoList){
      this.todoDetails = null;
    }    
    if (!this.todoId) return;
    this.relatedTo = 0;
    this.comments();
  }

  async comments() {
    if (!this.todoId) return;
    this.linkItemType = APP_CONST.TODO;
    this.linkItemId = this.todoId;
    var data = { communityId: CommonMethods.getCommunityId(), id: this.todoId };
    await this._todoService.details(data).toPromise().then((result: any) => {
      this.todoData = result;
      this.todoDataBind();
    });
  }

  async todoDataBind() {
    if (!this.todoData) return;    
    var result = this.todoData;
    this._uiService.linkItem = { id: this.todoData.id, type: APP_CONST.TODO, name: this.todoData.task };
    await this._todoService.dependentTasks(result.id).toPromise().then((response: any) => {
      this.dependentData = response;
      this.bindDependencyData();
    });
    if (result.linkItemTypeId) {
      var linkItemType = CommonMethods.linkItemTypes.filter((x: any) => x.id == result.linkItemTypeId);
      if (linkItemType.length > 0) {
        result.linkItemType = linkItemType[0].name;
      }
      if (result.linkItemTypeId == 403 && result.linkItemId) {
        await this._threadService.details(result.linkItemId).toPromise().then((response: any) => {
          result.thread = response;
        })
      } else {
        result.thread = null;
      }
    }
    var priority = CommonMethods.todoPriority.filter((x: any) => x.id == result.priority);
    if (priority.length > 0) {
      result.priority = priority[0].name;
    }
    var status = CommonMethods.todoStatus.filter((x: any) => x.id == result.status);
    if (status.length > 0) {
      result.status = status[0].name;
    }
    if (result.category) {
      this.categoryTags = result.category.split(",");
    }
    this.todoDetails = result;
  }


  saveComment() {
    if (this.comment == "" || this.comment == null) {
      this._tosterService.error("Comment can not be blank");
      return;
    }
    var data = { communityId: CommonMethods.getCommunityId(), taskId: this.todoId, comment: this.comment, id: this.commentId };
    this._todoService.comment(data).subscribe((result: any) => {
      this._uiService.todoDataReload.next(true);
      this._tosterService.success("Comment added");
      this.comment = "";
      this.comments();
      return;
    });
  }

  editComment(item: any) {
    if (!item.id) return;
    this.commentId = item.id;
    this.comment = item.comment;
  }

  deleteComment() {
    //console.log(this.deleteParams.itemId);
    if (this.deleteTodoCommentParams.pressBtn == "Ok") {
      this._todoService.deleteComment(this.deleteTodoCommentParams.itemId).subscribe((result: any) => {
        this._tosterService.success("Comment Deleted");
        this._uiService.todoDataReload.next(true);
        this.comments();
        return;
      });
    }
  }

  deleteCommentPopUp(item: any) {
    this.deleteTodoCommentParams.itemId = item.id;
    this.deleteTodoCommentParams.itemName = (item.comment.length > 60) ? item.comment.slice(0, 60) + "...." : item.comment;;
    this._uiService.deleteItemData = this.deleteTodoCommentParams;
    this._uiService.deleteItemCallBack = this.deleteComment.bind(this);
    this._router.navigate([{
      outlets: { centerPopup: ['delete-item'] }
    }]);
  }

  redirectTo(item: any) {
    this._router.navigate([{
      outlets: { leftMainPopup: ["todo-details", item.id] }
    }]);
    return;
  }

  visiteLog(item: any) {
    this._router.navigate([{
      outlets: { leftMainPopup: ["maintenance-log", 0] }
    }]);
    return;
  }

  relatedTodo(item: any) {
    this._uiService.increaseRightPanelWidth = 1;
    this._router.navigate([{
      outlets: { rightMainPopup: ["related-todo", item.id] }
    }]);
    return;
  }

  async bindDependencyData() {
    if (!this.dependentData) return;
    var dependency = [];
    for (var i = 0; i < this.dependentData.length; i++) {
      await this._todoService.details({ id: this.dependentData[i] }).toPromise().then((res: any) => {
        var dateTime = res.dueDate.split(" ");
        var date = dateTime[0].split("-");
        res.day = CommonMethods.daySuffix(date[2]);
        var priority = CommonMethods.todoPriority.filter((x: any) => x.id == res.priority);
        if (priority.length > 0) {
          res.priority = priority[0].name;
        }
        var status = CommonMethods.todoStatus.filter((x: any) => x.id == res.status);
        if (status.length > 0) {
          res.status = status[0].name;
        }
        res.categories = res.category.split(",");
        dependency.push({
          id: res.id,
          task: res.task,
          assignToMobile: res.assignToMobile,
          assignToName: res.assignToName,
          categories: res.categories,
          description: res.description,
          day: res.day,
          dueDate: res.dueDate,
          priority: res.priority,
          status: res.status,
          completeDate: res.completeDate
        });
      });
    }
    this.dependentTasks = dependency;
    console.log("dependency", dependency);
  }
}
