import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { UserModel } from '../Model/user.model';
import { UserService } from '../api-client/user.service';
import { BlockFormBuilder } from '../block-details/block.form-builder';
import { UiService } from '../ui.service';
import { CommonMethods } from '../common-methods';
import { LocationService } from '../api-client/location.service';
import { StaffFormBuilder } from './staff.form-builder';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { StaffService } from '../api-client/staff.service';
import { StaffModel } from '../Model/staff.model';
import { CommunityService } from '../api-client/community.service';
import { APP_CONST } from '../app-constants';

@Component({
  selector: 'app-staff-details',
  templateUrl: './staff-details.component.html',
  styleUrls: ['./staff-details.component.css']
})
export class StaffDetailsComponent implements OnInit {

  userForm: any;
  submitted = false;
  communityId: any;
  userId = null;
  userName: any;
  model = new StaffModel();
  countryId: any;
  countryName = "--select--";
  stateName = "--select--";
  staffRoles: any;
  roleList = [];
  role_id: any;
  deleteParams: any = {
    itemId: 0,
    itemName: null,
    deleteText: "Are you sure, you want to delete this staff?"
  };
  public config = <IDayCalendarConfig>{
    locale: "al",
    format: "YYYY-MM-DD"
  };
  error = [];
  constructor(
    private _tosterService: ToastrService,
    private _router: Router,
    private _communityService: CommunityService,
    private _route: ActivatedRoute,
    private _formBuilder: StaffFormBuilder,
    private _uiService: UiService,
    private _locationService: LocationService,
    private _staffService: StaffService
  ) { }

  ngOnInit() {

    this.userForm = this._formBuilder.getForm();
    this.roles();
    this.onChanges();
    this.leftMenu();
    this._route.params.subscribe(params => {
      if (params.id > 0) {
        this.details(params.id);
        //this.userId = params.id;
        /* this.userForm.get('email').clearValidators();
        this.userForm.get('email').updateValueAndValidity();

        this.userForm.get('mobile').clearValidators();
        this.userForm.get('mobile').updateValueAndValidity();

        this.userForm.get('roleId').clearValidators();
        this.userForm.get('roleId').updateValueAndValidity(); */
      } else {
        this.resetForm();
      }
    })
  }

  get f() { return this.userForm.controls; }

  details(id: number) {
    this._staffService.details(id).subscribe((result: any) => {
      this.communityId = result.communityId;
      this.userId = result.id;
      this.deleteParams.itemId = result.id;
      this.deleteParams.itemName = result.name;
      this.userName = result.name;
      this.countryId = result.countryId;
      this.role_id = result.roleId;
      this._formBuilder.setValues(result);
      if (result.countryId) {
        this._locationService.countryDetails(result.countryId).subscribe((country: any) => {
          this.countryName = country.countryName;
        });
      }

      if (result.stateId) {
        this._locationService.stateDetails(result.stateId).subscribe((state: any) => {
          this.stateName = state.stateName;
        });
      } else {
        this.stateName = "--select--";
      }
      setTimeout(() => {
        //this.disableFields();
      }, 1000);
    })
  }

  async onSubmit() {
    this.error = [];
    this.submitted = true;
    if (this.userForm.invalid) {
      return;
    }
    this.model.deserialize(this.userForm.value);
    this.model.communityId = Number(CommonMethods.getCommunityId());
    this.model.id = this.userId;
    this.model.profileCompleteness = 1;
    //console.log(this.model);return;
    //delete this.model.numberOfBuildings;
    await this._staffService.save(this.model)
      .toPromise().then(
        (response: StaffModel) => {
          if (response) {
            this._tosterService.success("Staff save");
            //this.changeRole();
            if (!this.userId) {
              this._router.navigateByUrl(`staff/${response.id}`);
            }
            this.error = [];
          }else{
            this.error.push("server error");
          }
        },
        error => {
          this._tosterService.error(error.message);
        }
      )
  }

  async save() {
    await this.onSubmit();
    if(this.error.length>0) return;
    setTimeout(() => {
      if (this.userId > 0) {
        this._router.navigateByUrl(`staff/${this.userId}`);
        return;
      }
    }, 1000);
  }

  async saveAndNew() {
    await this.onSubmit();
    if(this.error.length>0) return;
    if (!this.userForm.invalid) {      
      this.resetForm();
    }
  }

  deletePopUp() {
    if (this.userId > 0) {
      this._uiService.deleteItemData = this.deleteParams;
      this._uiService.deleteItemCallBack = this.delete.bind(this);
      this._router.navigate([{
        outlets: { centerPopup: ['delete-item'] }
      }]);
    }
  }

  delete(id: any) {
    if (this.deleteParams.pressBtn == "Ok") {
      this._staffService.delete(this.deleteParams.itemId).subscribe((result: any) => {
        this._tosterService.success("Staff Deleted");
        this.resetForm();
        return;
      })
    }
  }
  resetForm() {
    this.submitted = false;
    this.userId = null;
    this.userName = "";
    this.error = [];
    this.userForm.reset();
    this.populateAddress();
    setTimeout(() => {
      this._router.navigateByUrl("staff/0");
    }, 100)
  }

  onChanges(): void {
    this.userForm.get('countryId').valueChanges.subscribe((val: any) => {
      this.countryId = val;
    });
  }

  redirectionType(mode: any, displayType: any) {
    let params: any;
    if (displayType == 'listing') {
      if (mode == "staff") {
        params = [{ outlets: { rightMainPopup: ["staffs"] } }];
      } else if (mode == "dashboard") {
        this._router.navigateByUrl("/dashboard"); return;
      }
      this._router.navigate(params); return;
    }
  }

  async roles() {
    var communityId = Number(CommonMethods.getCommunityId());
    await this._communityService.roles(communityId).toPromise().then((result: any) => {
      this.roleList = result;
      var list = [];
      for (var i = 0; i < result.length; i++) {
        if (result[i].roleId < 60) {
          list.push(result[i]);
        }
      }
      this.staffRoles = list;
    });
  }

  changeRole() {
    if (!this.role_id) return;
    var data = { communityId: CommonMethods.getCommunityId(), userId: this.userId, roleId: this.role_id };
    //console.log(data); return;
    this._communityService.changeRole(data).subscribe((result: any) => {
      this._tosterService.success("Role changed");
      return;
    })
  }

  async populateAddress() {
    var address = CommonMethods.getCommunityDetails();
    address.name = "";
    address.id = null;
    address.openingBalance = null;
    address.mobile = null;
    this._formBuilder.setValues(address);
    if (address.countryId) {
      await this._locationService.countryDetails(address.countryId).toPromise().then((result: any) => {
        this.countryName = result.countryName;
      });
    }
    if (address.stateId) {
      await this._locationService.stateDetails(address.stateId).toPromise().then((result: any) => {
        this.stateName = result.stateName;
      });
    }
  }

  ngOnDestroy(): void {
    this._uiService.leftMenuItems.next([]);
  }

  disableFields() {
    this.userForm.disable();
  }

  leftMenu() {
    this._uiService.leftMenuItems.next([
      { code: 'F4', name: 'Save', action: this.save.bind(this), class: 'green lighten-1', privilege: APP_CONST.MEMBER_READ },
      { code: 'F5', name: 'Save & Add New', action: this.saveAndNew.bind(this), privilege: APP_CONST.MEMBER_WRITE },
      { code: 'F7', name: 'Delete', action: this.deletePopUp.bind(this), class: 'red lighten-1', privilege: APP_CONST.MEMBER_REMOVE }
    ]);
  }
}
