import { Deseriazable } from './deserializable.model';

export class BlockModel implements Deseriazable
{
    id: number;
    communityId: number;
    communityBlockCode: string;
    communityBlockName: string;
    communityBlockRepresentative: number;

    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }
}