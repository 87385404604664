import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../api-client/user.service';
import { UiService } from '../ui.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DiscountTemplateService } from '../api-client/discount-template.service';
import { LateFeeTemplateService } from '../api-client/late-fee-template.service';

@Component({
  selector: 'app-late-fee-templates',
  templateUrl: './late-fee-templates.component.html',
  styleUrls: ['./late-fee-templates.component.css']
})
export class LateFeeTemplatesComponent implements OnInit {

  templates:any;
  filterTemplates: any;
  constructor(
    private _tosterService: ToastrService,
    private _userService: UserService,
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _lateFeeTemplateService: LateFeeTemplateService
  ) { }

  ngOnInit() {
    this.list();
  }

  list(){
    this._lateFeeTemplateService.all().subscribe((result:any)=>{
        this.templates = result;
        this.filterTemplates = this.templates;
    })
  }

  filtertemplate(ele: any) {
    var items: any;
    if (ele.value) {
      this.filterTemplates = this.templates.filter((x: any) => x.name.toLowerCase().includes(ele.value.toLowerCase()));
    } else {
      //this.list();
    }
  }

  redirectTo(item:any){
    if (this._uiService.activeListCallBack) {
      this._uiService.activeListCallBack(item.id, item.name);
      this._uiService.activeListCallBack = null;
      this._uiService.closeItself();
    } else {
      this._router.navigateByUrl(`late-fee-details/${item.id}`);
      return false;
    }
  }

}
