import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { ToastrService } from 'ngx-toastr';
import { BlockService } from '../api-client/block.service';
import { VoucherService } from '../api-client/voucher.service';
import { IDayCalendarConfig } from 'ng2-date-picker';

@Component({
  selector: 'app-block-income-details-report',
  templateUrl: './block-income-details-report.component.html',
  styleUrls: ['./block-income-details-report.component.css']
})
export class BlockIncomeDetailsReportComponent implements OnInit {

  communityId: number = parseInt(CommonMethods.getCommunityId());
  blockId: number = null;
  blockSelectedText: string = '';
  dateFrom: any;
  dateTo: any;
  accountGroup: string;
  public config = <IDayCalendarConfig>{
    format: "DD-MM-YYYY"
  };

  constructor(private _route: ActivatedRoute,
    private _tosterService: ToastrService,
    private _blockService: BlockService,
    private _voucherService: VoucherService) { }

  ngOnInit() {
  }

  clearDateFrom(): void {
    this.dateFrom = null;
  }

  clearDateTo(): void {
    this.dateTo = null;
  }

  /*---Triggered when block changed from filter---*/
  blockChanged(): void {
    if(this.blockId > 0){
      this._blockService.details(this.blockId).subscribe((result:any)=>{
        this.blockSelectedText = result.communityBlockName;
      }, error => {
        this.blockSelectedText = null;
      });
    }else{
      this.blockSelectedText = null;
    }
  }

  /*---Clear all selected data---*/
  clearSelection(): void {
    this.blockSelectedText = null;
    this.blockId = null;
    this.dateFrom = null;
    this.dateTo = null;
  }

  /*---Export statement---*/
  export(): void {
    if(this.blockId > 0){
      let data = {
        communityId: this.communityId,
        fromDate: this.dateFrom?CommonMethods.dbToUsDate(this.dateFrom):null,
        toDate: this.dateTo?CommonMethods.dbToUsDate(this.dateTo):null,
        blockId: this.blockId
      }
      this._voucherService.exportBlockDetailsReport(data);
    }else{
      this._tosterService.error("Please select a block first.");
    }
  }

}
