import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject,Observable, of  } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../config';
import { BookingModel } from '../Model/booking.model';

@Injectable({
  providedIn: 'root'
})
export class BookingService {  

  constructor(private _http: HttpClient) {
  }

  all(data:any){
    return this._http
      .post(config.apiEndPointBase + "/booking/list", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  save(data:any) {
    if(data.id){
      return this._http.put(config.apiEndPointBase + "/booking",data, {responseType: 'text'})
      .pipe(map((response:any) => {
        return response;
      }));
    }else{
      return this._http.post(config.apiEndPointBase + "/booking",data, {responseType: 'text'})
      .pipe(map((response:any) => {
        return response;
      }));
    }
  }

  details(bookingId:any){
    return this._http.get(config.apiEndPointBase + `/booking/${bookingId}`);
  }

  listofOwn(data:any){
    return this._http
      .post(config.apiEndPointBase + `/booking/my_booking_list`, data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  cancel(data: any) {
    return this._http.put(config.apiEndPointBase + "/booking/update_status", data, { responseType: 'text' })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  availibility(data: any) {
    return this._http.post(config.apiEndPointBase + "/booking/amenity_availability", data)
      .pipe(map((response: any) => {
        return response;
      }));
  }
}