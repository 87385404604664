import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { UiService } from './ui.service';
import { PreviousRouteService } from './api-client/previous-route.service';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ekata';
  isLogin: boolean = true;
  isCommunitySelected: boolean = true;
  unknownConnectionIssue:any = null;
  
  private _headerShowSubscription: Subscription;
  private _communityShowSubscription: Subscription;
  private _unknownConnectionIssueSubscription: Subscription;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _uiService: UiService,
    private _toster: ToastrService,
    private _previousRouteService: PreviousRouteService
  ) {
    document.onkeydown = this.handleKeyboardEvent.bind(this);
  }

  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode == 27) {
      let leftPop = document.querySelector('.left-pop');
      let rightPop = document.querySelector('.right-pop');  
      if (leftPop!=null) {
        this.closeLeftPopup();
      } else if (rightPop!=null) {
        this.closeQuickSummary();
      } else if(this._uiService.useEscToBack) {
        window.history.back();
      }
      return false;
    }

    var keysPresed = [];
    if (event.ctrlKey) {
      keysPresed.push('Ctrl');
    }
    if (event.altKey) {
      keysPresed.push('Alt');
    }
    if (event.shiftKey) {
      keysPresed.push('Shift');
    }

    if (keysPresed.length > 0 || (event.keyCode >= 112 && event.keyCode <= 123)) {
      keysPresed.push(event.key.toUpperCase());
      let shortCode = keysPresed.join('+');
      let element = document.querySelector(`[short-code="${shortCode}"]`) as HTMLElement;
      if (element) {
        element.click();
        event.preventDefault();
        event.stopPropagation();
        event.cancelBubble = true;
        return false;
      }
    }
    return true;
  }

  ngOnInit(): void {
    this._router.events.subscribe((url: any) => {
      let getUrl = this._router.url;
      let explode = getUrl.split("/");
      if (explode.indexOf("login") > -1 || explode.indexOf("reset-password") > -1) {
        this.isLogin = false;
      }
      if (explode.indexOf("community") > -1) {
        this.isCommunitySelected = false;
      }
    });
    this._headerShowSubscription = this._uiService.isLogin.subscribe(status => {
      setTimeout(() => {
        this.isLogin = status;
      }, 100);
    });
    this._communityShowSubscription = this._uiService.isCommunitySelected.subscribe(status => {
      setTimeout(() => {
        this.isCommunitySelected = status;
      }, 100);
    });

    this._unknownConnectionIssueSubscription = this._uiService.unknownConnectionIssue.subscribe(issue => {
      this.unknownConnectionIssue = issue;
    });
    
  }

  closeQuickSummary() {
    this._router.navigate(
      [
        "../",
        {
          outlets: {
            rightMainPopup: null
          }
        }
      ],
      {
        relativeTo: this._route
      }
    );
  }

  closeLeftPopup() {
    this._router.navigate(
      [
        "../",
        {
          outlets: {
            leftMainPopup: null
          }
        }
      ],
      {
        relativeTo: this._route
      }
    );
  }

  hideCenterPopup() {
    this._router.navigate(
      [
        "../",
        {
          outlets: {
            centerPopup: null
          }
        }
      ],
      {
        relativeTo: this._route
      }
    );
  }

  fullOutletActivate() {
    setTimeout(() => {
      let firstInput = document.querySelector('.full-outlet input');
      if (firstInput) {
        (firstInput as HTMLElement).focus();
      }
    }, 500);
  }

  rightMainPopupActivate() {
    setTimeout(() => {
      let firstInput = document.querySelector('.quick-summary input');
      if (firstInput) {
        (firstInput as HTMLElement).focus();
      }
    }, 500);
  }

  reloadPage(){
    window.location.href = window.location.href;
  }
  ngOnDestroy(): void {
    this._headerShowSubscription.unsubscribe();
    this._communityShowSubscription.unsubscribe();
    this._unknownConnectionIssueSubscription.unsubscribe();
  }
}
