import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonMethods } from '../common-methods';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardService implements CanActivate {

  constructor(private _router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let authkey = CommonMethods.getItem("authKey");
    let udid = CommonMethods.getItem("UDID");
    let communityId = CommonMethods.getItem("communityId"); 

    if (authkey && udid) {
      this._router.navigate(["community"]);
      return false;
    }
    return true;
  }
}
