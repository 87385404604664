import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../../app/config';
import { BuildingModel } from './../Model/building.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BuildingService {

  private societyInvoiceSubject: BehaviorSubject<any>;
  public societyInvoice: Observable<any>;
  constructor(private _http: HttpClient) {
    this.societyInvoiceSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('societyInvoice')));
    this.societyInvoice = this.societyInvoiceSubject.asObservable();
  }

  all(data: any) {
    return this._http
      .post(config.apiEndPointBase + "/building/listByCommunityBlock", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  buildingsBycommunity(data: any) {
    return this._http
      .post(config.apiEndPointBase + "/building/list", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  save(data: BuildingModel): Observable<BuildingModel> {
    if (data.id) {
      return this._http.put(config.apiEndPointBase + "/building", data, { responseType: 'text' })
        .pipe(map((response: any) => {
          return new BuildingModel().deserialize(response);
        }));
    } else {
      return this._http.post(config.apiEndPointBase + "/building", data, {})
        .pipe(map((response: any) => {
          return new BuildingModel().deserialize(response);
        }));
    }
  }

  details(id: number) {
    return this._http.get(config.apiEndPointBase + `/building/${id}`);
  }

  getBlockByBuildingId(data: any) {
    return this._http
      .post(config.apiEndPointBase + "/building/getBlock", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  delete(id: number) {
    return this._http.delete(config.apiEndPointBase + `/building/${id}`, { responseType: 'text' });
  }

  storeSocietyFilterData(data:any){
    localStorage.setItem('societyInvoice', JSON.stringify(data));
    this.societyInvoiceSubject.next(data);
  }

  removeSocietyFilterData(){
    localStorage.removeItem('societyInvoice');
    this.societyInvoiceSubject.next(null);
  }
}
