import { Component, OnInit } from '@angular/core';
import { CommonMethods } from '../common-methods';
import { ToastrService } from 'ngx-toastr';
import { CommunityService } from '../api-client/community.service';
import { UiService } from '../ui.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../api-client/user.service';

@Component({
  selector: 'app-staffs',
  templateUrl: './staffs.component.html',
  styleUrls: ['./staffs.component.css']
})
export class StaffsComponent implements OnInit {

  staffs: any;
  filteredStaffs: any;
  routeParams: any;

  constructor(
    private _tosterService: ToastrService,
    private _communityService: CommunityService,
    private _userService: UserService,
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit() {
    this.list();
  }

  list() { 
    let data = {
      communityId: CommonMethods.getCommunityId()
    };   
    this._userService.members(data).subscribe((result: any) => {
      this.staffs = result;
      this.filteredStaffs = this.staffs.data;
    })
    this._route.params.subscribe(data => {
      this.routeParams = data;
    })
  }

  filterStaff(eve: any) {
    var code = eve.keyCode;
    if(code == 37 || code == 38 || code == 39 || code == 40) {
        return;
    }
    var ele = eve.target;
    var items: any;
    if (ele.value) {
      this.filteredStaffs = this.staffs.filter((x: any) => x.name.toLowerCase().includes(ele.value.toLowerCase()));
    } else {
      //this.list();
    }
  }

  details(item: any) {
    
        if (this._uiService.activeListCallBack) {
          this._uiService.activeListCallBack(item.id, item.name);
          this._uiService.activeListCallBack = null;
          this._uiService.closeItself();
          return;
        }
        this._router.navigateByUrl("staff/" + item.id);
  }

  redirectTo(item: any) {
    this._router.navigateByUrl("staff/" + item.id);
  }

}
