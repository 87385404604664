import { config } from './../config';
import { Component, OnInit } from '@angular/core';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { UserService } from '../api-client/user.service';
import { UiService } from '../ui.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { MaintenanceLogModel } from '../Model/maintenance-log.model';
import { MaintenanceLogFormBuilder } from './maintenancelog.form-builder';
import { CommonMethods } from '../common-methods';
import { MaintenanceLogService } from '../api-client/maintenancelog.service';
import { LedgerService } from '../api-client/ledger.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { VoucherService } from '../api-client/voucher.service';
import { CategoryService } from '../api-client/category.service';
import { APP_CONST } from '../app-constants';

@Component({
  selector: 'app-maintenance-log',
  templateUrl: './maintenance-log.component.html',
  styleUrls: ['./maintenance-log.component.css']
})
export class MaintenanceLogComponent implements OnInit {

  submitted = false;
  disabled = false;
  model = new MaintenanceLogModel();
  logId = null;
  maintenanceLogForm: any;
  vouchers: any;
  deletedAt: boolean = false;
  vendorName = "--select--";
  voucherName = "--select--";
  tags: any;
  linkItemType = null;
  linkItemName = null;
  routeExtras: NavigationExtras;

  public configDateTime = <IDayCalendarConfig>{
    format: "YYYY-MM-DD HH:mm:ss"
  };
  public configDate = <IDayCalendarConfig>{
    format: "YYYY-MM-DD"
  };
  deleteVisiteLogParams: any = {
    itemId: 0,
    itemName: null,
    deleteText: "Are you sure, you want to cancle this log?"
  }

  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  APP_CONST = APP_CONST;
  constructor(
    private _userService: UserService,
    private _uiService: UiService,
    private _tosterService: ToastrService,
    private _router: Router,
    private _formBuilder: MaintenanceLogFormBuilder,
    private _ledgerService: LedgerService,
    private _maintenanceService: MaintenanceLogService,
    private _voucherService: VoucherService,
    private _categoryService: CategoryService,
    private _route: ActivatedRoute) { }

  ngOnInit() {
    this.typeWisevouchers();
    this.categories();
    this.maintenanceLogForm = this._formBuilder.getForm();
    this._route.params.subscribe(params => {
      if (params.id > 0) {
        var data = { communityId: CommonMethods.getCommunityId(), logId: params.id };
        this.details(data);
        this.disableAllFields();
      } else {
        this.maintenanceLogForm.reset();
        this.enableAllFields();
      }
    });
  }

  get f() { return this.maintenanceLogForm.controls; }

  async details(data: any) {
    await this._maintenanceService.details(data).toPromise().then((result: any) => {
      this.logId = result.id;
      if (result.linkItemTypeId) {
        var linkType = CommonMethods.linkItemTypes.filter((x: any) => x.id == result.linkItemTypeId);
        if (linkType.length > 0) {
          this.linkItemType = linkType[0].name;
        }
      }
      if (result.linkItemName) {
        this.linkItemName = result.linkItemName;
      }
      if (result.deletedAt) {
        this.deletedAt = true;
      }
      if (result.linkedVoucherId) {
        this._voucherService.details(result.linkedVoucherId).toPromise().then((result: any) => {
          this.voucherName = result.voucherNumber;
        })
      }
      if (result.vendorId) {
        this._ledgerService.details(result.vendorId).toPromise().then((result: any) => {
          this.vendorName = result.ledgerName;
        })
      }
      if (result.category) {
        var tags = [];
        var category = result.category.split(",");
        for (var i = 0; i < category.length; i++) {
          tags.push({ id: category[i], name: category[i] });
        }
        result.category = tags;
      }
      this.disabled = true;
      this._formBuilder.setValues(result);
    })
  }

  onSubmit() {
    this.submitted = true;
    if (this.maintenanceLogForm.invalid) {
      return;
    }
    if (this.maintenanceLogForm.get('nextActionRequired').value) {
      if (this.maintenanceLogForm.get('nextActionDate').value == "") {
        this._tosterService.error("Next action date required");
        return;
      }
    }
    this.model.id = this.logId;
    this.model.deserialize(this.maintenanceLogForm.value);

    this.model.communityId = Number(CommonMethods.getCommunityId());
    this.model.linkItemId = this._uiService.linkItem.id;
    this.model.linkItemTypeId = this._uiService.linkItem.type;
    this.model.linkItemName = this._uiService.linkItem.name;
    //console.log(this.model); return;
    if (this.maintenanceLogForm.get('category').value) {
      var result = this.maintenanceLogForm.get('category').value;
      var tags = [];
      for (var i = 0; i < result.length; i++) {
        tags.push(result[i].name);
      }
      this.model.category = tags.join(",");
    }
    this._maintenanceService.save(this.model)
      .subscribe(
        (response: any) => {
          this._uiService.maintenanceDataReload.next(true);
          this._tosterService.success("Log save");
          this._uiService.closeLeftPopUp();
          return false;
        },
        error => {
          this._tosterService.error(error.message);
        }
      )
  }

  categories() {
    var data = { communityId: CommonMethods.getCommunityId() };
    this._categoryService.all(data).subscribe((result: any) => {
      for (var i = 0; i < result.length; i++) {
        result[i].id = result[i].name;
      }
      this.tags = result;
    });
  }

  onFileChange(event: any) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          console.log(event.target.result);
        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  typeWisevouchers() {
    var data = { communityId: CommonMethods.getCommunityId(), type: 8 };
    var voucherType = 8;
    this._voucherService.listByType(voucherType, data).subscribe((result: any) => {
      this.vouchers = result;
    })
  }


  deleteLogPopUp(item: any) {
    if (!item.id) return;
    this.deleteVisiteLogParams.itemId = item.id;
    this.deleteVisiteLogParams.itemName = "Log";
    this._uiService.deleteItemData = this.deleteVisiteLogParams;
    this._uiService.deleteItemCallBack = this.changeStatus.bind(this);
    this._router.navigate([{
      outlets: { centerPopup: ['delete-item'] }
    }]);
  }

  changeStatus() {
    if (this.deleteVisiteLogParams.pressBtn == "Ok") {
      var today = new Date();
      var date = today.getFullYear() + '-' + ((today.getMonth() + 1) > 9 ? (today.getMonth() + 1) : ('0' + (today.getMonth() + 1))) + '-' + (today.getDate() > 9 ? today.getDate() : '0' + today.getDate()) + " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

      var data = { communityId: CommonMethods.getCommunityId(), logId: this.logId, deleteAt: date };
      //console.log(data);return;
      this._maintenanceService.delete(data).subscribe((result: any) => {
        this._uiService.maintenanceDataReload.next(true);
        this._tosterService.success("Log cancelled");
        this.deletedAt = false;
        this._uiService.closeLeftPopUp();
        /* setTimeout(() => {
          this._router.navigateByUrl(`resource/${this._uiService.linkItem.id}`);
        }, 100) */

        return false;
      })
    }
  }

  disableAllFields() {
    this.maintenanceLogForm.disable();
  }
  enableAllFields() {
    this.maintenanceLogForm.enable();
  }

}
