import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject,Observable, of  } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../../app/config';

@Injectable({
  providedIn: 'root'
})
export class LocationService {  

  constructor(private _http: HttpClient) {
  }
  
  allCountry(){
    return this._http.get(config.apiEndPointBase + `/countries`);
  }

  countryDetails(id:number){
    return this._http.get(config.apiEndPointBase + `/country/${id}`);
  }

  stateDetails(id:number){
    return this._http.get(config.apiEndPointBase + `/state/${id}`);
  }

  districtsByState(stateId:number){
    return this._http.get(config.apiEndPointBase + `/districts/${stateId}`);
  }

  statesByCountry(countryId:number){
    return this._http.get(config.apiEndPointBase + `/states/${countryId}`);
  }
}
