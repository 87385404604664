import { Component, OnInit } from '@angular/core';
import { CommonMethods } from '../common-methods';
import { StaffModel } from '../Model/staff.model';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../ui.service';
import { ProfileFormBuilder } from './profile.form-builder';
import { LocationService } from '../api-client/location.service';
import { StaffService } from '../api-client/staff.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  userForm: any;
  submitted = false;
  communityId: any;
  userId = null;
  userName: any;
  model = new StaffModel();
  countryId: any;
  countryName = "--select--";
  stateName = "--select--";
  staffRoles: any;
  deleteParams: any = {
    itemId: 0,
    itemName: null,
    deleteText: "Are you sure, you want to delete this staff?"
  };
  public config = <IDayCalendarConfig>{
    format: "YYYY-MM-DD"
  };

  constructor(
    private _tosterService: ToastrService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _formBuilder: ProfileFormBuilder,
    private _uiService: UiService,
    private _locationService: LocationService,
    private _staffService: StaffService
  ) { }

  ngOnInit() {

    this._uiService.leftMenuItems.next([
      { code: 'F4', name: 'Save', action: this.onSubmit.bind(this), class: 'green lighten-1' },
      //{ code: 'F5', name: 'Save & Add New', action: this.onSubmitAndNew.bind(this) },
      //{ code: 'F7', name: 'Delete', action: this.deletePopUp.bind(this), class: 'red lighten-1' }
    ]);
    this.userForm = this._formBuilder.getForm();
    this.details();
    this.onChanges();
  }

  get f() { return this.userForm.controls; }

  details() {
    this._staffService.getMyProfile().subscribe((result: any) => {
      this.communityId = result.communityId;
      this.userId = result.id;
      this.userName = result.name;
      this.countryId = result.countryId;
      this._formBuilder.setValues(result);
      if (result.countryId) {
        this._locationService.countryDetails(result.countryId).subscribe((country: any) => {
          this.countryName = country.countryName;
        });
      }

      if (result.stateId) {
        this._locationService.stateDetails(result.stateId).subscribe((state: any) => {
          this.stateName = state.stateName;
        });
      } else {
        this.stateName = "--select--";
      }

    })
  }

  onSubmit() {
    this.submitted = true;
    if (this.userForm.invalid) {
      return;
    }
    this.model.deserialize(this.userForm.value);
    this.model.communityId = Number(CommonMethods.getCommunityId());
    this.model.id = this.userId;
    this.model.profileCompleteness = 1;
    //console.log(this.model);return;
    //delete this.model.numberOfBuildings;
    this._staffService.profile(this.model)
      .subscribe(
        (response: StaffModel) => {
          this._tosterService.success("Profile save"); 
          CommonMethods.setProfile(this.model);
          return false;
        },
        error => {
          this._tosterService.error(error.message);
        }
      )
  }

  onSubmitAndNew() {
    this.onSubmit();
    if (!this.userForm.invalid) {
      this.submitted = false;
      this.resetForm();
    }
  }

  
  resetForm() {
    this.userId = null;
    this.userName = "";
    this.userForm.reset();
    setTimeout(() => {
      this._router.navigateByUrl("staff/0");
    }, 100)
  }

  onChanges(): void {
    this.userForm.get('countryId').valueChanges.subscribe((val: any) => {
      this.countryId = val;
      console.log("country",val);
    });
  }  

  redirectionType(mode: any, displayType: any) {
    let params: any;
    if (displayType == 'listing') {
      if (mode == "staff") {
        params = [{ outlets: { rightMainPopup: ["staffs"] } }];
      } else if (mode == "dashboard") {
        this._router.navigateByUrl("/dashboard"); return;
      }
      this._router.navigate(params); return;
    }
  }

  ngOnDestroy(): void {
    this._uiService.leftMenuItems.next([]);
  }

}
