import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { UiService } from '../ui.service';
import { APP_CONST } from '../app-constants';
import { VoucherModel } from '../Model/voucher.model';
import { ToastrService } from 'ngx-toastr';
import { CommunityService } from '../api-client/community.service';
import { LedgerService } from '../api-client/ledger.service';
import { VoucherService } from '../api-client/voucher.service';
import { PropertyService } from '../api-client/property.service';
import { BuildingService } from '../api-client/building.service';
import { ResourceService } from '../api-client/resource.service';
import { VoucherTemplateService } from '../api-client/voucher-template.service';
import { LateFeeTemplateService } from '../api-client/late-fee-template.service';
import { DiscountTemplateService } from '../api-client/discount-template.service';
import { config } from '../config';
import { IDayCalendarConfig } from 'ng2-date-picker';

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.css']
})
export class InvoiceDetailsComponent implements OnInit, OnDestroy {

  invoiceType: any;
  submitted = false;
  model = new VoucherModel();
  id: number;
  voucherNumber: string = '';
  voucherType: number;
  voucherFromName: string = '';
  voucherFromAddress: string = '';
  voucherToName: string = '';
  voucherToAddress: string = '';
  narration: string = '';
  voucherDate: string = CommonMethods.usDateToDb(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
  paymentType: string;
  status: string = "Pending";
  ledgerToId: number = 0;
  ledgerFromId: number = 0;
  totalGrossAmount: number;
  applicableOnceInPeriod: number = 0;
  periodStartFrom: string = null;
  periodEndOn: string = null;
  totalNetAmount: number = 0;
  pendingAmount: number = 0;
  propertyId: number = null;
  propertyTypeId: number = 0;
  communityId: number;
  manualDueDate: string = CommonMethods.usDateToDb(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
  ledgerDefaultGroup = "";
  billingLineItems = [];
  isPeriodic: number = 0;
  communityName: string;
  communityAddress: string;
  propertyTypes: any;
  defaultAccountSelectedText: any = 'Select Party Account';
  accountSelectedText: any = '';
  accountSelectionType: any = 'owners';
  propertyObj: any = { id: null, name: "" };
  accountObj: any = { id: 0, name: "" };
  invoiceTotal: number = 0;
  defaultPropertySelectedText: any = 'Select Property / Asset';
  propertySelectedText: any = '';
  lineItemDescriptions = [];
  templateId: number = null;
  templateSelectedText: any = 'Select Invoice Template';
  lateFeeTemplateId: number = null;
  defaultLateFeeSelectedText: any = 'Select Late Fee Template';
  latefeeSelectedText: any = '';
  discountTemplateId: number = null;
  defaultDiscountSelectedText: any = 'Select Discount Template';
  discountSelectedText: any = '';
  selectedBlockId: number = null;
  propertyUrl: any;
  templateIsActive: any;
  templateVoucherType: any;
  templateAppliedForBlockId: any;
  templatePropertyType: any;
  templateIsPropertyType: any;
  templateIsPropertyTypeDisabled: any;
  templateCreditPeriodDays: any;
  templateSendToOwners: any;
  templateSendToTenant: any;
  templateBlockId: any;
  templatePeriods = [];
  templateLineItems: any = [];
  eligiblePeriods: any = [];
  units: any;
  public config = <IDayCalendarConfig>{
    format: "DD-MM-YYYY"
  };
  listName: string = "";
  selectedTypeName: string = "";
  particularsSelectedText: any = 'Select Particular';
  unitsSelectedText: any = 'Select Unit';
  allowedAccountableLedger: number = null;
  allowedAccountableBlocks = [];
  validatedAll: boolean = true;
  periodMonthDiff: number = 0;
  itemsInitialized: boolean = false;
  communitySettings: any = null;
  societyInvoiceType: number = APP_CONST.INVOICE_TYPE_SOCIETY;
  rentInvoiceType: number = APP_CONST.INVOICE_TYPE_RENT;
  purchaseInvoiceType: number = APP_CONST.INVOICE_TYPE_PURCHASE;
  assetPropertyType: number = APP_CONST.PROPERTY_TYPE_ASSETS;
  buildingPropertyType: number = APP_CONST.PROPERTY_TYPE_BUILDINGS;

  readPrivilege: string;
  writePrivilege: string;
  trashPrivilege: string;
  removePrivilege: string;
  payPrivilege: string;
  checkEachMonth: any;
  lastResult: any;
  eachMonthCheckBox:boolean;
  invoiceTypeId: number = 0;
  constructor(private _uiService: UiService,
    private _router: Router,
    private _route: ActivatedRoute,
    private datePipe: DatePipe,
    private _tosterService: ToastrService,
    private _communityService: CommunityService,
    private _ledgerService: LedgerService,
    private _propertyService: PropertyService,
    private _buildingService: BuildingService,
    private _resourceService: ResourceService,
    private _voucherService: VoucherService,
    private _voucherTemplateService: VoucherTemplateService,
    private _lateFeeTemplateService: LateFeeTemplateService,
    private _discountTemplateService: DiscountTemplateService) {
    /*---Get invoice template if selected any---*/
    if (this._router.getCurrentNavigation().extras.state) {
      var state = this._router.getCurrentNavigation().extras.state;
      if (state.templateId) {
        this.templateId = parseInt(state.templateId);
      }
      if (state.propertyId) {
        this._propertyService.details(state.propertyId).subscribe((res: any) => {
          this.propertyObj = { id: state.propertyId, name: res.unitNo }
          this.propertySelectedText = res.unitNo;
          this.propertyId = state.propertyId;
          this.changeProperty(res.propertyType);
        });
      }
      this._uiService.activeListCallBack = null;
      this._uiService.closeItself();
    }
  }

  ngOnInit(): void {
    this.eachMonthCheckBox=true;
    this.getPropertyTypes();
    this.propertyTypeChanged();
    this.getUnits();
    this.getCommunitySettings();
    this._route.data.subscribe(params => {
      this.invoiceType = parseInt(params.invoiceType);
      this.voucherType = this.invoiceType;
      this.communityId = parseInt(CommonMethods.getCommunityId());
      this._communityService.details(this.communityId).subscribe((result: any) => {
        this.communityName = result.name;
        this.communityAddress = result.address;
        if (this.invoiceType == this.societyInvoiceType || this.invoiceType == this.rentInvoiceType) {
          this.ledgerDefaultGroup = "Income";
          this.accountSelectionType = "owners";
          this.voucherFromName = this.communityName;
          this.voucherFromAddress = this.communityAddress;
        } else {
          this.ledgerDefaultGroup = "Expenses";
          this.accountSelectionType = "vendors";
          this.voucherToName = this.communityName;
          this.voucherToAddress = this.communityAddress;
        }
      }, error => {
        this._tosterService.error(error.message);
      });
    });
    this._route.params.subscribe(params => {
      if (this.invoiceType == this.societyInvoiceType) {
        this.listName = "Society Invoices";
        this.selectedTypeName = "Society Invoice";
        this.readPrivilege = APP_CONST.SOCIETY_INVOICE_READ;
        this.writePrivilege = APP_CONST.SOCIETY_INVOICE_WRITE;
        this.trashPrivilege = APP_CONST.SOCIETY_INVOICE_TRASH;
        this.removePrivilege = APP_CONST.SOCIETY_INVOICE_REMOVE;
        this.payPrivilege = APP_CONST.COLLECTION_WRITE;
      } else if (this.invoiceType == this.rentInvoiceType) {
        this.listName = "Tenant Invoices";
        this.selectedTypeName = "Tenant Invoice";
        this.readPrivilege = APP_CONST.SOCIETY_INVOICE_READ;
        this.writePrivilege = APP_CONST.SOCIETY_INVOICE_WRITE;
        this.trashPrivilege = APP_CONST.SOCIETY_INVOICE_TRASH;
        this.removePrivilege = APP_CONST.SOCIETY_INVOICE_REMOVE;
        this.payPrivilege = APP_CONST.COLLECTION_WRITE;
      } else {
        this.listName = "Purchase Invoices";
        this.selectedTypeName = "Purchase Invoice";
        this.readPrivilege = APP_CONST.PURCHASE_INVOICE_READ;
        this.writePrivilege = APP_CONST.PURCHASE_INVOICE_WRITE;
        this.trashPrivilege = APP_CONST.PURCHASE_INVOICE_TRASH;
        this.removePrivilege = APP_CONST.PURCHASE_INVOICE_REMOVE;
        this.payPrivilege = APP_CONST.PAYMENT_WRITE;
      }
      if (params.id) {
        this.id = params.id;
        this.eachMonthCheckBox=false;
        this.getInvoice(params.id);
      } else {
        this.addNewRow();
        this._uiService.leftMenuItems.next([
          { code: 'F2', name: this.listName, action: this.goToList.bind(this), privilege: this.readPrivilege },
          { code: 'F4', name: 'Save', action: this.onSubmit.bind(this), class: 'green lighten-1', privilege: this.writePrivilege },
          { code: 'F5', name: 'Save & New', action: this.onSubmitAndNew.bind(this), privilege: this.writePrivilege }
        ]);
        if (this.templateId > 0) {
          this.selectTemplate(this.templateId);
          
        }else{
          this.eachMonthCheckBox=false;
          this.invoiceTypeId = 0;
          this.narration = "Bill of " + this.propertyObj.name;
        }
      }
    });
  }

  /*---Get all Property Types from DB---*/
  getPropertyTypes(): void {
    this._propertyService.types().subscribe((result: any) => {
      this.propertyTypes = result;
    }, error => {
      this._tosterService.error(error.message);
    });
  }

  /*---Get all Units from DB---*/
  getUnits(): void {
    this._voucherService.measurementUnits().subscribe((result: any) => {
      this.units = result;
    }, error => {
      this._tosterService.error(error.message);
    });
  }

  /*---Get community settings from DB---*/
  getCommunitySettings(){
    var communityId = parseInt(CommonMethods.getCommunityId());
    this._communityService.getSettings(communityId).subscribe((result)=>{
      this.communitySettings = result;
    }, error => {
      this._tosterService.error(error.message);
    });
  }

  /*---Initialize new line item---*/
  newLineItems(): void {
    this.billingLineItems = [];
  }

  /*---Add new line item row---*/
  addNewRow(): void {
    var item = {
      rate: 0,
      quantity: 0,
      unit: '',
      description: '',
      itemGrossAmount: 0,
      debitToAccountId: 0,
      creditToAccountId: 0,
      account: { id: 0, name: "" },
      unitObj: { id: 0, name: "" }
    }
    this.billingLineItems.push(item);
  }

  /*---Delete line item row and calculate invoice total---*/
  deleteItem(item: any): void {
    this.billingLineItems.splice(this.billingLineItems.indexOf(item), 1);
    this.invoiceTotalChange();
  }

  /*---Populate Due Date as per Voucher Date---*/
  populateDueDate(): void {
    if (this.periodStartFrom && this.templateId && this.templateId > 0) {
      var manualDueDateObj = new Date(new Date(CommonMethods.dbToUsDate(this.periodStartFrom) + ' 00:00:00').getTime() + this.templateCreditPeriodDays * 24 * 60 * 60 * 1000);
      var manualMonth: any = manualDueDateObj.getMonth() + 1;
      var manualDay: any = manualDueDateObj.getDate();
      if (manualMonth < 10) {
        manualMonth = '0' + manualMonth;
      }
      // Check if due day has been fixed from settings
      if(this.communitySettings && this.communitySettings.fixedDueDay && this.communitySettings.fixedDueDay > 0){
        let diff = this.communitySettings.fixedDueDay - manualDay;
        manualDay = manualDay+diff;
      }
      if (manualDay < 10) {
        manualDay = '0' + manualDay;
      }
      this.manualDueDate = CommonMethods.usDateToDb(manualDueDateObj.getFullYear() + '-' + manualMonth + '-' + manualDay);
    }
  }

    /*---Populate Due Date as per Voucher Date---*/
    populateMonthlyDueDate(j:any) {
      if (j && this.templateId && this.templateId > 0) {
        var manualDueDateObj = new Date(new Date(j + ' 00:00:00').getTime() + this.templateCreditPeriodDays * 24 * 60 * 60 * 1000);
        var manualMonth: any = manualDueDateObj.getMonth() + 1;
        var manualDay: any = manualDueDateObj.getDate();
        if (manualMonth < 10) {
          manualMonth = '0' + manualMonth;
        }
        // Check if due day has been fixed from settings
        if(this.communitySettings && this.communitySettings.fixedDueDay && this.communitySettings.fixedDueDay > 0){
          let diff = this.communitySettings.fixedDueDay - manualDay;
          manualDay = manualDay+diff;
        }
        if (manualDay < 10) {
          manualDay = '0' + manualDay;
        }
        this.manualDueDate = CommonMethods.usDateToDb(manualDueDateObj.getFullYear() + '-' + manualMonth + '-' + manualDay);
        console.log(this.manualDueDate);
        return  manualDueDateObj.getFullYear() + '-' + manualMonth + '-' + manualDay;
      }
    }

  /*---Triggered when Property Type is changed---*/
  propertyTypeChanged(): void {
    if (this.propertyTypeId == this.assetPropertyType) {
      this.propertyUrl = { "mode": "ResourceSelections" };
    } else if (this.propertyTypeId == this.buildingPropertyType) {
      this.propertyUrl = { "mode": "BuildingSelections" };
    } else {
      this.propertyUrl = { "mode": "PropertySelections", "type": this.propertyTypeId };
    }
  }

  /*---Triggered when Once In A Period checkbox clicked---*/
  changeIsPeriodic(elem: any): void {
    if (elem.checked) {
      this.isPeriodic = 1;
    } else {
      this.isPeriodic = 0;
    }
  }

  clearPeriodStart(): void {
    this.periodStartFrom = null;
  }

  clearPeriodEnd(): void {
    this.periodEndOn = null;
  }

  changeEachMonth(event): void {
    this.checkEachMonth = event.target.checked;
    if (event.target.checked == true) {
      this.invoiceMonthlyChange(this.periodMonthDiff);
    } else {
      this.billingLineItems = [];
      this.lineItemsdata(this.eligiblePeriods[0]);
    }

  }

  /*---Calculate Monthly Invoice Total Amount---*/
  invoiceMonthlyChange(periodMonthDiff: number): void {
    this.invoiceTotal = 0;
    this.billingLineItems.forEach(obj => {
      obj.rate = isNaN(obj.rate) ? 0 : parseFloat(obj.rate);
      obj.rate = parseInt(obj.rate);
      obj.quantity = isNaN(obj.quantity) ? 0 : parseInt(obj.quantity) / periodMonthDiff;
      obj.itemGrossAmount = parseInt(obj.quantity) * parseFloat(obj.rate);
      obj.itemGrossAmount = isNaN(obj.itemGrossAmount) ? 0 : obj.itemGrossAmount;
      this.invoiceTotal += parseFloat(obj.itemGrossAmount);
    });
  }

  /*---Calculate Invoice Total Amount---*/
  invoiceTotalChange(): void {
    this.invoiceTotal = 0;
    if (this.checkEachMonth == true) {
      this.invoiceMonthlyChange(this.periodMonthDiff);
    } else {
      this.billingLineItems.forEach(obj => {
        obj.rate = isNaN(obj.rate) ? 0 : parseFloat(obj.rate);
        obj.quantity = isNaN(obj.quantity) ? 0 : parseInt(obj.quantity);
        obj.itemGrossAmount = parseInt(obj.quantity) * parseFloat(obj.rate);
        obj.itemGrossAmount = isNaN(obj.itemGrossAmount) ? 0 : obj.itemGrossAmount;
        this.invoiceTotal += parseFloat(obj.itemGrossAmount);
      });
    }
  }

  /*---Triggered when Account changed---*/
  changeAccount(elem: any): void {
    if (elem && elem.id > 0) {
      if (this.voucherType == this.purchaseInvoiceType) {
        this.allowedAccountableBlocks = [];
        this._ledgerService.details(elem.id).subscribe((result: any) => {
          this.voucherFromName = result.ledgerName;
          this.voucherFromAddress = result.address;
        }, error => {
          this._tosterService.error(error.message);
        });
      } else {
        this._ledgerService.details(elem.id).subscribe((result: any) => {
          this.voucherToName = result.ledgerName;
          this.voucherToAddress = result.address;
        }, error => {
          this._tosterService.error(error.message);
        });
        this._propertyService.getByLedger(elem.id).subscribe((result: any) => {
          var data: any;
          for (let i = 0; i < result.length; i++) {
            data = { propertyId: result[i].id };
            this._propertyService.getBlockByPropertyId(data).subscribe((result: any) => {
              this.allowedAccountableBlocks.push(result.id);
            }, error => {
              this._tosterService.error(error.message);
            });
          }
        });
      }
    } else {
      this.accountSelectedText = '';
      if (this.voucherType == this.purchaseInvoiceType) {
        this.voucherFromName = "";
        this.voucherFromAddress = "";
        this.allowedAccountableBlocks = [];
      } else {
        this.voucherToName = "";
        this.voucherToAddress = "";
        this.allowedAccountableBlocks = [];
      }
    }
  }

  /*---Triggered when Property changed---*/
  changeProperty(propertyType:any): void {
    if (this.propertyObj.id > 0) {
      this.propertyId = this.propertyObj.id;
      if(this.invoiceTypeId == 202){
        this.narration = "Rent Bill of " + this.propertyObj.name;
      }else{
        this.narration = "Bill of " + this.propertyObj.name;
      }
      
      this.getBlock();
      if (this.voucherType == this.societyInvoiceType || this.voucherType == this.rentInvoiceType) {
        this.getAssociateLedger();
      } else {
        this.allowedAccountableLedger = null;
      }
    } else {
      this.propertyId = null;
      this.selectedBlockId = null;
      this.allowedAccountableLedger = null;
    }
  }

  /*---Get block id from selected property---*/
  getBlock(): void {
    if (this.propertyId > 0) {
      var data: any;
      if (this.propertyTypeId == this.assetPropertyType) {
        data = { resourceId: this.propertyId };
        this._resourceService.getBlockByResourceId(data).subscribe((result: any) => {
          this.selectedBlockId = result.id;
        }, error => {
          this._tosterService.error(error.message);
        });
      } else if (this.propertyTypeId == this.buildingPropertyType) {
        data = { buildingId: this.propertyId };
        this._buildingService.getBlockByBuildingId(data).subscribe((result: any) => {
          this.selectedBlockId = result.id;
        }, error => {
          this._tosterService.error(error.message);
        });
      } else {
        data = { propertyId: this.propertyId };
        this._propertyService.getBlockByPropertyId(data).subscribe((result: any) => {
          this.selectedBlockId = result.id;
        }, error => {
          this._tosterService.error(error.message);
        });
      }
    }
  }

  /*---Get Associate Ledger from selected property---*/
  getAssociateLedger(): void {
    let data: any;
    if (this.propertyId > 0) {
      data = { propertyId: this.propertyId };
      this._propertyService.associateLedger(data).subscribe((result: any) => {
        this.allowedAccountableLedger = result.ledgerId;
        this._ledgerService.details(this.allowedAccountableLedger).subscribe((res: any) => {
          this.accountObj = { "id": this.allowedAccountableLedger, "name": res.ledgerName };
          this.accountSelectedText = res.ledgerName;
          if (this.voucherType == this.purchaseInvoiceType) {
            this.voucherFromName = res.ledgerName;
            this.voucherFromAddress = res.address;
          } else {
            this.voucherToName = res.ledgerName;
            this.voucherToAddress = res.address;
          }
        }, error => {
          this._tosterService.error(error.message);
        });
      }, error => {
        this._tosterService.error(error.message);
        this.allowedAccountableLedger = null;
      });
    }
  }

  /*---Get Property details from DB of selected property---*/
  getPropertyDetail(): void {
    if (this.propertyId > 0) {
      this._propertyService.details(this.propertyId).subscribe((result: any) => {
        this.propertyObj = { id: this.propertyId, name: result.unitNo };
        this.propertySelectedText = result.unitNo;
      }, error => {
        this._tosterService.error(error.message);
      });
    }
  }

  /*---Triggered when period end date changed---*/
  periodChanged(): void {
    if (this.periodEndOn != null && this.periodStartFrom != null && this.templateId > 0) {
      let yr = new Date(CommonMethods.dbToUsDate(this.periodStartFrom) + ' 00:00:00').getFullYear();
      let mnth: any = new Date(CommonMethods.dbToUsDate(this.periodStartFrom) + ' 00:00:00').getMonth();
      let dt = new Date(CommonMethods.dbToUsDate(this.periodStartFrom) + ' 00:00:00').getDate();
      if(dt != 1){
        mnth = mnth + 1;
        if (mnth < 10) {
          mnth = '0' + mnth;
        }
        let newDt = '01-' + mnth + '-' + yr;
        this.periodStartFrom = newDt;
        this.periodChanged();
      }else{
        let d1 = new Date(CommonMethods.dbToUsDate(this.periodStartFrom));
        let d2 = new Date(CommonMethods.dbToUsDate(this.periodEndOn));
        this.periodMonthDiff = this.monthDiff(d1, d2);
        this.validateTemplate();
      }
    }
  }

  /*Get number of month between two dates */
  monthDiff(d1: Date, d2: Date): number {
    let months: number;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    months += 1;
    return months <= 0 ? 0 : months;
  }

  /*---Cancel invoice---*/
  cancel(): void {
    var data = { communityId: this.communityId };
    this._voucherService.cancelVoucher(this.id, data).subscribe((result: any) => {
      this._tosterService.success("Voucher cancelled successfully.");
      this.getInvoice(this.id);
      if (this.invoiceType == this.purchaseInvoiceType) {
        this._router.navigateByUrl("purchase-invoice/" + this.id, { replaceUrl: true });
      } else {
        this._router.navigateByUrl("society-invoice/" + this.id, { replaceUrl: true });
      }
    }, error => {
      this._tosterService.error(error.message);
    });
  }

  /*---Pay invoice---*/
  paymentAcknowledge(): void {
    if (this.status == 'Pending' || this.status == 'Partially Paid') {
      if (this.invoiceType == this.societyInvoiceType || this.invoiceType == this.rentInvoiceType) {
        this._router.navigate(["collection"], { state: { "invoices": [this.id] } });
      } else {
        this._router.navigate(["payment"], { state: { "invoices": [this.id] } });
      }
    } else {
      this._tosterService.error("Only Pending or Partially Paid invoices are payable.");
    }
  }

  /*---Validate all input parameters---*/
  validateParameters(): boolean {
    if (this.status != "Pending") {
      this._tosterService.error("Invoice can not be updated as it is already Paid.");
      return false;
    }
    if (this.voucherType == this.societyInvoiceType || this.voucherType == this.rentInvoiceType) {
      this.ledgerFromId = 0;
      if (this.accountObj.id > 0) {
        this.ledgerToId = this.accountObj.id;
      }
      if (!this.ledgerToId || this.ledgerToId == 0 || this.ledgerToId == null) {
        this._tosterService.error("Please select an account for the Invoice.");
        return false;
      }
    } else {
      this.ledgerToId = 0;
      if (this.accountObj.id > 0) {
        this.ledgerFromId = this.accountObj.id;
      }
      if (!this.ledgerFromId || this.ledgerFromId == 0 || this.ledgerFromId == null) {
        this._tosterService.error("Please select an account for the Invoice.");
        return false;
      }
    }
    if (this.propertyObj && this.propertyObj.id > 0) {
      this.propertyId = this.propertyObj.id;
    } else {
      this.propertyId = null;
    }
    if (this.propertyTypeId > 0 && !this.propertyId) {
      this._tosterService.error("Please select a property/asset.");
      return false;
    }
    if (this.voucherType != this.purchaseInvoiceType && this.propertyId > 0 && this.allowedAccountableLedger > 0 && this.ledgerToId != this.allowedAccountableLedger) {
      this._tosterService.error("Selected account is not associated with the selected property.");
      return false;
    }
    this.totalGrossAmount = 0;
    this.lineItemDescriptions = [];
    this.billingLineItems.forEach(obj => {
      if (this.voucherType == this.societyInvoiceType || this.voucherType == this.rentInvoiceType) {
        if (obj.account) {
          obj.creditToAccountId = obj.account.id;
          obj.description = obj.account.name;
        }
        if (!obj.creditToAccountId || obj.creditToAccountId == 0 || obj.creditToAccountId == null) {
          this._tosterService.error("Item Particulars can not be blank.");
          return false;
        }
        if (this.lineItemDescriptions.includes(obj.creditToAccountId)) {
          this._tosterService.error("Same line item cant be added multiple times.");
          return false;
        } else {
          this.lineItemDescriptions.push(obj.creditToAccountId);
        }
      } else {
        if (obj.account) {
          obj.debitToAccountId = obj.account.id;
          obj.description = obj.account.name;
        }
        if (!obj.debitToAccountId || obj.debitToAccountId == 0 || obj.debitToAccountId == null) {
          this._tosterService.error("Item Particulars can not be blank.");
          return false;
        }
        if (this.lineItemDescriptions.includes(obj.debitToAccountId)) {
          this._tosterService.error("Same line item cant be added multiple times.");
          return false;
        } else {
          this.lineItemDescriptions.push(obj.debitToAccountId);
        }
      }
      if (this.voucherType == this.societyInvoiceType || this.voucherType == this.rentInvoiceType) {
        obj.debitToAccountId = this.ledgerToId;
      } else {
        obj.creditToAccountId = this.ledgerFromId;
      }
      if (obj.unitObj) {
        obj.unit = obj.unitObj.name;
      }
      if (!obj.rate || obj.rate == 0 || obj.rate == '') {
        this._tosterService.error("Item Rate can not be blank.");
        return false;
      }
      if (!obj.quantity || obj.quantity == 0 || obj.quantity == '') {
        this._tosterService.error("Item Quantity can not be blank.");
        return false;
      }
      if (!obj.unit || obj.unit == '' || obj.unit == null) {
        this._tosterService.error("Item Unit can not be blank.");
        return false;
      }
      obj.rate = parseFloat(obj.rate);
      obj.quantity = parseInt(obj.quantity);
      obj.itemGrossAmount = parseFloat(obj.itemGrossAmount);
      obj.netAmount = obj.itemGrossAmount;
      if (!obj.itemGrossAmount || obj.itemGrossAmount == 0 || obj.itemGrossAmount == '') {
        this._tosterService.error("Item Amount can not be blank.");
        return false;
      }
      this.totalGrossAmount += parseFloat(obj.netAmount);
    });
    this.totalNetAmount = this.totalGrossAmount;
    this.pendingAmount = this.totalGrossAmount;
    this.applicableOnceInPeriod = this.isPeriodic;

    if (this.voucherType == this.purchaseInvoiceType && (!this.voucherNumber || this.voucherNumber == '' || this.voucherNumber == null)) {
      this._tosterService.error("Invoice Number can not be blank.");
      return false;
    }
    if (!this.voucherFromName || this.voucherFromName == null || this.voucherFromName == '') {
      this._tosterService.error("From Name can not be blank.");
      return false;
    }
    if (!this.voucherToName || this.voucherToName == null || this.voucherToName == '') {
      this._tosterService.error("To Name can not be blank.");
      return false;
    }
    if (!this.voucherFromAddress || this.voucherFromAddress == null || this.voucherFromAddress == '') {
      this._tosterService.error("From Address can not be blank.");
      return false;
    }
    if (!this.voucherToAddress || this.voucherToAddress == null || this.voucherToAddress == '') {
      this._tosterService.error("To Address can not be blank.");
      return false;
    }
    if (!this.narration || this.narration == null || this.narration == '') {
      this._tosterService.error("Narration can not be blank.");
      return false;
    }
    if (!this.voucherDate || this.voucherDate == null || this.voucherDate == '') {
      this._tosterService.error("Date can not be blank.");
      return false;
    }
    if (!this.manualDueDate || this.manualDueDate == null || this.manualDueDate == '') {
      this._tosterService.error("Due Date can not be blank.");
      return false;
    }
    if (!this.totalNetAmount || this.totalNetAmount == null || this.totalNetAmount <= 0) {
      this._tosterService.error("Total Amount must be more than 0.");
      return false;
    }
    if (this.isPeriodic == 1 && (!this.periodStartFrom || this.periodStartFrom == null || !this.periodEndOn || this.periodEndOn == null)) {
      this._tosterService.error("Please select Period Range.");
      return false;
    }
    if (this.periodStartFrom && this.periodEndOn && new Date(CommonMethods.dbToUsDate(this.periodStartFrom)).getTime() > new Date(CommonMethods.dbToUsDate(this.periodEndOn)).getTime()) {
      this._tosterService.error("Period start date cannot be greater than period end date.");
      return false;
    }
    return true;
  }

  /*---Validate All---*/
  async validateAll(): Promise<boolean> {
    this.validatedAll = true;
    /*return await this.validateTemplate().then(async (res:any)=>{
      if(res){
        return await this.validateLateFeeTemplate().then(async (lt:any)=>{
          if(lt){
            return await this.validateDiscountTemplate().then(async (dis:any)=>{
              if(dis){
                return this.validateParameters();
              }else{
                return false;
              }
            });
          }else{
            return false;
          }
        });
      }else{
        return false;
      }
    });*/
    return this.validateParameters();
  }

  /*---Reset all parameters---*/
  async resetAll(): Promise<boolean> {
    this.id = null;
    this.voucherNumber = '';
    this.accountSelectedText = null;
    this.accountObj = { id: 0, name: "" };
    this.ledgerFromId = null;
    this.ledgerToId = null;
    this.narration = '';
    this.voucherDate = CommonMethods.usDateToDb(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
    this.paymentType = null;
    this.status = "Pending";
    this.totalGrossAmount = 0;
    this.applicableOnceInPeriod = 0;
    this.periodStartFrom = null;
    this.periodEndOn = null;
    this.totalNetAmount = 0;
    this.pendingAmount = 0;
    this.manualDueDate = CommonMethods.usDateToDb(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
    this.billingLineItems = [];
    this.isPeriodic = 0;
    this.propertyObj = { id: 0, name: "" };
    this.propertyId = null;
    this.propertyTypeId = 0;
    this.invoiceTotal = 0;
    this.lineItemDescriptions = [];
    this.templateId = null;
    this.latefeeSelectedText = null;
    this.discountSelectedText = null;
    this.lateFeeTemplateId = null;
    this.discountTemplateId = null;
    this.selectedBlockId = null;
    this.propertyUrl = null;
    this.voucherType = this.invoiceType;
    this.communityId = parseInt(CommonMethods.getCommunityId());
    this._communityService.details(this.communityId).subscribe((result: any) => {
      this.communityName = result.name;
      this.communityAddress = result.address;
      if (this.invoiceType == this.societyInvoiceType || this.invoiceType == this.rentInvoiceType) {
        this.ledgerDefaultGroup = "Income";
        this.accountSelectionType = "owners";
        this.voucherFromName = this.communityName;
        this.voucherFromAddress = this.communityAddress;
        this.voucherToName = null;
        this.voucherToAddress = null;
      } else {
        this.ledgerDefaultGroup = "Expenses";
        this.accountSelectionType = "vendors";
        this.voucherToName = this.communityName;
        this.voucherToAddress = this.communityAddress;
        this.voucherFromName = null;
        this.voucherFromAddress = null;
      }
    }, error => {
      this._tosterService.error(error.message);
    });
    this.addNewRow();
    return true;
  }

  /*---Save invoice and create new---*/
  async onSubmitAndNew(): Promise<void> {
    await this.onSubmit(true);
  }

  /*---Save invoice---*/
  async onSubmit(followedByNew: boolean = false): Promise<boolean> {
    this.submitted = true;
    let validatedAll = await this.validateAll();
    if (validatedAll == false) {
      return false;
    }
    var voucherData: { [k: string]: any } = {
      voucherNumber: this.voucherNumber,
      voucherType: this.invoiceTypeId,
      voucherFromName: this.voucherFromName,
      voucherFromAddress: this.voucherFromAddress,
      voucherToName: this.voucherToName,
      voucherToAddress: this.voucherToAddress,
      narration: this.narration,
      voucherDate: CommonMethods.dbToUsDate(this.voucherDate),
      status: this.status,
      ledgerToId: this.ledgerToId,
      ledgerFromId: this.ledgerFromId,
      totalGrossAmount: this.totalGrossAmount,
      applicableOnceInPeriod: this.applicableOnceInPeriod,
      totalNetAmount: this.totalNetAmount,
      pendingAmount: this.pendingAmount,
      paymentType: 'Full',
      maintenanceYear: null,
      maintenanceMonth: null,
      tdsAmount: null,
      tdsn: null,
      cancelReason: null,
      metaData: null,
      propertyId: this.propertyId,
      propertyTypeId: this.propertyTypeId,
      templateId: this.templateId,
      latefeeTemplateId: this.lateFeeTemplateId,
      discountTemplateId: this.discountTemplateId,
      communityId: this.communityId,
      manualDueDate: CommonMethods.dbToUsDate(this.manualDueDate),
      lineItems: this.billingLineItems
    }
    if (this.id > 0) {
      voucherData.id = this.id;
    }
    if (this.periodStartFrom) {
      voucherData.periodStartFrom = CommonMethods.dbToUsDate(this.periodStartFrom);
    } else {
      voucherData.periodStartFrom = null;
    }
    if (this.periodEndOn) {
      voucherData.periodEndOn = CommonMethods.dbToUsDate(this.periodEndOn);
    } else {
      voucherData.periodEndOn = null;
    }
    if (this.checkEachMonth == true) {

      await this.onMonthlySubmit(this.periodMonthDiff, voucherData)
    } else {
      if (this.id > 0) {

        this._voucherService.update(voucherData).subscribe(async (result: any) => {
          this._tosterService.success('Invoice Updated Successfully.');
          if (followedByNew) {
            await this.resetAll().then(flag => {
              if (flag) {
                if (this.voucherType == this.societyInvoiceType || this.voucherType == this.rentInvoiceType) {
                  this._router.navigateByUrl("society-invoice", { replaceUrl: true });
                } else {
                  this._router.navigateByUrl("purchase-invoice", { replaceUrl: true });
                }
              }
            });
          }
        }, error => {
          this._tosterService.error(error.message);
        });
      } else {
        this._voucherService.create(voucherData).subscribe(async (result: any) => {
          this._tosterService.success('Invoice Created Successfully.');
          if (followedByNew) {
            await this.resetAll().then(flag => {
              if (flag) {
                if (this.voucherType == this.societyInvoiceType || this.voucherType == this.rentInvoiceType) {
                  this._router.navigateByUrl("society-invoice", { replaceUrl: true });
                } else {
                  this._router.navigateByUrl("purchase-invoice", { replaceUrl: true });
                }
              }
            });
          } else {
            if (this.voucherType == this.societyInvoiceType || this.voucherType == this.rentInvoiceType) {
              this._router.navigateByUrl("society-invoice/" + result.id, { replaceUrl: true });
            } else {
              this._router.navigateByUrl("purchase-invoice/" + result.id, { replaceUrl: true });
            }
          }
        }, error => {
          this._tosterService.error(error.message);
        });
      }
    }


  }

  async onMonthlySubmit(periodMonthDiff: number, voucherData: any, followedByNew: boolean = false) {
    voucherData.periodStartFrom = null;

    voucherData.periodEndOn = null;
    var k = 0;


    if (this.id > 0) {
      if (this.periodStartFrom) {
        voucherData.periodStartFrom = CommonMethods.dbToUsDate(this.periodStartFrom);
      } else {
        voucherData.periodStartFrom = null;
      }
      if (this.periodEndOn) {
        voucherData.periodEndOn = CommonMethods.dbToUsDate(this.periodEndOn);
      } else {
        voucherData.periodEndOn = null;
      }

      this._voucherService.update(voucherData).toPromise().then((result: any) => {
        this._tosterService.success('Invoice Updated Successfully.');
        this.lastResult = result;

      }, error => {
        this._tosterService.error(error.message);
      });
    } else {

      for (var j = 0; j < periodMonthDiff; j++) {

        await new Promise((resolve, reject) => {
          if (j == 0) {
            k;
          } else {
            k++;
          }
          if (this.periodStartFrom) {
            voucherData.periodStartFrom = CommonMethods.dbToUsMonthDate(this.periodStartFrom, j);
          } else {
            voucherData.periodStartFrom = null;
          }
          if (this.periodEndOn) {
            voucherData.periodEndOn = CommonMethods.dbToUsMonthEndDate(this.periodStartFrom, k);
          } else {
            voucherData.periodEndOn = null;
          }
          voucherData.manualDueDate = this.populateMonthlyDueDate(voucherData.periodStartFrom);

          console.log('voucherData',voucherData);
          this._voucherService.create(voucherData).toPromise().then((result: any) => {
            this._tosterService.success('Invoice Created Successfully.');
            this.lastResult = result;
          }, error => {
            this._tosterService.error(error.message);
          });


          resolve(true);
        });
      }
    }



    if (this.id > 0) {
      if (followedByNew) {
        await this.resetAll().then(flag => {
          if (flag) {
            if (this.voucherType == this.societyInvoiceType || this.voucherType == this.rentInvoiceType) {
              this._router.navigateByUrl("society-invoice", { replaceUrl: true });
            } else {
              this._router.navigateByUrl("purchase-invoice", { replaceUrl: true });
            }
          }
        });
      }
    } else {

      if (followedByNew) {
        await this.resetAll().then(flag => {
          if (flag) {
            if (this.voucherType == this.societyInvoiceType || this.voucherType == this.rentInvoiceType) {
              this._router.navigateByUrl("society-invoice", { replaceUrl: true });
            } else {
              this._router.navigateByUrl("purchase-invoice", { replaceUrl: true });
            }
          }
        });
      } else {
        
        // if (k + 1 == this.periodMonthDiff) {
        if (this.voucherType == this.societyInvoiceType || this.voucherType == this.rentInvoiceType) {
          //  this._router.navigateByUrl("society-invoice/"+this.lastResult.id, {replaceUrl:true});
          this._router.navigateByUrl("society-invoice", { replaceUrl: true });
        } else {
          // this._router.navigateByUrl("purchase-invoice/"+this.lastResult.id, {replaceUrl:true});
          this._router.navigateByUrl("purchase-invoice", { replaceUrl: true });

        }
      //  }
      }

    }


  }

  /*---Get invoice details---*/
  getInvoice(id: number = 0): void {
    if (id > 0) {
      
      this._voucherService.getVoucher(id).subscribe((result: any) => {
        this.id = result.id;

        this.voucherNumber = result.voucherNumber;
        this.voucherType = result.voucherType;
        this.invoiceTypeId = result.voucherType;
        this.voucherFromName = result.voucherFromName;
        this.voucherFromAddress = result.voucherFromAddress;
        this.voucherToName = result.voucherToName;
        this.voucherToAddress = result.voucherToAddress;
        this.narration = result.narration;
        this.voucherDate = CommonMethods.usDateToDb(this.datePipe.transform(result.voucherDate, "yyyy-MM-dd"));
        this.paymentType = result.paymentType;
        this.status = result.status;
        this.ledgerToId = result.ledgerToId;
        this.ledgerFromId = result.ledgerFromId;
        this.totalGrossAmount = result.totalGrossAmount;
        this.applicableOnceInPeriod = result.applicableOnceInPeriod;
        this.isPeriodic = this.applicableOnceInPeriod;
        this.periodStartFrom = result.periodStartFrom != '' ? CommonMethods.usDateToDb(this.datePipe.transform(result.periodStartFrom, "yyyy-MM-dd")) : "";
        this.periodEndOn = result.periodEndOn != '' ? CommonMethods.usDateToDb(this.datePipe.transform(result.periodEndOn, "yyyy-MM-dd")) : "";
        this.totalNetAmount = result.totalNetAmount;
        this.pendingAmount = result.pendingAmount;
        this.propertyId = result.propertyId;
        this.propertyTypeId = result.propertyTypeId;
        this.getBlock();
        this.templateId = result.templateId;
        this.lateFeeTemplateId = result.latefeeTemplateId;
        this.discountTemplateId = result.discountTemplateId;
        if (this.propertyId > 0) {
          this.getPropertyDetail();
        }
        if (result.templateId > 0) {
          this.templateId = result.templateId;
          this._voucherTemplateService.details(result.templateId).subscribe((res: any) => {
            this.templateSelectedText = res.name;
          }, error => {
            this._tosterService.error(error.message);
          });
        }
        if (result.latefeeTemplateId > 0) {
          this.lateFeeTemplateId = result.latefeeTemplateId;
          this._lateFeeTemplateService.details(result.latefeeTemplateId).subscribe((res: any) => {
            this.latefeeSelectedText = res.name;
          }, error => {
            this._tosterService.error(error.message);
          });
        }
        if (result.discountTemplateId > 0) {
          this.discountTemplateId = result.discountTemplateId;
          this._discountTemplateService.details(result.discountTemplateId).subscribe((res: any) => {
            this.discountSelectedText = res.name;
          }, error => {
            this._tosterService.error(error.message);
          });
        }
        this.communityId = result.communityId;
        this.manualDueDate = CommonMethods.usDateToDb(this.datePipe.transform(result.manualDueDate, "yyyy-MM-dd"));
        this.billingLineItems = result.lineItems;
        if (this.voucherType == this.purchaseInvoiceType) {
          this._ledgerService.details(this.ledgerFromId).subscribe((result: any) => {
            this.accountSelectedText = result.ledgerName;
            this.accountObj = { id: this.ledgerFromId, name: result.ledgerName };
          }, error => {
            this._tosterService.error(error.message);
          });
        } else {
          this._ledgerService.details(this.ledgerToId).subscribe((result: any) => {
            this.accountSelectedText = result.ledgerName;
            this.accountObj = { id: this.ledgerToId, name: result.ledgerName };
          }, error => {
            this._tosterService.error(error.message);
          });
        }
        this.invoiceTotal = this.totalNetAmount;
        if (this.status == "Pending") {
          this._uiService.leftMenuItems.next([
            { code: 'F2', name: this.listName, action: this.goToList.bind(this), privilege: this.readPrivilege },
            { code: 'F4', name: 'Save', action: this.onSubmit.bind(this), class: 'green lighten-1', privilege: this.writePrivilege },
            { code: 'F5', name: 'Save & New', action: this.onSubmitAndNew.bind(this), privilege: this.writePrivilege },
            { code: 'F6', name: 'Payment Acknowledgement', action: this.paymentAcknowledge.bind(this), class: 'brown lighten-1', privilege: this.payPrivilege },
            { code: 'F7', name: 'Cancel', action: this.cancel.bind(this), class: 'red lighten-1', privilege: this.writePrivilege }
          ]);
        } else {
          this._uiService.leftMenuItems.next([
            { code: 'F2', name: this.listName, action: this.goToList.bind(this), privilege: this.readPrivilege }
          ]);
        }
      }, error => {
        this._tosterService.error(error.message);
      });
    }
  }

  /*---Triggered when invoice template changed---*/
  async validateTemplate(): Promise<boolean> {
    if (this.templateId > 0 && !this.id) {
      return await this.getTemplate(this.templateId);
    }
    return true;
  }

  /*---Get invoice templated details---*/
  async getTemplate(id: number = 0): Promise<boolean> {
    if (id > 0) {
      return this._voucherTemplateService.details(id).toPromise().then(async (result: any) => {
        /*if(result.voucherType != this.voucherType){
          this._tosterService.error("Selected Invoice Template's Invoice Type does not match.");
          this.validatedAll = false;
          return false;
        }*/
        this.templateCreditPeriodDays = result.creditPeriodDays > 0 ? (result.creditPeriodDays - 1) : 0;
        if (result.appliedForBlockId > 0 && (!this.propertyId || this.propertyId == null || this.propertyId == 0)) {
          this._tosterService.error("Need to choose Property to select this Invoice Template.");
          this.validatedAll = false;
          return false;
        }
        if (result.appliedForBlockId > 0 && result.appliedForBlockId != this.selectedBlockId) {
          this._tosterService.error("Selected Invoice Template Block does not matched.");
          this.validatedAll = false;
          return false;
        }
        if (result.propertyType > 0 && result.propertyType != this.propertyTypeId) {
          this._tosterService.error("Selected Invoice Template Property Type does not match.");
          this.validatedAll = false;
          return false;
        }
        if ((result.sendToOwners == 1 && result.sendToTenant == 1) && this.voucherType != this.societyInvoiceType && this.voucherType != this.rentInvoiceType) {
          this._tosterService.error("Selected Invoice Template is only applicable for Owners or Tenants.");
          this.validatedAll = false;
          return false;
        }
        if ((result.sendToOwners == 1 && result.sendToTenant != 1) && this.voucherType != this.societyInvoiceType) {
          this._tosterService.error("Selected Invoice Template is only applicable for Owners.");
          this.validatedAll = false;
          return false;
        }
        if ((result.sendToTenant == 1 && result.sendToOwners != 1) && this.voucherType != this.rentInvoiceType) {
          this._tosterService.error("Selected Invoice Template is only applicable for Tenants.");
          this.validatedAll = false;
          return false;
        }
        if (this.itemsInitialized == true) {
          return await this.periodsBytemplate(result.id).then(async (res: any) => {
            if (res) {
              this.templateAppliedForBlockId = result.appliedForBlockId;
              this.templatePropertyType = result.propertyType;
              this.templateBlockId = result.appliedForBlockId;
              this.populateDueDate();
              return true;
            } else {
              return false;
            }
          });
        } else {
          return true;
        }
      });
    } else {
      return true;
    }
  }

  /*---Get invoice templated periods---*/
  async periodsBytemplate(templateId: number = 0): Promise<boolean> {
    if (templateId > 0) {
      return this._voucherTemplateService.periods(templateId).toPromise().then(async (result: any) => {
        this.templatePeriods = result;
        var periodStart = [];
        var periodEnds = [];
        this.eligiblePeriods = [];
        if(this.periodStartFrom){
          let yr = new Date(CommonMethods.dbToUsDate(this.periodStartFrom) + ' 00:00:00').getFullYear();
          let mnth: any = new Date(CommonMethods.dbToUsDate(this.periodStartFrom) + ' 00:00:00').getMonth();
          mnth = mnth + 1;
          if (mnth < 10) {
            mnth = '0' + mnth;
          }
          let newDt = '01-' + mnth + '-' + yr;
          this.periodStartFrom = newDt;
        }else{
          let yr = new Date(CommonMethods.dbToUsDate(this.voucherDate) + ' 00:00:00').getFullYear();
          let mnth: any = new Date(CommonMethods.dbToUsDate(this.voucherDate) + ' 00:00:00').getMonth();
          mnth = mnth + 1;
          if (mnth < 10) {
            mnth = '0' + mnth;
          }
          let newDt = '01-' + mnth + '-' + yr;
          this.periodStartFrom = newDt;
        }
        this.templatePeriods.forEach(elem => {
          if (this.periodStartFrom && this.periodEndOn && new Date(elem.startsOn).getTime() <= new Date(CommonMethods.dbToUsDate(this.periodStartFrom) + ' 00:00:00').getTime() && new Date(elem.expriesOn).getTime() >= new Date(CommonMethods.dbToUsDate(this.periodEndOn) + ' 00:00:00').getTime()) {
            this.eligiblePeriods.push(elem.id);
            periodStart.push(elem.startsOn);
            periodEnds.push(elem.expriesOn);
          }
        });
        if (this.eligiblePeriods.length > 0) {
          if (this.eligiblePeriods.length > 1) {
            this._tosterService.error("Selected Invoice Template is not applicable as period range does not matched.");
            this.validatedAll = false;
            return false;
          } else {
            this.isPeriodic = 1;
            return await this.fillLineItemsdata(this.eligiblePeriods[0]);
          }
        } else {
          this._tosterService.error("Selected Invoice Template is not applicable as period range does not matched.");
          this.validatedAll = false;
          return false;
        }
      });
    } else {
      this._tosterService.error("Selected Invoice Template is invalid.");
      this.validatedAll = false;
      return false;
    }
  }

  /*---Get line items of a period---*/
  async fillLineItemsdata(id: number): Promise<boolean> {
    if (id > 0) {
      let error: boolean = false;
      return this._voucherTemplateService.period(id).toPromise().then(async (result: any) => {
        this.billingLineItems = [];
        this.templateLineItems = result.lineItems;
        for (var i = 0; i < this.templateLineItems.length; i++) {
          try {
            var unitDetails = this.units.filter((x: any) => x.id == this.templateLineItems[i].chargesUnitId);
            if (unitDetails.length > 0) {
              this.templateLineItems[i].unitName = unitDetails[0].name;
              await this._ledgerService.details(this.templateLineItems[i].creditToLedgerId).toPromise().then(async (res: any) => {
                let qty = this.periodMonthDiff > 0 ? this.periodMonthDiff : 1;
                var item = {
                  rate: parseFloat(this.templateLineItems[i].amountEvalFormula),
                  quantity: qty,
                  unit: this.templateLineItems[i].unitName,
                  description: res.ledgerName,
                  itemGrossAmount: parseFloat(this.templateLineItems[i].amountEvalFormula) * qty,
                  debitToAccountId: 0,
                  creditToAccountId: this.templateLineItems[i].creditToLedgerId,
                  account: { id: this.templateLineItems[i].creditToLedgerId, name: res.ledgerName },
                  unitObj: { id: this.templateLineItems[i].chargesUnitId, name: this.templateLineItems[i].unitName }
                }
                this.billingLineItems.push(item);
              });
              this.invoiceTotalChange();
            }
          } catch (e) {
            this.validatedAll = false;
            error = true;
            this._tosterService.error("Some error occured while populating line items. Please try again.");
          }
          if (error) {
            return false;
          }
        }
        return true;
      });
    } else {
      this.validatedAll = false;
      this._tosterService.error("Some error occured while populating line items. Please try again.");
      return false;
    }
  }

  /*---Triggered when discount template changed---*/
  changeDiscountTemplate(elem: number = 0): void {
    if (elem > 0) {
      this.getDiscountTemplate(elem);
    }
  }

  /*---Validate Discount Template---*/
  async validateDiscountTemplate(): Promise<boolean> {
    if (this.discountTemplateId > 0) {
      return await this.getDiscountTemplate(this.discountTemplateId);
    } else {
      return true;
    }
  }

  /*---Get discount template details---*/
  async getDiscountTemplate(id: number = 0): Promise<boolean> {
    if (id > 0) {
      return this._discountTemplateService.details(id).toPromise().then(async (result: any) => {
        if (result.isActive != 1) {
          this._tosterService.error("Selected Discount Template is not enabled.");
          this.validatedAll = false;
          return false;
        }
        if (result.voucherType != this.voucherType) {
          this._tosterService.error("Selected Discount Template Type does not match.");
          this.validatedAll = false;
          return false;
        }
        if (result.appliedForBlockId > 0 && this.propertyId == null) {
          this._tosterService.error("Need to choose Property to select this Discount Template.");
          this.validatedAll = false;
          return false;
        }
        if (result.appliedForBlockId > 0 && result.appliedForBlockId != this.selectedBlockId) {
          this._tosterService.error("Selected Discount Template Block does not matched.");
          this.validatedAll = false;
          return false;
        }
        if (result.propertyType > 0 && result.propertyType != this.propertyTypeId) {
          this._tosterService.error("Selected Discount Template Property Type does not match.");
          this.validatedAll = false;
          return false;
        }
        if ((result.sendToOwners == 1 && result.sendToTenant == 1) && this.voucherType != this.societyInvoiceType && this.voucherType != this.rentInvoiceType) {
          this._tosterService.error("Selected Discount Template is only applicable for Owners or Tenants.");
          this.validatedAll = false;
          return false;
        }
        else if ((result.sendToTenant == 1 && result.sendToOwners != 1) && this.voucherType != this.rentInvoiceType) {
          this._tosterService.error("Selected Discount Template is only applicable for Tenants.");
          this.validatedAll = false;
          return false;
        } else if ((result.sendToOwners == 1 && result.sendToTenant != 1) && this.voucherType != this.societyInvoiceType) {
          this._tosterService.error("Selected Discount Template is only applicable for Owners.");
          this.validatedAll = false;
          return false;
        }
        if (result.discountType == 2) {
          if ((!this.periodStartFrom || !this.periodEndOn)) {
            this._tosterService.error("Selected Discount Template is only applicable for yearly invoices.");
            this.validatedAll = false;
            return false;
          } else {
            let monthDiff = ((new Date(CommonMethods.dbToUsDate(this.periodEndOn)).getTime() - new Date(CommonMethods.dbToUsDate(this.periodStartFrom)).getTime()) / 1000) / (60 * 60 * 24 * 7 * 4);
            if (monthDiff < 11 || monthDiff > 13) {
              this._tosterService.error("Selected Discount Template is only applicable for yearly invoices.");
              this.validatedAll = false;
              return false;
            }
          }
        }
        return true;
      });
    } else {
      return true;
    }
  }

  /*---Triggered when late fee template changed---*/
  changeLateFeeTemplate(elem: number = 0): void {
    if (elem > 0) {
      this.getLateFeeTemplate(elem);
    }
  }

  /*---Validate Late Fee Template---*/
  async validateLateFeeTemplate(): Promise<boolean> {
    if (this.lateFeeTemplateId > 0) {
      return await this.getLateFeeTemplate(this.lateFeeTemplateId);
    } else {
      return true;
    }
  }

  /*--Get late fee template details---*/
  async getLateFeeTemplate(id: number = 0): Promise<boolean> {
    if (id > 0) {
      return this._lateFeeTemplateService.details(id).toPromise().then(async (result: any) => {
        if (result.isActive != 1) {
          this._tosterService.error("Selected Latefee Template is not enabled.");
          this.validatedAll = false;
          return false;
        }
        if (result.voucherType != this.voucherType) {
          this._tosterService.error("Selected Latefee Template Type does not match.");
          this.validatedAll = false;
          return false;
        }
        if (result.appliedForBlockId > 0 && this.propertyId == null) {
          this._tosterService.error("Need to choose Property to select this Latefee Template.");
          this.validatedAll = false;
          return false;
        }
        if (result.appliedForBlockId > 0 && result.appliedForBlockId != this.selectedBlockId) {
          this._tosterService.error("Selected Latefee Template Block does not matched.");
          this.validatedAll = false;
          return false;
        }
        if (result.propertyType > 0 && result.propertyType != this.propertyTypeId) {
          this._tosterService.error("Selected Latefee Template Property Type does not match.");
          this.validatedAll = false;
          return false;
        }
        if ((result.sendToOwners == 1 && result.sendToTenant == 1) && this.voucherType != this.societyInvoiceType && this.voucherType != this.rentInvoiceType) {
          this._tosterService.error("Selected Latefee Template is only applicable for Owners or Tenants.");
          this.validatedAll = false;
          return false;
        }
        else if ((result.sendToTenant == 1 && result.sendToOwners != 1) && this.voucherType != this.rentInvoiceType) {
          this._tosterService.error("Selected Latefee Template is only applicable for Tenants.");
          this.validatedAll = false;
          return false;
        } else if ((result.sendToOwners == 1 && result.sendToTenant != 1) && this.voucherType != this.societyInvoiceType) {
          this._tosterService.error("Selected Latefee Template is only applicable for Owners.");
          this.validatedAll = false;
          return false;
        }
        return true;
      });
    } else {
      return true;
    }
  }

  /*---Triggered if invoice template is selected in the beginning---*/
  selectTemplate(id: number = 0): void {
    if (id > 0) {
      this.voucherDate = CommonMethods.usDateToDb(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      this._voucherTemplateService.details(id).subscribe((result: any) => {
        this.invoiceTypeId = parseInt(result.voucherType);
        this.templateAppliedForBlockId = result.appliedForBlockId;
        this.templatePropertyType = result.propertyType;
        this.templateCreditPeriodDays = result.creditPeriodDays > 0 ? (result.creditPeriodDays - 1) : 0;
        this.templateBlockId = result.appliedForBlockId;
        this.propertyTypeId = this.templatePropertyType;
        this.templateCreditPeriodDays = result.creditPeriodDays > 0 ? (result.creditPeriodDays - 1) : 0;
        this.propertyTypeChanged();
        this.getPeriodsBytemplate(result.id);

        if(this.invoiceTypeId == 202){
          this.narration = "Rent Bill of " + this.propertyObj.name;
        }else{
          this.narration = "Bill of " + this.propertyObj.name;
        }
      }, error => {
        this._tosterService.error(error.message);
      });
    }


  }

  /*---Get period of invoice template, if it is selected in the beginning---*/
  getPeriodsBytemplate(templateId: number = 0): void {
    if (templateId > 0) {
      this._voucherTemplateService.periods(templateId).subscribe((result: any) => {
        this.templatePeriods = result;
        var periodStart = [];
        var periodEnds = [];
        this.templatePeriods.forEach(elem => {
          if (new Date(elem.startsOn).getTime() <= new Date(CommonMethods.dbToUsDate(this.voucherDate) + ' 00:00:00').getTime() && new Date(elem.expriesOn).getTime() >= new Date(CommonMethods.dbToUsDate(this.voucherDate) + ' 00:00:00').getTime()) {
            this.eligiblePeriods.push(elem.id);
            periodStart.push(elem.startsOn);
            periodEnds.push(elem.expriesOn);
          }
        });
        if (this.eligiblePeriods.length > 0) {
          if (this.eligiblePeriods.length > 1) {
            this._tosterService.error("Selected Invoice Template not applicable as period range does not matched.");
          } else {
            this.isPeriodic = 1;
            this.periodStartFrom = CommonMethods.usDateToDb(periodStart[0]);
            this.periodEndOn = CommonMethods.usDateToDb(periodEnds[0]);
            this.lineItemsdata(this.eligiblePeriods[0]);
            this.populateDueDate();
          }
        } else {
          this._tosterService.error("Selected Invoice Template not applicable as period range does not matched.");
        }
      }, error => {
        this._tosterService.error(error.message);
      });
    }
  }

  /*---Get line items of a period---*/
  async lineItemsdata(id: number = 0): Promise<boolean> {
    if (id > 0) {
      return this._voucherTemplateService.period(id).toPromise().then(async (result: any) => {
        this.templateLineItems = result.lineItems;
        this.billingLineItems = [];
        let error: boolean = false;
        for (var i = 0; i < this.templateLineItems.length; i++) {
          try {
            var unitDetails = this.units.filter((x: any) => x.id == this.templateLineItems[i].chargesUnitId);
            if (unitDetails.length > 0) {
              this.templateLineItems[i].unitName = unitDetails[0].name;
              await this._ledgerService.details(this.templateLineItems[i].creditToLedgerId).toPromise().then(async (res: any) => {
                let qty = this.periodMonthDiff > 0 ? this.periodMonthDiff : 1;
                var item = {
                  rate: parseFloat(this.templateLineItems[i].amountEvalFormula),
                  quantity: qty,
                  unit: this.templateLineItems[i].unitName,
                  description: res.ledgerName,
                  itemGrossAmount: parseFloat(this.templateLineItems[i].amountEvalFormula) * qty,
                  debitToAccountId: 0,
                  creditToAccountId: this.templateLineItems[i].creditToLedgerId,
                  account: { id: this.templateLineItems[i].creditToLedgerId, name: res.ledgerName },
                  unitObj: { id: this.templateLineItems[i].chargesUnitId, name: this.templateLineItems[i].unitName }
                }
                this.billingLineItems.push(item);
              });
              this.invoiceTotalChange();
            }
          } catch (e) {
            this.validatedAll = false;
            error = true;
            this._tosterService.error("Some error occured while populating line items. Please try again.");
          }
        }
        if (error) {
          return false;
        }
        this.itemsInitialized = true;
        return true;
      });
    } else {
      this.validatedAll = false;
      this._tosterService.error("Some error occured while populating line items. Please try again.");
      return false;
    }
  }

  /*---Navigate to list page---*/
  goToList(): void {
    let listUrl: string = "";
    if (this.invoiceType == this.societyInvoiceType) {
      listUrl = "society-invoices";
    } else if (this.invoiceType == this.rentInvoiceType) {
      listUrl = "society-invoices";
    } else {
      listUrl = "purchase-invoices";
    }
    this._router.navigate([listUrl]);
  }

  /*---Breadcrumb navigation---*/
  breadcrumbNavigate(mode: any): void {
    if (mode == "list") {
      this.goToList();
    } else if (mode == "dashboard") {
      this._router.navigateByUrl("/dashboard");
    }
  }

  /*---Download voucher---*/
  downloadInvoice(invoice: any): void {
    var authkey = localStorage.getItem("authKey");
    var udid = localStorage.getItem("UDID");
    var communityId = CommonMethods.getCommunityId();
    var is_duble_invoice = 1;
    window.open(`${config.apiEndPointBase}/voucher/download/${invoice.id}?authkey=${authkey}&udid=${udid}&communityId=${communityId}&is_duble_invoice=${is_duble_invoice}`);
    return;
  }


  ngOnDestroy(): void {
    this._uiService.leftMenuItems.next([]);
  }

  invoiceTypeChanged(e:any){
    console.log(e.target.value);

  }
}
