import { APP_CONST } from '../../src/app/app-constants';

let MENU_ITEMS = [
  { "id": 1, "title": "Masters", "parent_id": 0, "shortcut": "M","action": null },
  { "id": 2, "title": "Transactions", "parent_id": 0, "shortcut": "T","action": null },
  { "id": 3, "title": "Reports", "parent_id": 0 , "shortcut": "R","action": null },

  { "id": 4, "title": "Properties", "parent_id": 1, "shortcut": "o", "action":null },
  { "id": 5, "title": "Accounts", "parent_id": 1, "shortcut": "o", "action":null },
  { "id": 6, "title": "Charges & Discounts", "parent_id": 1, "shortcut": "o", "action":null },
  { "id": 7, "title": "Members", "parent_id": 1, "shortcut": "o", "action":["members"] },

  { "id": 8, "title": "Billing Charges", "parent_id": 6, "shortcut": "o", "action":[{"outlets": { "rightMainPopup": ["billing-charges"]} }] },
  { "id": 9, "title": "Discounts", "parent_id": 6, "shortcut": "o", "action":[{"outlets": { "rightMainPopup": ["discounts"]} }] },
  { "id": 10, "title": "Late Fees", "parent_id": 6, "shortcut": "o", "action":[{"outlets": { "rightMainPopup": ["late-fees"]} }] },

  { "id": 11, "title": "Society Invoice", "parent_id": 2, "shortcut": "e", "action":["society-invoices"] },
  { "id": 12, "title": "Purchase Invoice", "parent_id": 2, "shortcut": "c", "action":["purchase-invoices"] },
  { "id": 13, "title": "Collection", "parent_id": 2, "shortcut": "t", "action":["collections"] },
  { "id": 14, "title": "Payments", "parent_id": 2, "shortcut": "y", "action":["payments"] },
  //{ "id": 15, "title": "Account Statement", "parent_id": 3, "shortcut": "S" },

  { "id": 16, "title": "Blocks", "parent_id": 4, "shortcut": "E", "action":[{"outlets": { "rightMainPopup": ["blocks"] } }]},
  { "id": 17, "title": "Buildings", "parent_id": 4, "shortcut": "L",  "action":[{"outlets": { "rightMainPopup": ["blocks",{"mode":"BuildingSelections"}]}}]},    
  { "id": 18, "title": "Residentials", "parent_id": 4, "shortcut": "t", "action":null},
  { "id": 19, "title": "Commercial", "parent_id": 4, "shortcut": "c"},
  { "id": 20, "title": "Garages", "parent_id": 4, "shortcut": "S"},

  { "id": 21, "title": "Parking Spaces", "parent_id": 4, "shortcut": "S"},

  { "id": 22, "title": "Amenity", "parent_id": 4, "shortcut": "P", "action":[{"outlets": { "rightMainPopup": ["blocks",{"mode":"PropertySelections","type":APP_CONST.PROPERTY_TYPE_AMENITY}]}}]},    
  { "id": 23, "title": "Resources / Assets", "parent_id": 4, "shortcut": "P", "action":[{"outlets": { "rightMainPopup": ["blocks",{"mode":"ResourceSelections"}]}}]},

  { "id": 24, "title": "Groups", "parent_id": 5, "shortcut": "r", "action":[{"outlets": { "rightMainPopup": ["account-groups"] } }]},
  { "id": 25, "title": "Owners", "parent_id": 5, "shortcut": "w",  "action":[{"outlets": { "rightMainPopup": ["ledgers", "owners"] }}]},
  { "id": 26, "title": "Tenants", "parent_id": 5, "shortcut": "t", "action":[{"outlets": { "rightMainPopup": ["ledgers", "tenants"] }}]},
  { "id": 27, "title": "Bank Accounts", "parent_id": 5, "shortcut": "c", "action":[{"outlets": { "rightMainPopup": ["ledgers", "bank"] }}]},
  { "id": 28, "title": "Cash Books", "parent_id": 5, "shortcut": "s", "action":[{"outlets": { "rightMainPopup": ["ledgers", "cash"] }}]},
  { "id": 29, "title": "Vendors", "parent_id": 5, "shortcut": "V", "action":[{"outlets": { "rightMainPopup": ["ledgers", "vendors"] }}]},
  { "id": 30, "title": "Outsiders", "parent_id": 5, "shortcut": "t", "action":[{"outlets": { "rightMainPopup": ["ledgers", "outsiders"] }}]},
  { "id": 31, "title": "General Ledgers", "parent_id": 5, "shortcut": "G", "action":[{"outlets": { "rightMainPopup": ["ledgers", "general"] }}]},
  { "id": 32, "title": "Hierarchy", "parent_id": 5, "shortcut": "h", "action":["hierarchy"] },
  
  { "id": 33, "title": "Offices", "parent_id": 19, "shortcut": "c", "action":[{"outlets": { "rightMainPopup": ["blocks",{"mode":"PropertySelections","type":APP_CONST.PROPERTY_TYPE_OFFICES}]}}]},
  { "id": 34, "title": "Shops", "parent_id": 19, "shortcut": "S", "action":[{"outlets": { "rightMainPopup": ["blocks",{"mode":"PropertySelections","type":APP_CONST.PROPERTY_TYPE_SHOPS}]}}]},

  { "id": 35, "title": "Garage(2 wheeler)", "parent_id": 20, "shortcut": "G", "action":[{"outlets": { "rightMainPopup": ["blocks",{"mode":"PropertySelections","type":APP_CONST.PROPERTY_TYPE_GARAGES_TWO_WHEELER}]}}]},
  { "id": 36, "title": "Garage(4 wheeler)", "parent_id": 20, "shortcut": "e", "action":[{"outlets": { "rightMainPopup": ["blocks",{"mode":"PropertySelections","type":APP_CONST.PROPERTY_TYPE_GARAGES_FOUR_WHEELER}]}}]},
  { "id": 37, "title": "Parking(2 wheeler)", "parent_id": 21, "shortcut": "P", "action":[{"outlets": { "rightMainPopup": ["blocks",{"mode":"PropertySelections","type":APP_CONST.PROPERTY_TYPE_PARKING_SPACES_TWO_WHEELER}]}}]},
  { "id": 38, "title": "Parking(4 wheeler)", "parent_id": 21, "shortcut": "P", "action":[{"outlets": { "rightMainPopup": ["blocks",{"mode":"PropertySelections","type":APP_CONST.PROPERTY_TYPE_PARKING_SPACES_FOUR_WHEELER}]}}]},
  
  { "id": 39, "title": "Flat", "parent_id": 18, "shortcut": "G", "action":[{"outlets": { "rightMainPopup": ["blocks",{"mode":"PropertySelections","type":APP_CONST.PROPERTY_TYPE_FLAT}]}}]},
  { "id": 40, "title": "Quarter", "parent_id": 18, "shortcut": "e", "action":[{"outlets": { "rightMainPopup": ["blocks",{"mode":"PropertySelections","type":APP_CONST.PROPERTY_TYPE_QUARTER}]}}]},
  { "id": 41, "title": "Tags", "parent_id": 1, "shortcut": "C", "action":["tags"] },
  { "id": 42, "title": "Transfer (Contra)", "parent_id": 2, "shortcut": "r", "action":["transfers"] },

  { "id": 43, "title": "Accountwise Dues", "parent_id": 3, "shortcut": "A", "action":[{"outlets": { "leftMainPopup":"accountwise-dues-report" }}]},
  { "id": 44, "title": "Propertywise Dues", "parent_id": 3, "shortcut": "P", "action":[{"outlets": { "leftMainPopup":"propertywise-dues-report" }}]},
  { "id": 45, "title": "Propertywise Owner", "parent_id": 3, "shortcut": "p", "action":[{"outlets": { "leftMainPopup":"propertywise-woner-report" }}]},
  { "id": 46, "title": "Income Statement", "parent_id": 3, "shortcut": "c", "action":[{"outlets": { "leftMainPopup":"income-statement-report" }}]},
  { "id": 47, "title": "Expense Statement", "parent_id": 3, "shortcut": "e", "action":[{"outlets": { "leftMainPopup":"expense-statement-report" }}]},
  { "id": 48, "title": "Blockwise Income Summary", "parent_id": 3, "shortcut": "e", "action":[{"outlets": { "leftMainPopup":"blockwise-income-summary-report" }}]},
  { "id": 49, "title": "Block Income Details", "parent_id": 3, "shortcut": "e", "action":[{"outlets": { "leftMainPopup":"block-income-details-report" }}]},
  { "id": 50, "title": "Inter Transfer Report", "parent_id": 3, "shortcut": "r", "action":[{"outlets": { "leftMainPopup":"inter-transfer-report" }}]},
]
export const MENU = MENU_ITEMS;