import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormControl, FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { WebServiceInterceptor } from './api-client/web-service-interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { ToastrModule } from 'ngx-toastr';
import { OtpComponent } from './otp/otp.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { ApiClientModule } from './api-client/api-client.module';
import { BlocksComponent } from './blocks/blocks.component';
import { BlockDetailsComponent } from './block-details/block-details.component';
import { BuildingsComponent } from './buildings/buildings.component';
import { BuildingDetailsComponent } from './building-details/building-details.component';
import { CommunitiesComponent } from './communities/communities.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { UnitsResourcesComponent } from './units-resources/units-resources.component';
import { UnitDetailsComponent } from './unit-details/unit-details.component';
import { ResourceDetailsComponent } from './resource-details/resource-details.component';
import { CommunityDetailsComponent } from './community-details/community-details.component';
import { PreviousRouteService } from './api-client/previous-route.service';
import { ResourcesComponent } from './resources/resources.component';
import { AccountGroupComponent } from './account-group/account-group.component';
import { AccountGroupDetailsComponent } from './account-group-details/account-group-details.component';
import { LedgersComponent } from './ledgers/ledgers.component';
import { LedgerDetailsComponent } from './ledger-details/ledger-details.component';
import { HierarchyComponent } from './hierarchy/hierarchy.component';
import { SelectBoxComponent } from './ui-elements/select-box/select-box.component';
import { CountriesComponent } from './countries/countries.component';
import { StatesComponent } from './states/states.component';
import { AutofocusDirective } from './auto-focus.directive';
import { ActionPrivilegeDirective } from './action-privilege.directive';
import { BillingChargesComponent } from './billing-charges/billing-charges.component';
import { BillingChargesDetailsComponent } from './billing-charges-details/billing-charges-details.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { MeasurementUnitsComponent } from './measurement-units/measurement-units.component';
import { InvoiceDetailsComponent } from './invoice-details/invoice-details.component';
import { ListNavigatorDirective } from './ui-elements/list-navigator/list-navigator.directive';
import { DatePipe } from '@angular/common';
import { CommunityMembersComponent } from './community-members/community-members.component';
import { PropertyTypesComponent } from './property-types/property-types.component';
import { CommunityBuildingsComponent } from './community-buildings/community-buildings.component';
import { DiscountTemplatesComponent } from './discount-templates/discount-templates.component';
import { DiscountTemplateDetailsComponent } from './discount-template-details/discount-template-details.component';
import { LateFeeTemplatesComponent } from './late-fee-templates/late-fee-templates.component';
import { LateFeeTemplateDetailsComponent } from './late-fee-template-details/late-fee-template-details.component';
import { VoucherFilterComponent } from './voucher-filter/voucher-filter.component';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { PaymentsComponent } from './payments/payments.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { UsersComponent } from './users/users.component';
import { PaginationControlComponent } from './ui-elements/pagination-control/pagination-control.component';
import { StaffDetailsComponent } from './staff-details/staff-details.component';
import { StaffsComponent } from './staffs/staffs.component';
import { PaymentFilterComponent } from './payment-filter/payment-filter.component';
import { MaintenanceLogComponent } from './maintenance-log/maintenance-log.component';
import { MaintenanceContractComponent } from './maintenance-contract/maintenance-contract.component';
import { VouchersByTypeComponent } from './vouchers-by-type/vouchers-by-type.component';
import { MaintenanceVisitLogsComponent } from './maintenance-visit-logs/maintenance-visit-logs.component';
import { MaintenanceContractsComponent } from './maintenance-contracts/maintenance-contracts.component';
import { TodosComponent } from './todos/todos.component';
import { RelatedVouchersComponent } from './related-vouchers/related-vouchers.component';
import { TodoDetailsComponent } from './todo-details/todo-details.component';
import { BulkInvoiceComponent } from './bulk-invoice/bulk-invoice.component';
import { AllMaintenanceVisitorLogComponent } from './all-maintenance-visitor-log/all-maintenance-visitor-log.component';
import { AllMaintenanceContractsComponent } from './all-maintenance-contracts/all-maintenance-contracts.component';
import { AllTodoComponent } from './all-todo/all-todo.component';
import { LogFilterComponent } from './log-filter/log-filter.component';
import { DeleteItemComponent } from './delete-item/delete-item.component';
import { AssociateLedgerComponent } from './associate-ledger/associate-ledger.component';
import { ContractFilterComponent } from './contract-filter/contract-filter.component';
import { DiscussionsComponent } from './discussions/discussions.component';
import { DiscussionViewComponent } from './discussion-view/discussion-view.component';
import { DiscussionDetailsComponent } from './discussion-details/discussion-details.component';
import { VouchersComponent } from './vouchers/vouchers.component';
import { CategoryComponent } from './category/category.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DiscussionFilterComponent } from './discussion-filter/discussion-filter.component';
import { ComplainsComponent } from './complains/complains.component';
import { TodoListComponent } from './todo-list/todo-list.component';
import { TodoCommentsComponent } from './todo-comments/todo-comments.component';
import { TodoFilterComponent } from './todo-filter/todo-filter.component';
import { TodoViewComponent } from './todo-view/todo-view.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { LinkProfileComponent } from './link-profile/link-profile.component';
import { ProfileComponent } from './profile/profile.component';
import { FundTransferComponent } from './fund-transfer/fund-transfer.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MembersComponent } from './members/members.component';
import { MemberFilterComponent } from './member-filter/member-filter.component';
import { ChangeRoleComponent } from './change-role/change-role.component';
import { DuesReportComponent } from './dues-report/dues-report.component';
import { CapabilitiesComponent } from './capabilities/capabilities.component';
import { CommunitySettingsComponent } from './community-settings/community-settings.component';
import { StatementsReportComponent } from './statements-report/statements-report.component';
import { RentTemplateComponent } from './rent-template/rent-template.component';
import { BlockwiseIncomeReportComponent } from './blockwise-income-report/blockwise-income-report.component';
import { BlockIncomeDetailsReportComponent } from './block-income-details-report/block-income-details-report.component';
import { BookingCalenderComponent } from './booking-calender/booking-calender.component';
import { BookingsComponent } from './bookings/bookings.component';
import { BookingDetailsComponent } from './booking-details/booking-details.component';
import { BookingFilterComponent } from './booking-filter/booking-filter.component';
import { BookingViewComponent } from './booking-view/booking-view.component';
import { BookingAvalibilityComponent } from './booking-avalibility/booking-avalibility.component';
import { InterTransferReportComponent } from './inter-transfer-report/inter-transfer-report.component';
import { PropertyWonerReportComponent } from './property-woner-report/property-woner-report.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    OtpComponent,
    DashboardComponent,
    MainMenuComponent,
    TopNavComponent,
    LeftMenuComponent,
    BlocksComponent,
    BlockDetailsComponent,
    BuildingsComponent,
    BuildingDetailsComponent,
    CommunitiesComponent,
    UnitsResourcesComponent,
    UnitDetailsComponent,
    ResourceDetailsComponent,
    CommunityDetailsComponent,
    ResourcesComponent,
    AccountGroupComponent,
    AccountGroupDetailsComponent,
    LedgersComponent,
    LedgerDetailsComponent,
    HierarchyComponent,
    SelectBoxComponent,
    CountriesComponent,
    StatesComponent,
    AutofocusDirective,
    ActionPrivilegeDirective,
    BillingChargesComponent,
    BillingChargesDetailsComponent,
    InvoiceComponent,
    MeasurementUnitsComponent,
    InvoiceDetailsComponent,
    ListNavigatorDirective,
    PaginationControlComponent,
    CommunityMembersComponent,
    PropertyTypesComponent,
    CommunityBuildingsComponent,
    DiscountTemplatesComponent,
    DiscountTemplateDetailsComponent,
    LateFeeTemplatesComponent,
    LateFeeTemplateDetailsComponent,
    VoucherFilterComponent,
    PaymentDetailsComponent,
    PaymentsComponent,
    UsersComponent,
    StaffDetailsComponent,
    StaffsComponent,
    PaymentFilterComponent,
    MaintenanceLogComponent,
    MaintenanceContractComponent,
    VouchersByTypeComponent,
    MaintenanceVisitLogsComponent,
    MaintenanceContractsComponent,
    TodosComponent,
    RelatedVouchersComponent,
    TodoDetailsComponent,
    BulkInvoiceComponent,
    AllMaintenanceVisitorLogComponent,
    AllMaintenanceContractsComponent,
    AllTodoComponent,
    LogFilterComponent,
    DeleteItemComponent,
    AssociateLedgerComponent,
    ContractFilterComponent,
    DiscussionsComponent,
    DiscussionViewComponent,
    DiscussionDetailsComponent,
    VouchersComponent,
    CategoryComponent,
    DiscussionFilterComponent,
    ComplainsComponent,
    TodoListComponent,
    TodoCommentsComponent,
    TodoFilterComponent,
    TodoViewComponent,
    LinkProfileComponent,
    ProfileComponent,
	FundTransferComponent,
	ResetPasswordComponent,
	MembersComponent,
	MemberFilterComponent,
	ChangeRoleComponent,
	DuesReportComponent,
	CapabilitiesComponent,
	CommunitySettingsComponent,
	StatementsReportComponent,
	RentTemplateComponent,
	BlockwiseIncomeReportComponent,
	BlockIncomeDetailsReportComponent,
	BookingCalenderComponent,
	BookingsComponent,
	BookingDetailsComponent,
	BookingFilterComponent,
	BookingViewComponent,
	BookingAvalibilityComponent,
	InterTransferReportComponent,
	PropertyWonerReportComponent
  ],
  imports: [
    BrowserModule,
    NgxPaginationModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot(),
    ToastrModule.forRoot(),
    NoopAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ApiClientModule,
    DpDatePickerModule,
    GoogleChartsModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    CKEditorModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: WebServiceInterceptor, multi: true },
    PreviousRouteService,
    DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
