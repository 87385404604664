import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../../app/config';
import { PropertyModel } from '../Model/property.model';
import { MaintenanceLogModel } from '../Model/maintenance-log.model';
import { MaintenanceContractModel } from '../Model/maintenance-contract.model';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceContractService {

  constructor(private _http: HttpClient) {
  }

  all(data: any) {
    return this._http
      .post(config.apiEndPointBase + "/contract/list", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  filterContract(data: any) {
    return this._http
      .post(config.apiEndPointBase + "/contract/list", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  details(id: any) {
    return this._http
      .get(config.apiEndPointBase + `/contract/${id}`, {});
  }

  save(data: MaintenanceContractModel): Observable<MaintenanceContractModel> {
    if (data.id) {
      return this._http.put(config.apiEndPointBase + "/contract", data, { responseType: 'text' })
        .pipe(map((response: any) => {
          return new MaintenanceContractModel().deserialize(response);
        }));
    } else {
      return this._http.post(config.apiEndPointBase + "/contract", data, {})
        .pipe(map((response: any) => {
          return new MaintenanceContractModel().deserialize(response);
        }));
    }
  }

  delete(id: number) {
    return this._http.delete(config.apiEndPointBase + `/contract/${id}`, { responseType: 'text' });
  }

  getContracts(resourceId: number) {
    return this._http.get(config.apiEndPointBase + `/property/${resourceId}`);
  }

  saveMedia(data: any) {
    return this._http.post(config.apiEndPointBase + "/contract/save_media", data, { responseType: 'text' })
      .pipe(map((response: any) => {
        return response;
      }));
  }
  
  deleteMedia(id: number) {
    return this._http.delete(config.apiEndPointBase + `/contract/delete_media/${id}`, { responseType: 'text' });
  }

}
