import { Component, OnInit } from '@angular/core';
import { BlockService } from '../api-client/block.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../api-client/user.service';
import { async } from '@angular/core/testing';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { UiService } from '../ui.service';
import { CommonMethods } from '../common-methods';
import { PreviousRouteService } from '../api-client/previous-route.service';

@Component({
  selector: 'app-blocks',
  templateUrl: './blocks.component.html',
  styleUrls: ['./blocks.component.css']
})
export class BlocksComponent implements OnInit {

  blocks: any;
  filteredBlocks: any;
  routeParams: any;
  routeExtras: NavigationExtras;
  multiSelect: false;

  constructor(
    private _blockService: BlockService,
    private _tosterService: ToastrService,
    private _userService: UserService,
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this.routeExtras = this._router.getCurrentNavigation().extras;
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      if (params.mode=="BlockSelections" && params.multiSelect) {
        this.multiSelect = params.multiSelect;
      }
      this.list();
    });
    
  }

  list() {
    var data = { communityId: CommonMethods.getCommunityId() };
    this._blockService.all(data).subscribe((result: any) => {
      this.blocks = result;
      this.filteredBlocks = this.blocks;
    })
    this._route.params.subscribe(data => {
      this.routeParams = data;
    })
  }

  filterBlock(eve: any) {
    var code = eve.keyCode;
    if (code == 37 || code == 38 || code == 39 || code == 40) {
      return;
    }
    var ele = eve.target;
    var items: any;
    if (ele.value) {
      this.filteredBlocks = this.blocks.filter((x: any) => x.communityBlockName.toLowerCase().includes(ele.value.toLowerCase()));
    } else {
      //this.list();
    }
  }

  details(item: any) {
    //this._uiService.changeBlockDetails.next(item);
    //debugger;

    switch (this.routeParams.mode) {
      case 'BuildingSelections':
      case 'PropertySelections':
      case 'ResourceSelections':
      case 'RentInvoiceCreation':  
        let params = Object.assign({ blockId: item.id }, this.routeParams);
        this._router.navigate([{
          outlets: { rightMainPopup: ['buildings', params] }
        }], this.routeExtras);
        break;
      default:
        if (this._uiService.activeListCallBack) {
          this._uiService.activeListCallBack(item.id, item.communityBlockName);
          this._uiService.activeListCallBack = null;
          this._uiService.closeItself();
          return;
        }
        this._router.navigateByUrl("block/" + item.id);

    }
    return false;
  }

  redirectTo(item: any) {
    this._router.navigateByUrl("block/" + item.id);
  }

  findMatchingItemInSelectedItems(id:any){
    if (this.routeExtras && this.routeExtras.state && this.routeExtras.state.selectedItems) {
      let items = this.routeExtras.state.selectedItems.filter((x:any)=>x.id==id);
      if(items.length>0){
        return items[0];
      }
    }
  }

  itemSelectChange(eve: any, block: any) {
    if (this.routeExtras && this.routeExtras.state && this.routeExtras.state.selectedItems) {
      if (eve.target.checked) {
        this.routeExtras.state.selectedItems.push({id:block.id, name:block.communityBlockName});
      }else{
        let item = this.findMatchingItemInSelectedItems(block.id);
        if(item){
          let index = this.routeExtras.state.selectedItems.indexOf(item);
          this.routeExtras.state.selectedItems.splice(index, 1);
        }
      }
    }
  }

}
