import { Deseriazable } from './deserializable.model';

export class PropertyModel implements Deseriazable {
    id: number;
    communityId: number;
    buildingId: number;
    communityBlockId: number;
    propertyType: number;
    ownerId: number;
    name: string;
    unitNo: string;
    floor: string;
    superBuildArea: number;
    buildUpArea: number;
    carpetArea: number;
    description: string;
    category: string;
    assignTo: string;
    assignMobile: string;
    propertySettings: string
    customLastPaidPreriod: any;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}