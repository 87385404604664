import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { LedgerService } from '../api-client/ledger.service';
import { PropertyService } from '../api-client/property.service';
import { BuildingService } from '../api-client/building.service';
import { ResourceService } from '../api-client/resource.service';
import { BlockService } from '../api-client/block.service';
import { UiService } from '../ui.service';
import { APP_CONST } from '../app-constants';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-payment-filter',
  templateUrl: './payment-filter.component.html',
  styleUrls: ['./payment-filter.component.css']
})
export class PaymentFilterComponent implements OnInit {

  selType:string = 'Bank';
  propertyUrl: any;
  propertyTypes: any;
  filterParams: any = {
    selectedPropertyType: null,
    propertyId: null,
    propertySelectedText: '',
    invoiceFrom: null,
    invoiceTo: null,
    paymentMethod: '',
    ledgerToId: null,
    ledgerFromId: null,
    keyword: null,
    invoiceType: null,
    paymentType:null,
    accountSelectedText: '',
    blockId: null,
    blockSelectedText: '',
    buildingId: null,
    buildingSelectedText: ''
  };
  datepickeroptions = {
    format: "YYYY-MM-DD",
  };
  societyInvoiceType:number = APP_CONST.INVOICE_TYPE_SOCIETY;
  rentInvoiceType:number = APP_CONST.INVOICE_TYPE_RENT;
  purchaseInvoiceType:number =  APP_CONST.INVOICE_TYPE_PURCHASE;
  transferInvoiceType:number = APP_CONST.INVOICE_TYPE_TRANSFERS;
  assetPropertyType:number = APP_CONST.PROPERTY_TYPE_ASSETS;
  buildingPropertyType:number = APP_CONST.PROPERTY_TYPE_BUILDINGS;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _propertyService: PropertyService,
    private _buildingService: BuildingService,
    private _resourceService: ResourceService,
    private _blockService: BlockService,
    private _ledgerService: LedgerService,
    private _uiService: UiService) {
  }

  /*---Get all Property Types from DB---*/
  fillPropertyTypes(): void {
    this._propertyService.types().subscribe((result: any) => {
      this.propertyTypes = result;
      this.propertyTypeChanged();
    });
  }

  /*---Triggered when property type changed---*/
  propertyTypeChanged(): void {
    if (this.filterParams.selectedPropertyType > 0) {
      if (this.filterParams.selectedPropertyType == this.assetPropertyType) {
        this.propertyUrl = { "mode": "ResourceSelections" };
      } else if (this.filterParams.selectedPropertyType == this.buildingPropertyType) {
        this.propertyUrl = { "mode": "BuildingSelections" };
      } else {
        this.propertyUrl = { "mode": "PropertySelections", "type": this.filterParams.selectedPropertyType };
      }
    }
  }

  ngOnInit(): void {
    if(!this._uiService.activeFilterData){
      this.closeItself();
      return;
    }
    this.filterParams = this._uiService.activeFilterData;
    this.fillPropertyTypes();
  }

  /*---Clear Invoice From Date from filter---*/
  clearInvoiceFrom(): void {
    this.filterParams.invoiceFrom = null;
  }

  /*---Clear Invoice To Date from filter---*/
  clearInvoiceTo(): void {
    this.filterParams.invoiceTo = null;
  }

  /*---Triggered when account changed from filter---*/
  accountChanged(): void {
    if(this.filterParams.invoiceType == this.societyInvoiceType || this.filterParams.invoiceType == this.rentInvoiceType){
      if(this.filterParams.ledgerFromId > 0){
        this._ledgerService.details(this.filterParams.ledgerFromId).subscribe((result:any)=>{
          this.filterParams.accountSelectedText = result.ledgerName;
        }, error => {
          this.filterParams.accountSelectedText = null;
        });
      }else{
        this.filterParams.accountSelectedText = null;
      }
    }else{
      if(this.filterParams.ledgerToId > 0){
        this._ledgerService.details(this.filterParams.ledgerToId).subscribe((result:any)=>{
          this.filterParams.accountSelectedText = result.ledgerName;
        }, error => {
          this.filterParams.accountSelectedText = null;
        });
      }else{
        this.filterParams.accountSelectedText = null;
      }
    }
  }

  /*---Triggered when property changed from filter---*/
  propertyChanged(): void {
    if(this.filterParams.propertyId > 0){
      if (this.filterParams.selectedPropertyType == this.assetPropertyType) {
        this._resourceService.details(this.filterParams.propertyId).subscribe((result:any)=>{
          this.filterParams.propertySelectedText = result.resourceName;
        }, error => {
          this.filterParams.propertySelectedText = null;
        });
      } else if (this.filterParams.selectedPropertyType == this.buildingPropertyType) {
        this._buildingService.details(this.filterParams.propertyId).subscribe((result:any)=>{
          this.filterParams.propertySelectedText = result.name;
        }, error => {
          this.filterParams.propertySelectedText = null;
        });
      } else {
        this._propertyService.details(this.filterParams.propertyId).subscribe((result:any)=>{
          this.filterParams.propertySelectedText = result.unitNo;
        }, error => {
          this.filterParams.propertySelectedText = null;
        });
      }
    }else{
      this.filterParams.propertySelectedText = null;
    }
  }

  /*---Triggered when block changed from filter---*/
  blockChanged(): void {
    if(this.filterParams.blockId > 0){
      this._blockService.details(this.filterParams.blockId).subscribe((result:any)=>{
        this.filterParams.blockSelectedText = result.communityBlockName;
      }, error => {
        this.filterParams.blockSelectedText = null;
      });
    }else{
      this.filterParams.blockSelectedText = null;
    }
  }

  /*---Triggered when building changed from filter---*/
  buildingChanged(): void {
    if(this.filterParams.buildingId > 0){
      this._buildingService.details(this.filterParams.buildingId).subscribe((result:any)=>{
        this.filterParams.buildingSelectedText = result.name;
      }, error => {
        this.filterParams.buildingSelectedText = null;
      });
    }else{
      this.filterParams.buildingSelectedText = null;
    }
  }

  /*---Close Filter Panel---*/
  closeItself(): void {
    this._router.navigate(
      [
        "../",
        {
          outlets: {
            leftMainPopup: null
          }
        }
      ],
      {
        relativeTo: this._route
      }
    );
  }

  /*---Submit filter data---*/
  applyFilter(): void {
    this._uiService.activeFilterCallBack();
    this.closeItself();
  }

  /*---Clear filter data---*/
  clearFilter(): void {
    this.filterParams.selectedPropertyType = null;
    this.filterParams.propertyId = null,
    this.filterParams.propertySelectedText = '';
    this.filterParams.invoiceFrom = null;
    this.filterParams.invoiceTo = null;
    this.filterParams.paymentMethod = null;
    this.filterParams.ledgerToId = null;
    this.filterParams.ledgerFromId = null;
    this.filterParams.keyword = null;
    this.filterParams.paymentType = null;
    this.filterParams.accountSelectedText = '';
    this.filterParams.blockId = null;
    this.filterParams.blockSelectedText = '';
    this.filterParams.buildingId = null;
    this.filterParams.buildingSelectedText = '';
  }

}
