import { Component, OnInit, HostListener, AfterViewChecked, OnDestroy, AfterViewInit, ElementRef } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {MENU} from '../../assets/menuitems';
import { Router } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { createHash } from 'crypto';
import { keyframes } from '@angular/animations';
import { UiService } from '../ui.service';
import { APP_CONST } from '../app-constants';


@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit, AfterViewChecked, OnDestroy, AfterViewInit {

  observer: any;
  currentIndex = 0;
  menuItems: any;
  dataItems = MENU;
  parentId = 0;
  elements = null;
  parentIdsStack = [0];

  constructor(
    private httpClient: HttpClient,
    private _router: Router,
    private _eleRef: ElementRef,
    private _uiService: UiService
  ) { }

  ngOnInit() {
    this._uiService.leftMenuItems.next([
      { code: 'F10', name: 'Settings', action: this.redirectToCommunitySetting.bind(this), privilege: APP_CONST.COMMUNITY_READ },
      { code: 'F11', name: 'Community Detail', action: this.redirectToCommunity.bind(this), privilege: APP_CONST.COMMUNITY_READ },
      { code: 'F12', name: 'Select Community', action: this.openSelectCommunityPopup.bind(this), privilege: APP_CONST.COMMUNITY_READ }
    ]);
    this.getItems();
  }

  @HostListener('window:focus', ['$event'])
  handleWindowFocusEvent(event: FocusEvent) {
    this._eleRef.nativeElement.tabindex = -1;
    this._eleRef.nativeElement.focus();
  }

  ngAfterViewInit(): void {
    this._uiService.useEscToBack = false;
    this._eleRef.nativeElement.tabindex = -1;
    this._eleRef.nativeElement.focus();
    const node = this._eleRef.nativeElement.querySelector('ul');
    this.observer = new MutationObserver((mutations) => {
      this.onElementsListChange();
    });

    this.observer.observe(node, {
      childList: true,
      characterData: true
    });
  }

  onElementsListChange() {
    if (!this.elements || this.elements == null) {
      this.elements = this._eleRef.nativeElement.querySelectorAll('ul > li.menu-item');
      this.currentIndex = 0;
      if (this.elements.length > 0) {
        this.elements[0].classList.add('active');
      } else {
        this.currentIndex = -1;
      }
    }
  }

  onBack() {
    this.currentIndex = 0;
    this.elements = null;
    let prevParentId = 0;
    if (this.parentIdsStack.length > 1) {
      prevParentId = this.parentIdsStack.pop();
      if(this.parentIdsStack.length==1){
        prevParentId = 0;
      }
    }

    this.menuItems = this.getSingleLavelWithChildren(this.dataItems, prevParentId);
  }

  ngOnDestroy(): void {
    this._uiService.useEscToBack = true;
    this._uiService.leftMenuItems.next([]);
    this.observer.disconnect();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.elements == null) return true;

    if (event.keyCode == 27 && this.parentIdsStack.length > 0) {
      this.onBack();
      event.stopPropagation();
      event.preventDefault();
      return false;
    } else if (event.keyCode >= 65 && event.keyCode <= 90) {//select top lavel
      let index = event.keyCode - 65;
      if (index < this.elements.length) {
        this.elements[this.currentIndex].classList.remove('active');
        this.currentIndex = index;
        this.elements[index].classList.add('active');
      }
    } else if (event.keyCode >= 49 && event.keyCode <= 57 && this.currentIndex >= 0) {//select top lavel
      let index = event.keyCode - 49;
      let childElements = this.elements[this.currentIndex].querySelectorAll('li a');
      if (index < childElements.length) {
        childElements[index].click();
      }
    }
  }


  ngAfterViewChecked() {
    this.onElementsListChange();
  }

  getItems() {
    this.menuItems = this.getNestedChildren(this.dataItems, this.parentId);
  }

  item(element: any) {
    if (element.action) {
      this.redirectTo(element);
    } else {
      this.currentIndex = 0;
      this.elements = null;
      if (element.parent_id != 0) {
        this.parentIdsStack.push(element.parent_id);
        console.log(this.parentIdsStack);
        document.querySelector(".dynamicWidth").classList.remove("w-50");
        document.querySelector(".dynamicWidth").classList.add("w-25");
      }
      this.menuItems = this.getSingleLavelWithChildren(this.dataItems, element.id);
    }
  }

  getSingleLavelWithChildren(items: any, parentId: any) {
    let mainItem: any;
    let children = [];
    for (var i in items) {
      if (items[i].id == parentId) {
        mainItem = items[i];
      } else if (items[i].parent_id == parentId) {
        children.push(items[i]);
      }
    }
    if (mainItem) {
      mainItem.children = children;
      return [mainItem];
    } else {
      return children;
    }
  }

  getNestedChildren(items: any, parentId: any) {
    var newItems = [];
    for (var i in items) {
      if (items[i].parent_id == parentId) {
        var children = this.getNestedChildren(items, items[i].id);
        if (children.length > 0) {
          items[i].children = children;
        }
        newItems.push(items[i]);
      }
    }
    return newItems;
  }

  replaceShortcut(value: any) {
    return value.title.replace(value.shortcut, `<strong>${value.shortcut}</strong>`);
  }

  redirectTo(item: any) {
    this._router.navigate(item.action);
  }

  getChar(index: number) {
    return String.fromCharCode(index + 65);
  }

  openSelectCommunityPopup() {
    this._router.navigate(['/', { outlets: { fullOutlet: ['community'] } }]);
  }

  redirectToCommunity() {
    this._router.navigateByUrl('community-details');
  }

  redirectToCommunitySetting() {
    this._router.navigateByUrl('community-settings');
  }
}
