import { Deseriazable } from './deserializable.model';

export class LinkProfileModel implements Deseriazable
{
    id: number;
    name: string;
    mobile: string;
    email: string;
    ledgerId: number;
    message: string;
    communityId : string;
    status : number;    

    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }
}