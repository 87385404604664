import { Component, OnInit, Input, forwardRef, AfterViewInit, HostListener, OnChanges, ViewEncapsulation, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';
import { UiService } from 'src/app/ui.service';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectBoxComponent),
  multi: true
};

@Component({
  selector: 'app-select-box',
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class SelectBoxComponent implements OnInit, ControlValueAccessor {


  @Input() listNavigation: any;
  @Input() selectedText: any;
  @Input() defaultText: any;
  @Input() setValueIncludeName: any = false;
  @Input() inputCssClass: any;

  @Output() change:EventEmitter<any> = new EventEmitter();

  selectedValue: any;
  propagateChange = (_: any) => { }
  doingSetFocusOnValueSelected:boolean = false;

  constructor(
    private _router: Router,
    private _uiService: UiService,
    private _elRef:ElementRef
  ) { }

  writeValue(obj: any): void {
    if (obj instanceof Object) {
      this.selectedValue = obj.id;
    } else {
      this.selectedValue = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState?(isDisabled: boolean): void {

  }

  ngOnInit() {
  }

  listCallBack(id: any, name: any) {
    this.selectedText = name;
    this.selectedValue = id;
    
    this.doingSetFocusOnValueSelected = true;
    this._elRef.nativeElement.querySelector('input[type=text]').focus();
    this.doingSetFocusOnValueSelected = false;

    this.notifyChanges();
    this._uiService.activeListCallBack = null;
  }

  notifyChanges(){
    let value = null;
    if (this.setValueIncludeName) {
      value = {
        "id": this.selectedValue,
        "name": this.selectedText
      };
    }else{
      value = this.selectedValue;
    }
    this.propagateChange(value);
    this.change.emit(value);
  }

  openList() {
    if(this.doingSetFocusOnValueSelected) return;
    this._uiService.activeListCallBack = this.listCallBack.bind(this);
    var params = [{ "outlets": { "rightMainPopup": this.listNavigation } }];
    this._router.navigate(params);
  }

  clear() {
    this.selectedText = "";
    this.selectedValue = null;
    this.notifyChanges();
  }

}
