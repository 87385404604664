import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject,Observable, of  } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../config';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {  

  constructor(private _http: HttpClient) {
  }

  all(){
    return this._http
      .post(config.apiEndPointBase + "/voucher_template/list", {}, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  create(data:any){
    return this._http
      .post(config.apiEndPointBase + "/voucher", data,  {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  update(data:any){
    return this._http
      .put(config.apiEndPointBase + "/voucher", data,  {responseType: 'text'})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  details(id:number){
    return this._http.get(config.apiEndPointBase + `/voucher/${id}`);
  }

  getVoucher(id:number){
    return this._http.get(config.apiEndPointBase + `/voucher/${id}`);
  }

  measurementUnits(){
    return this._http.get(config.apiEndPointBase + `/units`);
  }

  listByType(type:number,data:any){
    return this._http
      .post(config.apiEndPointBase + `/voucher/list/${type}`, data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  filterVoucher(data:any){
    return this._http
      .post(config.apiEndPointBase + `/voucher/filter`, data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  cancelVoucher(id:number,data:any){
    return this._http
      .post(config.apiEndPointBase + `/voucher/${id}/cancel`, data, {responseType: 'text'})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  checkBulkInvoice(data:any){
    return this._http
      .post(config.apiEndPointBase + `/voucher/checkBulkInvoice`, data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  markPaid(data:any){
    return this._http
      .post(config.apiEndPointBase + `/voucher/markPaid`, data, {responseType: 'text'})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  downloadVoucher(voucherId:number){
    return this._http.get(config.apiEndPointBase + `/voucher/download/${voucherId}`);
  }

  exportInvoicesSummary(data: any){
    return this._http
      .post(config.apiEndPointBase + `/voucher/exportSummary`, data, { responseType: 'blob' })
      .subscribe((res: any) => {
        saveAs(res, `invoice-summary.xls`);
      });
  }

  exportInvoicesDetails(data: any){
    return this._http
      .post(config.apiEndPointBase + `/voucher/exportDetails`, data, { responseType: 'blob' })
      .subscribe((res: any) => {
        saveAs(res, `invoice-details.xls`);
      });
  }

  exportLedgerDues(data: any){
    return this._http
      .post(config.apiEndPointBase + `/voucher/exportLedgerDues`, data, { responseType: 'blob' })
      .subscribe((res: any) => {
        saveAs(res, `accountwise-dues.xls`);
      });
  }

  exportPropertyDues(data: any){
    return this._http
      .post(config.apiEndPointBase + `/voucher/exportPropertyDues`, data, { responseType: 'blob' })
      .subscribe((res: any) => {
        saveAs(res, `propertywise-dues.xls`);
      });
  }

  exportPropertyOwner(data: any){
    return this._http
      .post(config.apiEndPointBase + `/property/exportPropertyOwner`, data, { responseType: 'blob' })
      .subscribe((res: any) => {
        saveAs(res, `propertywise-owner.xls`);
      });
  }

  exportStatement(data: any){
    return this._http
      .post(config.apiEndPointBase + `/voucher/exportStatement`, data, { responseType: 'blob' })
      .subscribe((res: any) => {
        saveAs(res, `statement.xls`);
      });
  }
  
  exportBlockwiseReport(data: any){
    return this._http
      .post(config.apiEndPointBase + `/voucher/block_waise_statement`, data, { responseType: 'blob' })
      .subscribe((res: any) => {
        saveAs(res, `blockwise-income-report.xls`);
      });
  }

  exportBlockDetailsReport(data: any){
    return this._http
      .post(config.apiEndPointBase + `/voucher/block_detail_statement`, data, { responseType: 'blob' })
      .subscribe((res: any) => {
        saveAs(res, `block-details-income-report.xls`);
      });
  }
  
  exportInterTransferReport(data: any){
    return this._http
      .post(config.apiEndPointBase + `/voucher/monthly_inter_transfer_statement`, data, { responseType: 'blob' })
      .subscribe((res: any) => {
        saveAs(res, `Inter-Transfer-Report.pdf`);
      });
  }
    
}
