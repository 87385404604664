import { Component, OnInit } from '@angular/core';
import { ResourceService } from '../api-client/resource.service';
import { BlockService } from '../api-client/block.service';
import { BuildingService } from '../api-client/building.service';
import { ToastrService } from 'ngx-toastr';
import { CommunityService } from '../api-client/community.service';
import { LocationService } from '../api-client/location.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { UiService } from '../ui.service';
import { ResourceModel } from './../Model/resource.model';
import { ResourceFormBuilder } from './resource.form-builder';
import { CommonMethods } from '../common-methods';
import { PropertyService } from '../api-client/property.service';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { VoucherService } from '../api-client/voucher.service';
import { MaintenanceLogService } from '../api-client/maintenancelog.service';
import { MaintenanceContractService } from '../api-client/maintenancecontract.service';
import { ThrowStmt } from '@angular/compiler';
import { APP_CONST } from '../app-constants';

@Component({
  selector: 'app-resource-details',
  templateUrl: './resource-details.component.html',
  styleUrls: ['./resource-details.component.css']
})
export class ResourceDetailsComponent implements OnInit {

  resourceDetails: any;
  resourceForm: any;
  submitted = false;
  buildingId: any;
  communityBlockId: any;
  resourceId = null;
  buildingName = 'Select Building';
  blockName = 'Select Block';
  propertyName = 'Select Property';
  unitName = 'Select Unit';
  resourceName: any;
  model = new ResourceModel();
  allMeasurements: any;
  maintenanceContractItems: any;
  maintenanceLogItems: any;
  routeParams: any;
  linkItemType: any;
  linkItemId: any;
  showBlock: boolean = true;
  showBuilding: boolean = true;
  isActive: boolean = false;
  deactivatedOn: boolean = true;
  deactivationReason: boolean = true;
  selectedBuildingId = 0;
  selectedBlockId = 0;
  deleteParams: any = {
    itemId: 0,
    itemName: null,
    btnOk: "Delete",
    btnCancel: "Close",
    deleteText: "Are you sure, you want to delete this resource?"
  }
  error = [];
  public config = <IDayCalendarConfig>{
    format: "YYYY-MM-DD HH:mm:ss"
  };
  routeExtras: NavigationExtras;

  constructor(
    private _blockService: BlockService,
    private _buildingService: BuildingService,
    private _tosterService: ToastrService,
    private _communityService: CommunityService,
    private _locationService: LocationService,
    private _resourceService: ResourceService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _voucherService: VoucherService,
    private _uiService: UiService,
    private _propertyService: PropertyService,
    private _maintenanceLogService: MaintenanceLogService,
    private _maintenanceContractService: MaintenanceContractService,
    private _formBuilder: ResourceFormBuilder
  ) {
    this.routeExtras = this._router.getCurrentNavigation().extras;
  }

  ngOnInit() {

    this._uiService.leftMenuItems.next([
      { code: 'F4', name: 'Save', action: this.save.bind(this), class: 'green lighten-1', privilege: APP_CONST.RESOURCE_WRITE },
      { code: 'F5', name: 'Save & Add New', action: this.saveAndNew.bind(this), privilege: APP_CONST.RESOURCE_WRITE },
      { code: 'F7', name: 'Delete', action: this.confirmationPopUp.bind(this), class: 'red lighten-1', privilege: APP_CONST.RESOURCE_REMOVE }
    ]);
    this.resourceDetails = {};
    this.measurementUnits();
    this.resourceForm = this._formBuilder.getForm();
    this._route.params.subscribe(params => {
      if (params.id > 0) {
        this.details(params.id);
        this.routeParams = params;
      }else{
        this.resetForm();
      }
      this._uiService.linkItem = { id: params.id, type: APP_CONST.PROPERTY_TYPE_ASSETS, name: null };
      this.linkItemType = APP_CONST.PROPERTY_TYPE_ASSETS;
      this.linkItemId = params.id;
    });
    this.changeState();
    this.onChanges();
  }

  get f() { return this.resourceForm.controls; }

  details(id: number) {
    this._resourceService.details(id).subscribe((result: any) => {
      this._uiService.linkItem.name = result.resourceName;
      this.resourceId = result.id;
      this._formBuilder.setValues(result);
      this.resourceName = result.resourceName;
      this.deleteParams.itemId = result.id;
      this.deleteParams.itemName = result.resourceName;
      if (result.buildingId) {
        this.selectedBuildingId = result.buildingId;
        this.buildingDetails(result.buildingId);
      }
      if (result.communityBlockId) {
        this.selectedBlockId = result.communityBlockId;
        this.blockDetails(result.communityBlockId);
      }
      if (result.isActive) {
        this.resourceForm.controls['deactivatedOn'].disable();
        this.resourceForm.controls['deactivationReason'].disable();
      } else {
        this.resourceForm.controls['deactivatedOn'].enable();
        this.resourceForm.controls['deactivationReason'].enable();
      }
      if (result.propertyId) {
        this._propertyService.details(result.propertyId).subscribe((result: any) => {
          this.propertyName = result.unitNo;
        });
      }
      if (result.measurementUnitId) {
        var measurementUnits = this.allMeasurements.filter((x: any) => x.id == result.measurementUnitId);
        if (measurementUnits.length > 0) {
          this.unitName = measurementUnits[0].name;
        }
      }
      //this.contract();
    })
  }

  async onSubmit() {
    this.submitted = true;
    if (this.resourceForm.invalid) {
      return;
    }
    if (this.resourceForm.get('purchaseOn').value != "" && this.resourceForm.get('installOn').value != "") {
      var purchaseDate = new Date(this.resourceForm.get('purchaseOn').value);
      var installDate = new Date(this.resourceForm.get('installOn').value);
      if (purchaseDate.getTime() > installDate.getTime()) {
        this._tosterService.error("Purchase date not greater than install date");
        this.error.push("Purchase date not greater than install date");
        return false;
      }
    }
    if (this.resourceForm.get('isActive').value == "" && this.resourceForm.get('deactivatedOn').value == "") {
      this._tosterService.error("Presently in working condition or not, Not in working from date");
      this.error.push("Presently in working condition or not, Not in working from date");
      return;
    }
    this.model.id = this.resourceId;
    this.model.deserialize(this.resourceForm.value);
    this.model.communityId = Number(CommonMethods.getCommunityId());
    if (!this.showBuilding) {
      this.model.buildingId = 0;
    }
    if (!this.showBlock) {
      this.model.communityBlockId = 0;
    }
    if (!this.model.buildingId && !this.model.communityBlockId) {
      this._tosterService.error("Either Building or block have to select.");
      this.error.push("Either Building or block have to select.");
      return false;
    }
    await this._resourceService.save(this.model)
      .toPromise().then(
        (response: any) => {
          if (response) {
            this._tosterService.success("Resource save");
            if (!this.resourceId) {
              this.resourceId = response.id;
            }
            this.error = [];
          }else{
            this.error.push("Server error");
          }
        },
        error => {
          this._tosterService.error(error.message);
          this.error.push(error.message);
        }
      )
  }

  async save() {
    await this.onSubmit();
    if (this.error.length > 0) return;
    setTimeout(() => {
      if (this.resourceId > 0) {
        this._router.navigateByUrl(`resource/${this.resourceId}`);
        return;
      }
    }, 1000);
  }

  async saveAndNew() {
    await this.onSubmit();
    if (this.error.length > 0) return;
    if (!this.resourceForm.invalid) {
      this.resetForm();
    }
  }

  confirmationPopUp() {
    if (this.resourceId > 0) {
      this._uiService.deleteItemData = this.deleteParams;
      this._uiService.deleteItemCallBack = this.delete.bind(this);
      this._router.navigate([{
        outlets: { centerPopup: ['delete-item'] }
      }]);
    }
  }

  delete() {
    if (this.deleteParams.pressBtn == "Ok") {
      this._resourceService.delete(this.deleteParams.itemId).subscribe((result: any) => {
        this._tosterService.success("Resource Deleted");
        this.resetForm();
        return;
      });
    }
  }

  resetForm() {
    this.submitted = false;
    this.resourceId = null;
    this.buildingName = 'Select Building';
    this.blockName = 'Select Block';
    this.propertyName = 'Select Property';
    this.unitName = 'Select Unit';
    this.showBuilding = true;
    this.showBlock = true;
    this.isActive = false;
    this.deactivatedOn = true;
    this.deactivationReason = true;
    this.resourceName = "";
    this.error = [];
    this.resourceForm.reset();
    setTimeout(() => {
      this._router.navigateByUrl("resource/0");
    }, 500)
  }

  measurementUnits() {
    this._voucherService.measurementUnits().subscribe((result: any) => {
      this.allMeasurements = result;
    })
  }

  async contract() {
    if (!this.resourceId) return;
    var data = { communityId: CommonMethods.getCommunityId(), linkItemType: 'Resource', linkItemId: this.resourceId }
    await this._maintenanceContractService.all(data).toPromise().then((result: any) => {
      this.maintenanceContractItems = result;
    })
  }

  redirectTo(item: any, arg: any) {
    this._router.navigate([{
      outlets: { leftMainPopup: [arg, item.id] }
    }]);
    //this._router.navigateByUrl(`${arg}/${item.id}`);
    return;
  }

  blockDetails(id: any) {
    this.showBlock = true;
    this.showBuilding = false;
    this._blockService.details(id).subscribe((result: any) => {
      this.blockName = result.communityBlockName;
    });
  }

  buildingDetails(id: any) {
    this.showBlock = false;
    this.showBuilding = true;
    this._buildingService.details(id).subscribe((result: any) => {
      this.buildingName = result.name;
      this.selectedBlockId = result.communityBlockId;
    });
  }

  onChanges(): void {
    this.resourceForm.get('buildingId').valueChanges.subscribe((val: any) => {
      this.buildingName = 'Select Building';
      this.showBlock = true;
      if (val) {
        this.showBlock = false;
        this.showBuilding = true;
      }

    });
    this.resourceForm.get('communityBlockId').valueChanges.subscribe((val: any) => {
      this.blockName = 'Select Block';
      this.showBuilding = true;
      if (val) {
        this.showBuilding = false;
        this.showBlock = true;
      }

    });
    this.resourceForm.get('isActive').valueChanges.subscribe((val: any) => {
      if (val) {
        this.resourceForm.controls['deactivatedOn'].disable();
        this.resourceForm.controls['deactivationReason'].disable();
      } else {
        this.resourceForm.controls['deactivatedOn'].enable();
        this.resourceForm.controls['deactivationReason'].enable();
      }
    });
  }
  setBlockValue(id: any) {
    this.blockDetails(id);
    this.resourceForm.controls['communityBlockId'].setValue(id);
  }

  setBuildingValue(id: any) {
    this.buildingDetails(id);
    this.resourceForm.controls['buildingId'].setValue(id);
  }

  changeState() {
    if (this.routeExtras && this.routeExtras.state && this.routeExtras.state.itemValue) {
      if (this.routeExtras.state.itemName == "block") {
        this.selectedBlockId = this.routeExtras.state.itemValue;
        this.setBlockValue(this.routeExtras.state.itemValue);
      }
      if (this.routeExtras.state.itemName == "building") {
        this.selectedBuildingId = this.routeExtras.state.itemValue;
        this.setBuildingValue(this.routeExtras.state.itemValue);
      }
    }
    if (this.routeExtras && this.routeExtras.state && this.routeExtras.state.blockId && this.routeExtras.state.buildingId) {
      this.selectedBlockId = this.routeExtras.state.blockId;
      this.selectedBuildingId = this.routeExtras.state.buildingId;
    }
  }

  ngOnDestroy(): void {
    this._uiService.leftMenuItems.next([]);
  }

  redirectionType(item: any, itemName: any, itemValue: any, mode: any, displayType: any) {
    let params: any;
    if (displayType == 'listing') {
      if (mode == "block") {
        params = [{ outlets: { rightMainPopup: ["blocks", { "mode": "BlockSelections" }] } }];
      } else if (mode == "dashboard") {
        this._router.navigateByUrl("/dashboard"); return;
      }
      this._router.navigate(params); return;
    }
  }

  redirectToBuildingList() {
    let params: any;
    if (this.selectedBuildingId > 0) {
      params = Object.assign({ blockId: this.selectedBlockId });
      this._router.navigate([{
        outlets: { rightMainPopup: ['buildings', params] }
      }]);
    } else {
      params = [{ outlets: { rightMainPopup: ["blocks", { "mode": "BuildingSelections" }] } }];
      this._router.navigate(params);
    }
  }

  redirectToResourceList() {
    let params = Object.assign({ buildingId: this.selectedBuildingId, blockId: this.selectedBlockId, "mode": "ResourceSelections" });
    this._router.navigate([{
      outlets: { rightMainPopup: ['resources', params] }
    }]);
  }

}
