import { Component, OnInit } from '@angular/core';
import { CommonMethods } from '../common-methods';
import { APP_CONST } from '../app-constants';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { PropertyService } from '../api-client/property.service';
import { UiService } from '../ui.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-booking-filter',
  templateUrl: './booking-filter.component.html',
  styleUrls: ['./booking-filter.component.css']
})
export class BookingFilterComponent implements OnInit {

  filterParams: any = {
    searchData:null,
    staus : null,
    startDate : null,
    endDate : null
  };
  routeExtras: NavigationExtras;

  constructor(private _router: Router,
    private _route: ActivatedRoute,
    private _propertyService: PropertyService,
    private _tosterService: ToastrService,
    private _uiService: UiService) 
      { 
        this.routeExtras = this._router.getCurrentNavigation().extras;
      } 

  ngOnInit() {
    // if(!this._uiService.activeFilterData){
    //   this._uiService.closeLeftPopUp();
    //   return;
    // }
    // this.filterParams = this._uiService.activeFilterData;
  }

  applyFilter() {
    if(this.filterParams.startDate && this.filterParams.endDate){
      var startDate = new Date(this.filterParams.startDate);
      var endDate = new Date(this.filterParams.endDate);
      if(startDate.getTime() > endDate.getTime()){
        this._tosterService.error("Start date not greater than end date");
        return;
      }
    }
    this._uiService.activeFilterCallBack(); 
    this._uiService.closeLeftPopUp();
  }

  clearFilter(){
    this.filterParams.searchData = '';
    this.filterParams.status = null;
    this.filterParams.startDate = null;
    this.filterParams.endDate = null;
  }

}
