import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { UiService } from '../ui.service';
import { APP_CONST } from '../app-constants';
import { ToastrService } from 'ngx-toastr';
import { VoucherService } from '../api-client/voucher.service';
import { LedgerService } from '../api-client/ledger.service';
import { PaymentService } from '../api-client/payment.service';
import { IDayCalendarConfig } from 'ng2-date-picker';

@Component({
  selector: 'app-fund-transfer',
  templateUrl: './fund-transfer.component.html',
  styleUrls: ['./fund-transfer.component.css']
})
export class FundTransferComponent implements OnInit, OnDestroy {

  transferInvoiceType:number = APP_CONST.INVOICE_TYPE_TRANSFERS;
  bankAccountType:number = APP_CONST.ACCOUNT_TYPE_BANK;
  cashAccountType:number = APP_CONST.ACCOUNT_TYPE_CASH;
  invoiceType: number = this.transferInvoiceType;
  submitted = false;
  id: number;
  invoiceNumber: string = '';
  type: number;
  voucherFromName: string = '';
  voucherFromAddress: string = '';
  voucherToName: string = '';
  voucherToAddress: string = '';
  narration: string = '';
  paymentDate: string = CommonMethods.usDateToDb(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
  paymentType: string;
  status: string = "Pending";
  ledgerToId: number = 0;
  ledgerFromId: number = 0;
  paymentMethod: any = '';
  transactionId: string;
  chequeNumber: string;
  bankName: string;
  bankBranch: string;
  chequeDate: any;
  chequeClearanceDate: any;
  paymentAmount: number = 0;
  paymentDescription: string = null;
  paymentLineItems = [];
  communityId: number;
  maxAllowedAmount: number = 0;
  ledgerFrom:any = {id: 0, name: ""};
  ledgerTo:any = {id: 0, name: ""};
  fromAccountTypVal:number = this.bankAccountType;
  fromAccountType:any = 'Bank';
  ledgerFromSelectedText:any = '';
  toAccountTypeVal:number = this.cashAccountType;
  toAccountType:any = 'Cash';
  ledgerToSelectedText:any = '';
  public config = <IDayCalendarConfig>{
    format: "DD-MM-YYYY"
  };
  fromLedgerBalance: number = 0;
  toLedgerBalance: number = 0;

  constructor(private _uiService: UiService,
    private _router: Router,
    private _route: ActivatedRoute,
    private datePipe: DatePipe,
    private _tosterService: ToastrService,
    private _voucherService: VoucherService,
    private _ledgerService: LedgerService,
    private _paymentService: PaymentService) { }

  ngOnInit(): void {
    this.communityId = parseInt(CommonMethods.getCommunityId());
    this._route.params.subscribe(params => {
      if(params.id){
        this.getTransfer(params.id);
        this.id = params.id;
      }else{
        this._uiService.leftMenuItems.next([
          { code: 'F2', name: 'Transfers', action: this.goToList.bind(this), privilege: APP_CONST.TRANSFER_READ },
          { code: 'F4', name: 'Save', action: this.onSubmit.bind(this), class: 'green lighten-1', privilege: APP_CONST.TRANSFER_WRITE },
          { code: 'F5', name: 'Save & New', action: this.onSubmitAndNew.bind(this), privilege: APP_CONST.TRANSFER_WRITE }
        ]);
      }
    });
  }

  /*---Triggered when account type changed---*/
  accountTypeChanged(mode: any): void {
    this.ledgerFromId = 0;
    this.ledgerToId = 0;
    this.fromLedgerBalance = 0;
    this.toLedgerBalance = 0;
    this.ledgerFromSelectedText = null;
    this.ledgerToSelectedText = null;
    this.ledgerFrom = {id: 0, name: ""};
    this.ledgerTo = {id: 0, name: ""};
    if(mode == 'from'){
      if(this.fromAccountTypVal == this.bankAccountType){
        this.fromAccountType = 'Bank';
        this.toAccountTypeVal = this.cashAccountType;
        this.toAccountType = 'Cash';
      }else{
        this.fromAccountType = 'Cash';
        this.toAccountTypeVal = this.bankAccountType;
        this.toAccountType = 'Bank';
      }
    }else{
      if(this.toAccountTypeVal == this.bankAccountType){
        this.toAccountType = 'Bank';
        this.fromAccountTypVal = this.cashAccountType;
        this.fromAccountType = 'Cash';
      }else{
        this.toAccountType = 'Cash';
        this.fromAccountTypVal = this.bankAccountType;
        this.fromAccountType = 'Bank';
      }
    }
  }

  /*---Triggered when from account changed---*/
  changeFromAccount(elem:any): void {
    if(elem && elem.id > 0){
      this.ledgerFromId = elem.id;
      this._ledgerService.details(elem.id).subscribe((result:any)=>{
        this.voucherFromName = result.ledgerName;
        this.voucherFromAddress = result.address;
        this.maxAllowedAmount = result.ledgerCurrentBalance;
        this.fromLedgerBalance = result.ledgerCurrentBalance;
      });
    }else{
      this.ledgerFromId = null;
      this.voucherFromName = null;
      this.voucherFromAddress = null;
      this.maxAllowedAmount = 0;
      this.fromLedgerBalance = 0;
    }
  }

  /*---Triggered when to account changed---*/
  changeToAccount(elem:any): void {
    if(elem && elem.id > 0){
      this.ledgerToId = elem.id;
      this._ledgerService.details(elem.id).subscribe((result:any)=>{
        this.voucherToName = result.ledgerName;
        this.voucherToAddress = result.address;
        this.toLedgerBalance = result.ledgerCurrentBalance;
      });
    }else{
      this.ledgerToId = null;
      this.voucherToName = null;
      this.voucherToAddress = null;
      this.toLedgerBalance = 0;
    }
  }

  /*---Triggered when to payment method changed---*/
  paymentMethodChanged(): void {
    if(this.paymentMethod == 'Cash'){
      this.transactionId = null;
      this.chequeNumber = null;
      this.bankName = null;
      this.bankBranch = null;
      this.chequeDate = null;
      this.chequeClearanceDate = null;
    }else if(this.paymentMethod == 'Bank' || this.paymentMethod == 'NEFT' || this.paymentMethod == 'UPI'){
      this.chequeNumber = null;
      this.chequeDate = null;
      this.chequeClearanceDate = null;
    }
  }

  resetAll(): void {
    this.id = null;
    this.invoiceNumber = null;
    this.ledgerFromId = null;
    this.ledgerToId = null;
    this.fromLedgerBalance = 0;
    this.toLedgerBalance = 0;
    this.voucherFromName = null;
    this.voucherFromAddress = null;
    this.voucherToName = null;
    this.voucherToAddress = null;
    this.maxAllowedAmount = 0;
    this.paymentDate = CommonMethods.usDateToDb(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
    this.paymentMethod = '';
    this.paymentAmount = 0;
    this.narration = null;
    this.bankBranch = null;
    this.bankName = null;
    this.chequeNumber = null;
    this.chequeDate = null;
    this.chequeClearanceDate = null;
    this.ledgerFrom = {id: 0, name: ""};
    this.ledgerTo = {id: 0, name: ""};
    this.fromAccountTypVal = this.bankAccountType;
    this.fromAccountType = 'Bank';
    this.ledgerFromSelectedText = '';
    this.toAccountTypeVal = this.cashAccountType;
    this.toAccountType = 'Cash';
    this.ledgerToSelectedText = '';
    this.paymentLineItems = [];
  }

  /*---Get payment details---*/
  getTransfer(id: number): void {
    this._voucherService.getVoucher(id).subscribe((result: any) => {
      this.id = result.id;
      this.ledgerFromId = result.ledgerFromId;
      this._ledgerService.details(this.ledgerFromId).subscribe((ledgerFrom:any)=>{
        this.ledgerFromSelectedText = ledgerFrom.ledgerName;
        this.fromLedgerBalance = ledgerFrom.ledgerCurrentBalance;
        this.ledgerFrom = {id: this.ledgerFromId, name: this.ledgerFromSelectedText};
        this.voucherFromName = ledgerFrom.ledgerName;
        this.voucherFromAddress = ledgerFrom.address;
        this.maxAllowedAmount = ledgerFrom.ledgerCurrentBalance;
        this.fromAccountTypVal = ledgerFrom.accountTypeId;
        if(this.fromAccountTypVal == this.bankAccountType){
          this.fromAccountType = 'Bank';
        }else{
          this.fromAccountType = 'Cash';
        }
        this.ledgerToId = result.ledgerToId;
        this._ledgerService.details(this.ledgerToId).subscribe((ledgerTo:any)=>{
          this.ledgerToSelectedText = ledgerTo.ledgerName;
          this.ledgerTo = {id: this.ledgerToId, name: this.ledgerToSelectedText};
          this.voucherToName = ledgerTo.ledgerName;
          this.voucherToAddress = ledgerTo.address;
          this.toAccountTypeVal = ledgerTo.accountTypeId;
          this.toLedgerBalance = ledgerTo.ledgerCurrentBalance;
          if(this.toAccountTypeVal == this.bankAccountType){
            this.toAccountType = 'Bank';
          }else{
            this.toAccountType = 'Cash';
          }
        });
      });
      this.paymentMethod = result.paymentMethod;
      this.paymentAmount = result.totalNetAmount;
      this.narration = result.narration;
      this.paymentDate = CommonMethods.usDateToDb(this.datePipe.transform(result.voucherDate, "yyyy-MM-dd"));
      this.paymentType = result.paymentType;
      this.transactionId = result.transactionId;
      this.chequeNumber = result.chequeNumber;
      this.bankName = result.bankName;
      this.bankBranch = result.bankBranch;
      this.chequeDate = CommonMethods.usDateToDb(this.datePipe.transform(result.chequeDate, "yyyy-MM-dd"));
      this.chequeClearanceDate = CommonMethods.usDateToDb(this.datePipe.transform(result.chequeClearanceDate, "yyyy-MM-dd"));
      this.status = result.status;
      if(this.status == 'Cancelled'){
        this._uiService.leftMenuItems.next([
          { code: 'F2', name: 'Transfers', action: this.goToList.bind(this), privilege: APP_CONST.TRANSFER_READ }
        ]);
      }else{
        this._uiService.leftMenuItems.next([
          { code: 'F2', name: 'Transfers', action: this.goToList.bind(this), privilege: APP_CONST.TRANSFER_READ },
          { code: 'F7', name: 'Cancel', action: this.cancel.bind(this), class: 'red lighten-1', privilege: APP_CONST.TRANSFER_WRITE }
        ]);
      }
    }, error => {
      this._tosterService.error(error.message);
    });
  }

  /*---Validate all---*/
  validateAll(): boolean {
    if(!this.ledgerFromId || this.ledgerFromId == 0 || this.ledgerFromId == null){
      this._tosterService.error("Please select account from.");
      return false;
    }
    if(!this.ledgerToId || this.ledgerToId == 0 || this.ledgerToId == null){
      this._tosterService.error("Please select account to.");
      return false;
    }
    if(!this.paymentDate || this.paymentDate == '' || this.paymentDate == null){
      this._tosterService.error("Please select a date of transfer.");
      return false;
    }
    if(!this.paymentAmount || this.paymentAmount <= 0){
      this._tosterService.error("Please enter a valid amount to transfer.");
      return false;
    }
    if(!this.paymentMethod || this.paymentMethod == '' || this.paymentMethod == null){
      this._tosterService.error("Please select a payment method.");
      return false;
    } else {
      if(this.fromAccountType == 'Cash' && (this.paymentMethod == "Bank" || this.paymentMethod == "NEFT" || this.paymentMethod == "UPI" || this.paymentMethod == "Cheque")){
        this._tosterService.error("Payment method "+ this.paymentMethod +" cannot be used for "+this.fromAccountType+" to "+this.toAccountType+" transfer.");
        return false;
      }
      if(this.fromAccountType == 'Bank' && this.paymentMethod == "Cash"){
        this._tosterService.error("Payment method "+ this.paymentMethod +" cannot be used for "+this.fromAccountType+" to "+this.toAccountType+" transfer.");
        return false;
      }
      if (this.paymentMethod == "Bank" || this.paymentMethod == "NEFT" || this.paymentMethod == "UPI") {
        if (!this.transactionId || this.transactionId == "") {
          this._tosterService.error("Please input Transaction ID.");
          return false;
        }
      } else if (this.paymentMethod == "Cheque") {
        if (!this.chequeNumber || this.chequeNumber == "" || this.chequeNumber == null) {
          this._tosterService.error("Please input Cheque Number.");
          return false;
        }
        if (!this.chequeDate || this.chequeDate == "" || this.chequeDate == null) {
          this._tosterService.error("Please input Date of Cheque.");
          return false;
        }
        if (!this.chequeClearanceDate || this.chequeClearanceDate == "" || this.chequeClearanceDate == null) {
          this._tosterService.error("Please input Date of Cheque Clearance.");
          return false;
        }
        if(new Date(CommonMethods.dbToUsDate(this.chequeDate)).getTime() > new Date(CommonMethods.dbToUsDate(this.chequeClearanceDate)).getTime()){
          this._tosterService.error("Cheque date can not be after of cheque clearance date.");
          return false;
        }
        if (!this.bankName || this.bankName == "" || this.bankName == null) {
          this._tosterService.error("Please input Bank Name.");
          return false;
        }
        if (!this.bankBranch || this.bankBranch == "" || this.bankBranch == null) {
          this._tosterService.error("Please input Bank Branch Name.");
          return false;
        }
        this.status = "Processing";
      }
    }
    if(!this.narration || this.narration == '' || this.narration == null){
      this._tosterService.error("Please enter narration.");
      return false;
    }
    if(this.paymentAmount > this.maxAllowedAmount){
      if(this.maxAllowedAmount > 0){
        this._tosterService.error("Maximum amount can be transferred from "+ this.voucherFromName +" is "+ this.maxAllowedAmount +".");
        return false;
      }else{
        this._tosterService.error("No amount can be transferred from "+ this.voucherFromName +".");
        return false;
      }
    }
    return true;
  }

  /*---Save transfer and create new---*/
  onSubmitAndNew(): void {
    this.onSubmit(true);
  }

  /*---Save transfer---*/
  onSubmit(followedByNew: boolean = false): void {
    if(this.validateAll()){
      this.submitted = true;
      this.paymentLineItems = [];
      var item = {
        rate: this.paymentAmount,
        quantity: 1,
        unit: "Piece(s)",
        description: "Transfer",
        itemGrossAmount: this.paymentAmount,
        netAmount: this.paymentAmount,
        debitToAccountId: this.ledgerToId,
        creditToAccountId: this.ledgerFromId
      }
      this.paymentLineItems.push(item);
      var data: { [k: string]: any } = {
        communityId: this.communityId,
        vouchers: null,
        paymentLedgerFromId: this.ledgerFromId,
        paymentLedgerToId: this.ledgerToId,
        paymentMethod: this.paymentMethod,
        paymentDate: CommonMethods.dbToUsDate(this.paymentDate),
        billToName: this.voucherToName,
        billToAddress: this.voucherToAddress,
        transactionId: this.transactionId,
        chequeNumber: this.chequeNumber,
        bankName: this.bankName,
        bankBranch: this.bankBranch,
        chequeDate: this.chequeDate != null ? CommonMethods.dbToUsDate(this.chequeDate) : '',
        chequeClearanceDate: this.chequeClearanceDate != null ? CommonMethods.dbToUsDate(this.chequeClearanceDate) : '',
        paymentAmount: this.paymentAmount,
        paymentDescription: this.paymentDescription,
        narration: this.narration,
        type: 'Transfer',
        invoiceNumber: null,
        status: 1,
        paymentPropertyTypeId: null,
        paymentPropertyId: null,
        paymentLineItems: this.paymentLineItems
      };
      this._paymentService.save(data).subscribe((response: any) => {
        this._tosterService.success("Transfer Saved Successfully.");
        if (followedByNew) {
          this.resetAll();
          this._router.navigateByUrl("transfer", { replaceUrl: true });
        }else{
          this.getTransfer(response.id);
        }
      }, error => {
        this._tosterService.error(error.message);
      });
    }
  }

  /*---Cancel transfer---*/
  cancel(): void {
    var data = { communityId: this.communityId };
    this._paymentService.cancel(this.id, data).subscribe((result: any) => {
      this._tosterService.success("Transfer cancelled successfully.");
      this.getTransfer(this.id);
      this._router.navigateByUrl('transfer/' + this.id, { replaceUrl: true });
    }, error => {
      this._tosterService.error(error.message);
    });
  }

  /*---Navigate to list page---*/
  goToList(): void {
    this._router.navigate(['transfers']);
  }

  /*---Breadcrumb navigation---*/
  breadcrumbNavigate(mode: any): void { 
    if (mode == "list") {
      this.goToList();
    } else if (mode == "dashboard") {
      this._router.navigateByUrl("/dashboard");
    }
  }

  ngOnDestroy(): void {
    this._uiService.leftMenuItems.next([]);
  }

}
