import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LedgerModel } from './../Model/ledger.model';
import { MaintenanceLogModel } from '../Model/maintenance-log.model';
import { MaintenanceContractModel } from '../Model/maintenance-contract.model';

@Injectable({
    providedIn: 'root'
})

export class MaintenanceContractFormBuilder {
    private form;
    private validationRules = {
        vendorId: ['',''],
        contractType: ['', Validators.required],
        mode: ['', Validators.required],
        rate: ['', Validators.required],
        quantity: ['', Validators.required],
        amount: ['', Validators.required],
        startDate: ['', Validators.required],
        expiryDate: ['', Validators.required],
        status: ['', Validators.required],
        category: ['',''],
        cancelReason: ['', ''],
        remarks: ['', ''],
    }

    constructor(private fb: FormBuilder) {
        this.form = fb.group(this.validationRules);
    }

    getForm() {
        return this.form;
    }

    setValues(response: MaintenanceContractModel) {
        this.form.patchValue({
            id: response.id,
            vendorId: response.vendorId,
            contractType: response.contractType,
            mode: response.mode,
            rate: response.rate,
            quantity: response.quantity,
            amount: response.amount,
            startDate: response.startDate,
            expiryDate: response.expiryDate,
            remarks: response.remarks,
            status: response.status,
            category: response.category,
            cancelReason: response.cancelReason,
        });
    }
}