import { ResourceService } from './../api-client/resource.service';
import { PropertyService } from './../api-client/property.service';
import { Component, OnInit } from '@angular/core';
import { BuildingService } from '../api-client/building.service';
import { BlockService } from '../api-client/block.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { UiService } from '../ui.service';
import { CommunityService } from '../api-client/community.service';
import { LocationService } from '../api-client/location.service';
import { UserService } from '../api-client/user.service';
import { BuildingFormBuilder } from './building.form-builder';
import { BuildingModel } from './../Model/building.model';
import { CommonMethods } from '../common-methods';
import { APP_CONST } from '../app-constants';

@Component({
  selector: 'app-building-details',
  templateUrl: './building-details.component.html',
  styleUrls: ['./building-details.component.css']
})
export class BuildingDetailsComponent implements OnInit {

  buildingDetails: any;
  buildingForm: any;
  submitted = false;
  communityId: any;
  countryId: any;
  typeWisePropertyCount: any;
  ammenitiesArray = [];
  ammenities = [];
  resources = [];
  buildingId = null;
  blockId: number;
  selectedCountryName = "Select country";
  selectedStateName = "Select state";
  blockName = 'Select Block';
  model = new BuildingModel();
  buildingName: any;
  linkItemType: any;
  linkItemId: any;
  args: any;
  APP_CONST = APP_CONST;
  routeExtras: NavigationExtras;
  deleteParams: any = {
    itemId: 0,
    itemName: null,
    btnOk: "Delete",
    btnCancel: "Close",
    deleteText: "Are you sure, you want to delete this building?"
  }
  selectedBlockId = 0;
  error = [];
  constructor(
    private _blockService: BlockService,
    private _buildingService: BuildingService,
    private _tosterService: ToastrService,
    private _communityService: CommunityService,
    private _locationService: LocationService,
    private _router: Router,
    private _userService: UserService,
    private _route: ActivatedRoute,
    private _uiService: UiService,
    private _formBuilder: BuildingFormBuilder,
    private _propertyService: PropertyService,
    private _resourceService: ResourceService
  ) {
    this.routeExtras = this._router.getCurrentNavigation().extras;
  }

  ngOnInit() {
    this._uiService.leftMenuItems.next([
      { code: 'F4', name: 'Save', action: this.save.bind(this), class: 'green lighten-1', privilege: APP_CONST.BUILDING_WRITE },
      { code: 'F5', name: 'Save & Add New', action: this.saveAndNew.bind(this), privilege: APP_CONST.BUILDING_WRITE },
      { code: 'F7', name: 'Delete', action: this.confirmationPopUp.bind(this), class: 'red lighten-1', privilege: APP_CONST.BUILDING_REMOVE }
    ]);
    this.blockId = 0;
    this.buildingDetails = {};
    this.buildingForm = this._formBuilder.getForm();
    this.getBlockIdThroughState();
    this.changeBlockThroughState();
    this.onChanges();
    this._route.params.subscribe(params => {
      if (params.id > 0) {
        this.details(params.id);
        this.args = {
          "communityId": CommonMethods.getItem("communityId"),
          "buildingId": params.id
        };
      } else {
        this.resetForm();
      }
      this._uiService.linkItem = { id: params.id, type: APP_CONST.PROPERTY_TYPE_BUILDINGS, name: null };
      this.linkItemType = APP_CONST.PROPERTY_TYPE_BUILDINGS;
      this.linkItemId = params.id;
      this.populateBuildingInsideItemsCount(this.blockId, params.id);
      this.ammenitiesList(this.blockId, params.id, APP_CONST.PROPERTY_TYPE_AMENITY);
      this.resourceList(this.args);
    })
  }

  get f() { return this.buildingForm.controls; }

  details(id: number) {
    this._buildingService.details(id).subscribe((result: any) => {
      this._uiService.linkItem.name = result.name;
      this.selectedBlockId = result.communityBlockId;
      this.countryId = result.countryId;
      this.communityId = result.communityId;
      this.buildingName = result.name;
      this.deleteParams.itemId = result.id;
      this.deleteParams.itemName = result.name;
      this.blockDetails(result.communityBlockId);
      if (result.countryId) {
        this._locationService.countryDetails(result.countryId).subscribe((result: any) => {
          this.selectedCountryName = result.countryName;
        });
      }
      if (result.stateId) {
        this._locationService.stateDetails(result.stateId).subscribe((result: any) => {
          this.selectedStateName = result.stateName;
        });
      }
      this.buildingId = result.id;
      this._formBuilder.setValues(result);
    })
  }

  async  onSubmit() {
    this.error = [];
    this.submitted = true;
    if (this.buildingForm.invalid) {
      return;
    }
    this.model.id = this.buildingId;
    this.model.deserialize(this.buildingForm.value);
    this.model.communityId = Number(CommonMethods.getCommunityId());
    //console.log(this.model);return;
    await this._buildingService.save(this.model)
      .toPromise().then(
        (response: any) => {
          if (response) {
            this._tosterService.success("Building save");
            if (!this.buildingId) {
              this.buildingId = response.id;
            }
            this.error = [];
          }else{
            this.error.push("Server error");
          }
        },
        error => {
          this._tosterService.error(error.message);
        }
      )
  }

  async populateBuildingInsideItemsCount(blockId: number, id: number) {
    await this._propertyService.types().toPromise().then((propertyTypes: any) => {
      this.typeWisePropertyCount = {};
      for (let k = 0; k < propertyTypes.length; k++) {
        this.typeWisePropertyCount[propertyTypes[k].id] = {
          id: propertyTypes[k].id,
          name: propertyTypes[k].name,
          count: 0
        };
      }
    });
    await this._propertyService.getCount(blockId, id).toPromise().then((result: any) => {
      for (let i = 0; i < result.length; i++) {
        if (this.typeWisePropertyCount[result[i].property_type]) {
          this.typeWisePropertyCount[result[i].property_type].count = result[i].property_count;
        }
      }
    });
  }

  async ammenitiesList(blockId: number, buildingId: number, propertyType: any) {
    await this._propertyService.getDetailsById(blockId, buildingId, propertyType).toPromise().then((result: any) => {
      this.ammenities = result;
    })
  }

  async resourceList(data: any) {
    await this._resourceService.all(data).subscribe((result: any) => {
      this.resources = result;
    })
  }

  redirectToResource(item: any) {
    this._router.navigateByUrl("resource/" + item.id);
  }

  propertyDetails(item: any) {
    var propertyType = CommonMethods.propertyTypes.filter((x: any) => x.id == item.propertyType);
    if (propertyType.length > 0) {
      this._router.navigateByUrl(`${propertyType[0].name}/${item.id}`);
    }
  }

  async save() {
    await this.onSubmit();
    if(this.error.length>0) return;
    setTimeout(() => {
      if (this.buildingId > 0) {
        this._router.navigateByUrl(`building/${this.buildingId}`);
        return;
      }
    }, 1000);
  }

  async saveAndNew() {
    await this.onSubmit();
    if(this.error.length>0) return;
    if (!this.buildingForm.invalid) {
      this.resetForm();
    }
  }

  onChanges(): void {
    this.buildingForm.get('countryId').valueChanges.subscribe((val: any) => {
      this.countryId = val;
    });
  }

  changeBlockThroughState() {
    if (this.routeExtras && this.routeExtras.state && this.routeExtras.state.itemValue) {
      this.setBlockValue(this.routeExtras.state.itemValue);
    }
  }

  getBlockIdThroughState() {
    if (this.routeExtras && this.routeExtras.state && this.routeExtras.state.blockId) {
      this.selectedBlockId = this.routeExtras.state.blockId;
    }
  }

  blockDetails(id: any) {
    this._blockService.details(id).subscribe((result: any) => {
      this.blockName = result.communityBlockName;
    });
  }

  setBlockValue(id: any) {
    this.blockDetails(id);
    this.buildingForm.controls['communityBlockId'].setValue(id);
  }

  confirmationPopUp() {
    if (this.buildingId > 0) {
      this._uiService.deleteItemData = this.deleteParams;
      this._uiService.deleteItemCallBack = this.delete.bind(this);
      this._router.navigate([{
        outlets: { centerPopup: ['delete-item'] }
      }]);
    }
  }

  delete() {
    if (this.deleteParams.pressBtn == "Ok") {
      this._buildingService.delete(this.deleteParams.itemId).subscribe((result: any) => {
        this._tosterService.success("Building Deleted");
        this.resetForm();
        return;
      });
    }
  }

  resetForm() {
    this.submitted = false;
    this.countryId = "";
    this.buildingId = null;
    this.selectedCountryName = "Select country";
    this.selectedStateName = "Select state";
    this.blockName = 'Select Block';
    this.buildingName = "";
    this.error = [];
    this.buildingForm.reset();
    this.populateAddress();
    setTimeout(() => {
      this._router.navigateByUrl("building/0");
    }, 100)
  }

  redirectToProperties(property:any,buildingId:number){
    //console.log(buildingId,property.key);return;
    var params = [{ outlets: { rightMainPopup: ["units", { "mode": "PropertySelections", "blockId": 0,"buildingId":buildingId,"type":property.key }] } }];
    this._router.navigate(params); return;
  }

  redirectionType(item: any, itemName: any, itemValue: any, mode: any, displayType: any) {
    let params: any;
    if (displayType == 'listing') {
      if (mode == "block") {
        params = [{ outlets: { rightMainPopup: ["blocks", { "mode": "BlockSelections" }] } }];
      } else if (mode == "building") {
        params = [{ outlets: { rightMainPopup: ["blocks", { "mode": "BuildingSelections" }] } }];
      } else if (mode == "resource") {
        params = [{ outlets: { rightMainPopup: ["blocks", { "mode": "ResourceSelections" }] } }];
      } else if (mode == "property") {
        params = [{ outlets: { rightMainPopup: ["blocks", { "mode": "PropertySelections", "type": item.id }] } }];
      } else if (mode == "dashboard") {
        this._router.navigateByUrl("/dashboard"); return;
      }
      this._router.navigate(params); return;
    } else if (displayType == 'add') {
      if (mode == "block") {
        params = ["block", 0];
      } else if (mode == "building") {
        params = ["building", 0];
      } else if (mode == "resource") {
        params = ["resource", 0];
      } else if (mode == "property") {
        var propertyTypeSlug = CommonMethods.propertyTypes.filter((x: any) => x.id == item.id);
        params = [propertyTypeSlug[0].name, 0];
      }
      this._router.navigate(params, { state: { "itemName": itemName, "itemValue": itemValue } }); return;
    }
    this._router.navigate(params); return;
  }

  redirectToBuildingList() {
    let params = Object.assign({ blockId: this.selectedBlockId });
    this._router.navigate([{
      outlets: { rightMainPopup: ['buildings', params] }
    }]);
  }

  redirectToProperty(item: any) {
    this._router.navigate([{ outlets: { rightMainPopup: ["blocks", { "mode": "PropertySelections", "type": item.id }] } }]);
    return;
  }

  async populateAddress() {
    var address = CommonMethods.getCommunityDetails();
    address.name = "";
    this._formBuilder.setValues(address);
    if (address.countryId) {
      await this._locationService.countryDetails(address.countryId).toPromise().then((result: any) => {
        this.selectedCountryName = result.countryName;
      });
    }
    if (address.stateId) {
      await this._locationService.stateDetails(address.stateId).toPromise().then((result: any) => {
        this.selectedStateName = result.stateName;
      });
    }
  }

  ngOnDestroy(): void {
    this._uiService.leftMenuItems.next([]);
  }

}
