import { Component, OnInit } from '@angular/core';
import { CommunityService } from '../api-client/community.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { UiService } from '../ui.service';
import { StaffService } from '../api-client/staff.service';

@Component({
  selector: 'app-communities',
  templateUrl: './communities.component.html',
  styleUrls: ['./communities.component.css']
})
export class CommunitiesComponent implements OnInit {

  communities: any;
  profile: any;
  constructor(
    private _communityService: CommunityService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _staffService: StaffService,
    private _uiService: UiService
  ) { }

  ngOnInit() {
    this.list();
  }

  list() {
    this._communityService.all().subscribe((result: any) => {
      this.communities = result;
    })
  }

  filterCommunity(eve: KeyboardEvent) {
    var code = eve.keyCode;
    if (code == 37 || code == 38 || code == 39 || code == 40) {
      return;
    }

    var items: any;
    var inputBox = eve.target as HTMLInputElement;
    if (inputBox.value) {
      items = this.communities.filter((x: any) => x.name.toLowerCase().includes(inputBox.value.toLowerCase()));
      this.communities = items;
    } else {
      this.list();
    }
  }

 async activeCommunity(item: any) {
    if (!item) return;
    CommonMethods.setItem("communityId", item.id);
    CommonMethods.setCommunityDetails(item);
    this._uiService.isCommunitySelected.next(true);
    this._uiService.changeCommunityDetails.next(item);
    await this.getCapabilities(item.id);
    //this._router.navigate(["../",{outlets:{fullOutlet:null}}]);
    //this._router.navigate(["/dashboard",{outlets:{fullOutlet:null}}],{ replaceUrl: true,relativeTo: this._route });   
    //this._router.navigateByUrl("/dashboard", { replaceUrl: true });
    //setTimeout(()=>{
      window.location.href="/dashboard";
    //},500);    
    return false;
  }

  redirectTo(item: any) {
    this._router.navigate([{
      outlets: { rightMainPopup: ['blocks', item.id] }
    }]);
  }

  async getCapabilities(communityId: number) {
    await this._staffService.capabilities().toPromise().then((result: any) => {
      CommonMethods.setCapability(result[communityId]);
    })
  }
}
