import { Component, OnDestroy, OnInit } from '@angular/core';
import { BlockService } from '../api-client/block.service';
import { BuildingService } from '../api-client/building.service';
import { ToastrService } from 'ngx-toastr';
import { CommunityService } from '../api-client/community.service';
import { LocationService } from '../api-client/location.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { UiService } from '../ui.service';
import { PropertyService } from '../api-client/property.service';
import { VoucherTemplateService } from '../api-client/voucher-template.service';
import { UserService } from '../api-client/user.service';
import { LedgerService } from '../api-client/ledger.service';
import { unitFormBuilder } from './unit.form-builder';
import { PropertyModel } from '../Model/property.model';
import { CommonMethods } from '../common-methods';
import { CategoryService } from '../api-client/category.service';
import { APP_CONST } from '../app-constants';
import { config } from '../config';
import { IDayCalendarConfig } from 'ng2-date-picker';

@Component({
  selector: 'app-unit',
  templateUrl: './unit-details.component.html',
  styleUrls: ['./unit-details.component.css']
})
export class UnitDetailsComponent implements OnInit, OnDestroy {

  public config = <IDayCalendarConfig>{
    format: "DD-MM-YYYY"
  };
  unitDetails: any;
  unitId = null;
  unitForm: any;
  unitName: any;
  propertyType: any;
  unitPropertyName: any;
  buildingId: any;
  communityBlockId: any;
  submitted = false;
  assignMobile: any;
  assignTo: any;
  chooseToDate: any;
  chooseEndDate: any;
  ;
  assets = [];
  tags = [];
  daysVals = [];
  maxBookingDays = [];
  rentLineItems = [];
  periodStartDate: any;
  periodEndDate: any;
  currentPeriod: any;
  model = new PropertyModel();
  buildingName = 'Select Building';
  blockName = 'Select Block';
  ledgerName = 'Select Ledger';
  propertyTypeName = "Select name";
  linkItemType: any;
  linkItemId: any;
  lastPaidPeriod: any;
  bookingPerDay: any;
  seletedBookingPerDay: any;
  propertySettings: any = {
    bookingPerDay: 0,
    availableWeekDays: []
  };
  selectedDays: [];
  showBlock: boolean = true;
  showBuilding: boolean = true;
  showRentTab: boolean = true;
  showBooking: boolean = true;
  args: any;
  routeExtras: NavigationExtras;
  ledgerId: any;
  isOwner: any;
  selectedBlockId = 0;
  selectedBuildingId = 0;
  deleteParams: any = {
    itemId: 0,
    itemName: null,
    btnOk: "Delete",
    btnCancel: "Close",
    deleteText: "Are you sure, you want to delete this property?"
  }
  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  error = [];
  APP_CONST = APP_CONST;
  propertyId: any;
  templateId: any;
  property: any;
  constructor(
    private _blockService: BlockService,
    private _buildingService: BuildingService,
    private _tosterService: ToastrService,
    private _communityService: CommunityService,
    private _locationService: LocationService,
    private _propertyService: PropertyService,
    private _voucherTemplateService: VoucherTemplateService,
    private _categoryService: CategoryService,
    private _userService: UserService,
    private _ledgerService: LedgerService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _uiService: UiService,
    private _formBuilder: unitFormBuilder
  ) {
    this.routeExtras = this._router.getCurrentNavigation().extras;
  }

  ngOnInit() {
    this.propertyId = this._route.snapshot.paramMap.get('id');
    if (this.propertyId) {
      this.getTemplete(this.propertyId);
    }

    this._uiService.leftMenuItems.next([
      // {code:'F4', name:'Add New', action:this.createNew.bind(this), class:'green lighten-1'},
      { code: 'F4', name: 'Save', action: this.save.bind(this), class: 'green lighten-1', privilege: APP_CONST.UNIT_WRITE },
      { code: 'F5', name: 'Save & Add New', action: this.saveAndNew.bind(this), privilege: APP_CONST.UNIT_WRITE },
      { code: 'F7', name: 'Delete', action: this.confirmationPopUp.bind(this), class: 'red lighten-1', privilege: APP_CONST.UNIT_REMOVE }
    ]);
    this.unitDetails = {};
    this.rentLineItems = [];
    this.daysVals = CommonMethods.daysArray;
    this.maxBookingDays = CommonMethods.maxBookingDays;
    this.unitForm = this._formBuilder.getForm();
    this._route.data.subscribe(params => {
      this.propertyType = params.propertyType;
      this.linkItemType = params.propertyType;
    });
    this._route.params.subscribe(params => {
      if (params.id > 0) {
        this.showRentTab = true;
        this.details(params.id);
        this.assetList(params.id);
        this.getLastPaidPeriod(params.id);
      } else {
        this.showRentTab = false;
        this.resetForm();
      }
      this._uiService.linkItem = { id: params.id, type: this.propertyType, name: null };
      this.linkItemId = params.id;
      var propertyTypeSlug = CommonMethods.propertyTypes.filter((x: any) => x.id == this.propertyType);
      if (propertyTypeSlug.length > 0) {
        if (propertyTypeSlug[0].name == 'flat-house') {
          this.unitPropertyName = "flat/house";
        } else {
          this.unitPropertyName = propertyTypeSlug[0].name;
        }
      }
      if (this.propertyType == APP_CONST.PROPERTY_TYPE_AMENITY) {
        this.showBooking = true;
      } else {
        this.showBooking = false;
      }
    });
    this.categories();
    this.changeState();
    this.onChanges();
  };

  get f() { return this.unitForm.controls; }

  async onSubmit() {
    this.error = [];
    this.submitted = true;
    if (this.unitForm.invalid) {
      return;
    }
    this.unitForm.assignTo = "";
    this.unitForm.assignMobile = "";
    this.model.deserialize(this.unitForm.value);
    this.model.communityId = Number(CommonMethods.getCommunityId());
    this.model.propertyType = this.propertyType;
    this.model.id = this.unitId;
    this.model.propertySettings = JSON.stringify(this.propertySettings);
    this.model.customLastPaidPreriod = this.unitForm.value.customLastPaidPreriod;
    if (!this.showBuilding) {
      this.model.buildingId = 0;
    }
    if (!this.showBlock) {
      this.model.communityBlockId = 0;
    }

    if (!this.model.buildingId && !this.model.communityBlockId) {
      this.error.push("Either Building or block have to select.");
      this._tosterService.error("Either Building or block have to select.");
      return false;
    }
    if (this.model.propertyType === 108) {
      if (this.model.assignTo == "" || this.model.assignMobile == "") {
        this.error.push("Either Building or block have to select.");
        this._tosterService.error("Assign to or assign mobile number can not be blank");
        return false;
      }
    }
    if (this.unitForm.get('category').value) {
      var result = this.unitForm.get('category').value;
      var tags = [];
      for (var i = 0; i < result.length; i++) {
        tags.push(result[i].name);
      }
      this.model.category = tags.join(",");
    }
    await this._propertyService.save(this.model)
      .toPromise().then(
        (response: any) => {
          if (response) {
            this._tosterService.success("Property save");
            if (!this.unitId) {
              this.unitId = response.id;
            }
            this.error = [];
          } else {
            this.error.push("Server error");
          }
        },
        error => {
          this._tosterService.error(error.message);
          this.error.push(error.message);
        }
      )
  }


  async details(id: number) {
    await this._propertyService.details(id).toPromise().then((result: any) => {
      this._uiService.linkItem.name = result.unitNo;
      if (result.category) {
        var tags = [];
        var category = result.category.split(",");
        for (var i = 0; i < category.length; i++) {
          tags.push({ id: category[i], name: category[i] });
        }
        result.category = tags;
      }
      this._formBuilder.setValues(result);
      this.unitId = result.id;
      this.unitName = result.unitNo;
      this.selectedBuildingId = result.buildingId;
      this.unitForm.get('customLastPaidPreriod').setValue(result.customLastPaidPreriod);
      this.propertySettings = JSON.parse(result.propertySettings);
      if (this.propertySettings && this.propertySettings.bookingPerDay > 0) {
        this.seletedBookingPerDay = this.propertySettings.bookingPerDay;
      } else {
        this.seletedBookingPerDay = 0;
      }
      this.deleteParams.itemId = result.id;
      this.deleteParams.itemName = result.unitNo;
      this.associateLedger({ propertyId: result.id });
      this.getRentCharges({ propertyId: result.id });
      this._propertyService.type(result.propertyType).subscribe((response: any) => {
        this.propertyTypeName = response.name;

      });
      this._communityService.details(result.communityId).subscribe((result: any) => {
        this.unitDetails.communityName = result.name;
      });

      if (result.buildingId) {
        this.selectedBuildingId = result.buildingId;
        this.buildingDetails(result.buildingId);
      }
      if (result.communityBlockId) {
        this.selectedBlockId = result.communityBlockId;
        this.blockDetails(result.communityBlockId);
      }
      if (result.ledgerId) {
        this._ledgerService.details(result.ledgerId).subscribe((result: any) => {
          this.ledgerName = result.ledgerName;
        });
      } else {
        this.ledgerName = null;
      }
    })
  }

  categories() {
    var data = { communityId: CommonMethods.getCommunityId() };
    this._categoryService.all(data).subscribe((result: any) => {
      for (var i = 0; i < result.length; i++) {
        result[i].id = result[i].name;
      }
      this.tags = result;
    });
  }

  getLastPaidPeriod(id: number) {
    this._propertyService.getLastPaidPeriod(id).subscribe((data: any) => {
      this.lastPaidPeriod = data;
    });
  }

  getRentCharges(data) {
    var currentMonth = new Date().getMonth() + 1;
    this.currentPeriod = Date.now();
    this._voucherTemplateService.items(data).subscribe((result: any) => {
      if (result.length < 0) {
        return;
      }
      for (var i = 0; i < result.length; i++) {
        var periodStartDateMonth = new Date(result[i].startDate).getMonth() + 1;
        var periodEndDateMonth = new Date(result[i].endDate).getMonth() + 1;
        // if (currentMonth <= periodStartDateMonth && currentMonth <= periodEndDateMonth) {
        if (result[i].lineItems.length > 0) {
          for (var k = 0; k < result[i].lineItems.length; k++) {
            this.rentLineItems.push(result[i].lineItems[k]);
          }
        }
        // }
      }
    })
  }

  getTemplete(propertyId: number) {
    this._voucherTemplateService.rentTemplate(propertyId).subscribe((result: any) => {
      this.property = result;
      if (this.property) {
        this.templateId = this.property.id;
      }
    });
  }


  associateLedger(data: any) {
    this._propertyService.associateLedger(data).subscribe((result: any) => {
      if (result) {
        this.isOwner = result.isOwner;
        this.ledgerId = result.ledgerId;
      }
    })
  }

  assetList(id: number) {
    this._propertyService.getAssets(id).subscribe((result: any) => {
      this.assets = result;
    });
  }

  redirectToResources(item: any) {
    this._router.navigateByUrl("resource/" + item.id);
  }

  async save() {
    await this.onSubmit();
    if (this.error.length > 0) return;
    setTimeout(() => {
      if (this.unitId > 0) {
        var propertyTypeSlug = CommonMethods.propertyTypes.filter((x: any) => x.id == this.propertyType);
        if (propertyTypeSlug.length > 0) {
          this._router.navigateByUrl(`${propertyTypeSlug[0].name}/${this.unitId}`);
        }
        return;
      }
    }, 1000);
  }

  async saveAndNew() {
    await this.onSubmit();
    if (this.error.length > 0) return;
    if (!this.unitForm.invalid) {
      this.resetForm();;
    }
  }

  daysOfWeekSelected(days_id: number) {
    if (this.propertySettings && this.propertySettings.availableWeekDays.length > 0) {
      let index = this.propertySettings.availableWeekDays.indexOf(days_id);
      if (index > -1) {
        return 1;
      }
    } else {
      return 0;
    }
  }

  changeAvailabilityDay(ele: any, day: number) {
    if (ele.target.checked) {
      this.propertySettings.availableWeekDays.push(day);
    } else {
      let index = this.propertySettings.availableWeekDays.indexOf(day);
      if (index > -1) {
        this.propertySettings.availableWeekDays.splice(index, 1);
      }
    }
  }

  changeBookingPerDay(ele: any) {
    if (ele.target.value > 0) {
      let day = ele.target.value;
      this.propertySettings.bookingPerDay = day;
    }
  }

  confirmationPopUp() {
    if (this.unitId > 0) {
      this._uiService.deleteItemData = this.deleteParams;
      this._uiService.deleteItemCallBack = this.delete.bind(this);
      this._router.navigate([{
        outlets: { centerPopup: ['delete-item'] }
      }]);
    }
  }

  delete() {
    if (this.deleteParams.pressBtn == "Ok") {
      this._propertyService.delete(this.deleteParams.itemId).subscribe((result: any) => {
        this._tosterService.success("Property Deleted");
        this.resetForm();
        return;
      });
    }
  }

  resetForm() {
    this.submitted = false;
    this.unitId = null;
    this.buildingName = 'Select Building';
    this.blockName = 'Select Block';
    this.ledgerName = 'Select Ledger';
    this.propertyTypeName = "Select name";
    this.showBuilding = true;
    this.showBlock = true;
    this.error = [];
    this.tags = [];
    this.propertySettings = {
      bookingPerDay: 0,
      availableWeekDays: []
    };
    this.seletedBookingPerDay = 0;
    var propertyTypeSlug = CommonMethods.propertyTypes.filter((x: any) => x.id == this.propertyType);
    this.unitForm.reset();
    setTimeout(() => {
      if (propertyTypeSlug.length > 0) {
        this._router.navigateByUrl(`${propertyTypeSlug[0].name}/0`);
      }
    }, 1000)
  }

  onChanges(): void {

    /* this.unitForm.get('propertyType').valueChanges.subscribe((val: any) => {
      this.propertyType = val;
    }); */
    this.unitForm.get('buildingId').valueChanges.subscribe((val: any) => {
      this.buildingName = 'Select Building';
      this.showBlock = true;
      if (val) {
        this.showBlock = false;
        this.showBuilding = true;
      }

    });
    this.unitForm.get('communityBlockId').valueChanges.subscribe((val: any) => {
      this.blockName = 'Select Block';
      this.showBuilding = true;
      if (val) {
        this.showBuilding = false;
        this.showBlock = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.unitForm.get('customLastPaidPreriod').setValue(null);
    this._uiService.leftMenuItems.next([]);
  }

  changeState() {
    if (this.routeExtras && this.routeExtras.state && this.routeExtras.state.itemValue) {
      if (this.routeExtras.state.itemName == "block") {
        this.selectedBlockId = this.routeExtras.state.itemValue;
        this.setBlockValue(this.routeExtras.state.itemValue);
      }
      if (this.routeExtras.state.itemName == "building") {
        this.selectedBuildingId = this.routeExtras.state.itemValue;
        this.setBuildingValue(this.routeExtras.state.itemValue);
      }
    }
    if (this.routeExtras && this.routeExtras.state && this.routeExtras.state.blockId && this.routeExtras.state.buildingId) {
      this.selectedBlockId = this.routeExtras.state.blockId;
      this.selectedBuildingId = this.routeExtras.state.buildingId;
    }
  }

  blockDetails(id: any) {
    this.showBlock = true;
    this.showBuilding = false;
    this._blockService.details(id).subscribe((result: any) => {
      this.blockName = result.communityBlockName;
    });
  }

  buildingDetails(id: any) {
    this.showBlock = false;
    this.showBuilding = true;
    this._buildingService.details(id).subscribe((result: any) => {
      this.buildingName = result.name;
      this.selectedBlockId = result.communityBlockId;
    });
  }

  setBlockValue(id: any) {
    this.blockDetails(id);
    this.unitForm.controls['communityBlockId'].setValue(id);
  }

  setBuildingValue(id: any) {
    this.buildingDetails(id);
    this.unitForm.controls['buildingId'].setValue(id);
  }

  redirectionType(item: any, itemName: any, itemValue: any, mode: any, displayType: any) {
    let params: any;
    this.unitForm.get('customLastPaidPreriod').setValue(null);
    if (displayType == 'listing') {
      if (mode == "block") {
        params = [{ outlets: { rightMainPopup: ["blocks", { "mode": "BlockSelections" }] } }];
      } else if (mode == "resource") {
        params = [{ outlets: { rightMainPopup: ["blocks", { "mode": "ResourceSelections" }] } }];
      } else if (mode == "dashboard") {
        this._router.navigateByUrl("/dashboard"); return;
      }
      this._router.navigate(params); return;
    } else if (displayType == 'add') {
      if (mode == "resource") {
        params = ["resource", 0];
      }
      this._router.navigate(params, { state: { "itemName": itemName, "itemValue": itemValue } }); return;
    }
    this._router.navigate(params); return;
  }

  redirectToBuildingList() {
    let params: any;
    this.unitForm.get('customLastPaidPreriod').setValue(null);
    if (this.selectedBuildingId > 0) {
      params = Object.assign({ blockId: this.selectedBlockId });
      this._router.navigate([{
        outlets: { rightMainPopup: ['buildings', params] }
      }]);
    } else {
      params = [{ outlets: { rightMainPopup: ["blocks", { "mode": "BuildingSelections" }] } }];
      this._router.navigate(params);
    }
  }

  redirectToUnitList() {
    this.unitForm.get('customLastPaidPreriod').setValue(null);
    let params = Object.assign({ buildingId: this.selectedBuildingId, blockId: this.selectedBlockId, "mode": "PropertySelections", "type": this.propertyType });
    this._router.navigate([{
      outlets: { rightMainPopup: ['units', params] }
    }]);
  }

  redirectToRent(itemId: number) {
    this.unitForm.get('customLastPaidPreriod').setValue(null);
    this._router.navigateByUrl(`rentcharge/${itemId}`);
    return;
  }

  redirectToBooking() {
    //this._router.navigateByUrl(`booking-details/0`);
    //return;
  }

  redirectToRentInvoice(itemId: number) {
    let invoiceUrl: string = "";
    invoiceUrl = "society-invoice";
    this._router.navigate([invoiceUrl], { state: { "templateId": this.templateId, "propertyId": this.propertyId } });
  }



  toDateChanged(e: any) {
    if (e) {
      this.chooseToDate = 1;
    }


  }

  endDateChanged(e: any) {
    if (e) {
      this.chooseEndDate = 1;
    }


  }

  clearToDate(): void {
    this.chooseToDate = false;
    this.unitForm.get('toDate').setValue(null);

  }
  clearEndDate(): void {
    this.chooseEndDate = false;
    this.unitForm.get('endDate').setValue(null);

  }



}
