import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';

@Component({
  selector: 'app-delete-item',
  templateUrl: './delete-item.component.html',
  styleUrls: ['./delete-item.component.css']
})
export class DeleteItemComponent implements OnInit {

  deleteParams: any = {
    itemName:'',
    itemId:0,
    deleteText:"",
    btnCancel:"Cancel",
    btnOk:"Ok",
    pressBtn:""
  };
  constructor(private _router: Router,
    private _route: ActivatedRoute,
    private _uiService: UiService) { }

  ngOnInit() {
    if(!this._uiService.deleteItemData){
      this._uiService.hideCenterPopup();
      return;
    }    
    this.deleteParams = this._uiService.deleteItemData;
    if(!this._uiService.deleteItemData.btnOk){
      this.deleteParams.btnOk = "Ok";
    }
    if(!this._uiService.deleteItemData.btnCancel){
      this.deleteParams.btnCancel = "Cancel";
    }
  }

  delete() {
    this.deleteParams.pressBtn = "Ok";
    this._uiService.deleteItemCallBack();
    this._uiService.hideCenterPopup();
  }

  cancel(){
    this.deleteParams.pressBtn = "Cancel";
    this._uiService.deleteItemCallBack();
    this._uiService.hideCenterPopup();
  }

}
