import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LinkProfileModel } from '../Model/link-profile.model';

@Injectable({
    providedIn: 'root'
})

export class LinkProfileFormBuilder {
    private form;
    private validationRules = {
        name: ['', Validators.required],
        mobile: ['', Validators.required],
        email: ['', ''],
        status: ['',''],
        ledgerId: ['',''],
        message: ['', '']
    }

    constructor(private fb: FormBuilder) {
        this.form = fb.group(this.validationRules);
    }

    getForm() {
        return this.form;
    }

    setValues(response: LinkProfileModel) {
        this.form.patchValue({
            id: response.id,
            ledgerId: response.ledgerId,
            name: response.name,
            mobile: response.mobile,
            email: response.email,
            message: response.message,
        });
    }
}