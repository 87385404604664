import { Deseriazable } from './deserializable.model';

export class CommunityModel implements Deseriazable {
    id:string;
    name: string;
    registrationType: string;
    registrationNumber: string;
    communityStatus: string;
    mobile: string;
    email: string;
    centrexNo:string;
    houseNumber: string;
    street: string;
    block: string;
    landmark: string;
    postOffice: string;
    policeStation: string;
    town: string;
    district: string;
    subDistrict: string;
    pinCode: string;
    stateId: number;
    countryId: number;
    image: number;
    numberOfBlocks: number;
    numberOfBuildings: number;
    numberOfResources: number;
    numberOfCommercialUnits: number;
    numberOfResidentialUnits: number;
    numberOfGarages: number;
    numberOfParkingSpaces: number;
    numberOfMembers: number;
    numberOfActiveMembers: number;
    communityPayable: number;
    communityReceivable: number;
    isSample: number;


    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}