import { Component, OnInit } from '@angular/core';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { TodoModel } from '../Model/todo.model';
import { UiService } from '../ui.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { TodoFormBuilder } from './todo.form-builder';
import { TodoService } from '../api-client/todo.service';
import { PropertyService } from '../api-client/property.service';
import { CommonMethods } from '../common-methods';
import { CategoryService } from '../api-client/category.service';
import { APP_CONST } from '../app-constants';

@Component({
  selector: 'app-todo-details',
  templateUrl: './todo-details.component.html',
  styleUrls: ['./todo-details.component.css']
})
export class TodoDetailsComponent implements OnInit {

  submitted = false;
  model = new TodoModel();
  todoForm: any;
  todoId = null;
  excludeLinkitemTypes = [APP_CONST.PROPERTY_TYPE_PARKING_SPACES, APP_CONST.TODO];
  linkItemTypes = CommonMethods.excludeLinkItemTypes(CommonMethods.linkItemTypes, this.excludeLinkitemTypes);
  linkItemTypeId: number;
  navMode: any;
  propertyType: any;
  listNavigation: any;
  itemName = "--Select--";
  todoName = "--Select--";
  tags = [];
  relatedTo = 0;
  dependentTasks: any = [];
  fetchDependentTasks = [];
  dependentTaskCompleteStatus = [];
  routeExtras: NavigationExtras;
  public config = <IDayCalendarConfig>{
    format: "YYYY-MM-DD HH:mm:ss"
  };
  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  APP_CONST = APP_CONST;
  constructor(private _uiService: UiService,
    private _tosterService: ToastrService,
    private _router: Router,
    private _formBuilder: TodoFormBuilder,
    private _todoService: TodoService,
    private propertyService: PropertyService,
    private _categoryService: CategoryService,
    private _route: ActivatedRoute) {
    this.routeExtras = this._router.getCurrentNavigation().extras;
  }


  ngOnInit() {
    this.todoForm = this._formBuilder.getForm();
    this.todoForm.controls['completeDate'].disable();
    setTimeout(() => {
      this.selectedLinkTypeThroughState();
    }, 100);
    this._route.params.subscribe(params => {
      if (params.id > 0) {
        var data = { communityId: CommonMethods.getCommunityId(), id: params.id };
        this.details(data);
      } else {
        this.resetForm();
      }
    });
    this.categories();
    this.onChanges();
  }

  get f() { return this.todoForm.controls; }

  details(data: any) {
    this._todoService.details(data).subscribe((result: any) => {
      this.todoId = result.id;
      if (result.category) {
        var tags = [];
        var category = result.category.split(",");
        for (var i = 0; i < category.length; i++) {
          tags.push({ id: category[i], name: category[i] });
        }
        result.category = tags;
      }
      this._formBuilder.setValues(result);
      this.getDependentTasks();
      this.itemName = result.linkItemName;
      if (result.completeDate) {
        this.todoForm.controls['completeDate'].enable();
      }

      if (result.linkItemTypeId) {
        this.changeType(result.linkItemTypeId);
      }
    })
  }

  async onSubmit() {
    this.submitted = true;
    if (this.todoForm.invalid) {
      return;
    }
    //console.log(this.selectedRelatedItems);return;   
    this.model.id = this.todoId;
    this.model.deserialize(this.todoForm.value);
    this.model.communityId = Number(CommonMethods.getCommunityId());
    if (document.querySelector<HTMLInputElement>("div.linkItemId input[type='text']")) {
      this.model.linkItemName = document.querySelector<HTMLInputElement>("div.linkItemId input[type='text']").value;
    }
    if (this.todoForm.get('category').value) {
      var result = this.todoForm.get('category').value;
      var tags = [];
      for (var i = 0; i < result.length; i++) {
        tags.push(result[i].name);
      }
      this.model.category = tags.join(",");
    }

    if (this.model.status == "2") {
      if (this.model.completeDate == null || this.model.completeDate == "") {
        this._tosterService.error("Complete date can not be blank");
        return;
      }
      if (this.model.completeDate) {
        var completeDate = new Date(this.model.completeDate);
        var dueDate = new Date(this.model.dueDate);
        if (dueDate.getTime() > completeDate.getTime()) {
          this._tosterService.error("Complete date must be greater than due date");
          return;
        }
      }
      var completeStatus = [];
      for (var i = 0; i < this.dependentTasks.length; i++) {
        await this._todoService.details(this.dependentTasks[i]).toPromise().then((result: any) => {
          if (result.status != 2) {
            completeStatus.push(result.task);
          }
        });
      }
      if (completeStatus.length > 0) {
        for (var i = 0; i < completeStatus.length; i++) {
          this._tosterService.error(`Dependent task ${completeStatus[i]} not completed`);
        }
        return;
      }
    }
    //console.log(this.model);return;
    this._todoService.save(this.model)
      .subscribe(
        (response: any) => {
          this._uiService.todoDataReload.next(true);
          this._uiService.discussionCommentDataReload.next(true);
          this._tosterService.success("To-Do save");
          this.saveDependentTasks();
          this._uiService.closeLeftPopUp();
          return false;
        },
        error => {
          this._tosterService.error(error.message);
        }
      )
  }

  changeType(value: any) {
    this.linkItemTypeId = 0;
    this.relatedTo = 0;
    document.querySelector(".dynamicWidth").classList.remove("w-50");
    document.querySelector(".dynamicWidth").classList.add("w-25");
    if (value > 0) {
      this.linkItemTypeId = value;
      var listNavigation = CommonMethods.linkItemTypes.filter((x: any) => x.id == this.linkItemTypeId);
      if (listNavigation.length > 0) {
        var type = null;
        if (Object(listNavigation[0].action[1]).type) {
          type = Object(listNavigation[0].action[1]).type;
        }
        this.listNavigation = [listNavigation[0].action[0], { mode: Object(listNavigation[0].action[1]).mode, type: type }];
      }
    }
  }

  onSubmitAndNew() {
    this.onSubmit();
    if (!this.todoForm.invalid) {
      this.resetForm();
    }
  }

  resetForm() {
    this.submitted = false;
    this.todoId = null;
    this.todoForm.reset();
  }

  categories() {
    var data = { communityId: CommonMethods.getCommunityId() };
    this._categoryService.all(data).subscribe((result: any) => {
      for (var i = 0; i < result.length; i++) {
        result[i].id = result[i].name;
      }
      this.tags = result;
    });
  }

  changeStatus(value: any) {
    if (value == 2) {
      this.todoForm.controls['completeDate'].enable();
    } else {
      this.todoForm.controls['completeDate'].disable();
    }
  }

  redirectTo() {
    if (!this.todoId) return;
    this._router.navigate([{
      outlets: { rightMainPopup: ["todo-comments", this.todoId] }
    }]);
    return;
  }

  openRelatedItemsSelectionPopup() {
    this._router.navigate([{
      outlets: { rightMainPopup: ["todo-list", { "mode": "TodoSelections", "todoId": this.todoId, "multiSelect": true }] }
    }], { state: { "selectedItems": this.dependentTasks } });
    return;
  }

  deleteItem(item: any) {
    this.dependentTasks.splice(this.dependentTasks.indexOf(item), 1);
  }

  saveDependentTasks() {
    if (!this.todoId) return;
    if (this.dependentTasks.length == 0) return;
    var dependentTaskIds = [];
    for (var i = 0; i < this.dependentTasks.length; i++) {
      dependentTaskIds.push(this.dependentTasks[i].id);
    }
    var data = { communityId: CommonMethods.getCommunityId(), mainTaskId: this.todoId, dependentTaskIds: dependentTaskIds };
    this._todoService.saveDependentTasks(data).subscribe((result: any) => {
      this._tosterService.success("Dependent task saved");
      return;
    })

  }

  async getDependentTasks() {
    if (!this.todoId) return;
    await this._todoService.dependentTasks(this.todoId).toPromise().then((result: any) => {
      this.fetchDependentTasks = result;
      this.bindNameWithDependentTasks();
    });
  }

  async bindNameWithDependentTasks() {
    if (!this.fetchDependentTasks) return;
    var dependentTasks = [];
    for (var i = 0; i < this.fetchDependentTasks.length; i++) {
      await this._todoService.details({ id: this.fetchDependentTasks[i] }).toPromise().then((result: any) => {
        dependentTasks.push({ id: result.id, name: result.task });
      });
    }
    this.dependentTasks = dependentTasks;
  }

  async dependentTaskStatus() {
    if (this.dependentTasks.length > 0) {
      var completeStatus = [];
      for (var i = 0; i < this.dependentTasks.length; i++) {
        await this._todoService.details(this.dependentTasks[i]).toPromise().then((result: any) => {
          if (result.status != 2) {
            completeStatus.push(this.dependentTasks[i].name);
          }
        });
      }
      this.dependentTaskCompleteStatus = completeStatus;
    }
  }

  selectedLinkTypeThroughState() {
    if (this.routeExtras && this.routeExtras.state && this.routeExtras.state.type) {
      this.todoForm.controls.linkItemTypeId.setValue(this.routeExtras.state.type);
      this.todoForm.controls.linkItemId.setValue(this.routeExtras.state.id);
      this.itemName = this.routeExtras.state.name;
      this.changeType(this.routeExtras.state.type);
    }
  }

  relatedTodo(item: any) {
    this.relatedTo = 1;
    document.querySelector(".dynamicWidth").classList.remove("w-25");
    document.querySelector(".dynamicWidth").classList.add("w-50");
    this._uiService.increaseRightPanelWidth = 1;
    this._router.navigate([{
      outlets: { rightMainPopup: ["related-todo", item.id] }
    }]);
    return;
  }

  onChanges(): void {
    this.todoForm.get('linkItemTypeId').valueChanges.subscribe((val: any) => {
      if (val) {
        this.itemName = "--Select--";
        this.todoForm.controls['linkItemId'].setValue(null);
        document.querySelector<HTMLInputElement>("div.linkItemId input[type='text']").value = "";
      }
    });
  }

}
