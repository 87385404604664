import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { CommonMethods } from '../common-methods';
import { MaintenanceLogService } from '../api-client/maintenancelog.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../ui.service';
import { LedgerService } from '../api-client/ledger.service';
import { APP_CONST } from '../app-constants';

@Component({
  selector: 'app-maintenance-visit-logs',
  templateUrl: './maintenance-visit-logs.component.html',
  styleUrls: ['./maintenance-visit-logs.component.css']
})
export class MaintenanceVisitLogsComponent implements OnInit,OnChanges {

  @Input() linkItemType: any;
  @Input() linkItemId: any;
  @Input() tagName: any;
  @Input() relatedTo = 0;
  @Input() hideShowAll:boolean = true;
  maintenanceLogItems=[];
  maintenanceLogs=[];
  vendorName:any;
  APP_CONST = APP_CONST;
  constructor(
    private _maintenanceLogService: MaintenanceLogService,    
    private _uiService: UiService,
    private _router: Router,
    private _ledgerService: LedgerService,
    private _route: ActivatedRoute,) { }
  
  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    console.log(this.linkItemType,this.linkItemId);
    this.logs();
  }

  ngOnInit() {
    this._uiService.maintenanceDataReload.subscribe((result:any)=>{
      this.logs();
    });    
  }

  async logs(){
    if(!this.linkItemType && !this.tagName) return;
    this.maintenanceLogs = [];
    var data = {communityId: CommonMethods.getCommunityId(),linkItemTypeId: this.linkItemType,linkItemId:this.linkItemId,searchData:this.tagName }
    await this._maintenanceLogService.all(data).toPromise().then((result:any)=>{
      this.maintenanceLogs = result.data;
      this.allLogsbindvendor();      
    })
  }

  async allLogsbindvendor(){
    if(!this.maintenanceLogs) return;
    this.maintenanceLogItems = this.maintenanceLogs;
    for(var i=0;i<this.maintenanceLogItems.length;i++){
      var dateTime = this.maintenanceLogItems[i].maintenanceDate.split(" ");
      var date = dateTime[0].split("-");
      this.maintenanceLogItems[i].day = CommonMethods.daySuffix(date[2]);
      if(this.maintenanceLogItems[i].vendorId){
       await this._ledgerService.details(this.maintenanceLogItems[i].vendorId).toPromise().then((result:any)=>{
          this.maintenanceLogItems[i].vendor = result.ledgerName;
        })
      }else{
        this.maintenanceLogItems[i].vendor = null;
      }
    }
  }

  redirectTo(item: any) {
    this._router.navigate([{
      outlets: { leftMainPopup: ["maintenance-log", item.id] }
    }]);
    return;
  }

  allLogs(){
    this._router.navigateByUrl("professional's-visits");
    return;
  }

}
