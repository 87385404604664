import { Component, OnInit } from '@angular/core';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { Router, ActivatedRoute } from '@angular/router';
import { PropertyService } from '../api-client/property.service';
import { UiService } from '../ui.service';
import { CommonMethods } from '../common-methods';
import { APP_CONST } from '../app-constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contract-filter',
  templateUrl: './contract-filter.component.html',
  styleUrls: ['./contract-filter.component.css']
})
export class ContractFilterComponent implements OnInit {

  filterParams: any = {
    searchData: '',
    //linkItemName: '',
    type : '',
    linkItemTypeId: 0,
    linkItemId: 0,
    startDate : null,
    expiryDate : null,
    vendorId: null,
    mode:null,
    status:null
  };
  excludeLinkitemTypes = [APP_CONST.INVOICE_TYPE_SOCIETY,APP_CONST.INVOICE_TYPE_PURCHASE];
  linkItemTypes = CommonMethods.excludeLinkItemTypes(CommonMethods.linkItemTypes,this.excludeLinkitemTypes);
  itemName= "--Select--";
  vendorName = "--Select--";
  listNavigation:any;
  public config = <IDayCalendarConfig>{
    format: "YYYY-MM-DD"
  };
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _propertyService: PropertyService,
    private _tosterService: ToastrService,
    private _uiService: UiService) {
  }  

  ngOnInit() {
    if(!this._uiService.activeFilterData){
      this._uiService.closeLeftPopUp();
      return;
    }
    this.filterParams = this._uiService.activeFilterData;
    if(this.filterParams.linkItemId){
      if(localStorage.getItem("linkItemName")){
        this.itemName = localStorage.getItem("linkItemName");
      }
    }else{
      localStorage.removeItem("linkItemName");
    }
    if(this.filterParams.linkItemTypeId){
      this.changeType(this.filterParams.linkItemTypeId);
    }
    if(this.filterParams.vendorId){
      if(localStorage.getItem("vendorName")){
        this.vendorName = localStorage.getItem("vendorName");
      }else{
        localStorage.removeItem("vendorName");
      }
    }
  }

  applyFilter() {
    if(this.filterParams.startDate && this.filterParams.expiryDate){
      var fromDate = new Date(this.filterParams.startDate);
      var toDate = new Date(this.filterParams.expiryDate);
      if(fromDate.getTime() > toDate.getTime()){
        this._tosterService.error("Start date not greater than expire date");
        return;
      }
    }
    this._uiService.activeFilterCallBack();
    if(this.filterParams.linkItemId){
      localStorage.setItem("linkItemName",document.querySelector<HTMLInputElement>("div.linkItemId input[type='text']").value);
    }
    if(this.filterParams.vendorId){
      localStorage.setItem("vendorName",document.querySelector<HTMLInputElement>("div.vendorId input[type='text']").value);
    }
    this._uiService.closeLeftPopUp();
  }

  changeType(value: any) {
    this.filterParams.linkItemTypeId = 0;
    if (value > 0) {
      this.filterParams.linkItemTypeId = value;
      var listNavigation = this.linkItemTypes.filter((x: any) => x.id == this.filterParams.linkItemTypeId);
      if (listNavigation.length > 0) {
        var type = null;
        if (Object(listNavigation[0].action[1]).type) {
          type = Object(listNavigation[0].action[1]).type;
        }
        this.listNavigation = [listNavigation[0].action[0], { mode: Object(listNavigation[0].action[1]).mode, type: type }];
      }
    }
  }

  clearFilter(){
    this.filterParams.searchData = '';
    this.filterParams.type = '';
    this.filterParams.linkItemTypeId = 0;
    this.filterParams.linkItemId = 0;
    this.filterParams.startDate = null;
    this.filterParams.expiryDate = null;
    this.filterParams.vendorId = null;
    this.filterParams.mode = null;
    this.filterParams.status = null;

    document.querySelector<HTMLInputElement>("div.linkItemId input[type='text']").value = "";
    document.querySelector<HTMLInputElement>("div.vendorId input[type='text']").value = "";

    localStorage.removeItem("linkItemName");
    localStorage.removeItem("vendorName");   
  }

}
