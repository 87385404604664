import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject,Observable, of  } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../../app/config';
import { StaffModel } from '../Model/staff.model';

@Injectable({
  providedIn: 'root'
})
export class StaffService {
  

  constructor(private _http: HttpClient) {
  }

  save(data:StaffModel): Observable<StaffModel> {
    if(data.id){
      return this._http.put(config.apiEndPointBase + "/user/web/update",data, {responseType: 'text'})
      .pipe(map((response:any) => {
        return new StaffModel().deserialize(response);
      }));
    }else{
      return this._http.post(config.apiEndPointBase + "/user/web/create",data, {})
      .pipe(map((response:any) => {
        return new StaffModel().deserialize(response);
      }));
    }
  }

  details(userId:any){
    return this._http.get(config.apiEndPointBase + `/user/${userId}/web`);
  }

  delete(userId:any){
    return this._http.get(config.apiEndPointBase + `/user/${userId}/web`,{responseType: 'text'});
  }

  roles(data:any){
    return this._http.post(config.apiEndPointBase + "/user/web/roles",data, {})
    .pipe(map((response:any) => {
      return response;
    }));
  }

  getMyProfile(){
    return this._http.get(config.apiEndPointBase + `/me`);
  }

  profile(data:any){
    return this._http
      .put(config.apiEndPointBase + "/me", data,{responseType: 'text'})
      .pipe(map((response: any) => {
        return response;
      }));
  }
  
  capabilities(){
    return this._http.get(config.apiEndPointBase + `/me/capabilities`);
  }
  
}
