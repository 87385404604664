import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { AccountGroupService } from '../api-client/account-group.service';
import { LedgerService } from '../api-client/ledger.service';

@Component({
  selector: 'app-hierarchy',
  templateUrl: './hierarchy.component.html',
  styleUrls: ['./hierarchy.component.css']
})
export class HierarchyComponent implements OnInit {

  groups: any;
  ledgers = [];
  activeGroup:number;
  hierachy = [];
  hierarchyData = [];
  finalData = [];

  constructor(
    private _accountGroupService: AccountGroupService,
    private _ledgerService: LedgerService,
    private _route: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.listGroups();
  }

  listGroups(): void {
    var data = { communityId: CommonMethods.getCommunityId() };
    this._accountGroupService.all(data).subscribe((result: any) => {
      this.groups = result;
      this.getHierarchy();
      this.finalData = this.finalData[0];
    })
  }

  toggleGroup(item:any): void {
    this.activeGroup = item.id;
    this.listLedgers(item);
  }

  listLedgers(item:any): void {
    var data = { communityId: CommonMethods.getCommunityId(), accountGroupId:item.id  };
    this._ledgerService.itemsByGroup(data).subscribe((result: any) => {
      this.ledgers = result;
    });
  }

  getHierarchy(): void {
    this.populateHierarchy(0);
    this.groups.forEach((val,key) => {
      val.children = this.getChildren(val.id);
      this.hierarchyData[key] = val;
    });
    this.populateHierarchyData(0);
    this.finalData = this.finalData.filter(value => Object.keys(value).length !== 0);
  }

  getChildren(id:number = 0): any[] {
    var obj = [];
    this.hierachy.forEach((val,key) => {
      if(key == id){
        obj.push(val);
      }
    });
    return obj;
  }

  populateHierarchy(id:number): void {   
    this.hierachy[id] = [];
    this.groups.forEach(element => {
      if(element.parentId == id){
        this.hierachy[id].push(element);
        this.populateHierarchy(element.id);
      }
    });
  }

  populateHierarchyData(id:number): void {   
    this.finalData[id] = [];
    this.hierarchyData.forEach(element => {
      if(element.parentId == id){
        this.finalData[id].push(element);
        this.populateHierarchyData(element.id);
      }
    });
  }

  redirectionType(): void {
    this._router.navigateByUrl("/dashboard");
  }

}
