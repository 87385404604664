import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject,Observable, of  } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../../app/config';
import { AccountGroupModel } from '../Model/accountGroup.model';

@Injectable({
  providedIn: 'root'
})

export class AccountGroupService{

    constructor(private _http:HttpClient)
    {

    }

    all(data:any) {
        return this._http
          .post(config.apiEndPointBase + "/accountgroup/list", data, {})
          .pipe(map((response: any) => {
            return response;
          }));
    }

    save(data:AccountGroupModel): Observable<AccountGroupModel> {
      if(data.id){
        return this._http.put(config.apiEndPointBase + "/accountgroup",data, {responseType: 'text'})
        .pipe(map((response:any) => {
          return new AccountGroupModel().deserialize(response);
        }));
      }else{
        return this._http.post(config.apiEndPointBase + "/accountgroup",data, {})
        .pipe(map((response:any) => {
          return new AccountGroupModel().deserialize(response);
        }));
      }
    }

    delete(id:number){
      return this._http.delete(config.apiEndPointBase + `/accountgroup/${id}`,{responseType: 'text'});
    }

    details(id:number){
        return this._http.get(config.apiEndPointBase + `/accountgroup/${id}`);
    }

    subGroupsByGroupId(id:number){
      return this._http.get(config.apiEndPointBase + `/accountgroup/sub_group_list/${id}`);
    }
}