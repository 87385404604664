import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UiService } from '../ui.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonMethods } from '../common-methods';
import { VoucherService } from '../api-client/voucher.service';


@Component({
  selector: 'app-vouchers-by-type',
  templateUrl: './vouchers-by-type.component.html',
  styleUrls: ['./vouchers-by-type.component.css']
})
export class VouchersByTypeComponent implements OnInit {

  units:any;
  vouchers:any;
  params:any;
  filterVouchers: any;
  voucherType: any;
  constructor( 
    private _tosterService: ToastrService,
    private _uiService: UiService,
    private _voucherService: VoucherService,
    private _route: ActivatedRoute,
    private _router: Router) { }

  ngOnInit() {
    this._route.params.subscribe((params:any) => {
      if(CommonMethods.voucherType.includes(params.type.toLowerCase())){
        this.voucherType = CommonMethods.voucherType.indexOf(params.type.toLowerCase());
      }
      var args = {"communityId": CommonMethods.getItem("communityId"),
                  type:this.voucherType};
      this.voucherList(args);
    })
  } 

  voucherList(data:any){
    this._voucherService.listByType(this.voucherType,data).subscribe((result:any)=>{
      console.log("voucher",result);
      this.vouchers = result;
      this.filterVouchers = this.vouchers;
    })
  }
  filterVoucher(eve:any){
    var code = eve.keyCode;
    if(code == 37 || code == 38 || code == 39 || code == 40) {
        return;
    }
    var ele = eve.target;
    if (ele.value) {
      this.filterVouchers = this.vouchers.filter((x: any) => x.resourceName.toLowerCase().includes(ele.value.toLowerCase()));
    } else {
      //this.resourceList(this.params);
    }
  }

  details(item:any){
    if (this._uiService.activeListCallBack) {
      this._uiService.activeListCallBack(item.id, item.voucherNumber);
      this._uiService.activeListCallBack = null;
      this._uiService.closeItself();
    } else {
      //this._router.navigateByUrl(`resource/${item.id}`);       
      return false;
    }
  }

}
