import { Component, OnInit } from '@angular/core';
import { BuildingService } from '../api-client/building.service';
import { ToastrService } from 'ngx-toastr';
import { PropertyService } from '../api-client/property.service';
import { ResourceService } from '../api-client/resource.service';
import { UserService } from '../api-client/user.service';
import { UiService } from '../ui.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { CommonMethods } from '../common-methods';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements OnInit {

  units: any;
  resources: any;
  params: any;
  filterResources: any;
  routeExtras: NavigationExtras;
  multiSelect: false;
  constructor(private _buildingkService: BuildingService,
    private _tosterService: ToastrService,
    private _propertyService: PropertyService,
    private _resourceService: ResourceService,
    private _userService: UserService,
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _router: Router) {
    this.routeExtras = this._router.getCurrentNavigation().extras;
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this.params = {
        "communityId": CommonMethods.getItem("communityId"),
        "blockId": params.blockId,
        "buildingId": params.buildingId
      };
      if (params.multiSelect) {
        this.multiSelect = params.multiSelect;
      }
      this.resourceList(this.params);

    })
  }

  resourceList(data: any) {
    this._resourceService.all(data).subscribe((result: any) => {
      console.log("resource", result);
      this.resources = result;
      this.filterResources = this.resources;
    })
  }
  filterResource(eve: any) {
    var code = eve.keyCode;
    if (code == 37 || code == 38 || code == 39 || code == 40) {
      return;
    }
    var ele = eve.target;
    if (ele.value) {
      this.filterResources = this.resources.filter((x: any) => x.resourceName.toLowerCase().includes(ele.value.toLowerCase()));
    } else {
      //this.resourceList(this.params);
    }
  }

  details(item: any) {
    if (this._uiService.activeListCallBack) {
      this._uiService.activeListCallBack(item.id, item.resourceName);
      this._uiService.activeListCallBack = null;
      this._uiService.closeItself();
    } else {
      this._router.navigateByUrl(`resource/${item.id}`);
      return false;
    }
  }

  redirectTo(item: any) {
    this._router.navigate(["resource/" + item.id],{ state: { "blockId": this.params.blockId ,"buildingId": this.params.buildingId} });
    setTimeout(()=>{
      this._uiService.closeItself();
    },100);
    return;
  }

  findMatchingItemInSelectedItems(id:any){
    if (this.routeExtras && this.routeExtras.state && this.routeExtras.state.selectedItems) {
      let items = this.routeExtras.state.selectedItems.filter((x:any)=>x.id==id);
      if(items.length>0){
        return items[0];
      }
    }
  }

  itemSelectChange(eve: any, resource: any) {
    if (this.routeExtras && this.routeExtras.state && this.routeExtras.state.selectedItems) {
      if (eve.target.checked) {
        this.routeExtras.state.selectedItems.push({id:resource.id, name:resource.resourceName});
      }else{
        let item = this.findMatchingItemInSelectedItems(resource.id);
        if(item){
          let index = this.routeExtras.state.selectedItems.indexOf(item);
          this.routeExtras.state.selectedItems.splice(index, 1);
        }
      }
    }
  }

}
