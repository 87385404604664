import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LedgerModel } from './../Model/ledger.model';
import { CustomValidator } from '../customvalidation';
import { MaintenanceLogModel } from '../Model/maintenance-log.model';

@Injectable({
    providedIn: 'root'
})

export class MaintenanceLogFormBuilder {
    private form;
    private validationRules = {
        type: ['', Validators.required],
        visitorName: ['', Validators.required],
        visitorDesignation: ['', Validators.required],
        visitorPhoneNumber: ['', [Validators.required,Validators.minLength(10),CustomValidator.numeric]],
        verifierDesignation: ['', Validators.required],
        verifierName: ['', Validators.required],
        verifierPhoneNumber: ['', [Validators.required,Validators.minLength(10),CustomValidator.numeric]],
        maintenanceDate: ['', Validators.required], 
        linkedVoucherId: ['', ''],
        isJobDone: ['',''],
        remarks: ['', Validators.required],
        vendorId: ['', ''],
        category: ['', ''],
        nextActionRequired: ['', ''],
        nextActionDate: ['', ''],
        contractId: ['', ''],
    }

    constructor(private fb: FormBuilder) {
        this.form = fb.group(this.validationRules);
    }

    getForm() {
        return this.form;
    }

    setValues(response: MaintenanceLogModel) {
        this.form.patchValue({
            id: response.id,
            type: response.type,
            isJobDone: Number(response.isJobDone),
            visitorName: response.visitorName,
            visitorDesignation: response.visitorDesignation,
            visitorPhoneNumber: response.visitorPhoneNumber,
            verifierDesignation: response.verifierDesignation,
            verifierName: response.verifierName,
            verifierPhoneNumber: response.verifierPhoneNumber,
            maintenanceDate: response.maintenanceDate,
            linkedVoucherId: response.linkedVoucherId,
            remarks: response.remarks,
            vendorId: response.vendorId,
            contractId: response.contractId,
            category: response.category,
            nextActionRequired: response.nextActionRequired,
            nextActionDate: response.nextActionDate,
        });
    }
}