import { ResourceService } from './../api-client/resource.service';
import { PropertyService } from './../api-client/property.service';
import { BlockModel } from './../Model/block.model';
import { Component, OnInit } from '@angular/core';
import { BlockService } from '../api-client/block.service';
import { BuildingService } from '../api-client/building.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../ui.service';
import { UserService } from '../api-client/user.service';
import { BlockFormBuilder } from './block.form-builder';
import { CommonMethods } from '../common-methods';
import { APP_CONST } from '../app-constants';

@Component({
  selector: 'app-block-details',
  templateUrl: './block-details.component.html',
  styleUrls: ['./block-details.component.css']
})
export class BlockDetailsComponent implements OnInit {

  blockDetails: any;
  buildings = [];
  blockForm: any;
  typeWisePropertyCount: any;
  ammenitiesArray = [];
  ammenities = [];
  resources = [];
  submitted = false;
  communityId: any;
  blockId: any;
  buildingId: number;
  blockName: any;
  routeParams: any;
  args: any;
  representativeName = "--select--";
  model = new BlockModel();
  APP_CONST = APP_CONST;
  linkItemType: any;
  linkItemId: any;
  linkItemName: any;
  deleteParams: any = {
    itemId: 0,
    itemName: null,
    btnOk: "Delete",
    btnCancel: "Close",
    deleteText: "Are you sure, you want to delete this block?"
  }
  error = [];
  constructor(
    private _blockService: BlockService,
    private _buildingService: BuildingService,
    private _tosterService: ToastrService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _userServie: UserService,
    private _formBuilder: BlockFormBuilder,
    private _uiService: UiService,
    private _userService: UserService,
    private _propertyService: PropertyService,
    private _resourceService: ResourceService,
  ) { }

  ngOnInit() {
    this._uiService.leftMenuItems.next([
      { code: 'F4', name: 'Save', action: this.save.bind(this), class: 'green lighten-1', privilege: APP_CONST.BLOCK_WRITE  },
      { code: 'F5', name: 'Save & Add New', action: this.saveAddNew.bind(this), privilege: APP_CONST.BLOCK_WRITE },
      { code: 'F7', name: 'Delete', action: this.confirmationPopUp.bind(this), class: 'red lighten-1', privilege: APP_CONST.BLOCK_REMOVE }
    ]);

    this.buildingId = 0;
    this.blockDetails = {};
    this.blockForm = this._formBuilder.getForm();

    this._route.params.subscribe(params => {
      if (params.id > 0) {
        this.details(params.id);
        this.args = {
          "communityId": CommonMethods.getItem("communityId"),
          "communityBlockId": params.id
        };
        this.buildingList(this.args);
        this.ammenitiesList(params.id, this.buildingId, APP_CONST.PROPERTY_TYPE_AMENITY);
        this.resourceList(this.args);
      } else {
        this.resetForm();
      }
      this.populateBuildingOutsideItemsCount(params.id, this.buildingId);
      this.routeParams = params;
      this._uiService.linkItem = { id: params.id, type: APP_CONST.PROPERTY_TYPE_BLOCKS, name: null };
      this.linkItemType = APP_CONST.PROPERTY_TYPE_BLOCKS;
      this.linkItemId = params.id;
    });
  }

  get f() { return this.blockForm.controls; }

  async details(id: number) {
    await this._blockService.details(id).toPromise().then((result: any) => {
      this._uiService.linkItem.name = result.communityBlockName;
      this.linkItemName = result.communityBlockName;
      this.communityId = result.communityId;
      this.blockId = result.id;
      this.blockName = result.communityBlockName;
      this.deleteParams.itemId = result.id;
      this.deleteParams.itemName = result.communityBlockName;
      if (result.communityBlockRepresentative) {
        this._userService.details(result.communityBlockRepresentative).subscribe((result: any) => {
          this.representativeName = result.name;
        })
      } else {
        this.representativeName = null;
      }

      this._formBuilder.setValues(result);
    })
  }

  async onSubmit() {
    this.submitted = true;
    if (this.blockForm.invalid) {
      return;
    }
    this.model.deserialize(this.blockForm.value);
    this.model.communityId = Number(CommonMethods.getCommunityId());
    this.model.id = this.blockId;
    await this._blockService.save(this.model)
      .toPromise().then(
        (response: BlockModel) => {
          if (response) {
            this._tosterService.success("Block save");
            if (!this.blockId) {
              this.blockId = response.id;
            }
            this.error = [];
          }else{
            this.error.push("Server error");
          }
        },
        error => {
          this._tosterService.error(error.message);
        }
      )
  }

  buildingList(data: any) {
    this._buildingService.all(data).subscribe((result: any) => {
      this.buildings = result;
    });
  }

  redirectToBuilding(item: any) {
    this._router.navigateByUrl("building/" + item.id);
  }

  async populateBuildingOutsideItemsCount(id: number, buildingId: number) {
    await this._propertyService.types().toPromise().then((propertyTypes: any) => {
      this.typeWisePropertyCount = {};
      for (let k = 0; k < propertyTypes.length; k++) {
        this.typeWisePropertyCount[propertyTypes[k].id] = {
          id: propertyTypes[k].id,
          name: propertyTypes[k].name,
          count: 0
        };
      }
    });

    await this._propertyService.getCount(id, buildingId).toPromise().then((result: any) => {
      for (let i = 0; i < result.length; i++) {
        if (this.typeWisePropertyCount[result[i].property_type]) {
          this.typeWisePropertyCount[result[i].property_type].count = result[i].property_count;
        }
      }
    });
  }

  async ammenitiesList(blockId: number, buildingId: number, propertyType: any) {
    await this._propertyService.getDetailsById(blockId, buildingId, propertyType).toPromise().then((result: any) => {
      this.ammenities = result;
    })
  }

  async resourceList(data: any) {
    await this._resourceService.all(data).subscribe((result: any) => {
      this.resources = result;
    })
  }

  redirectToResource(item: any) {
    this._router.navigateByUrl("resource/" + item.id);
  }

  propertyDetails(item: any) {
    var propertyType = CommonMethods.propertyTypes.filter((x: any) => x.id == item.propertyType);
    if (propertyType.length > 0) {
      this._router.navigateByUrl(`${propertyType[0].name}/${item.id}`);
    }
  }

  async save() {
    await this.onSubmit();
    if(this.error.length>0) return;
    setTimeout(() => {
      if (this.blockId > 0) {
        this._router.navigateByUrl(`block/${this.blockId}`);
        return;
      }
    }, 1000);
  }

  async saveAddNew() {
    await this.onSubmit();
    if(this.error.length>0) return;
    if (!this.blockForm.invalid) {
      this.resetForm();
    }
  }

  confirmationPopUp() {
    if (this.blockId > 0) {
      this._uiService.deleteItemData = this.deleteParams;
      this._uiService.deleteItemCallBack = this.delete.bind(this);
      this._router.navigate([{
        outlets: { centerPopup: ['delete-item'] }
      }]);
    }
  }

  delete() {
    if (this.deleteParams.pressBtn == "Ok") {
      this._blockService.delete(this.deleteParams.itemId).subscribe((result: any) => {
        this._tosterService.success("Block Deleted");
        this.resetForm();
        return;
      });
    }
  }

  resetForm() {
    this.submitted = false;
    this.blockId = null;
    this.blockName = "";
    this.representativeName = "--select--";
    this.error = [];
    this.blockForm.reset();
    setTimeout(() => {
      this._router.navigateByUrl("block/0");
    }, 100)
  }

  redirectToProperties(blockId:number,property:any){
    console.log(blockId,property.key);
    var params = [{ outlets: { rightMainPopup: ["units", { "mode": "PropertySelections", "blockId": blockId,"buildingId":0,"type":property.key }] } }];
    this._router.navigate(params); return;
  }

  redirectionType(item: any, itemName: any, itemValue: any, mode: any, displayType: any) {
    let params: any;
    if (displayType == 'listing') {
      if (mode == "block") {
        params = [{ outlets: { rightMainPopup: ["blocks", { "mode": "BlockSelections" }] } }];
      } else if (mode == "building") {
        params = [{ outlets: { rightMainPopup: ["blocks", { "mode": "BuildingSelections" }] } }];
      } else if (mode == "resource") {
        params = [{ outlets: { rightMainPopup: ["blocks", { "mode": "ResourceSelections" }] } }];
      } else if (mode == "property") {
        params = [{ outlets: { rightMainPopup: ["blocks", { "mode": "PropertySelections", "type": item.id }] } }];
      } else if (mode == "dashboard") {
        this._router.navigateByUrl("/dashboard"); return;
      }
      this._router.navigate(params); return;
    } else if (displayType == 'add') {
      if (mode == "block") {
        params = ["block", 0];
      } else if (mode == "building") {
        params = ["building", 0];
      } else if (mode == "resource") {
        params = ["resource", 0];
      } else if (mode == "property") {
        var propertyTypeSlug = CommonMethods.propertyTypes.filter((x: any) => x.id == item.id);
        params = [propertyTypeSlug[0].name, 0];
      }
      this._router.navigate(params, { state: { "itemName": itemName, "itemValue": itemValue } }); return;
    }
    this._router.navigate(params); return;
  }

  redirectToProperty(item: any) {
    this._router.navigate([{ outlets: { rightMainPopup: ["blocks", { "mode": "PropertySelections", "type": item.id }] } }]);
    return;
  }

  ngOnDestroy(): void {
    this._uiService.leftMenuItems.next([]);
  }

}
