import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { OtpComponent } from './otp/otp.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { AuthGuardService } from './_guards/auth-guard.service';
import { BlocksComponent } from './blocks/blocks.component';
import { CommunitiesComponent } from './communities/communities.component';
import { CommunityGuardService } from './_guards/community-guard.service';
import { BlockDetailsComponent } from './block-details/block-details.component';
import { BuildingsComponent } from './buildings/buildings.component';
import { BuildingDetailsComponent } from './building-details/building-details.component';
import { LoginGuardService } from './_guards/login-guard.service';
import { UnitsResourcesComponent } from './units-resources/units-resources.component';
import { UnitDetailsComponent } from './unit-details/unit-details.component';
import { ResourceDetailsComponent } from './resource-details/resource-details.component';
import { CommunityDetailsComponent } from './community-details/community-details.component';
import { ResourcesComponent } from './resources/resources.component';
import { AccountGroupComponent } from './account-group/account-group.component';
import { AccountGroupDetailsComponent } from './account-group-details/account-group-details.component';
import { LedgersComponent } from './ledgers/ledgers.component';
import { LedgerDetailsComponent } from './ledger-details/ledger-details.component';
import { HierarchyComponent } from './hierarchy/hierarchy.component';
import { CountriesComponent } from './countries/countries.component';
import { StatesComponent } from './states/states.component';
import { BillingChargesComponent } from './billing-charges/billing-charges.component';
import { BillingChargesDetailsComponent } from './billing-charges-details/billing-charges-details.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { MeasurementUnitsComponent } from './measurement-units/measurement-units.component';
import { InvoiceDetailsComponent } from './invoice-details/invoice-details.component';
import { CommunityMembersComponent } from './community-members/community-members.component';
import { PropertyTypesComponent } from './property-types/property-types.component';
import { CommunityBuildingsComponent } from './community-buildings/community-buildings.component';
import { DiscountTemplatesComponent } from './discount-templates/discount-templates.component';
import { DiscountTemplateDetailsComponent } from './discount-template-details/discount-template-details.component';
import { LateFeeTemplatesComponent } from './late-fee-templates/late-fee-templates.component';
import { LateFeeTemplateDetailsComponent } from './late-fee-template-details/late-fee-template-details.component';
import { VoucherFilterComponent } from './voucher-filter/voucher-filter.component';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { PaymentsComponent } from './payments/payments.component';
import { UsersComponent } from './users/users.component';
import { StaffDetailsComponent } from './staff-details/staff-details.component';
import { PaymentFilterComponent } from './payment-filter/payment-filter.component';
import { StaffsComponent } from './staffs/staffs.component';
import { MaintenanceLogComponent } from './maintenance-log/maintenance-log.component';
import { MaintenanceContractComponent } from './maintenance-contract/maintenance-contract.component';
import { VouchersByTypeComponent } from './vouchers-by-type/vouchers-by-type.component';
import { MaintenanceVisitLogsComponent } from './maintenance-visit-logs/maintenance-visit-logs.component';
import { MaintenanceContractsComponent } from './maintenance-contracts/maintenance-contracts.component';
import { TodosComponent } from './todos/todos.component';
import { RelatedVouchersComponent } from './related-vouchers/related-vouchers.component';
import { TodoDetailsComponent } from './todo-details/todo-details.component';
import { BulkInvoiceComponent } from './bulk-invoice/bulk-invoice.component';
import { AllTodoComponent } from './all-todo/all-todo.component';
import { AllMaintenanceVisitorLogComponent } from './all-maintenance-visitor-log/all-maintenance-visitor-log.component';
import { AllMaintenanceContractsComponent } from './all-maintenance-contracts/all-maintenance-contracts.component';
import { LogFilterComponent } from './log-filter/log-filter.component';
import { DeleteItemComponent } from './delete-item/delete-item.component';
import { APP_CONST } from './app-constants';
import { AssociateLedgerComponent } from './associate-ledger/associate-ledger.component';
import { ContractFilterComponent } from './contract-filter/contract-filter.component';
import { DiscussionsComponent } from './discussions/discussions.component';
import { DiscussionViewComponent } from './discussion-view/discussion-view.component';
import { DiscussionDetailsComponent } from './discussion-details/discussion-details.component';
import { VouchersComponent } from './vouchers/vouchers.component';
import { CategoryComponent } from './category/category.component';
import { DiscussionFilterComponent } from './discussion-filter/discussion-filter.component';
import { ComplainsComponent } from './complains/complains.component';
import { TodoListComponent } from './todo-list/todo-list.component';
import { TodoCommentsComponent } from './todo-comments/todo-comments.component';
import { TodoFilterComponent } from './todo-filter/todo-filter.component';
import { TodoViewComponent } from './todo-view/todo-view.component';
import { ProfileComponent } from './profile/profile.component';
import { CapabilityGuardService } from './_guards/capability-guard.service';
import { FundTransferComponent } from './fund-transfer/fund-transfer.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MembersComponent } from './members/members.component';
import { MemberFilterComponent } from './member-filter/member-filter.component';
import { ChangeRoleComponent } from './change-role/change-role.component';
import { DuesReportComponent } from './dues-report/dues-report.component';
import { CapabilitiesComponent } from './capabilities/capabilities.component';
import { CommunitySettingsComponent } from './community-settings/community-settings.component';
import { StatementsReportComponent } from './statements-report/statements-report.component';
import { RentTemplateComponent } from './rent-template/rent-template.component';
import { BlockwiseIncomeReportComponent } from './blockwise-income-report/blockwise-income-report.component';
import { BlockIncomeDetailsReportComponent } from './block-income-details-report/block-income-details-report.component';
import { BookingCalenderComponent } from './booking-calender/booking-calender.component';
import { BookingsComponent } from './bookings/bookings.component';
import { BookingViewComponent } from './booking-view/booking-view.component';
import { BookingDetailsComponent } from './booking-details/booking-details.component';
import { BookingFilterComponent } from './booking-filter/booking-filter.component';
import { BookingAvalibilityComponent} from './booking-avalibility/booking-avalibility.component';
import { InterTransferReportComponent } from './inter-transfer-report/inter-transfer-report.component';
import { PropertyWonerReportComponent } from './property-woner-report/property-woner-report.component';

 
const routes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [AuthGuardService] },
  { path: 'login', component: LoginComponent,canActivate: [LoginGuardService] },
  { path: 'otp', component: OtpComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'dashboard', component: DashboardComponent,canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'menu', component: MainMenuComponent },
  //{ path: 'blocks', component: BlocksComponent,canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'blocks', component: BlocksComponent,outlet: 'rightMainPopup',data:{capability:APP_CONST.BLOCK_READ},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'block/:id', component: BlockDetailsComponent,data:{capability:APP_CONST.BLOCK_WRITE}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  
  { path: 'buildings', component: BuildingsComponent,outlet: 'rightMainPopup',data:{capability:APP_CONST.BUILDING_READ},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'building/:id', component: BuildingDetailsComponent,data:{capability:APP_CONST.BUILDING_WRITE},canActivate: [AuthGuardService, CommunityGuardService,CapabilityGuardService] },
  
  { path: 'units', component: UnitsResourcesComponent,outlet: 'rightMainPopup',data:{capability:APP_CONST.UNIT_READ},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  //{ path: 'unit/:propertyType/:id', component: UnitDetailsComponent,canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'flat-house/:id', component: UnitDetailsComponent,data:{propertyType:APP_CONST.PROPERTY_TYPE_FLAT,capability:APP_CONST.UNIT_WRITE},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'offices/:id', component: UnitDetailsComponent,data:{propertyType:APP_CONST.PROPERTY_TYPE_OFFICES,capability:APP_CONST.UNIT_WRITE},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'shops/:id', component: UnitDetailsComponent,data:{propertyType:APP_CONST.PROPERTY_TYPE_SHOPS,capability:APP_CONST.UNIT_WRITE},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'parking-space/:id', component: UnitDetailsComponent,data:{propertyType:APP_CONST.PROPERTY_TYPE_PARKING_SPACES,capability:APP_CONST.UNIT_WRITE},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'garage-2wheeler/:id', component: UnitDetailsComponent,data:{propertyType:APP_CONST.PROPERTY_TYPE_GARAGES_TWO_WHEELER,capability:APP_CONST.UNIT_WRITE},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'garage-4wheeler/:id', component: UnitDetailsComponent,data:{propertyType:APP_CONST.PROPERTY_TYPE_GARAGES_FOUR_WHEELER,capability:APP_CONST.UNIT_WRITE},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'amenity/:id', component: UnitDetailsComponent,data:{propertyType:APP_CONST.PROPERTY_TYPE_AMENITY,capability:APP_CONST.UNIT_WRITE},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'quarter/:id', component: UnitDetailsComponent,data:{propertyType:APP_CONST.PROPERTY_TYPE_QUARTER,capability:APP_CONST.UNIT_WRITE},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'parking-space-2wheeler/:id', component: UnitDetailsComponent,data:{propertyType:APP_CONST.PROPERTY_TYPE_PARKING_SPACES_TWO_WHEELER,capability:APP_CONST.UNIT_WRITE},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'parking-space-4wheeler/:id', component: UnitDetailsComponent,data:{propertyType:APP_CONST.PROPERTY_TYPE_PARKING_SPACES_FOUR_WHEELER,capability:APP_CONST.UNIT_WRITE},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  
  { path: 'tags', component: CategoryComponent,data:{capability:APP_CONST.CATEGORY_READ},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'resources', component: ResourcesComponent,outlet: 'rightMainPopup',data:{capability:APP_CONST.RESOURCE_READ},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'resource/:id', component: ResourceDetailsComponent,data:{capability:APP_CONST.RESOURCE_WRITE},canActivate: [AuthGuardService, CommunityGuardService,CapabilityGuardService] },
  { path: 'community-details', component: CommunityDetailsComponent,data:{capability:APP_CONST.COMMUNITY_READ},canActivate: [AuthGuardService, CommunityGuardService,CapabilityGuardService] },
  { path: 'community', component: CommunitiesComponent,outlet: 'fullOutlet',data:{capability:APP_CONST.COMMUNITY_READ},canActivate: [AuthGuardService] },
  { path: 'account-groups', component: AccountGroupComponent,outlet: 'rightMainPopup',data:{capability:APP_CONST.ACCOUNTING_SETUP_READ},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'account-group/:id', component: AccountGroupDetailsComponent,data:{capability:APP_CONST.ACCOUNTING_SETUP_WRITE}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'ledgers/:type', component: LedgersComponent,outlet: 'rightMainPopup',data:{capability:APP_CONST.ACCOUNTING_SETUP_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'ledger/:id', component: LedgerDetailsComponent,data:{capability:APP_CONST.ACCOUNTING_SETUP_WRITE}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'hierarchy', component: HierarchyComponent,data:{capability:APP_CONST.ACCOUNTING_SETUP_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },  
  { path: 'countries', component: CountriesComponent,outlet: 'rightMainPopup',canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'states/:countryId', component: StatesComponent,outlet: 'rightMainPopup',canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'billing-charges', component: BillingChargesComponent,outlet: 'rightMainPopup',data:{capability:APP_CONST.BILLING_CHARGES_SETUP_READ},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'billing-charges-details/:id', component: BillingChargesDetailsComponent,data:{capability:APP_CONST.BILLING_CHARGES_SETUP_WRITE},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'ledgerByDefaultGroup/:group', component: LedgersComponent,outlet: 'rightMainPopup', canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'ledgersByType/:ledgerType', component: LedgersComponent,outlet: 'rightMainPopup', canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'measurement-units', component: MeasurementUnitsComponent,outlet: 'rightMainPopup', canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'ledgersByAccountGroup/:accountGroup', component: LedgersComponent,outlet: 'rightMainPopup', canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'community-members', component: CommunityMembersComponent,outlet: 'rightMainPopup',data:{capability:APP_CONST.MEMBER_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'property-types', component: PropertyTypesComponent,outlet: 'rightMainPopup',data:{capability:APP_CONST.UNIT_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'community-buildings', component: CommunityBuildingsComponent,outlet: 'rightMainPopup', canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'discounts', component: DiscountTemplatesComponent,outlet: 'rightMainPopup',data:{capability:APP_CONST.BILLING_CHARGES_SETUP_WRITE}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'discount-details/:id', component: DiscountTemplateDetailsComponent,data:{capability:APP_CONST.BILLING_CHARGES_SETUP_WRITE}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'late-fees', component: LateFeeTemplatesComponent,outlet: 'rightMainPopup',data:{capability:APP_CONST.BILLING_CHARGES_SETUP_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'late-fee-details/:id', component: LateFeeTemplateDetailsComponent,data:{capability:APP_CONST.BILLING_CHARGES_SETUP_WRITE}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'voucher-filter', component: VoucherFilterComponent, outlet: 'leftMainPopup', canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'vouchers-by-type/:type', component: VouchersByTypeComponent, outlet: 'rightMainPopup', canActivate: [AuthGuardService, CommunityGuardService] },
  
  { path: 'society-invoice', component: InvoiceDetailsComponent,data:{invoiceType:APP_CONST.INVOICE_TYPE_SOCIETY,capability:APP_CONST.SOCIETY_INVOICE_WRITE},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'society-invoice/:id', component: InvoiceDetailsComponent,data:{invoiceType:APP_CONST.INVOICE_TYPE_SOCIETY,capability:APP_CONST.SOCIETY_INVOICE_READ},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'purchase-invoice', component: InvoiceDetailsComponent,data:{invoiceType:APP_CONST.INVOICE_TYPE_PURCHASE,capability:APP_CONST.PURCHASE_INVOICE_WRITE},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'purchase-invoice/:id', component: InvoiceDetailsComponent,data:{invoiceType:APP_CONST.INVOICE_TYPE_PURCHASE,capability:APP_CONST.PURCHASE_INVOICE_READ},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'society-invoices', component: InvoiceComponent,data:{invoiceType:APP_CONST.INVOICE_TYPE_SOCIETY,capability:APP_CONST.SOCIETY_INVOICE_READ},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'purchase-invoices', component: InvoiceComponent,data:{invoiceType:APP_CONST.INVOICE_TYPE_PURCHASE,capability:APP_CONST.PURCHASE_INVOICE_READ},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'collection', component: PaymentDetailsComponent,data:{invoiceType:APP_CONST.INVOICE_TYPE_SOCIETY,capability:APP_CONST.COLLECTION_WRITE},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'payment', component: PaymentDetailsComponent,data:{invoiceType:APP_CONST.INVOICE_TYPE_PURCHASE,capability:APP_CONST.PAYMENT_WRITE},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'collections', component: PaymentsComponent,data:{invoiceType:APP_CONST.INVOICE_TYPE_SOCIETY,capability:APP_CONST.COLLECTION_READ},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'payments', component: PaymentsComponent,data:{invoiceType:APP_CONST.INVOICE_TYPE_PURCHASE,capability:APP_CONST.PAYMENT_READ},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'ledgersAccountable', component: LedgersComponent,outlet: 'rightMainPopup', canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'collection/:id', component: PaymentDetailsComponent,data:{invoiceType:APP_CONST.INVOICE_TYPE_SOCIETY,capability:APP_CONST.COLLECTION_READ},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'payment/:id', component: PaymentDetailsComponent,data:{invoiceType:APP_CONST.INVOICE_TYPE_PURCHASE,capability:APP_CONST.PAYMENT_READ},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'users', component: UsersComponent,outlet: 'rightMainPopup',data:{capability:APP_CONST.MEMBER_READ},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'staffs', component: StaffsComponent,outlet: 'rightMainPopup',data:{capability:APP_CONST.MEMBER_READ},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'staff/:id', component: StaffDetailsComponent,data:{capability:APP_CONST.MEMBER_WRITE}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'payment-filter', component: PaymentFilterComponent, outlet: 'leftMainPopup', canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'maintenance-visit-logs', component: MaintenanceVisitLogsComponent,data:{capability:APP_CONST.PROFESSIONALS_VISIT_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'maintenance-contracts', component: MaintenanceContractsComponent,data:{capability:APP_CONST.DOCUMENTS_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'maintenance-log/:id', component: MaintenanceLogComponent,outlet: 'leftMainPopup',data:{capability:APP_CONST.PROFESSIONALS_VISIT_WRITE}, canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'maintenance-contract/:id', component: MaintenanceContractComponent,data:{capability:APP_CONST.DOCUMENTS_WRITE}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'todos', component: TodosComponent,data:{capability:APP_CONST.TODO_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'related-vouchers', component: RelatedVouchersComponent, canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'todo-details/:id', component: TodoDetailsComponent,outlet: 'leftMainPopup',data:{capability:APP_CONST.TODO_WRITE}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'bulk-invoice', component: BulkInvoiceComponent,data:{capability:APP_CONST.COLLECTION_WRITE}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'all-todo', component: AllTodoComponent,data:{capability:APP_CONST.TODO_READ},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: "professional's-visits", component: AllMaintenanceVisitorLogComponent,data:{capability:APP_CONST.PROFESSIONALS_VISIT_READ},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'all-contract', component: AllMaintenanceContractsComponent,data:{capability:APP_CONST.DOCUMENTS_READ},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'log-filter', component: LogFilterComponent, outlet: 'leftMainPopup',data:{capability:APP_CONST.PROFESSIONALS_VISIT_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'contract-filter', component: ContractFilterComponent,data:{capability:APP_CONST.DOCUMENTS_READ}, outlet: 'leftMainPopup', canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'delete-item', component: DeleteItemComponent, outlet: 'centerPopup', canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'associate-ledger', component: AssociateLedgerComponent, canActivate: [AuthGuardService, CommunityGuardService] },
  //{ path: 'discussions/:type', component: DiscussionsComponent, canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'discussions', component: DiscussionsComponent,data:{type:APP_CONST.THREAD_TYPE_DISCUSSION,capability:APP_CONST.DISCUSSION_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'complaints', component: DiscussionsComponent,data:{type:APP_CONST.THREAD_TYPE_COMPLAINT,capability:APP_CONST.COMPLAINT_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'notices', component: DiscussionsComponent,data:{type:APP_CONST.THREAD_TYPE_NOTICE,capability:APP_CONST.NOTICE_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'rules', component: DiscussionsComponent,data:{type:APP_CONST.THREAD_TYPE_RULES,capability:APP_CONST.GUIDELINE_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'discussion-view', component: DiscussionViewComponent, canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'discussion-details/:id', component: DiscussionDetailsComponent,outlet: 'leftMainPopup', canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'vouchers', component: VouchersComponent, outlet: 'rightMainPopup', canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'discussion-filter', component: DiscussionFilterComponent, outlet: 'leftMainPopup', canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'complains', component: ComplainsComponent,data:{capability:APP_CONST.COMPLAINT_READ},outlet: 'rightMainPopup', canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'todo-list', component: TodoListComponent,outlet: 'rightMainPopup',data:{capability:APP_CONST.TODO_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'todo-comments/:id', component: TodoCommentsComponent,outlet: 'rightMainPopup',data:{capability:APP_CONST.TODO_COMMENT_WRITE}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'todo-filter', component: TodoFilterComponent, outlet: 'leftMainPopup',data:{capability:APP_CONST.TODO_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'todo-view', component: TodoViewComponent,data:{capability:APP_CONST.TODO_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'related-todo/:id', component: TodoViewComponent,outlet: 'rightMainPopup',data:{capability:APP_CONST.TODO_WRITE}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'profile', component: ProfileComponent,data:{capability:APP_CONST.PROFILE_READ},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'transfer', component: FundTransferComponent,data:{capability:APP_CONST.TRANSFER_WRITE}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'transfer/:id', component: FundTransferComponent,data:{capability:APP_CONST.TRANSFER_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'transfers', component: PaymentsComponent,data:{invoiceType:APP_CONST.INVOICE_TYPE_TRANSFERS,capability:APP_CONST.TRANSFER_READ},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'members', component: MembersComponent ,data:{capability:APP_CONST.MEMBER_READ},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'member-filter', component: MemberFilterComponent, outlet: 'leftMainPopup',data:{capability:APP_CONST.MEMBER_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'change-role/:id', component: ChangeRoleComponent,outlet: 'rightMainPopup',data:{capability:APP_CONST.MEMBER_WRITE}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'accountwise-dues-report', component: DuesReportComponent,outlet: 'leftMainPopup',data:{type:1,capability:APP_CONST.REPORT_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'propertywise-dues-report', component: DuesReportComponent,outlet: 'leftMainPopup',data:{type:2,capability:APP_CONST.REPORT_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'capabilities', component: CapabilitiesComponent,data:{capability:APP_CONST.CAPABILITY_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'community-settings', component: CommunitySettingsComponent,data:{capability:APP_CONST.COMMUNITY_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'income-statement-report', component: StatementsReportComponent,outlet: 'leftMainPopup',data:{type:1,capability:APP_CONST.REPORT_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'expense-statement-report', component: StatementsReportComponent,outlet: 'leftMainPopup',data:{type:2,capability:APP_CONST.REPORT_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'rentcharge/:id', component: RentTemplateComponent,data:{capability:APP_CONST.BILLING_CHARGES_SETUP_WRITE},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'blockwise-income-summary-report', component: BlockwiseIncomeReportComponent,outlet: 'leftMainPopup',data:{capability:APP_CONST.REPORT_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'block-income-details-report', component: BlockIncomeDetailsReportComponent,outlet: 'leftMainPopup',data:{capability:APP_CONST.REPORT_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'booking-calender', component: BookingCalenderComponent,data:{capability:APP_CONST.BILLING_CHARGES_SETUP_WRITE},canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'bookings', component: BookingsComponent,data:{type:APP_CONST.THREAD_TYPE_DISCUSSION,capability:APP_CONST.DISCUSSION_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
  { path: 'booking-view', component: BookingViewComponent, canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'booking-details/:id', component: BookingDetailsComponent, canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'booking-filter', component: BookingFilterComponent, outlet: 'leftMainPopup', canActivate: [AuthGuardService, CommunityGuardService] },
  { path: 'booking-avalibility', component: BookingAvalibilityComponent, outlet: 'leftMainPopup',canActivate: [AuthGuardService, CommunityGuardService]},
  { path: 'inter-transfer-report', component: InterTransferReportComponent, outlet: 'leftMainPopup',data:{type:1,capability:APP_CONST.REPORT_READ},canActivate: [AuthGuardService, CommunityGuardService]},
  { path: 'propertywise-woner-report', component: PropertyWonerReportComponent,outlet: 'leftMainPopup',data:{type:2,capability:APP_CONST.REPORT_READ}, canActivate: [AuthGuardService, CommunityGuardService, CapabilityGuardService] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
