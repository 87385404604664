import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DashboardService } from './../api-client/dashboard.service';
import { CommonMethods } from '../common-methods';
import { UiService } from '../ui.service';
import { APP_CONST } from '../app-constants';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  dashboardDetails: any;

  piedata = [
    ['LAS', 45.0],
    ['Security', 26.8],
    ['Rent', 12.8]
  ];

  collectionSummaryData = {
    title: "",
    type: "PieChart",
    data: [],
    columnNames: ['Item', 'Percentage'],
    options: {
      theme: 'maximized',
      colors: ['#7cb342', '#689f38', '#558b2f', '#33691e'],
      pieHole: 0.3,
      backgroundColor: { fill: 'transparent' },
      legend: { position: 'none' }
    }
  };

  expenseSummaryData = {
    title: "",
    type: "PieChart",
    data: [],
    columnNames: ['Item', 'Percentage'],
    options: {
      theme: 'maximized',
      colors: ['#f4511e', '#e64a19', '#d84315', '#bf360c'],
      pieHole: 0.3,
      backgroundColor: { fill: 'transparent' },
      legend: { position: 'none' }
    },
  };

  args: any;
  customColorScheme = [
    '#DFD6A7', '#D4AFCD', '#F7CE5B', '#AC9969', '#A1D2CE',
    '#78CAD2', '#62A8AC', '#5497A7', '#50858B', '#2E6171'
  ];

  amountStatisticsData = {
    title: 'Collection vs Expense Last Three Months',
    type: 'AreaChart',
    data: [/* 
      ['Dec-2019', 30000, 20000],
      ['Jan-2020', 50000, 60000],
      ['Feb-2020', 30000, 20000],
      ['Mar-2020', 40000, 70000] */
    ],
    columnNames: ['Year', 'Collection', 'Expense'],
    options: {
      theme: 'maximized',
      colors: ['#7cb342', '#f4511e'],
      backgroundColor: { fill: 'transparent' },
      legend: { position: 'top' },
      is3D: true,
      pointsVisible:true,

    }
  };
  collectionMode:number = 2;
  expenseMode:number = 2;
  collectionAmountText: string = "This Month's Collections";
  collectionAmountVal: number = 0;
  expenseAmountText: string = "This Month's Expenses";
  expenseAmountVal: number = 0;

  constructor(
    private _dashboardService: DashboardService,
    private _router: Router,
    private _uiService: UiService) {
  }

  ngOnInit() {
    if (!CommonMethods.getCommunityId()) return;
    this.dashboardDetails = {};
    this.amountStatistics();
    this.numberFormatter(this.args);
    this.details();
    this.collectionSummary();
    this.expenseSummary();
    //this.collectionSummaryData.data = this.piedata;
    //this.expenseSummaryData.data = this.piedata;
  }

  numberFormatter(x: any) {
    if (isNaN(x)) return x;

    if (x < 9999) {
      return x;
    }

    if (x < 1000000) {
      return Math.round(x / 1000) + "K";
    }
    if (x < 10000000) {
      return (x / 1000000).toFixed(2) + "M";
    }

    if (x < 1000000000) {
      return Math.round((x / 1000000)) + "M";
    }

    if (x < 1000000000000) {
      return Math.round((x / 1000000000)) + "B";
    }

    return "1T+";
  }

  details() {
    var communityId = Number(CommonMethods.getCommunityId());
    this._dashboardService.details(communityId).subscribe((result: any) => {
      this.dashboardDetails = this.numberFormatter(result);
      /* this._uiService.topMenuItems.next([
        { name:'To-Do', icon:'fa fa-tasks', number:this.dashboardDetails.toDoCount,action:"all-todo"},
        { name:'Discussions', icon:'fa fa-comments', number:this.dashboardDetails.discussionsCount,action:`discussions/${APP_CONST.THREAD_TYPE_DISCUSSION}`},
        { name:'Services Visit Log', icon:'fa fa-th', number:this.dashboardDetails.maintenanceLogCount,action:"professional's-visits"},
        { name:'Documents & Agreements', icon:'fa fa-briefcase', number:this.dashboardDetails.contractCount,action:"all-contract"},       
        { name:'Notices', icon:'fa fa-bullhorn',number:this.dashboardDetails.noticesCount,action:`discussions/${APP_CONST.THREAD_TYPE_NOTICE}`},
        { name:'Guidelines', icon:'fas fa-book-reader', number:this.dashboardDetails.guidelinesCount,action:`discussions/${APP_CONST.THREAD_TYPE_RULES}`},
      ]); */
      return;
    })
  }

  async amountStatistics() {
    var communityId = Number(CommonMethods.getCommunityId());
    await this._dashboardService.amountStatistics(communityId).toPromise().then((result: any) => {
      //this.amountStatisticsData = result;
      //console.log('111111',result);
      var collectionExpanse = [];
      for (var i = 0; i < result.length; i++) {
        var date = new Date(result[i].year + "-" + result[i].month);
        var dateToString = CommonMethods.monthNames[date.getMonth()] + " " + result[i].year;
        collectionExpanse.push([dateToString, result[i].collections, result[i].expenses]);
      }
      this.amountStatisticsData.data = collectionExpanse;
      //console.log(this.amountStatisticsData);
    })
  }

  async collectionSummary() {
    var communityId = Number(CommonMethods.getCommunityId());
    var data = {"communityId": communityId, "mode":this.collectionMode};
    this.collectionAmountVal = 0;
    await this._dashboardService.collectionSummary(data).toPromise().then((result: any) => {
      //console.log("collectionSummary",result);
      var collectionData = [];
      //var res = this.piedata;
      for (var i = 0; i < result.length; i++) {
        collectionData.push([result[i].groupName, result[i].amount]);
        this.collectionAmountVal += parseFloat(result[i].amount);
      }
      this.collectionSummaryData.data = collectionData;

    });
  }

  async expenseSummary() {
    var communityId = Number(CommonMethods.getCommunityId());
    var data = {"communityId": communityId, "mode":this.expenseMode};
    this.expenseAmountVal = 0;
    await this._dashboardService.expenseSummary(data).toPromise().then((result: any) => {
      //console.log("expenseSummary",result);
      var expenseData = [];
      //var res = this.piedata;
      for (var i = 0; i < result.length; i++) {
        expenseData.push([result[i].groupName, result[i].amount]);
        this.expenseAmountVal += parseFloat(result[i].amount);
      }
      this.expenseSummaryData.data = expenseData;
    })
  }

  collectionModeChanged(){
    this.collectionMode = this.collectionMode == 1 ? 2:1;
    if(this.collectionMode == 1){
      this.collectionAmountText = "Today's Collections";
    }else{
      this.collectionAmountText = "This Month's Collections";
    }
    this.collectionSummary();
  }

  expenseModeChanged(){
    this.expenseMode = this.expenseMode == 1 ? 2:1;
    if(this.expenseMode == 1){
      this.expenseAmountText = "Today's Expenses";
    }else{
      this.expenseAmountText = "This Month's Expenses";
    }
    this.expenseSummary();
  }

  redirectToComplain(){
    this._router.navigateByUrl(`discussions/${APP_CONST.THREAD_TYPE_COMPLAINT}`);
    return;
  }

  redirectTo(arg:any){
    this._router.navigateByUrl(arg);
    return;
  }
}
