import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject,Observable, of  } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../../app/config';
import { BlockModel } from './../Model/block.model';

@Injectable({
  providedIn: 'root'
})
export class BlockService {  

  constructor(private _http: HttpClient) {
  }

  all(data:any){
    return this._http
      .post(config.apiEndPointBase + "/community_block/list", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  save(data:BlockModel): Observable<BlockModel> {
    if(data.id){
      return this._http.put(config.apiEndPointBase + "/community_block",data, {responseType: 'text'})
      .pipe(map((response:any) => {
        return new BlockModel().deserialize(response);
      }));
    }else{
      return this._http.post(config.apiEndPointBase + "/community_block",data, {})
      .pipe(map((response:any) => {
        return new BlockModel().deserialize(response);
      }));
    }
  }

  delete(id:number){
    return this._http.delete(config.apiEndPointBase + `/community_block/${id}`,{responseType: 'text'});
  }

  details(id:number){
    return this._http.get(config.apiEndPointBase + `/community_block/${id}`);
  }
}
