import { Component, OnInit } from '@angular/core';
import { PropertyService } from '../api-client/property.service';
import { ResourceService } from '../api-client/resource.service';
import { BuildingService } from '../api-client/building.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../api-client/user.service';
import { VoucherTemplateService } from '../api-client/voucher-template.service';
import { UiService } from '../ui.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { CommonMethods } from '../common-methods';

@Component({
  selector: 'app-units-resources',
  templateUrl: './units-resources.component.html',
  styleUrls: ['./units-resources.component.css']
})
export class UnitsResourcesComponent implements OnInit {

  units: any;
  params: any;
  filteredUnits: any;
  routeExtras: NavigationExtras;
  multiSelect: false;
  routeParams: any;

  constructor(private _buildingkService: BuildingService,
    private _tosterService: ToastrService,
    private _propertyService: PropertyService,
    private _resourceService: ResourceService,
    private _userService: UserService,
    private _voucherTemplateService: VoucherTemplateService,
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _router: Router) {
    this.routeExtras = this._router.getCurrentNavigation().extras;
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this.routeParams = params;
      this.params = {
        "communityId": CommonMethods.getItem("communityId"),
        "blockId": params.blockId,
        "buildingId": params.buildingId, "propertyType": params.type
      };

      if (params.multiSelect) {
        this.multiSelect = params.multiSelect;
      }

      if (params.type && (params.buildingId == 0 || params.buildingId == null || params.buildingId == "") && params.blockId) {
        this.blockWiseProperties(params.blockId, 0, params.type);
      } else if (params.type && (params.blockId == 0 || params.blockId == null || params.blockId == "") && params.buildingId) {
        this.blockWiseProperties(0, params.buildingId, params.type);
      } else if (params.type) {
        this.unitByType(this.params);
      } else {
        this.allUnits(this.params);
      }
    })
  }

  blockWiseProperties(blockId: number, buildingId: number, type: number) {
    this._propertyService.getDetailsById(blockId, buildingId, type).subscribe((result: any) => {
      this.units = result;
      this.filteredUnits = this.units;
    })
  }

  unitByType(data: any) {
    this._propertyService.all(data).subscribe((result: any) => {
      this.units = result;
      this.filteredUnits = this.units;
    })
  }

  allUnits(data: any) {
    this._propertyService.list(data).subscribe((result: any) => {
      this.units = result;
      this.filteredUnits = this.units;
    })
  }

  filterUnit(eve: any) {
    var code = eve.keyCode;
    if (code == 37 || code == 38 || code == 39 || code == 40) {
      return;
    }
    var ele = eve.target;
    var items: any;
    if (ele.value) {
      this.filteredUnits = this.units.filter((x: any) => x.unitNo.toLowerCase().includes(ele.value.toLowerCase()));

    } else {
      //this.unitList(this.params);
    }
  }

  async details(item: any) {
    if (this._uiService.activeListCallBack) {
      this._uiService.activeListCallBack(item.id, item.unitNo);
      this._uiService.activeListCallBack = null;
      this.closeItself();
    } else {
      if (this.routeParams.mode && this.routeParams.mode == 'RentInvoiceCreation') {
        this._uiService.activeListCallBack = null;
        this.closeItself();
        this._voucherTemplateService.rentTemplate(item.id).subscribe((res: any) => {
          if (res != null && res.id > 0) {
            this._router.navigate(["society-invoice"], { state: { "templateId": res.id, "propertyId": item.id } });
          } else {
            this._tosterService.error("No rent defined.");
          }
        });
      } else if (this.routeParams.page && this.routeParams.page == 'booking') {

        if (this.routeExtras.state) {
          var startDate = this.routeExtras.state.startDate;
          var endDate = this.routeExtras.state.endDate;
          var params = { startDate: startDate, endtDate: endDate, propertyId: item.id, communityId: CommonMethods.getCommunityId() };
          await this._voucherTemplateService.items(params).toPromise().then((result: any) => {
            if (result.length > 0) {
              result[0]['propertyId'] = item.id;
              result[0]['unitNumber'] = item.unitNo;
              if (this.routeExtras.state.selectedBookingItems.length > 0) {
                var data = this.routeExtras.state.selectedBookingItems.filter((x: any) => x.propertyId == item.id);
                if (data.length == 0) {
                  this.routeExtras.state.selectedBookingItems.push(result[0]);
                }
              } else {
                this.routeExtras.state.selectedBookingItems.push(result[0]);
              }
            } else {
              this._tosterService.error("No line items found");
            }
            console.log(result);
          });

        }


        /*var item = this.routeExtras.state.selectedBookingItems.filter((x: any) => x.id.includes(item.id));
        //setTimeout(()=>{
        console.log(item);
        if(item.length==0){
          this.routeExtras.state.selectedBookingItems.push(item);
        }
        //},100)*/


        //this._router.navigate(["booking-details", { "id": this.routeParams.bookingId, "propertyId": item.id }]);
        //this.closeItself();
        //this._router.navigate(["booking-details",1], { state: { "propertyId": item.id} });
        //this._router.navigate(["booking",this.routeParams.bookingId], { queryParams: { } });
        setTimeout(() => {
          this.closeItself();
        }, 1000);

      }
      else {
        var propertyTypeSlug = CommonMethods.propertyTypes.filter((x: any) => x.id == item.propertyType);
        if (propertyTypeSlug.length > 0) {
          this._router.navigateByUrl(`${propertyTypeSlug[0].name}/${item.id}`);
        }
      }
    }
    return false;
  }

  closeItself() {
    this._router.navigate(
      [
        "../",
        {
          outlets: {
            rightMainPopup: null
          }
        }
      ],
      {
        relativeTo: this._route
      }
    );
  }

  redirectTo(item: any) {
    var propertyTypeSlug = CommonMethods.propertyTypes.filter((x: any) => x.id == this.params.propertyType);
    this._router.navigate([`${propertyTypeSlug[0].name}`, 0], { state: { "blockId": this.params.blockId, "buildingId": this.params.buildingId } });
    setTimeout(() => {
      this._uiService.closeItself();
    }, 100);
    /*if (propertyTypeSlug.length > 0) {
     this._router.navigateByUrl(`${propertyTypeSlug[0].name}/0`);
   } */
    return;
  }

  findMatchingItemInSelectedItems(id: any) {
    if (this.routeExtras && this.routeExtras.state && this.routeExtras.state.selectedItems) {
      let items = this.routeExtras.state.selectedItems.filter((x: any) => x.id == id);
      if (items.length > 0) {
        return items[0];
      }
    }
  }

  itemSelectChange(eve: any, unit: any) {
    if (this.routeExtras && this.routeExtras.state && this.routeExtras.state.selectedItems) {
      if (eve.target.checked) {
        this.routeExtras.state.selectedItems.push({ id: unit.id, name: unit.unitNo });
      } else {
        let item = this.findMatchingItemInSelectedItems(unit.id);
        if (item) {
          let index = this.routeExtras.state.selectedItems.indexOf(item);
          this.routeExtras.state.selectedItems.splice(index, 1);
        }
      }
    }
  }

}
