import { Component, OnInit } from '@angular/core';
import { CommonMethods } from '../common-methods';
import { MaintenanceContractModel } from '../Model/maintenance-contract.model';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { UserService } from '../api-client/user.service';
import { UiService } from '../ui.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { MaintenanceContractFormBuilder } from './maintenancecontract.form-builder';
import { LedgerService } from '../api-client/ledger.service';
import { MaintenanceContractService } from '../api-client/maintenancecontract.service';
import { APP_CONST } from '../app-constants';
import { CategoryService } from '../api-client/category.service';
import { Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-maintenance-contract',
  templateUrl: './maintenance-contract.component.html',
  styleUrls: ['./maintenance-contract.component.css']
})
export class MaintenanceContractComponent implements OnInit {

  submitted = false;
  model = new MaintenanceContractModel();
  contractId = null;
  maintenanceContractForm: any;
  vendorLedgers: any;
  vendorName = "--select--";
  linkItemTypeId: any;
  linkItemIds: any;
  selectedRelatedItems: any = [];
  excludeLinkitemTypes = [APP_CONST.PROPERTY_TYPE_PARKING_SPACES, APP_CONST.INVOICE_TYPE_SOCIETY, APP_CONST.INVOICE_TYPE_PURCHASE, APP_CONST.THREAD_TYPE_COMPLAINT, APP_CONST.TODO];
  linkItemTypes = CommonMethods.excludeLinkItemTypes(CommonMethods.linkItemTypes, this.excludeLinkitemTypes);
  showRasonBox: boolean = false;
  tags: any;
  attachment: any;
  allMediaInfo = [];
  startDate: string;
  endDate: string;
  mode: string;
  public config = <IDayCalendarConfig>{
    locale: "al",
    format: "YYYY-MM-DD HH:mm:ss"
  };

  deleteContractParams: any = {
    itemId: 0,
    itemName: null,
    deleteText: "Are you sure, you want to delete this contrect?"
  };
  deleteMediaParams: any = {
    itemId: 0,
    itemName: null,
    deleteText: "Are you sure, you want to delete this contract media?"
  }

  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  error = [];
  constructor(
    private _userService: UserService,
    private _uiService: UiService,
    private _tosterService: ToastrService,
    private _router: Router,
    private _formBuilder: MaintenanceContractFormBuilder,
    private _ledgerService: LedgerService,
    private _maintenanceService: MaintenanceContractService,
    private _categoryService: CategoryService,
    private _route: ActivatedRoute) { }

  ngOnInit() {
    this._uiService.leftMenuItems.next([
      { code: 'F4', name: 'Save', action: this.save.bind(this), class: 'green lighten-1', privilege: APP_CONST.DOCUMENTS_WRITE },
      { code: 'F5', name: 'Save & Add New', action: this.saveAndNew.bind(this), privilege: APP_CONST.DOCUMENTS_WRITE },
      //{ code: 'F7', name: 'Delete', action: this.deletePopUp.bind(this), class: 'red lighten-1' }
    ]);

    /* if(this._uiService.linkItem.type){
      this.selectedRelatedItems = [];
      this.linkItemTypeId = this._uiService.linkItem.type;
      if(this._uiService.linkItem.id>0){
        this.selectedRelatedItems.push({id:this._uiService.linkItem.id,name:this._uiService.linkItem.name});
      }      
    } */
    this.maintenanceContractForm = this._formBuilder.getForm();
    this._route.params.subscribe(params => {
      if (params.id > 0) {
        this.details(params.id);
      } else {
        this.resetForm();
      }
    });
    this.onChanges();
    this.categories();
  }

  get f() { return this.maintenanceContractForm.controls; }

  details(contractId: any) {
    this._maintenanceService.details(contractId).subscribe((result: any) => {
      this.contractId = result.id;
      for (var i = 0; i < result.contractMediaInfo.length; i++) {
        result.contractMediaInfo[i].mediaName = result.contractMediaInfo[i].mediaUrl.split('/').pop();
      }
      this.allMediaInfo = result.contractMediaInfo;
      if (result.vendorId) {
        this._ledgerService.details(result.vendorId).toPromise().then((result: any) => {
          this.vendorName = result.ledgerName;
        })
      }
      this.linkItemTypeId = result.linkItem[0].linkItemTypeId;
      var selectedItems = [];
      if (result.linkItem.length > 0) {
        for (var i = 0; i < result.linkItem.length; i++) {
          selectedItems.push({ id: result.linkItem[i].linkItemId, name: result.linkItem[i].linkItemName })
        }
      }
      this.selectedRelatedItems = selectedItems;
      if (result.status == 2) {
        this.showRasonBox = true;
      }
      if (result.category) {
        var tags = [];
        var category = result.category.split(",");
        for (var i = 0; i < category.length; i++) {
          tags.push({ id: category[i], name: category[i] });
        }
        result.category = tags;
      }
      this._formBuilder.setValues(result);
    })
  }

  async onSubmit() {
    this.error = [];
    this.submitted = true;
    if (this.maintenanceContractForm.invalid) {
      return;
    }
    //console.log(this.selectedRelatedItems);return;
    if (this.maintenanceContractForm.get('startDate').value != "" && this.maintenanceContractForm.get('expiryDate').value != "") {
      var startDate = new Date(this.maintenanceContractForm.get('startDate').value);
      var expDate = new Date(this.maintenanceContractForm.get('expiryDate').value);
      if (startDate.getTime() > expDate.getTime()) {
        this._tosterService.error("Start date not greater than expire date.");
        this.error.push("Start date not greater than expire date");
        return;
      }
    }
    this.model.communityId = Number(CommonMethods.getCommunityId());
    this.model.id = this.contractId;
    this.model.deserialize(this.maintenanceContractForm.value);
    if (this.model.status == 2 && (this.model.cancelReason == "" || this.model.cancelReason == null)) {
      this._tosterService.error("Cancel reason can not be blank");
      this.error.push("Cancel reason can not be blank");
      return;
    }
    if (this.linkItemTypeId == null || this.linkItemTypeId == "") {
      this._tosterService.error("Link item type can not be blank");
      this.error.push("Link item type can not be blank");
      return;
    }
    if (this.selectedRelatedItems.length == 0) {
      this._tosterService.error("Items required.");
      this.error.push("Items required");
      return;
    }
    if (this.maintenanceContractForm.invalid) {
      return;
    }
    this.model.communityId = Number(CommonMethods.getCommunityId());
    var items = [];
    for (var i = 0; i < this.selectedRelatedItems.length; i++) {
      items.push({ linkItemTypeId: this.linkItemTypeId, linkItemName: this.selectedRelatedItems[i].name, linkItemId: this.selectedRelatedItems[i].id });
    }
    this.model.linkItem = items;

    if (this.maintenanceContractForm.get('category').value) {
      var result = this.maintenanceContractForm.get('category').value;
      var tags = [];
      for (var i = 0; i < result.length; i++) {
        tags.push(result[i].name);
      }
      this.model.category = tags.join(",");
    }
    await this._maintenanceService.save(this.model)
      .toPromise().then(
        (response: any) => {
          if (response) {
            this._tosterService.success("Documents & Agreements save");
            if (!this.model.id) {
              this.contractId = response.id;
              //this._router.navigateByUrl(`maintenance-contract/${response.id}`);
            }
          } else {
            this.error.push("server error");
          }
        },
        error => {
          this._tosterService.error(error.message);
        }
      )
  }

  async save() {
    await this.onSubmit();
    if (this.error.length > 0) return;
    setTimeout(() => {
      if (this.contractId) {
        this._router.navigateByUrl(`maintenance-contract/${this.contractId}`);
      }
    }, 1000);
  }

  async saveAndNew() {
    await this.onSubmit();
    if (this.error.length > 0) return;
    if (!this.maintenanceContractForm.invalid) {
      this.resetForm();
    }
  }

  changeStatus(element: any) {
    this.showRasonBox = false;
    if (element.value == 2) {
      this.showRasonBox = true;
    }
  }

  categories() {
    var data = { communityId: CommonMethods.getCommunityId() };
    this._categoryService.all(data).subscribe((result: any) => {
      for (var i = 0; i < result.length; i++) {
        result[i].id = result[i].name;
      }
      this.tags = result;
    });
  }

  deletePopUp(item: any) {
    this.deleteContractParams.itemId = item.id;
    this.deleteContractParams.itemName = item.remarks;
    this._uiService.deleteItemData = this.deleteContractParams;
    this._uiService.deleteItemCallBack = this.delete.bind(this);
    this._router.navigate([{
      outlets: { centerPopup: ['delete-item'] }
    }]);
  }

  delete() {
    if (this.deleteContractParams.pressBtn == "Ok") {
      this._maintenanceService.delete(this.deleteContractParams.itemId).subscribe((result: any) => {
        this._tosterService.success("Documents & Agreements Deleted");
        this.resetForm();
        return;
      });
    }
  }

  deleteMediaPopUp(item: any) {
    if (!item.id) return;
    this.deleteMediaParams.itemId = item.id;
    this.deleteMediaParams.itemName = item.mediaOriginalName;
    this._uiService.deleteItemData = this.deleteMediaParams;
    this._uiService.deleteItemCallBack = this.deleteMedia.bind(this);
    this._router.navigate([{
      outlets: { centerPopup: ['delete-item'] }
    }]);
  }

  deleteMedia() {
    //console.log(this.deleteMediaParams.itemId);return;
    if (this.deleteMediaParams.pressBtn == "Ok") {
      this._maintenanceService.deleteMedia(this.deleteMediaParams.itemId).subscribe((result: any) => {
        this._tosterService.success("Media Deleted");
        this.details(this.contractId);
        return;
      });
    }
  }

  resetForm() {
    this.error = [];
    this.submitted = false;
    this.contractId = null;
    this.allMediaInfo = [];
    this.categories();
    this.selectedRelatedItems = [];
    this.linkItemTypeId = null;
    this.vendorName = "";
    this.maintenanceContractForm.reset();
    setTimeout(() => {
      this._router.navigateByUrl("maintenance-contract/0");
    }, 100)
  }

  openRelatedItemsSelectionPopup() {
    var listNavigation;
    var listnav = CommonMethods.linkItemTypes.filter((x: any) => x.id == this.linkItemTypeId);
    if (listnav.length > 0) {
      listNavigation = listnav[0].action;
      var params = [{ "outlets": { "rightMainPopup": listNavigation } }];
      this._router.navigate(params, { state: { "selectedItems": this.selectedRelatedItems } });
    }

  }
  deleteItem(item: any) {
    this.selectedRelatedItems.splice(this.selectedRelatedItems.indexOf(item), 1);
  }

  redirectionType(mode: any, displayType: any) {
    let params: any;
    if (displayType == 'listing') {
      if (mode == "contracts") {
        this._router.navigateByUrl("/all-contract"); return;
      } else if (mode == "dashboard") {
        this._router.navigateByUrl("/dashboard"); return;
      }
      this._router.navigate(params); return;
    }
  }

  resetItems() {
    this.selectedRelatedItems = [];
  }

  onFileChange(event: any) {
    if (!this.contractId) return;
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      //console.log(file.name.split('.').pop());
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.attachment = { fileName: file.name, fileType: file.name.split('.').pop().toLowerCase(), base64: event.target.result.split(',').pop() };
          this.addMedia();
        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  addMedia() {
    if (!this.contractId) return;
    var data = { communityId: CommonMethods.getCommunityId(), contractId: this.contractId, contractMedia: this.attachment.base64, type: this.attachment.fileType, mediaName: this.attachment.fileName };
    //console.log(data);return;
    this._maintenanceService.saveMedia(data).subscribe((result: any) => {
      this._tosterService.success("Attachment Added");
      this.attachment = {};
      this.details(this.contractId);
      return;
    })
  }


  onChanges(): void {
    this.maintenanceContractForm.get('startDate').valueChanges.subscribe((val: any) => {
      this.startDate = val;
      this.calculateEndDate();
    });
    this.maintenanceContractForm.get('mode').valueChanges.subscribe((val: any) => {
      this.mode = val;
      if (val == "lifetime") {
        this.maintenanceContractForm.get('expiryDate').clearValidators();
        this.maintenanceContractForm.get('expiryDate').updateValueAndValidity();
      } else {
        this.maintenanceContractForm.get('expiryDate').setValidators([Validators.required]);
        this.maintenanceContractForm.get('expiryDate').updateValueAndValidity();
      }
      this.calculateEndDate();
    });
  }

  calculateEndDate() {
    if (!this.mode) return;
    if (!this.startDate) return;
    var date = CommonMethods.contractMode.filter((x: any) => x.name == this.mode);
    if (date.length > 0) {
      var endDate = new Date(this.startDate);
      if (date[0].days > 0) {
        endDate.setDate(endDate.getDate() + date[0].days);
        var day = (endDate.getDate() < 10) ? "0" + endDate.getDate() : endDate.getDate();
        var month = ((endDate.getMonth() + 1) < 10) ? "0" + (endDate.getMonth() + 1) : (endDate.getMonth() + 1);
        var year = endDate.getFullYear();
        var hour = (endDate.getHours() < 10) ? "0" + endDate.getHours() : endDate.getHours();
        var minute = (endDate.getMinutes() < 10) ? "0" + endDate.getMinutes() : endDate.getMinutes();
        var second = (endDate.getSeconds() < 10) ? "0" + endDate.getSeconds() : endDate.getSeconds();
        var newDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        this.maintenanceContractForm.get('expiryDate').setValue(newDate);
      } else {
        this.maintenanceContractForm.get('expiryDate').setValue(null);
      }
    }
  }

}
