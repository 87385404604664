import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../api-client/user.service';
import { UiService } from '../ui.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationService } from '../api-client/location.service';

@Component({
  selector: 'app-states',
  templateUrl: './states.component.html',
  styleUrls: ['./states.component.css']
})
export class StatesComponent implements OnInit {

  states: any;
  routeParams: any;
  filteredStates: any;
  constructor(
    private _tosterService: ToastrService,
    private _userService: UserService,
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _locationService: LocationService,
    private _router: Router) { }

  ngOnInit() {
    this._route.params.subscribe(data => {
      this.routeParams = data.countryId;
      this.list(data.countryId);
    })
    
  }

  list(countryId:any) {
    //console.log(this._uiService.activeListCallBack);return;
    this._locationService.statesByCountry(countryId).subscribe((result: any) => {
      this.states = result;
      this.filteredStates = this.states;
    })
  }

  filterState(eve: any) {
    var code = eve.keyCode;
    if(code == 37 || code == 38 || code == 39 || code == 40) {
        return;
    }
    var ele = eve.target;
    var items: any;
    if (ele.value) {
      this.filteredStates = this.states.filter((x: any) => x.stateName.toLowerCase().includes(ele.value.toLowerCase()));
    } else {
      // this.list(this.routeParams);
    }
  }

  details(item: any) {
    if (this._uiService.activeListCallBack) {
      this._uiService.activeListCallBack(item.stateId, item.stateName);
      this._uiService.activeListCallBack = null;
      this._uiService.closeItself();
    }
  }

}
