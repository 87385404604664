import { Component, OnInit } from '@angular/core';
import { CommonMethods } from '../common-methods';
import { ThreadModel } from '../Model/thread.model';
import { APP_CONST } from '../app-constants';
import { ThreadService } from '../api-client/thread.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { DiscussionFormBuilder } from './discussion.form-builder';
import { UiService } from '../ui.service';
import { UserService } from '../api-client/user.service';
import { ThrowStmt } from '@angular/compiler';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { CategoryService } from '../api-client/category.service';

@Component({
  selector: 'app-discussion-details',
  templateUrl: './discussion-details.component.html',
  styleUrls: ['./discussion-details.component.css']
})
export class DiscussionDetailsComponent implements OnInit {

  formHeading = "";
  discussionForm: any;
  submitted = false;
  communityId: any;
  discussionId = null;
  threadType: any;
  routeParams: any;
  model = new ThreadModel();
  routeExtras: NavigationExtras;
  selectedMembers: any = [];
  showUsers: boolean = false;
  excludeLinkitemTypes = [APP_CONST.THREAD_TYPE_COMPLAINT,APP_CONST.PROPERTY_TYPE_PARKING_SPACES,APP_CONST.TODO];
  linkItemTypes = CommonMethods.excludeLinkItemTypes(CommonMethods.linkItemTypes,this.excludeLinkitemTypes);
  linkItemTypeId: number;
  listNavigation: any;
  propertyType: any;
  itemName = "--select--";
  repoterName = "--select--";
  members: any;
  tags: any;
  public config = <IDayCalendarConfig>{
    format: "YYYY-MM-DD"
  };
  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  APP_CONST= APP_CONST;
  writePrivileges = null;
  constructor(
    private _threadService: ThreadService,
    private _tosterService: ToastrService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _formBuilder: DiscussionFormBuilder,
    private _uiService: UiService,
    private _userService: UserService,
    private _categoryService: CategoryService
  ) { this.routeExtras = this._router.getCurrentNavigation().extras; }

  ngOnInit() {
    this.discussionForm = this._formBuilder.getForm();
    this.changeThreadTypeThroughState();
    this.changeStatus('Open');
    this.onChanges();
    this.categories();
    this._route.params.subscribe(params => {
      if (params.id > 0) {
        this.details(params.id);
      } else {
        this.resetForm();
      }
    })
  }

  get f() { return this.discussionForm.controls; }

  async details(id: number) {
    await this._threadService.details(id).toPromise().then((result: any) => {
      this.members = result.members;
      this.communityId = result.communityId;
      this.discussionId = result.id;
      this.threadType = result.threadType;
      if(result.category){
        var tags = [];
        var category = result.category.split(",");
        for(var i=0;i<category.length;i++){
          tags.push({id:category[i],name:category[i]});
        }
        result.category = tags;
      }
      this._formBuilder.setValues(result);
      this.selectMembers();
      this.itemName = result.linkItemName;
      if (result.reporterId) {
        this._userService.details(result.reporterId).subscribe((result: any) => {
          this.repoterName = result.name;
        })
      }
      
      if (result.linkItemTypeId) {
        this.changeType(result.linkItemTypeId);
      }
      this.selectFormHeading();
      this.changeMode(result.threadMode);
      this.changeStatus(result.threadStatus);
    })
  }

  onSubmit() {
    this.submitted = true;
    if (this.discussionForm.invalid) {
      return;
    }
    this.model.deserialize(this.discussionForm.value);
    this.model.communityId = CommonMethods.getCommunityId();
    this.model.id = this.discussionId;
    if (document.querySelector<HTMLInputElement>("div.linkItemId input[type='text']")) {
      this.model.linkItemName = document.querySelector<HTMLInputElement>("div.linkItemId input[type='text']").value;
    }
    this.model.threadType = this.threadType;
    var members = [];
    for (var i = 0; i < this.selectedMembers.length; i++) {
      members.push(this.selectedMembers[i].id);
    }
    this.model.members = members;
    if(this.discussionForm.get('category').value){
      var result = this.discussionForm.get('category').value;
      var tags = [];
      for(var i=0;i<result.length;i++){
        tags.push(result[i].name);
      }
      this.model.category = tags.join(",");
    }
    this._threadService.save(this.model)
      .subscribe(
        (response: ThreadModel) => {
          this._uiService.threadDataReload.next(true);
          this._tosterService.success("Thread save");
          this._router.navigateByUrl(this.redirectThread(this.threadType));
          return false;
        },
        error => {
          this._tosterService.error(error.message);
        }
      )
  }

  onChanges(): void {
    this.discussionForm.get('threadMode').valueChanges.subscribe((val: any) => {
      this.changeMode(val);
    });
    this.discussionForm.get('threadStatus').valueChanges.subscribe((val: any) => {
      this.changeStatus(val);
    });
    this.discussionForm.get('linkItemTypeId').valueChanges.subscribe((val: any) => {
      if (val) {
        this.itemName = "--Select--";
        this.discussionForm.controls['linkItemId'].setValue(null);
        document.querySelector<HTMLInputElement>("div.linkItemId input[type='text']").value = "";
      }
    });
  }

  changeMode(value: any) {
    if (value == 'Private') {
      this.showUsers = true;
    } else {
      this.showUsers = false;
    }
  }

  changeStatus(value: any) {
    if (value == 'Closed') {
      this.discussionForm.controls['closingDate'].enable();
    } else {
      this.discussionForm.controls['closingDate'].disable();
    }
  }

  changeThreadTypeThroughState() {
    if (this.routeExtras && this.routeExtras.state && this.routeExtras.state.threadType) {
      this.threadType = this.routeExtras.state.threadType;
      this.selectFormHeading();
      console.log(this.routeExtras.state.threadType);
    }
  }

  openRelatedItemsSelectionPopup() {
    this._router.navigate([{
      outlets: { rightMainPopup: ["users", { "mode": "UserSelections", "multiSelect": true }] }
    }], { state: { "selectedItems": this.selectedMembers } });
    return;
  }

  deleteItem(item: any) {
    this.selectedMembers.splice(this.selectedMembers.indexOf(item), 1);
  }

  changeType(value: any) {
    this.linkItemTypeId = 0;
    if (value > 0) {
      this.linkItemTypeId = value;
      var listNavigation = CommonMethods.linkItemTypes.filter((x: any) => x.id == this.linkItemTypeId);
      if (listNavigation.length > 0) {
        var type = null;
        if (Object(listNavigation[0].action[1]).type) {
          type = Object(listNavigation[0].action[1]).type;
        }
        this.listNavigation = [listNavigation[0].action[0], { mode: Object(listNavigation[0].action[1]).mode, type: type }];
      }
    }
  }

  async selectMembers() {
    if (!this.members) return;
    console.log(this.members);
    var members = [];
    for (var i = 0; i < this.members.length; i++) {
      await this._userService.details(this.members[i]).toPromise().then((result: any) => {
        members.push({ id: result.id, name: result.name });
      })
    }
    this.selectedMembers = members;
  }

  selectFormHeading() {
    if (!this.threadType) return;
    var threadTypeHeading = CommonMethods.threadTypes.filter((x: any) => x.id == this.threadType);
    if (threadTypeHeading.length > 0) {
      this.formHeading = threadTypeHeading[0].name;
    }
  }

  resetForm() {
    this.itemName = null;
    this.repoterName = null;
    this.discussionForm.reset();

  }

  categories() {
    var data = { communityId: CommonMethods.getCommunityId() };
    this._categoryService.all(data).subscribe((result: any) => {
      for(var i=0;i<result.length;i++){
        result[i].id = result[i].name;
      }
      this.tags = result;
    });
  }

  discussionPrivileges(type: number) {
    if (type == APP_CONST.THREAD_TYPE_DISCUSSION) {
      this.writePrivileges = APP_CONST.DISCUSSION_WRITE;
    } else if (type == APP_CONST.THREAD_TYPE_COMPLAINT) {
      this.writePrivileges = APP_CONST.COMPLAINT_WRITE;
    } else if (type == APP_CONST.THREAD_TYPE_NOTICE) {
      this.writePrivileges = APP_CONST.NOTICE_WRITE;
    } else if (type == APP_CONST.THREAD_TYPE_RULES) {
      this.writePrivileges = APP_CONST.GUIDELINE_WRITE;
    }
  }

  redirectThread(type:number){
    if(type==APP_CONST.THREAD_TYPE_DISCUSSION){
      return "discussions";
    }else if(type==APP_CONST.THREAD_TYPE_COMPLAINT){
      return "complaints";
    }else if(type==APP_CONST.THREAD_TYPE_NOTICE){
      return "notices";      
    }else if(type==APP_CONST.THREAD_TYPE_RULES){
      return "rules";      
    }
  }

}
